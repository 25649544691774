import React from 'react';
import ArtLogo from '../../../assets/images/static/Artnstock Logo Magazine Logo.svg';
import leftMagzineImg from '../../../assets/images/static/MagineLeftImage.jpg';
import magzineArtImg from '../../../assets/images/static/MagazineArt.jpg';

import magzineProducts from '../../../assets/images/static/MagazineProducts.jpg';

import magzineHeading from '../../../assets/images/static/MagzineHeader.svg';
import magzineBottomArt from '../../../assets/images/static/magzineBottomArt.jpg';
import { useNavigate } from 'react-router-dom';

const Magzine = ({ type }) => {
  return (
    <div className='pt-[96px]'>
      <img className='mx-auto' src={ArtLogo} alt='' />
      <p className='text-primaryGray text-sm14 mt-2 mb-6 text-center'>
        Our periodical publication on Artnstock Magazine shall keep
        you on tap with our refurbished stock and information <br />
        for your visual spark.
      </p>

      <div className='flex flex-wrap p-[30px] gap-[30px] shadow-regCardShadow max-w-[1168px] w-[100%] mx-auto rounded-[32px]'>
        <div className=''>
          <img
            className='rounded-[16px]'
            src={
              type === 'home'
                ? leftMagzineImg
                : type === 'art'
                ? magzineArtImg
                : magzineProducts
            }
            alt=''
          />
        </div>

        <div className='max-w-[530px] w-[100%]'>
          <div>
            <img src={magzineHeading} alt='' />
            <p className='mt-[8px] mb-[20px] text-primaryGray text-sm12 text-left leading-[15px]'>
              Artnstock is the finest site to purchase high-quality
              stock photographs of <br />
              any subject or genre. For those who require high-quality
              images at reasonable prices, <br />
              our photography collection will expand the possibilities
              for your visual projects.
            </p>
            <button
              onClick={() =>
                window.open('https://www.google.com', '_blank')
              }
              className='blackBtn '
            >
              Go to Artnstock Magazine
            </button>
            <img
              className='mt-[90px]'
              src={magzineBottomArt}
              alt=''
            />
            <p className='text-sm11 text-primaryGray mt-2.5'>
              All artwork and designs used in this magazine remain the
              property of <br />
              Artnstock Private Limited.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Magzine;
