import { useState, useEffect } from 'react';
import { ReactComponent as InfoSvg } from '../../../assets/images/contributor/fileManager/iIconSmall.svg';
// import infofile from '../../../assets/images/contributor/infofile.png';
import { ReactComponent as MoveToSvg } from '../../../assets/images/contributor/fileManager/moveToIconSmall.svg';
import { ReactComponent as EditContent } from '../../../assets/images/contributor/fileManager/editIconSmall.svg';
import { ReactComponent as ProductsSvg } from '../../../assets/images/contributor/fileManager/Icon_Products.svg';

import { ReactComponent as ShareSvg } from '../../../assets/images/contributor/fileManager/shareIconSmall.svg';

import { ReactComponent as DeleteSvg } from '../../../assets/images/contributor/fileManager/deleteIconSmall.svg';

import { ReactComponent as Exclusive_Icon16 } from '../../../assets/images/contributor/add_details/Icon_Exclusive_16x16.svg';
import { ReactComponent as Featured_Icon16 } from '../../../assets/images/contributor/add_details/Icon_Featured_16x16.svg';
// import sharefile from '../../../assets/images/contributor/sharefile.png';
// import deletefile from '../../../assets/images/contributor/deletefile.png';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedAllFilesImages } from '../../../store/fileImageSlice';

const FolderFiles = ({ image }) => {
  // hover
  const [hovered, setHovered] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  const dispatch = useDispatch();

  const selectedAllFilesImages = useSelector(
    (state) => state.fileimages.selectedAllFilesImages
  );

  // useEffect(() => {
  //   console.log('Contri ', selectedAllFilesImages);
  // }, [selectedAllFilesImages]);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);

    if (!isChecked) {
      dispatch(
        setSelectedAllFilesImages([image])
        // setSelectedAllFilesImages([...selectedAllFilesImages, image])
      );
    } else {
      dispatch(
        setSelectedAllFilesImages(
          selectedAllFilesImages.filter(
            (img) => img.artId !== image.artId
          )
        )
      );
    }
  };

  useEffect(() => {
    dispatch(setSelectedAllFilesImages([]));
  }, []);

  return (
    <>
      <div className='flex flex-col text-center'>
        <div
          onMouseEnter={() => setHovered(image.artId)}
          onMouseLeave={() => setHovered(null)}
          className='w-[181px] h-[181px] rounded-[16px] inset-0 relative'
        >
          <div
            className={`h-[100%] w-[100%] bg-no-repeat bg-center bg-cover filter brightness-100 absolute rounded-[16px] overflow-hidden ${
              hovered === image.artId ? 'brightness-[70%]' : ''
            }`}
            style={{
              backgroundImage: `url("${image.imageMaster?.secureUrl}")`,
            }}
          ></div>
          {selectedAllFilesImages.find(
            (img) => img.artId === image.artId
          ) || hovered ? (
            <div className='absolute inset-0 flex items-center justify-center'>
              <button
                className='imageHoverButtonSmall'
                // onClick={handleButtonClick}
              >
                Set Cover
              </button>
            </div>
          ) : (
            <div></div>
          )}
          {selectedAllFilesImages.find(
            (img) => img.artId === image.artId
          ) || hovered ? (
            <div className='absolute top-0 right-0 p-2'>
              <label className='containerCheckWhite p-2'>
                <input
                  type='checkbox'
                  checked={
                    selectedAllFilesImages.length &&
                    selectedAllFilesImages[0].artId == image.artId
                  }
                  // checked={isChecked}
                  onChange={handleCheckboxChange}
                />{' '}
                {/* {obj.width}cm x {obj.height}cm */}
                <span className='checkmarkWhite'></span>
              </label>
            </div>
          ) : (
            <div></div>
          )}

          {/* 3buttons */}
          {selectedAllFilesImages.find(
            (img) => img.artId === image.artId
          ) || hovered ? (
            <>
              <div className='absolute bottom-0 left-0 p-[10px] flex items-center justify-center gap-[8px]'>
                <InfoSvg />
                <MoveToSvg />
                <EditContent />
                <ProductsSvg />
                <ShareSvg />
                <DeleteSvg />
              </div>
              <div className='absolute top-0 left-0 p-[10px] flex items-center justify-center gap-[8px]'>
                <Exclusive_Icon16 className='fill-[#333333]' />
                <Featured_Icon16 className='fill-[#333333]' />
              </div>
            </>
          ) : (
            <div></div>
          )}

          {/* 3buttons */}
        </div>
        <p className='text-sm12 text-primaryBlack font-medium pt-[7px]'>
          {image.name}
        </p>
        <p className='text-sm11 text-primaryGray mt-[2px]'>
          {/* Image ID: {image.artId.substring(0, 10)} */}Art ID:
          ANS9876543210
        </p>
        <p className='text-sm11 text-primaryGray'>
          {/* Uploaded: {image.submittedDate} */}
          {/* Uploaded: {image.submittedDate?.substring(0, 10)} */}
          Uploaded: Jan 4, 2022
        </p>
      </div>
    </>
  );
};

export default FolderFiles;
