import selectall from '../../../assets/images/contributor/selectall.png';
import deleteicon from '../../../assets/images/contributor/delete-icon.png';
import undo from '../../../assets/images/contributor/undo.png';
import redo from '../../../assets/images/contributor/redo.png';
import save from '../../../assets/images/contributor/save.png';
import keyword from '../../../assets/images/contributor/keyword.png';

import artcolor from '../../../assets/images/contributor/artcolor.png';
import shirtcolor from '../../../assets/images/contributor/shirtcolor.png';
import mugcolor from '../../../assets/images/contributor/mugcolor.png';
import glasscolor from '../../../assets/images/contributor/glasscolor.png';
import mousecolor from '../../../assets/images/contributor/mousecolor.png';
import coastercolor from '../../../assets/images/contributor/coastercolor.png';
import flowercolor from '../../../assets/images/contributor/flowercolor.png';
import giftcolor from '../../../assets/images/contributor/giftcolor.png';
import bagcolor from '../../../assets/images/contributor/bagcolor.png';
import notepadcolor from '../../../assets/images/contributor/notepadcolor.png';
import shirtfront from '../../../assets/images/contributor/shirtfront.png';
import shirtback from '../../../assets/images/contributor/shirtback.png';
import info from '../../../assets/images/contributor/info.png';
import scale from '../../../assets/images/contributor/scale.png';
import dropdown from '../../../assets/images/socials/dropdown.png';

import frree from '../../../assets/images/combo/free.png';

import { ReactComponent as Shirt } from '../../../assets/images/contributor/shirt.svg';

import { ReactComponent as IIcon } from '../../../assets/images/Icons/iIcon.svg';

import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Canvass from './Canvass';
import Slider from '@mui/material/Slider';
import { httpClient } from '../../../axios';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setpath2 } from '../../../store/contriPathSlice';
import trial from '../../../assets/images/combo/trial.png';
import html2canvas from 'html2canvas';

import CheckIcon from '@mui/icons-material/Check';
import ProductDetails from '../../../pages/product/productDetails/ProductDetails';
import { object } from 'yup';

import SliderButton from '../../../assets/images/artList/sliderButton1.svg';
import { useDetectClickOutside } from 'react-detect-click-outside';

import { ReactComponent as Icon_FullSleevesTShirt } from '../../../assets/images/art-details/iconsProducts/shirtIconsProd/icons/Icon_FullSleevesTShirt.svg';
import { ReactComponent as Icon_HalfSleevesTShirt } from '../../../assets/images/art-details/iconsProducts/shirtIconsProd/icons/Icon_HalfSleevesTShirt.svg';
import { ReactComponent as Icon_SleevelessTShirt } from '../../../assets/images/art-details/iconsProducts/shirtIconsProd/icons/Icon_SleevelessTShirt.svg';
import { ReactComponent as Icon_PrintOnBack } from '../../../assets/images/art-details/iconsProducts/shirtIconsProd/icons/Icon_PrintOnBack.svg';
import { ReactComponent as Icon_PrintOnFront } from '../../../assets/images/art-details/iconsProducts/shirtIconsProd/icons/Icon_PrintOnFront.svg';

import { ReactComponent as SelectAll } from '../../../assets/images/contributor/svg/Icon_SelectAll.svg';
import { ReactComponent as Deleteicon } from '../../../assets/images/contributor/svg/Icon_DeleteFiles.svg';
import { ReactComponent as Save } from '../../../assets/images/contributor/svg/Icon_SaveDraft.svg';
import { ReactComponent as Keyword } from '../../../assets/images/contributor/svg/Icon_KeywordManager.svg';

import alignHori from '../../../assets/images/contributor/activateProd/alignHori.jpg';
import alignVert from '../../../assets/images/contributor/activateProd/alignVert.jpg';
// import Recycle from '../../../assets/images/contributor/activateProd/recycle.jpg';

import { ReactComponent as AlignHori } from '../../../assets/images/contributor/activateProd/Icon_HorAlign.svg';
import { ReactComponent as AlignVert } from '../../../assets/images/contributor/activateProd/Icon_VertAlign.svg';
import { ReactComponent as Recycle } from '../../../assets/images/contributor/activateProd/Icon_Reset.svg';

// color Icons
import { ReactComponent as Icon_Backpacks } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_Backpacks.svg';
import { ReactComponent as Icon_Bandanas } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_Bandanas.svg';
import { ReactComponent as Icon_BaseballCaps } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_BaseballCaps.svg';
import { ReactComponent as Icon_BucketHats } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_BucketHats.svg';
import { ReactComponent as Icon_ButtonBadges } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_ButtonBadges.svg';
import { ReactComponent as Icon_CarryAllPouches } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_CarryAllPouches.svg';
import { ReactComponent as Icon_DrawstringBags } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_DrawstringBags.svg';
import { ReactComponent as Icon_DuffleBags } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_DuffleBags.svg';
import { ReactComponent as Icon_FannyPacks } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_FannyPacks.svg';
import { ReactComponent as Icon_Footwear } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_Footwear.svg';
import { ReactComponent as Icon_GiftPouches } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_GiftPouches.svg';
import { ReactComponent as Icon_Hoodies } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_Hoodies.svg';
import { ReactComponent as Icon_Scarfs } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_Scarfs.svg';
import { ReactComponent as Icon_Shorts } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_Shorts.svg';
import { ReactComponent as Icon_SunglassPouches } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_SunglassPouches.svg';
import { ReactComponent as Icon_ToteBags } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_ToteBags.svg';
import { ReactComponent as Icon_TShirts } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_TShirts.svg';
import { ReactComponent as Icon_Wristbands } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_WristBands.svg';

import { ReactComponent as Icon_Checkmark } from '../../../assets/images/contributor/activateProd/Icon_Checkmark.svg';

import { ReactComponent as DropArrow } from '../../../assets/images/Icons/Down arrow.svg';

import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

const styleDrop = [
  { a: `Men's T-Shirt` },
  { a: `Women's T-Shirt` },
  { a: `Kid's T-Shirt` },
];

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 48,
  height: 24,
  padding: 0,

  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      top: '1px',
      left: '1px',
      width: 25,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
      right: '1px',
      top: '1px',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    top: '1px',
    left: '1px',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      right: '1px',
      top: '1px',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor:
          theme.palette.mode === 'dark' ? '#8e8e8e' : '#8e8e8e',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 18,
    height: 18,
    top: '1px',
    borderRadius: 100,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 24 / 2,
    opacity: 1,
    top: '1px',
    backgroundColor:
      theme.palette.mode === 'dark' ? '#8e8e8e' : '#8e8e8e',
    // backgroundColor:
    //   theme.palette.mode === 'dark'
    //     ? 'rgba(255,255,255,.35)'
    //     : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const ActivateProducts = () => {
  const [hoveredId, setHoveredId] = useState(null);
  const [checkedId, setCheckedId] = useState(null);
  const [value, setValue] = useState('2.1');

  const [alignVerticalFocus, setAlignVerticalFocus] = useState(true);

  const [checked, setChecked] = useState([]); //Store ID temporary
  const [shirt, setShirt] = useState('#fff'); //Store ID temporary
  const [photo, setPhoto] = useState(''); //Store ID temporary

  // dropdown
  const [isAllStyleOpen, setIsAllStyleOpen] = useState(false);

  const dispatch = useDispatch();

  const userId = useSelector((state) => state.auth.userId);

  const [circle, setCircle] = useState([
    {
      id: 'icon1',
      backgroundColor: '#304f71',
      borderColor: '#304f71',
      textColor: '#FFFFFF',
      tick: true,
    },
    {
      id: 'icon2',
      backgroundColor: '#9cafdb',
      borderColor: '#9cafdb',
      textColor: '#FFFFFF',
      tick: true,
    },
    {
      id: 'icon3',
      backgroundColor: '#05a9f0',
      borderColor: '#05a9f0',
      textColor: '#FFFFFF',
      tick: true,
    },
    {
      id: 'icon4',
      backgroundColor: '#9ad15c',
      borderColor: '#9ad15c',
      textColor: '#FFFFFF',
      tick: true,
    },
    {
      id: 'icon5',
      backgroundColor: '#fdc506',
      borderColor: '#fdc506',
      textColor: 'white',
      tick: true,
    },
    {
      id: 'icon6',
      backgroundColor: '#f18c15',
      borderColor: '#f18c15',
      textColor: 'white',
      tick: true,
    },
    {
      id: 'icon7',
      backgroundColor: '#f884bb',
      borderColor: '#f884bb',
      textColor: 'black',
      tick: true,
    },
    {
      id: 'icon8',
      backgroundColor: '#de4492',
      borderColor: '#de4492',
      textColor: 'black',
      tick: true,
    },
    {
      id: 'icon9',
      backgroundColor: '#dddddd',
      borderColor: '#dddddd',
      textColor: 'black',
      tick: true,
    },
    {
      id: 'icon10',
      backgroundColor: '#010101',
      borderColor: '#010101',
      textColor: 'black',
      tick: true,
    },
    {
      id: 'icon11',
      backgroundColor: '#ffffff',
      borderColor: '#D6D6D6',
      textColor: 'black',
      tick: false,
    },
  ]);

  const toggleColorTick = (id) => {
    const newArray = circle.map((obj) => {
      if (obj.id === id && obj.tick === true) {
        return { ...obj, tick: false };
      } else {
        return { ...obj, tick: true };
      }
    });

    setCircle(newArray);
  };

  useEffect(() => {
    dispatch(setpath2('/ Activate Products'));
  }, []);

  const check = async (item) => {
    // this is old
    // setShirt(item.backgroundColor);

    const find = checked?.find((obj) => obj.color === item.color);

    if (!find) {
      // test
      const element = document.querySelector('.myDiv'); // Replace '.myDiv' with the appropriate selector for your div element
      const button = element.querySelector('.greenBlueButton'); // Replace '.greenBlueButton' with the appropriate selector for your button element
      setDottedLine(true); //hide dotted line
      button.style.display = 'none'; // Hide the button before taking the screenshot
      setProductImage(item.frontImage);
      // Delay the screenshot function until the next tick of the event loop
      setTimeout(() => {
        html2canvas(element, { useCORS: true }).then(
          async (canvas) => {
            button.style.display = 'block'; // Show the button again after the download link is clicked
            setDottedLine(false); // Show the dotted line again after the download link is clicked
            setProductImage(
              productData?.productDetails[0]?.frontImage // set the default product image again after screenshot of canvas
            );
            const dataUrl = canvas.toDataURL('image/png');

            let formData = new FormData();
            formData.append('file', dataURItoBlob(dataUrl));

            const res = await httpClient.post(
              '/CloudinaryImageUpload?parameter=false',
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
            );

            const find = checked?.find(
              (obj) => obj.color === item.color
            );

            if (find === undefined) {
              try {
                // const mergedObj = { ...item, image: res.data };

                // let images = []
                const mergedObj = {
                  color: item.color,
                  colorCode: item.hexCode,
                  image: res.data,
                };

                setChecked((prev) => [...prev, mergedObj]);
              } catch (error) {
                console.error(
                  'Error occurred during download:',
                  error
                );
              }
            } else if (find !== undefined) {
              setChecked(
                checked.filter((obj) => obj.color !== item.color)
              );
            }
            // old code
            // setShirt('#ffffff');!
          }
        );
      }, 0);
      // test
    } else if (find) {
      setChecked(checked.filter((obj) => obj.color !== item.color));
    }

    // setChecked(item);
    // setShirt(item.backgroundColor);
  };

  const [productData, setproductData] = useState(null);
  const [productImage, setProductImage] = useState('');

  // useEffect(() => {
  //   console.log(checked);
  // }, [checked]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMouseEnter = (id) => {
    setHoveredId(id);
  };

  const handleMouseLeave = () => {
    setHoveredId(null);
  };

  const [artName, setartName] = useState(null);

  const handleClick = (card) => {
    if (card.imageId === checkedId) {
      setCheckedId(null);
      setPhoto(null);
      setartName(null);
    } else {
      setCheckedId(card.imageId);
      setPhoto(card.imageOrientation?.verticalUrl);
      setartName(card.artName);
    }
  };

  const [sizeRangeValue, setSizeRangeValue] = useState(50);

  const sizeRange = (event) => {
    const value = event.target.value;
    if (value !== 0) {
      setSizeRangeValue(value);
      // console.log(value);
    }
  };

  // this code is just for triggering align buttons
  const [vertcalAlign, setVerticalAlign] = useState(null);

  const verticalAlign = () => {
    if (vertcalAlign === null) {
      setVerticalAlign(true);
    } else if (vertcalAlign === true || vertcalAlign === false) {
      setVerticalAlign(!vertcalAlign);
    }
    setAlignVerticalFocus(true);
  };

  const [horzontalAlign, setHorizontalAlign] = useState(null);
  const horizontalAlign = () => {
    if (horzontalAlign === null) {
      setHorizontalAlign(true);
    } else if (horzontalAlign === true || horzontalAlign === false) {
      setHorizontalAlign(!horzontalAlign);
    }
    setAlignVerticalFocus(false);
  };

  // state to hide dotted line around canvas
  const [dottedLine, setDottedLine] = useState(false);

  const [XYCanvas, setXYCanvas] = useState({});

  const handleDataFromChild = (data) => {
    setXYCanvas(data);
  };

  const onSave = async () => {
    try {
      let artProduct = {
        artId: checkedId,
        artProductName: artName,
        canvasSize: sizeRangeValue,
        canvasX: XYCanvas.canvasX,
        canvasY: XYCanvas.canvasY,
        images: checked,
        productId: productData.productId,
        // productSubCategoryId:
        //   productData.productSubCategoryMaster.productSubCategoryId,
        userId: userId,
      };

      const res = await httpClient.post(
        '/art_product_master/create',
        artProduct
      );
      console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  // Helper function to convert data URI to Blob
  function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI
      .split(',')[0]
      .split(':')[1]
      .split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  const getActiveProduct = async () => {
    const res = await httpClient.get(
      '/product_master/getActiveProductMaster'
    );

    let abc = res.data;
    let a;

    abc.forEach((product) =>
      product.productName === 'shirt' ? (a = product) : ''
    );

    setproductData(a);
    setProductImage(a.productDetails[0].frontImage);

    console.log(a);
  };

  const [artList, setartList] = useState(null);

  const getUserIdWiseArts = async () => {
    try {
      const res = await httpClient.get(
        `/draft_master/getDraftMasterByStatusAndActiveStatus/adddetails/true/${userId}`
      );
      setartList(res.data.imageMaster);
      handleClick(res.data.imageMaster[0]);
      console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  };
  // const getUserIdWiseArts = async () => {
  //   try {
  //     const res = await httpClient.get(
  //       `/art_master/getUserIdAndStatusWiseUserMaster/${userId}/Approved`
  //     );
  //     setartList(res.data);

  //     handleClick(res.data[0]);
  //     console.log(res.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  useEffect(() => {
    console.log(checked);
  }, [checked]);

  const [nestedLifeStyle, setNestedLifeStyle] = useState('tshirt');

  const [MarkUpIButton, setMarkUpIButton] = useState(false);

  useEffect(() => {
    getUserIdWiseArts();
    getActiveProduct();
  }, []);

  const handleClickOutsideCate = () => {
    setIsAllStyleOpen(false);
  };

  const CateClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideCate,
  });

  const handleClickOutsideFab = () => {
    setisFabricDropOpen(false);
  };

  const FabClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideFab,
  });

  const [isFabricDropOpen, setisFabricDropOpen] = useState(false);

  const [halfSleevesHover, setHalfSleevesHover] = useState(false);
  const [fullSleevesHover, setFullSleevesHover] = useState(false);
  const [sleevelessHover, setSleevelessHover] = useState(false);
  const [printOnFrontHover, setPrintOnFrontHover] = useState(false);
  const [printOnBackHover, setPrintOnBackHover] = useState(false);

  const [ActivateProdTabsValue, setActivateProdTabsValue] = useState(
    'Apparel & Accessories'
  );

  const grayBox = ['a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a'];

  const [grayNum, setgrayNum] = useState(grayBox?.length);

  useEffect(() => {
    if (artList?.length < 11) {
      setgrayNum(grayBox?.length - artList?.length);
    }

    console.log(artList?.length);
  }, [artList]);

  const [HoriHover, setHoriHover] = useState(false);
  const [VertHover, setVertHover] = useState(false);

  return (
    <div className='font-heebo'>
      <div className='flex justify-center mb-[16px]'>
        <div
          style={{
            width: 'fit-content',
          }}
          className='flex border-t border-b border-t-[#efefef] border-b-[#efefef]'
        >
          <div className='flex items-center gap-[5px]'>
            <span className='contributorUploadCount text-pinkColor'>
              13
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='contributorUploadtext pr-[8px]'>
                File(s) to be
                <br />
                submitted to
                <br />
                Artnstock
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span className='contributorUploadCount text-[#f5721a] opacity-[20%] pl-[5px]'>
              0
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                File(s)
                <br />
                pending for
                <br />
                review
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span className=' contributorUploadCount text-[#a9af20] opacity-[20%]  pl-[5px]'>
              0
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                File(s)
                <br />
                approved by
                <br />
                Artnstock
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span className='contributorUploadCount text-[#e7233b] opacity-[20%]  pl-[5px]'>
              0
            </span>
            <div className='opacity-[40%] h-[40px] flex items-center'>
              <p className='contributorUploadtext'>
                File(s)
                <br />
                rejected by
                <br />
                Artnstock
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='contributorTitleStyling'>
        <div className='w-[1168px] flex justify-between'>
          <div className='flex flex-col gap-[2px] max-w-[320px] w-[100%]'>
            <div className='flex gap-[8px] items-baseline'>
              <p className='text-[25px] font-medium leading-[1]  text-primaryBlack'>
                Activate Products
              </p>
              <p className='text-pinkColor text-[11px] leading-[13px] '>
                {checkedId === null ? <span>0</span> : <span>1</span>}
                /1 File(s) selected
              </p>
            </div>
            <p className='text-[11px] text-primaryGray leading-[14px]'>
              Select thumbnail(s) to apply on your Products, and
              <br /> set your markup.
              <span className='text-orangeColor'> Learn more</span>
            </p>
          </div>

          <div className='flex'>
            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef]'>
              <div className='flex flex-col  mt-[1px] gap-[8px] w-[52px] text-center opacity-[40%]'>
                {/* <img
                  src={selectall}
                  alt=''
                  className='w-[16px] h-[16px] mx-[auto] bg-[#d7d7d7]'
                /> */}
                <div>
                  <SelectAll className='mx-[auto]' />
                </div>
                <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                  Select <br /> All
                </p>
              </div>
            </div>

            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef]'>
              <div className='flex flex-col  mt-[1px] opacity-[40%]  gap-[8px] w-[52px] text-center'>
                {/* <img
                  src={deleteicon}
                  alt=''
                  className='w-[16px] h-[16px] mx-[auto] '
                /> */}
                <div>
                  <Deleteicon className='mx-[auto]' />
                </div>
                <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                  Delete Image
                </p>
              </div>
            </div>

            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef] border-r-[2px]'>
              <div className='flex flex-col  mt-[1px] opacity-[40%]  gap-[8px] w-[52px] text-center '>
                {/* <img
                  src={save}
                  alt=''
                  className='w-[16px] h-[16px] mx-[auto]'
                /> */}
                <div>
                  <Save className='mx-[auto]' />
                </div>
                <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                  Save <br /> Draft
                </p>
              </div>
            </div>

            <div className='contributorUploadIconsMainDiv '>
              <div className='flex flex-col  mt-[1px]  gap-[8px] w-[52px] text-center '>
                <div>
                  <Keyword className='mx-[auto]' />
                </div>
                <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                  Keyword Manager
                </p>
              </div>
            </div>
          </div>
          <div className='pl-[206px] leading-[1] '>
            <p className='text-[11px] text-primaryGray leading-[14px]'>
              Files uploaded this week
            </p>
            <p className='text-[25px] leading-[0.9] text-pinkColor  font-medium'>
              84/250
              <span className='text-[11px] font-normal ml-[2px]'>
                File(s)
              </span>
            </p>
            <button className='bg-[#888888] text-[white] text-[10px]  font-normal leading-[1] flex items-center rounded-[20px] px-[8px] h-[17px]'>
              Files Upload Summary
            </button>
          </div>
        </div>
      </div>

      <div className='w-[100%] flex justify-center pb-[28px]'>
        <div className='w-w1170 justify-center flex-column gap-[10px]'>
          <div className='flex justify-start flex-wrap gap-[12px]'>
            {/* px-[16.68px] */}
            {artList?.map((card) => (
              <div
                key={card.imageId}
                className='h-[106px] w-[106px] rounded-[16px] relative'
                onMouseEnter={() => handleMouseEnter(card.imageId)}
                onMouseLeave={() => handleMouseLeave()}
              >
                <div
                  className={`rounded-[16px] h-full w-full bg-no-repeat bg-center bg-cover filter brightness-[${
                    hoveredId === card.imageId ? '70%' : '100%'
                  }] absolute overflow-hidden inset-0`}
                  style={{
                    backgroundImage: `url(${card?.secureUrl})`,
                  }}
                ></div>

                {hoveredId === card.imageId ||
                checkedId === card.imageId ? (
                  <>
                    <div className='absolute inset-0 flex items-center justify-center'>
                      <button
                        onClick={() => handleClick(card)}
                        className='imageHoverButtonSmall'
                      >
                        {' '}
                        {checkedId === card.imageId ? (
                          <span>Deselect</span>
                        ) : (
                          <span>Select</span>
                        )}
                      </button>{' '}
                    </div>

                    <label className='containerCheckWhite p-2'>
                      <input
                        type='checkbox'
                        checked={checkedId === card.imageId}
                        onChange={() => handleClick(card)}
                      />{' '}
                      {/* {obj.width}cm x {obj.height}cm */}
                      <span className='checkmarkWhite'></span>
                    </label>
                    {/* <div className='absolute top-0 right-0 p-2'>
                      <input
                        type='checkbox'
                        className='w-6 h-6'
                        checked={checkedId === card.artId}
                        onChange={() => handleClick(card)}
                      />
                    </div> */}
                  </>
                ) : (
                  <div></div>
                )}
              </div>
            ))}

            {/* test */}
            {grayBox.slice(0, grayNum).map((card, index) => (
              <div
                className={
                  'h-[106px] w-[106px] bg-[#f7f7f7] rounded-[16px]'
                }
              ></div>
            ))}
            {/* test */}
          </div>
        </div>
      </div>

      {/* <TabContext value={value}> */}
      <div className='flex justify-center gap-[4px] mb-[8px]'>
        <div
          onClick={() => {
            // dispatch(setpath2('/ Earning Summary'));
            // dispatch(
            //   setNestedTabValueEarnings('/ Earning Summary')
            // );
            setActivateProdTabsValue('Apparel & Accessories');
          }}
          className={`${
            ActivateProdTabsValue === 'Apparel & Accessories'
              ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
              : 'border-[#eaeaea] text-primaryGray font-medium'
          }  border rounded-[14px] cursor-pointer h-[28px] px-[12px] hover:text-primaryBlack hover:border-[#e6e6e6] hover:bg-[#e6e6e6] justify-center flex items-center text-[12px]`}
        >
          Apparel & Accessories
        </div>

        <div
          onClick={() => {
            // dispatch(setpath2('/ Referral Earnings'));
            // dispatch(
            //   setNestedTabValueEarnings('/ Referral Earnings')
            // );
            setActivateProdTabsValue('Home & Living');
          }}
          className={`${
            ActivateProdTabsValue === 'Home & Living'
              ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
              : 'border-[#eaeaea] text-primaryGray font-medium'
          }  border rounded-[14px] h-[28px]  cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
        >
          Home & Living
        </div>

        <div
          onClick={() => {
            // dispatch(setpath2('/ Payment History'));
            // dispatch(
            //   setNestedTabValueEarnings('/ Payment History')
            // );
            setActivateProdTabsValue('Tech');
          }}
          className={`${
            ActivateProdTabsValue === 'Tech'
              ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
              : 'border-[#eaeaea] text-primaryGray font-medium'
          }  border rounded-[14px] h-[28px]  cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
        >
          Tech
        </div>

        <div
          onClick={() => {
            setActivateProdTabsValue('Stationary');
          }}
          className={`${
            ActivateProdTabsValue === 'Stationary'
              ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
              : 'border-[#eaeaea] text-primaryGray font-medium'
          }  border rounded-[14px] h-[28px] cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
        >
          Stationary
        </div>
      </div>

      {ActivateProdTabsValue === 'Apparel & Accessories' ? (
        <>
          <div className='w-[100%] flex justify-center'>
            <div className='flex pt-[5px]'>
              <div
                onClick={() => setNestedLifeStyle('tshirt')}
                className='flex-col w-[56px] '
              >
                <div>
                  <Icon_TShirts className='mx-[auto]' />
                </div>
                {/* <img className='mx-auto' src={notepadcolor} alt='' /> */}
                <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                  T-Shirts
                </p>
              </div>
              <div className='flex-col w-[56px] '>
                <div>
                  <Icon_Hoodies className='mx-[auto]' />
                </div>
                {/* <img className='mx-auto' src={notepadcolor} alt='' /> */}
                <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                  Hoodies
                </p>
              </div>
              <div className='flex-col w-[56px] '>
                <div>
                  <Icon_GiftPouches className='mx-[auto]' />
                </div>
                {/* <img className='mx-auto' src={notepadcolor} alt='' /> */}
                <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                  Gift <br /> Pouches
                </p>
              </div>
              <div className='flex-col w-[56px]'>
                <div>
                  <Icon_Bandanas className='mx-[auto]' />
                </div>
                {/* <img className='mx-auto' src={glasscolor} alt='' /> */}
                <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                  Bandanas
                </p>
              </div>
              <div className='flex-col w-[56px] '>
                <div>
                  <Icon_ToteBags className='mx-[auto]' />
                </div>
                {/* <img className='mx-auto' src={notepadcolor} alt='' /> */}
                <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                  Tote
                  <br />
                  Bags
                </p>
              </div>
              <div className='flex-col w-[56px] '>
                <div>
                  <Icon_DuffleBags className='mx-[auto]' />
                </div>
                {/* <img className='mx-auto' src={notepadcolor} alt='' /> */}
                <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                  Duffle <br />
                  Bags
                </p>
              </div>
              <div className='flex-col w-[56px] '>
                <div>
                  <Icon_Scarfs className='mx-[auto]' />
                </div>
                {/* <img className='mx-auto' src={notepadcolor} alt='' /> */}
                <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                  Scarfs
                </p>
              </div>
              <div className='flex-col w-[56px]'>
                <div>
                  <Icon_CarryAllPouches className='mx-[auto]' />
                </div>
                {/* <img className='mx-auto' src={giftcolor} alt='' /> */}
                <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                  Carry-All
                  <br />
                  Pouches
                </p>
              </div>
              <div className='flex-col w-[56px]'>
                <div>
                  <Icon_BucketHats className='mx-[auto]' />
                </div>
                {/* <img className='mx-auto' src={coastercolor} alt='' /> */}
                <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                  Bucket
                  <br />
                  Hats
                </p>
              </div>
              <div className='flex-col w-[56px]'>
                <div>
                  <Icon_Backpacks className='mx-[auto]' />
                </div>
                {/* <img className='mx-auto' src={mugcolor} alt='' /> */}
                <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                  Backpacks
                </p>
              </div>
              <div className='flex-col w-[56px] '>
                <div>
                  <Icon_FannyPacks className='mx-[auto]' />
                </div>
                {/* <img className='mx-auto' src={notepadcolor} alt='' /> */}
                <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                  Fanny <br /> Packs
                </p>
              </div>
              <div className='flex-col w-[56px] '>
                <div>
                  <Icon_SunglassPouches className='mx-[auto]' />
                </div>
                {/* <img className='mx-auto' src={notepadcolor} alt='' /> */}
                <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                  Sunglass
                  <br />
                  Pouches
                </p>
              </div>
              <div className='flex-col w-[56px]'>
                <div>
                  <Icon_DrawstringBags className='mx-[auto]' />
                </div>
                {/* <img className='mx-auto' src={bagcolor} alt='' /> */}
                <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                  Drawstring
                  <br />
                  Bags
                </p>
              </div>
              <div className='flex-col w-[56px] '>
                <div>
                  <Icon_Footwear className='mx-[auto]' />
                </div>
                {/* <img className='mx-auto' src={notepadcolor} alt='' /> */}
                <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                  Footwear
                </p>
              </div>
              <div className='flex-col w-[56px]'>
                <div>
                  <Icon_BaseballCaps className='mx-[auto]' />
                </div>
                {/* <img className='mx-auto' src={mousecolor} alt='' /> */}
                <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                  Baseball
                  <br />
                  Caps
                </p>
              </div>
              <div className='flex-col w-[56px]'>
                <div>
                  <Icon_ButtonBadges className='mx-[auto]' />
                </div>
                {/* <img className='mx-auto' src={flowercolor} alt='' /> */}
                <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                  Button
                  <br />
                  Badges
                </p>
              </div>

              <div className='flex-col w-[56px] '>
                <div>
                  <Icon_Wristbands className='mx-[auto]' />
                </div>
                {/* <img className='mx-auto' src={notepadcolor} alt='' /> */}
                <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                  Wrist <br />
                  Bands
                </p>
              </div>
              <div className='flex-col w-[56px] '>
                <div>
                  <Icon_Shorts className='mx-[auto]' />
                </div>
                {/* <img className='mx-auto' src={notepadcolor} alt='' /> */}
                <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                  Shorts
                </p>
              </div>
            </div>
          </div>

          {nestedLifeStyle === 'tshirt' ? (
            <>
              <div className='w-full flex justify-center pt-[30px]'>
                <div
                  className='w-w1170 flex p-[30px] rounded-[30px] gap-[30px]'
                  style={{
                    boxShadow: '#f0f0f0 0px 0px 4.3px 4px',
                  }}
                >
                  <div className='w-[50%]'>
                    <div className='myDiv w-[540px] h-[540px] rounded-[16.01px] bg-[#f5f5f7] flex flex-col justify-center  items-center'>
                      {/* <Shirt fill={`${shirt}`} /> */}
                      <img src={productImage} alt='' />
                      <div
                        className={`${
                          dottedLine
                            ? ''
                            : 'border border-dashed border-[#d6d6d6]'
                        } absolute `}
                      >
                        <div>
                          <Canvass
                            sendDataToParent={handleDataFromChild}
                            photo={photo}
                            sizeRangeValue={sizeRangeValue}
                            vertcalAlign={vertcalAlign}
                            horzontalAlign={horzontalAlign}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className='w-full flex justify-between'>
                        <div className='flex gap-[8px] pt-[8px]'>
                          <div
                            className='cursor-pointer'
                            onClick={horizontalAlign}
                          >
                            <svg
                              onMouseEnter={() => setHoriHover(true)}
                              onMouseLeave={() => setHoriHover(false)}
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <rect
                                x='23.5'
                                y='0.5'
                                width='23'
                                height='23'
                                rx='5.5'
                                transform='rotate(90 23.5 0.5)'
                                className={`${
                                  HoriHover
                                    ? 'stroke-[#333333]'
                                    : 'stroke-[#888888]'
                                }`}
                              />
                              <rect
                                x='13'
                                y='3'
                                width='18'
                                height='2'
                                rx='1'
                                transform='rotate(90 13 3)'
                                className={`${
                                  HoriHover
                                    ? 'fill-[#333333]'
                                    : 'fill-[#888888]'
                                }`}
                              />
                              <path
                                fill-rule='evenodd'
                                clip-rule='evenodd'
                                d='M22 12C22 11.4477 21.5522 11 21 11L17.2395 11L18.7214 9.56848C19.0929 9.20972 19.0929 8.62793 18.7214 8.26916C18.35 7.91028 17.7477 7.91028 17.3762 8.26916L14.2021 11.3352C14.0727 11.4602 14 11.6298 14 11.8066L14 12L14 12.1935C14 12.3702 14.0727 12.5398 14.2021 12.6648L17.3762 15.731C17.7477 16.0897 18.35 16.0897 18.7214 15.731C19.0929 15.3721 19.0929 14.7903 18.7214 14.4315L17.2395 13L21 13C21.5522 13 22 12.5523 22 12Z'
                                className={`${
                                  HoriHover
                                    ? 'fill-[#333333]'
                                    : 'fill-[#888888]'
                                }`}
                              />
                              <path
                                fill-rule='evenodd'
                                clip-rule='evenodd'
                                d='M2 12C2 11.4477 2.44775 11 3 11L6.7605 11L5.27856 9.56848C4.9071 9.20972 4.9071 8.62793 5.27856 8.26917C5.65002 7.91028 6.25232 7.91028 6.62378 8.26917L9.79785 11.3352C9.92725 11.4602 10 11.6298 10 11.8066L10 12L10 12.1935C10 12.3702 9.92725 12.5398 9.79785 12.6648L6.62378 15.731C6.25232 16.0897 5.65002 16.0897 5.27856 15.731C4.9071 15.3721 4.9071 14.7903 5.27856 14.4315L6.7605 13L3 13C2.44775 13 2 12.5523 2 12Z'
                                className={`${
                                  HoriHover
                                    ? 'fill-[#333333]'
                                    : 'fill-[#888888]'
                                }`}
                              />
                            </svg>

                            {/* <AlignHori /> */}
                          </div>
                          <div
                            className='cursor-pointer'
                            onClick={verticalAlign}
                          >
                            <svg
                              onMouseEnter={() => setVertHover(true)}
                              onMouseLeave={() => setVertHover(false)}
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <rect
                                x='0.5'
                                y='0.5'
                                width='23'
                                height='23'
                                rx='5.5'
                                className={`${
                                  VertHover
                                    ? 'stroke-[#333333]'
                                    : 'stroke-[#888888]'
                                }`}
                              />
                              <rect
                                x='3'
                                y='11'
                                width='18'
                                height='2'
                                rx='1'
                                className={`${
                                  VertHover
                                    ? 'fill-[#333333]'
                                    : 'fill-[#888888]'
                                }`}
                              />
                              <path
                                fill-rule='evenodd'
                                clip-rule='evenodd'
                                d='M12 2C11.4477 2 11 2.44775 11 3V6.7605L9.56848 5.27856C9.20972 4.9071 8.62793 4.9071 8.26917 5.27856C7.91028 5.65002 7.91028 6.25232 8.26917 6.62378L11.3352 9.79785C11.4602 9.92725 11.6298 10 11.8066 10H12H12.1935C12.3702 10 12.5398 9.92725 12.6648 9.79785L15.731 6.62378C16.0897 6.25232 16.0897 5.65002 15.731 5.27856C15.3721 4.9071 14.7903 4.9071 14.4315 5.27856L13 6.7605V3C13 2.44775 12.5523 2 12 2Z'
                                className={`${
                                  VertHover
                                    ? 'fill-[#333333]'
                                    : 'fill-[#888888]'
                                }`}
                              />
                              <path
                                fill-rule='evenodd'
                                clip-rule='evenodd'
                                d='M12 22C11.4477 22 11 21.5522 11 21V17.2395L9.56848 18.7214C9.20972 19.0929 8.62793 19.0929 8.26917 18.7214C7.91028 18.35 7.91028 17.7477 8.26917 17.3762L11.3352 14.2021C11.4602 14.0727 11.6298 14 11.8066 14H12H12.1935C12.3702 14 12.5398 14.0727 12.6648 14.2021L15.731 17.3762C16.0897 17.7477 16.0897 18.35 15.731 18.7214C15.3721 19.0929 14.7903 19.0929 14.4315 18.7214L13 17.2395V21C13 21.5522 12.5523 22 12 22Z'
                                className={`${
                                  VertHover
                                    ? 'fill-[#333333]'
                                    : 'fill-[#888888]'
                                }`}
                              />
                            </svg>

                            {/* <AlignVert /> */}
                          </div>
                          <div>
                            <Recycle />
                          </div>
                        </div>

                        <div>
                          <Slider
                            onChange={sizeRange}
                            sx={{
                              width: '203px',
                              height: '8px',
                              padding: '0',
                              '& .MuiSlider-rail': {
                                opacity: '50%',
                                background:
                                  'linear-gradient(to right, #fd4b9e, #9794d0, #46d59a, #beed10)',
                              },
                              '& .MuiSlider-track': {
                                opacity: '0',
                              },
                              '& .MuiSlider-thumb': {
                                width: '11px',
                                height: '13px',
                                borderStyle: 'solid',
                                borderRadius: '1px',
                                borderBottomLeftRadius: '21px',
                                borderBottomRightRadius: '21px',
                                backgroundColor: '#888888',
                              },
                              '& .MuiSlider-thumb': {
                                background: 'none',
                                boxShadow: 'none!important',
                                '-webkit-box-shadow':
                                  'none!important',
                                backgroundImage: `url(${SliderButton})`,

                                boxShadow: '0 0 #0000!important',
                              },
                              '& .css-eg0mwd-MuiSlider-thumb:before':
                                {
                                  boxShadow: 'none!important',
                                },
                              '& .MuiSlider-thumb:before': {
                                boxShadow: 'none!important',
                              },
                            }}
                            defaultValue={50}
                            aria-label='Disabled slider'
                          />

                          <img
                            className='relative bottom-[6px]'
                            src={scale}
                            alt=''
                          />
                        </div>

                        <div className='w-[94px]'></div>
                      </div>
                    </div>
                    {/* <div className='w-full flex gap-[5px] justify-center pt-[5px]'>
                      {alignVerticalFocus == true ? (
                        <button
                          onClick={verticalAlign}
                          className='w-[105px] h-[28px] bg-[#bbbbbb] text-[12px] rounded-[14px]'
                        >
                          Align Vertically
                        </button>
                      ) : (
                        <button
                          onClick={verticalAlign}
                          className='w-[105px] h-[28px] bg-[#ffffff] text-[#878787] text-[12px] rounded-[14px] border border-[#eaeaea]'
                        >
                          Align Vertically
                        </button>
                      )}
                      {alignVerticalFocus == true ? (
                        <button
                          onClick={horizontalAlign}
                          className='w-[105px] h-[28px] bg-[#ffffff] text-[#878787] text-[12px] rounded-[14px] border border-[#eaeaea]'
                        >
                          Align Horizontally
                        </button>
                      ) : (
                        <button className='w-[105px] h-[28px] bg-[#bbbbbb] text-[12px] rounded-[14px]'>
                          Align Horizontally
                        </button>
                      )}
                    </div> */}
                    <div>
                      <div className='w-[100%] bg-[#ffffff] rounded-gradient-border pt-[12px] pb-[15px] px-[12px] mt-[30px]'>
                        <div className='flex flex-col h-[100%] w-[100%] bg-[#ffffff] rounded-2xl text-primaryGray text-[12px]'>
                          <p className='text-[#bbbbbb] text-[11px] leading-[14px]'>
                            You have selected
                          </p>

                          <div className='mt-[8px] border-t border-[#EFEFEF] w-[100%] flex '>
                            <div
                              style={{
                                width: 'max-content',
                              }}
                              className='flex flex-col '
                            >
                              <p className='text-primaryGray text-sm12 font-medium leading-4 py-[1px]  border-b border-[#EFEFEF] whitespace-no-wrap'>
                                Category:
                              </p>
                              <p className='text-primaryGray text-sm12 font-medium leading-4  py-[1px]  border-b border-[#EFEFEF] whitespace-no-wrap'>
                                T-Shirt Style:
                              </p>
                              <p className='text-primaryGray text-sm12 font-medium leading-4   py-[1px] border-b border-[#EFEFEF] whitespace-no-wrap'>
                                Colours:
                              </p>
                              <p className='text-primaryGray text-sm12 font-medium leading-4  py-[1px] border-b border-[#EFEFEF] whitespace-no-wrap'>
                                T-Shirt Size:
                              </p>
                              <p
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                                className='text-primaryGray text-sm12 font-medium leading-4  py-[1px] border-b border-[#EFEFEF] whitespace-no-wrap'
                              >
                                Art Print Size:
                              </p>
                            </div>
                            <div
                              // style={{
                              //   width: 'fit-content',
                              // }}
                              className='flex flex-col w-[100%]'
                            >
                              <p className='text-primaryGray text-sm12 w-[100%] pl-[8px]  py-[1px] leading-4  border-b border-[#EFEFEF]'>
                                Men’s T-Shirt, Women’s T-Shirt, Kid’s
                                T-Shirt
                              </p>
                              <p className='text-primaryGray text-sm12  pl-[8px] leading-4  py-[1px]  border-b border-[#EFEFEF]'>
                                Half Sleeve, Full Sleeve
                              </p>
                              <div className='text-primaryGray flex items-center text-sm12  py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                                <div className='flex gap-[4px]'>
                                  <div className='w-[16px] h-[16px] bg-[#335077] rounded-[5000px]'></div>
                                  <div className='w-[16px] h-[16px] bg-[#27d3c9] rounded-[5000px]'></div>
                                  <div className='w-[16px] h-[16px] bg-[#e4d045] rounded-[5000px]'></div>
                                  <div className='w-[16px] h-[16px] bg-[#dddddd] rounded-[5000px]'></div>
                                </div>
                              </div>
                              <p className='text-primaryGray text-sm12  pl-[8px] leading-4  py-[1px] border-b border-[#EFEFEF]'>
                                XS, S, M, L, XL, 2XL, 3XL
                              </p>
                              <p className='text-primaryGray text-sm12  pl-[8px] leading-4  py-[1px] border-b border-[#EFEFEF]'>
                                2400 x 3200 Pixels
                              </p>
                            </div>
                          </div>

                          {/* <div className='text-[11px] leading-[1]'>
                            <div className='flex border-b border-t border-[#efefef]  h-[19px] flex items-center '>
                              <p className='w-[80px] text-primaryGray text-sm12 font-medium leading-4'>
                                Category:
                              </p>
                              <p className=' text-primaryGray text-sm12 leading-4'>
                                Men’s T-Shirt, Women’s T-Shirt, Kid’s
                                T-Shirt
                              </p>
                            </div>
                            <div className='flex border-b border-[#efefef]  h-[19px] flex items-center'>
                              <p className='w-[80px] font-medium text-primaryGray text-sm12 leading-4'>
                                T-Shirt Style:
                              </p>
                              <p className=' text-primaryGray text-sm12 leading-4'>
                                Half Sleeve, Full Sleeve
                              </p>
                            </div>
                            <div className='flex border-b border-[#efefef]  h-[19px] flex items-center'>
                              <p className='w-[80px] font-medium text-primaryGray text-sm12 leading-4'>
                                Colours:
                              </p>
                              <div className='flex gap-[4px]'>
                                <div className='w-[16px] h-[16px] bg-[#335077] rounded-[5000px]'></div>
                                <div className='w-[16px] h-[16px] bg-[#27d3c9] rounded-[5000px]'></div>
                                <div className='w-[16px] h-[16px] bg-[#e4d045] rounded-[5000px]'></div>
                                <div className='w-[16px] h-[16px] bg-[#dddddd] rounded-[5000px]'></div>
                              </div>
                            </div>
                            <div className='flex border-b border-[#efefef]  h-[19px] flex items-center'>
                              <p className='w-[80px] font-medium text-primaryGray text-sm12 leading-4'>
                                T-Shirt Size:
                              </p>
                              <p className=' text-primaryGray text-sm12 leading-4'>
                                XS, S, M, L, XL, 2XL, 3XL
                              </p>
                            </div>
                            <div className='flex border-b border-[#efefef]  h-[19px] flex items-center'>
                              <p className='w-[80px] font-medium text-primaryGray text-sm12 leading-4'>
                                Art Print Size:
                              </p>
                              <p className=' text-primaryGray text-sm12 leading-4'>
                                2400 x 3200 Pixels
                              </p>
                            </div>
                          </div> */}
                        </div>
                      </div>

                      <p className='text-[#bbbbbb] text-[11px] leading-[12px] mt-[15px]'>
                        Product Activation Status
                      </p>

                      <div className='flex mt-[10px]'>
                        <div className='flex flex-col pr-[10px] border-r border-r-[2px] border-r-[#efefef]'>
                          <p className='text-[54px] text-primaryBlack font-light leading-[45px]'>
                            95
                          </p>
                          <p className='text-[11px] leading-[12px] text-primaryGray text-center'>
                            Products
                          </p>
                        </div>
                        <div className='flex flex-col pr-[10px] pl-[10px] border-r border-r-[2px] border-r-[#efefef]'>
                          <p className='text-[54px] text-pinkColor font-thin leading-[45px]'>
                            26
                          </p>
                          <p className='text-[11px] leading-[12px] text-primaryGray text-center'>
                            Activated
                          </p>
                        </div>
                        <div className='flex flex-col  pr-[10px] pl-[10px] border-r border-r-[2px] border-r-[#efefef]'>
                          <p className='text-[54px] text-[#b3b3b3] font-thin leading-[45px]'>
                            24
                          </p>
                          <p className='text-[11px] leading-[12px] text-primaryGray text-center'>
                            De-Activated
                          </p>
                        </div>
                        <div className='flex flex-col  pr-[10px] pl-[10px] '>
                          <p className='text-[54px] text-primaryGray font-thin leading-[45px]'>
                            45
                          </p>
                          <p className='text-[11px] leading-[12px] text-primaryGray text-center'>
                            Remaining
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-[50%]'>
                    <p className='text-[25px] font-medium leading-[29px] text-primaryBlack mb-[7px]'>
                      T-Shirt
                    </p>

                    <div className='flex flex-col'>
                      <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-[8px]'>
                        Select Category
                      </p>
                      {/* <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-[8px]'>
                        Select Category
                      </p> */}

                      <div className='relative h-[40px]'>
                        <div
                          ref={CateClickOutside}
                          className={`${
                            isAllStyleOpen === true
                              ? 'shadow-dropShadow'
                              : ''
                          } absolute rounded-[20px] w-[228px] z-[99] max-h-[260px]`}
                        >
                          <button
                            onClick={() => {
                              setIsAllStyleOpen(!isAllStyleOpen);
                            }}
                            className={`${
                              isAllStyleOpen === true
                                ? 'rounded-t-[20px] border-b border-[#EFEFEF]'
                                : 'border rounded-[20px] border-[#d6d6d6]'
                            } flex items-center justify-between px-[15px]  text-sm14 font-medium cursor-pointer w-[228px] h-[40px] bg-[#FFFFFF]`}
                          >
                            <span className='text-[#bbbbbb]'>
                              Select Category
                            </span>
                            {/* <img
                              className='inline-block'
                              src={dropdown}
                              alt=''
                            /> */}
                            <DropArrow
                              className={`fill-[#757575]  ${
                                isAllStyleOpen ? 'rotate-180' : ''
                              }`}
                            />
                          </button>

                          {isAllStyleOpen && (
                            <ul
                              onClick={handleClickOutsideCate}
                              className='cursor-pointer rounded-b-2xl bg-[#ffffff] overflow w-[228px] text-left text-[13px] text-primaryGray max-h-[220px] overflow-y-auto'
                            >
                              {styleDrop.map((obj) => (
                                <li className='flex h-[40px] items-center px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'>
                                  {obj.a}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className='flex flex-col mt-[22px] '>
                      <div className='flex gap-[4px] items-center mb-[8px]'>
                        <p className='text-primaryBlack text-[15px] font-medium leading-[18px]'>
                          Style & Print Location
                        </p>
                        <div>
                          <IIcon />
                        </div>
                      </div>

                      <div className='flex gap-[16px]'>
                        <div className='flex'>
                          <div className='w-[48px] flex flex-col mr-[6px] cursor-pointer'>
                            <div className='mx-[auto]'>
                              <svg
                                onMouseEnter={() =>
                                  setHalfSleevesHover(true)
                                }
                                onMouseLeave={() =>
                                  setHalfSleevesHover(false)
                                }
                                className='z-[9999]'
                                width='48'
                                height='48'
                                viewBox='0 0 48 48'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  x='0.5'
                                  y='0.5'
                                  width='47'
                                  height='47'
                                  rx='9.5'
                                  stroke={`${
                                    halfSleevesHover
                                      ? '#333333'
                                      : '#BBBBBB'
                                  }`}
                                />
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M36.4615 8.89151C35.5019 8.31625 34.4071 8 33.2883 8H29C28.8006 9.16315 28.1815 10.2128 27.26 10.95C26.3385 11.6872 25.1785 12.0608 24 12C19.445 12 18.971 8.125 18.971 8.125L14.8001 8.03768C13.6259 8.0131 12.473 8.33871 11.4677 8.94583C10.3378 9.62818 9.41092 10.155 8 11L9 19H14V38C14 39.1046 14.8954 40 16 40H32C33.1046 40 34 39.1046 34 38V19H39L40 11C38.684 10.1963 37.6648 9.61281 36.4615 8.89151Z'
                                  fill={`${
                                    halfSleevesHover
                                      ? '#333333'
                                      : '#BBBBBB'
                                  }`}
                                />
                              </svg>
                            </div>
                            <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                              Half
                              <br /> Sleeves
                            </p>
                          </div>
                          <div className='flex flex-col w-[60px] cursor-pointer'>
                            <div className='mx-[auto]'>
                              <svg
                                onMouseEnter={() =>
                                  setFullSleevesHover(true)
                                }
                                onMouseLeave={() =>
                                  setFullSleevesHover(false)
                                }
                                width='48'
                                height='48'
                                viewBox='0 0 48 48'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  x='0.5'
                                  y='0.5'
                                  width='47'
                                  height='47'
                                  rx='9.5'
                                  stroke={`${
                                    fullSleevesHover
                                      ? '#333333'
                                      : '#BBBBBB'
                                  }`}
                                />
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M9 13C9 10.2386 11.2386 8 14 8H19C19 8 19.445 12 24 12C25.1786 12.0608 26.3385 11.6872 27.26 10.95C28.1815 10.2128 28.8006 9.16315 29 8H34C36.7614 8 39 10.2386 39 13V36C39 37.1046 38.1046 38 37 38H34V19H33V38C33 39.1046 32.1046 40 31 40H17C15.8954 40 15 39.1046 15 38V19H14V38H11C9.89543 38 9 37.1046 9 36V13Z'
                                  fill={`${
                                    fullSleevesHover
                                      ? '#333333'
                                      : '#BBBBBB'
                                  }`}
                                />
                              </svg>
                            </div>

                            <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                              Full
                              <br /> Sleeves
                            </p>
                          </div>
                          <div className='flex flex-col w-[60px] cursor-pointer'>
                            <div className='mx-[auto]'>
                              <svg
                                onMouseEnter={() =>
                                  setSleevelessHover(true)
                                }
                                onMouseLeave={() =>
                                  setSleevelessHover(false)
                                }
                                width='48'
                                height='48'
                                viewBox='0 0 48 48'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  x='0.5'
                                  y='0.5'
                                  width='47'
                                  height='47'
                                  rx='9.5'
                                  stroke={`${
                                    sleevelessHover
                                      ? '#333333'
                                      : '#BBBBBB'
                                  }`}
                                />
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M20 8C20 10.2091 21.7909 12 24 12C26.2091 12 28 10.2091 28 8H28.9H29H30H31C31.5523 8 32 8.44772 32 9V15C32 16.6569 33.3431 18 35 18H36V39C36 39.5523 35.5523 40 35 40H24H13C12.4477 40 12 39.5523 12 39V18H13C14.6569 18 16 16.6569 16 15V9C16 8.44772 16.4477 8 17 8H18H19H19.1H20Z'
                                  fill={`${
                                    sleevelessHover
                                      ? '#333333'
                                      : '#BBBBBB'
                                  }`}
                                />
                              </svg>
                            </div>
                            <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                              Sleeveless
                            </p>
                          </div>
                        </div>
                        <div className='flex'>
                          <div className='w-[48px] flex flex-col mr-[6px] cursor-pointer'>
                            <div className='mx-[auto]'>
                              <svg
                                onMouseEnter={() =>
                                  setPrintOnFrontHover(true)
                                }
                                onMouseLeave={() =>
                                  setPrintOnFrontHover(false)
                                }
                                width='48'
                                height='48'
                                viewBox='0 0 48 48'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  x='0.5'
                                  y='0.5'
                                  width='47'
                                  height='47'
                                  rx='9.5'
                                  stroke={`${
                                    printOnFrontHover
                                      ? '#333333'
                                      : '#BBBBBB'
                                  }`}
                                />
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M29 8H33.2883C34.4071 8 35.5018 8.31617 36.4614 8.89144C36.9346 9.1751 37.3793 9.43745 37.8248 9.70025C38.5122 10.1058 39.2015 10.5123 40 11L39 19H34V38C34 39.1046 33.1046 40 32 40H16C14.8954 40 14 39.1046 14 38V19H9L8 11C8.68129 10.592 9.24972 10.2581 9.78264 9.94516C10.3534 9.60993 10.8835 9.29862 11.4679 8.94572C12.4732 8.33859 13.6259 8.0131 14.8001 8.03768L18.971 8.125C18.971 8.125 19.445 12 24 12C25.1785 12.0608 26.3385 11.6872 27.26 10.95C28.1815 10.2128 28.8006 9.16315 29 8ZM27.75 17C28.9927 17 30 18.0074 30 19.25V26.75C30 27.9927 28.9927 29 27.75 29H20.25C19.0074 29 18 27.9927 18 26.75V19.25C18 18.0074 19.0074 17 20.25 17H27.75ZM24 21.6232C23.2582 20.775 22.3417 20.793 21.75 21.6232L19.5 24.7507H26.25L24 21.6232ZM24.7502 21.75H25.3127C25.9028 21.75 26.4586 22.0279 26.8127 22.5L28.5002 24.75L27.0001 24.7501L24.7502 21.75Z'
                                  fill={`${
                                    printOnFrontHover
                                      ? '#333333'
                                      : '#BBBBBB'
                                  }`}
                                />
                              </svg>
                            </div>

                            <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                              Print on
                              <br /> Front
                            </p>
                          </div>

                          <div className='flex flex-col  w-[60px] cursor-pointer'>
                            <div className='mx-[auto]'>
                              <svg
                                width='48'
                                height='48'
                                viewBox='0 0 48 48'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                                onMouseEnter={() =>
                                  setPrintOnBackHover(true)
                                }
                                onMouseLeave={() =>
                                  setPrintOnBackHover(false)
                                }
                              >
                                <rect
                                  x='0.5'
                                  y='0.5'
                                  width='47'
                                  height='47'
                                  rx='9.5'
                                  stroke={`${
                                    printOnBackHover
                                      ? '#333333'
                                      : '#BBBBBB'
                                  }`}
                                />
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M29 8H33.2883C34.4071 8 35.5018 8.31617 36.4614 8.89144C36.9346 9.1751 37.3793 9.43745 37.8248 9.70025C38.5122 10.1058 39.2015 10.5123 40 11L39 19H34V38C34 39.1046 33.1046 40 32 40H16C14.8954 40 14 39.1046 14 38V19H9L8 11C8.68129 10.592 9.24972 10.2581 9.78264 9.94516C10.3534 9.60993 10.8835 9.29862 11.4679 8.94572C12.4732 8.33859 13.6259 8.0131 14.8001 8.03768L18.971 8.125C18.971 8.125 19.445 12 24 12C25.1785 12.0608 26.3385 11.6872 27.26 10.95C28.1815 10.2128 28.8006 9.16315 29 8ZM27.75 17C28.9927 17 30 18.0074 30 19.25V26.75C30 27.9927 28.9927 29 27.75 29H20.25C19.0074 29 18 27.9927 18 26.75V19.25C18 18.0074 19.0074 17 20.25 17H27.75ZM24 21.6232C23.2582 20.775 22.3417 20.793 21.75 21.6232L19.5 24.7507H26.25L24 21.6232ZM24.7502 21.75H25.3127C25.9028 21.75 26.4586 22.0279 26.8127 22.5L28.5002 24.75L27.0001 24.7501L24.7502 21.75Z'
                                  fill={`${
                                    printOnBackHover
                                      ? '#333333'
                                      : '#BBBBBB'
                                  }`}
                                />
                              </svg>
                            </div>
                            <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                              Print on
                              <br /> Back
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className='flex flex-col mt-[21px]'>
                      <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-[8px]'>
                        Fabric Material
                      </p>

                      <div className='relative h-[40px]'>
                        <div
                          ref={FabClickOutside}
                          className={`${
                            isFabricDropOpen === true
                              ? 'shadow-dropShadow'
                              : ''
                          } absolute rounded-[20px] w-[228px] z-[99] max-h-[260px]`}
                        >
                          <button
                            onClick={() => {
                              setisFabricDropOpen(!isFabricDropOpen);
                            }}
                            className={`${
                              isFabricDropOpen === true
                                ? 'rounded-t-[20px] border-b border-[#EFEFEF]'
                                : 'border rounded-[20px] border-[#d6d6d6]'
                            } flex items-center justify-between px-[15px] text-primaryGray text-sm14 font-medium cursor-pointer w-[228px] h-[40px] bg-[#FFFFFF]`}
                          >
                            <span className='text-[#bbbbbb]'>
                              Select Fabric
                            </span>
                            <img
                              className='inline-block'
                              src={dropdown}
                              alt=''
                            />
                          </button>

                          {isFabricDropOpen && (
                            <ul
                              onClick={handleClickOutsideFab}
                              className='cursor-pointer rounded-b-2xl bg-[#ffffff] overflow  w-[228px] text-left text-[13px] text-primaryGray max-h-[220px] overflow-y-auto'
                            >
                              <li className='flex h-[40px] items-center px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'>
                                Cotton Fabric
                              </li>
                            </ul>
                          )}
                        </div>
                      </div>
                    </div> */}

                    <div className='mt-[22px]'>
                      <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-[8px]'>
                        Select Colours
                      </p>
                      <div className='flex gap-[8px]'>
                        {circle.map((item) => (
                          <div
                            key={item.id}
                            className={`w-[30px] h-[30px] rounded-[5000px] border flex justify-center items-center cursor-pointer`}
                            style={{
                              color: `${item.textColor}`,
                              backgroundColor: `${item.backgroundColor}`,
                              borderColor: `${item.borderColor}`,
                            }}
                            // onClick={() => check(item)}
                            // onClick={() => {
                            //   toggleColorTick(item.id);
                            // }}
                          >
                            {/* {item.tick && ( */}
                            <i className={`bi bi-check-lg`}></i>
                            <div>
                              <Icon_Checkmark
                                className={`${
                                  item.tick
                                    ? 'fill-[#ffffff]'
                                    : 'fill-[#8C8C8C]'
                                } `}
                              />
                            </div>
                            {/* )} */}
                          </div>
                        ))}

                        {/* {productData?.productDetails?.map(
                          (item, index) => (
                            <div
                              key={index}
                              className={`w-[32px] h-[32px] rounded-full border flex justify-center items-center cursor-pointer`}
                              style={{
                                color: `${
                                  item.hexCode === '#ffffff'
                                    ? '#000000'
                                    : '#ffffff'
                                }`,
                                backgroundColor: `${item.hexCode}`,
                                borderColor: `${
                                  item.hexCode === '#ffffff'
                                    ? '#000000'
                                    : ''
                                }`,
                              }}
                              onClick={() => check(item)}
                            >
                              {checked?.find(
                                (obj) => obj.color === item.color
                              ) && <CheckIcon />}
                            </div>
                          )
                        )} */}
                      </div>
                    </div>

                    <div className='flex flex-col mt-[22px]'>
                      <div className='flex gap-[5px] items-center mb-[8px]'>
                        <p className='text-primaryBlack text-[15px] font-medium leading-[18px] '>
                          Your Markup
                        </p>
                        <div
                          onMouseEnter={() => setMarkUpIButton(true)}
                          onMouseLeave={() => setMarkUpIButton(false)}
                          className='max-h-full flex items-center cursor-pointer'
                        >
                          <IIcon />
                        </div>
                        {MarkUpIButton && (
                          <p className='text-[12px] text-primaryGray '>
                            Need help?
                          </p>
                        )}
                      </div>
                      <p className='text-sm11 text-primaryGray'>
                        Please enter your mark-up below. Your markup
                        gets added to the base price to arrive at the
                        final sell price. Mark-up is 10% fixed for all
                        prints on products.
                      </p>

                      <div className='mt-[11px]'>
                        <table className='border-separate border-spacing-0 '>
                          <tbody>
                            <tr className='text-[14px] font-medium bg-[#ececec] '>
                              <td className='pl-[8px] w-[114px] text-primaryBlack h-[36px] border border-[#d6d6d6] rounded-tl-[16px]'>
                                Size
                              </td>

                              <td className='pl-[8px] w-[114px] text-primaryBlack h-[36px] border-t border-r border-b border-[#d6d6d6]'>
                                Your Markup
                              </td>
                              <td className='pl-[8px] w-[114px] text-primaryBlack h-[36px] border-t border-r border-b border-[#d6d6d6]'>
                                Base Price
                              </td>
                              {/* <td className='pl-[8px] w-[114px] text-primaryBlack h-[36px] border-t border-r border-b border-[#d6d6d6]'>
                                Your Markup
                              </td> */}
                              <td className='pl-[8px] w-[114px] text-primaryBlack h-[36px] border-t border-r border-b border-[#d6d6d6] rounded-tr-[16px]'>
                                Selling Price
                              </td>
                            </tr>

                            <tr className='text-[14px] font-medium'>
                              <td className='pl-[8px] text-primaryBlack text-[13px] h-[36px] border border-[#d6d6d6] bg-[#f7f7f7] '>
                                XS
                              </td>
                              <td className='pl-[8px] border-t border-r border-b border-[#d6d6d6]'>
                                <input
                                  style={{
                                    '::-webkit-inner-spin-button': {
                                      display: 'none',
                                    },
                                    '::-webkit-outer-spin-button': {
                                      display: 'none',
                                    },
                                    '-moz-appearance': 'textfield',
                                    appearance: 'none',
                                    MozAppearance:
                                      'textfield!important',
                                    WebkitAppearance:
                                      'none!important',
                                    appearance: 'none!important',
                                  }}
                                  type='number'
                                  name='price'
                                  className='w-[90px] h-[28px] border border-[#d6d6d6] text-primaryGray text-[13px] font-normal rounded-[5000px] pl-[8px] outline-none'
                                  placeholder='10%'
                                  // onChange={handleInputChangePrice}
                                  // value={formData.price}
                                />
                              </td>
                              <td className='pl-[8px]  text-[13px] h-[36px] text-primaryGray border-t border-r border-b border-[#d6d6d6] bg-[#f7f7f7]'>
                                <span className='opacity-[40%]'>
                                  18.00
                                </span>
                              </td>

                              <td className='pl-[8px]  text-[13px] h-[36px] border-t text-primaryGray border-r border-b border-[#d6d6d6] font-normal  '>
                                19.80
                              </td>
                            </tr>

                            <tr className='text-[14px] font-medium'>
                              <td className='pl-[8px] text-primaryBlack text-[13px] h-[36px] border-l border-b border-r border-[#d6d6d6] bg-[#f7f7f7] '>
                                S
                              </td>

                              <td className='pl-[8px] border-r border-b border-[#d6d6d6]'>
                                <input
                                  style={{
                                    '::-webkit-inner-spin-button': {
                                      display: 'none',
                                    },
                                    '::-webkit-outer-spin-button': {
                                      display: 'none',
                                    },
                                    '-moz-appearance': 'textfield',
                                    appearance: 'none',
                                    MozAppearance:
                                      'textfield!important',
                                    WebkitAppearance:
                                      'none!important',
                                    appearance: 'none!important',
                                  }}
                                  type='number'
                                  name='price'
                                  className='w-[90px] h-[28px] border border-[#d6d6d6] text-primaryGray text-[13px] font-normal rounded-[5000px] pl-[8px] outline-none'
                                  placeholder='10%'
                                  // onChange={handleInputChangePrice}
                                  // value={formData.price}
                                />
                              </td>
                              <td className='pl-[8px]  text-[13px] h-[36px] text-primaryGray border-r border-b border-[#d6d6d6] bg-[#f7f7f7]'>
                                <span className='opacity-[40%]'>
                                  18.00
                                </span>
                              </td>

                              <td className='pl-[8px]  text-[13px] h-[36px] text-primaryGray border-r border-b border-[#d6d6d6] font-normal  '>
                                19.80
                              </td>
                            </tr>
                            <tr className='text-[14px] font-medium'>
                              <td className='pl-[8px] text-primaryBlack text-[13px] h-[36px] border-l border-b border-r border-[#d6d6d6] bg-[#f7f7f7] '>
                                M
                              </td>
                              <td className='pl-[8px] border-r border-b border-[#d6d6d6]'>
                                <input
                                  style={{
                                    '::-webkit-inner-spin-button': {
                                      display: 'none',
                                    },
                                    '::-webkit-outer-spin-button': {
                                      display: 'none',
                                    },
                                    '-moz-appearance': 'textfield',
                                    appearance: 'none',
                                    MozAppearance:
                                      'textfield!important',
                                    WebkitAppearance:
                                      'none!important',
                                    appearance: 'none!important',
                                  }}
                                  type='number'
                                  name='price'
                                  className='w-[90px] h-[28px] border border-[#d6d6d6] text-primaryGray text-[13px] font-normal rounded-[5000px] pl-[8px] outline-none'
                                  placeholder='10%'
                                  // onChange={handleInputChangePrice}
                                  // value={formData.price}
                                />
                              </td>
                              <td className='pl-[8px]  text-[13px] h-[36px] text-primaryGray border-r border-b border-[#d6d6d6] bg-[#f7f7f7]'>
                                <span className='opacity-[40%]'>
                                  18.00
                                </span>
                              </td>

                              <td className='pl-[8px]  text-[13px] h-[36px] text-primaryGray border-r border-b border-[#d6d6d6] font-normal  '>
                                19.80
                              </td>
                            </tr>

                            <tr className='text-[14px] font-medium'>
                              <td className='pl-[8px] text-primaryBlack text-[13px] h-[36px] border-b border-r border-l border-[#d6d6d6] bg-[#f7f7f7] '>
                                L
                              </td>
                              <td className='pl-[8px] border-r border-b border-[#d6d6d6]'>
                                <input
                                  style={{
                                    '::-webkit-inner-spin-button': {
                                      display: 'none',
                                    },
                                    '::-webkit-outer-spin-button': {
                                      display: 'none',
                                    },
                                    '-moz-appearance': 'textfield',
                                    appearance: 'none',
                                    MozAppearance:
                                      'textfield!important',
                                    WebkitAppearance:
                                      'none!important',
                                    appearance: 'none!important',
                                  }}
                                  type='number'
                                  name='price'
                                  placeholder='10%'
                                  className='w-[90px] h-[28px] border border-[#d6d6d6] text-primaryGray text-[13px] font-normal rounded-[5000px] pl-[8px] outline-none'
                                  // onChange={handleInputChangePrice}
                                  // value={formData.price}
                                />
                              </td>
                              <td className='pl-[8px]  text-[13px] h-[36px] text-primaryGray border-r border-b border-[#d6d6d6] bg-[#f7f7f7]'>
                                <span className='opacity-[40%]'>
                                  21.00
                                </span>
                              </td>

                              <td className='pl-[8px]  text-[13px] h-[36px] text-primaryGray border-r border-b border-[#d6d6d6] font-normal  '>
                                19.80
                              </td>
                            </tr>

                            <tr className='text-[14px] font-medium'>
                              <td className='pl-[8px] text-primaryBlack text-[13px] h-[36px] border-b border-r border-l border-[#d6d6d6] bg-[#f7f7f7] '>
                                XL
                              </td>
                              <td className='pl-[8px] border-r border-b border-[#d6d6d6]'>
                                <input
                                  style={{
                                    '::-webkit-inner-spin-button': {
                                      display: 'none',
                                    },
                                    '::-webkit-outer-spin-button': {
                                      display: 'none',
                                    },
                                    '-moz-appearance': 'textfield',
                                    appearance: 'none',
                                    MozAppearance:
                                      'textfield!important',
                                    WebkitAppearance:
                                      'none!important',
                                    appearance: 'none!important',
                                  }}
                                  type='number'
                                  name='price'
                                  placeholder='10%'
                                  className='w-[90px] h-[28px] border border-[#d6d6d6] text-primaryGray text-[13px] font-normal rounded-[5000px] pl-[8px] outline-none'
                                  // onChange={handleInputChangePrice}
                                  // value={formData.price}
                                />
                              </td>
                              <td className='pl-[8px]  text-[13px] h-[36px] text-primaryGray border-r border-b border-[#d6d6d6] bg-[#f7f7f7]'>
                                <span className='opacity-[40%]'>
                                  18.00
                                </span>
                              </td>

                              <td className='pl-[8px]  text-[13px] h-[36px] text-primaryGray border-r border-b border-[#d6d6d6] font-normal  '>
                                19.80
                              </td>
                            </tr>

                            <tr className='text-[14px] font-medium'>
                              <td className='pl-[8px] text-primaryBlack text-[13px] h-[36px] border-b border-l border-r border-[#d6d6d6] bg-[#f7f7f7]'>
                                2XL
                              </td>
                              <td className='pl-[8px] border-r border-b border-[#d6d6d6]'>
                                <input
                                  style={{
                                    '::-webkit-inner-spin-button': {
                                      display: 'none',
                                    },
                                    '::-webkit-outer-spin-button': {
                                      display: 'none',
                                    },
                                    '-moz-appearance': 'textfield',
                                    appearance: 'none',
                                    MozAppearance:
                                      'textfield!important',
                                    WebkitAppearance:
                                      'none!important',
                                    appearance: 'none!important',
                                  }}
                                  type='number'
                                  name='price'
                                  placeholder='10%'
                                  className='w-[90px] h-[28px] border border-[#d6d6d6] text-primaryGray text-[13px] font-normal rounded-[5000px] pl-[8px] outline-none'
                                  // onChange={handleInputChangePrice}
                                  // value={formData.price}
                                />
                              </td>
                              <td className='pl-[8px]  text-[13px] h-[36px] text-primaryGray border-r border-b border-[#d6d6d6] bg-[#f7f7f7]'>
                                <span className='opacity-[40%]'>
                                  18.00
                                </span>
                              </td>

                              <td className='pl-[8px]  text-[13px] h-[36px] text-primaryGray border-r border-b border-[#d6d6d6] font-normal  '>
                                23.10
                              </td>
                            </tr>

                            <tr className='text-[14px] font-medium'>
                              <td className='pl-[8px] text-primaryBlack text-[13px] h-[36px] border-b border-l border-r border-[#d6d6d6] bg-[#f7f7f7] rounded-bl-[16px]'>
                                3XL
                              </td>
                              <td className='pl-[8px] border-r border-b border-[#d6d6d6]'>
                                <input
                                  style={{
                                    '::-webkit-inner-spin-button': {
                                      display: 'none',
                                    },
                                    '::-webkit-outer-spin-button': {
                                      display: 'none',
                                    },
                                    '-moz-appearance': 'textfield',
                                    appearance: 'none',
                                    MozAppearance:
                                      'textfield!important',
                                    WebkitAppearance:
                                      'none!important',
                                    appearance: 'none!important',
                                  }}
                                  type='number'
                                  name='price'
                                  placeholder='10%'
                                  className='w-[90px] h-[28px] border border-[#d6d6d6] text-primaryGray text-[13px] font-normal rounded-[5000px] pl-[8px] outline-none'
                                  // onChange={handleInputChangePrice}
                                  // value={formData.price}
                                />
                              </td>
                              <td className='pl-[8px]  text-[13px] h-[36px] text-primaryGray border-r border-b border-[#d6d6d6] bg-[#f7f7f7]'>
                                <span className='opacity-[40%]'>
                                  23.00
                                </span>
                              </td>

                              <td className='pl-[8px]  text-[13px] h-[36px] text-primaryGray border-r border-b border-[#d6d6d6] font-normal rounded-br-[16px] '>
                                25.30
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      {/* <div className='wrapper mt-[11px]'>
                        <table className='w-[520px] border-separate border-spacing-0'>
                          <tr className='overflow-hidden text-[15px] text-left'>
                            <th className='bg-[#ECECEC] rounded-tl-[10px] py-[0.4rem] px-2 border border-[#d6d6d6] font-medium text-[#333333]'>
                              Size
                            </th>
                            <th className='bg-[#ECECEC] py-[0.4rem] px-2 border-r border-b border-t border-[#d6d6d6] font-medium text-[#333333]'>
                              Base Price
                            </th>
                            <th className='bg-[#ECECEC] py-[0.4rem] px-2 border-r border-b border-t border-[#d6d6d6] font-medium text-[#333333]'>
                              Your Markup
                            </th>
                            <th className='bg-[#ECECEC] py-[0.4rem] px-2 border-r border-b border-t border-[#d6d6d6] rounded-tr-[10px] font-medium text-[#333333]'>
                              Sell Price
                            </th>
                          </tr>
                         
                          <tr className='text-start text-[13px]'>
                            <td
                              className={`pl-2 py-[0.1rem] bg-[#F7F7F7] border-l border-r border-b border-[#d6d6d6]  `}
                            >
                             XS
                            </td>
                            <td className=' px-2 py-[0.1rem] bg-[#F7F7F7] border-r border-b border-[#d6d6d6]'>
                              18.00
                            </td>
                            <td className=' px-2 py-[0.1rem] border-r border-b border-[#d6d6d6]'>
                              <input
                                type='text'
                                placeholder='10%'
                                className='rounded-xl border px-5 border-[#d6d6d6] w-[111.99px] outline-none '
                                disabled
                              />
                            </td>
                            <td
                              className={`px-2 py-[0.1rem] border-r border-b border-[#d6d6d6]  `}
                            >
                             19.80
                            </td>
                          </tr>

                          <tr className='text-start text-[13px]'>
                            <td
                              className={`pl-2 py-[0.1rem] bg-[#F7F7F7] border-l border-r border-b border-[#d6d6d6]  `}
                            >
                              S
                            </td>
                            <td className=' px-2 py-[0.1rem] bg-[#F7F7F7] border-r border-b border-[#d6d6d6]'>
                              18.00
                            </td>
                            <td className=' px-2 py-[0.1rem] border-r border-b border-[#d6d6d6]'>
                              <input
                                type='text'
                                placeholder='10%'
                                className='rounded-xl border px-5 border-[#d6d6d6] w-[111.99px] outline-none '
                                disabled
                              />
                            </td>
                            <td
                              className={`px-2 py-[0.1rem] border-r border-b border-[#d6d6d6]  `}
                            >
                              19.80
                            </td>
                          </tr>
                          <tr className='text-start text-[13px]'>
                            <td
                              className={`pl-2 py-[0.1rem] bg-[#F7F7F7] border-l border-r border-b border-[#d6d6d6]  `}
                            >
                             M
                            </td>
                            <td className=' px-2 py-[0.1rem] bg-[#F7F7F7] border-r border-b border-[#d6d6d6]'>
                             18.00
                            </td>
                            <td className=' px-2 py-[0.1rem] border-r border-b border-[#d6d6d6]'>
                              <input
                                type='text'
                                placeholder='10%'
                                className='rounded-xl border px-5 border-[#d6d6d6] w-[111.99px] outline-none '
                                disabled
                              />
                            </td>
                            <td
                              className={`px-2 py-[0.1rem] border-r border-b border-[#d6d6d6]  `}
                            >
                              19.80
                            </td>
                          </tr>
                          <tr className='text-start text-[13px]'>
                            <td
                              className={`pl-2 py-[0.1rem] bg-[#F7F7F7] border-l border-r border-b border-[#d6d6d6]  `}
                            >
                              L
                            </td>
                            <td className=' px-2 py-[0.1rem] bg-[#F7F7F7] border-r border-b border-[#d6d6d6]'>
                              18.00
                            </td>
                            <td className=' px-2 py-[0.1rem] border-r border-b border-[#d6d6d6]'>
                              <input
                                type='text'
                                placeholder='10%'
                                className='rounded-xl border px-5 border-[#d6d6d6] w-[111.99px] outline-none '
                                disabled
                              />
                            </td>
                            <td
                              className={`px-2 py-[0.1rem] border-r border-b border-[#d6d6d6]  `}
                            >
                              19.80
                            </td>
                          </tr>

                          <tr className='text-start text-[13px]'>
                            <td
                              className={`pl-2 py-[0.1rem] bg-[#F7F7F7] border-l border-r border-b border-[#d6d6d6]  `}
                            >
                              XL
                            </td>
                            <td className=' px-2 py-[0.1rem] bg-[#F7F7F7] border-r border-b border-[#d6d6d6]'>
                             18.00
                            </td>
                            <td className=' px-2 py-[0.1rem] border-r border-b border-[#d6d6d6]'>
                              <input
                                type='text'
                                placeholder='10%'
                                className='rounded-xl border px-5 border-[#d6d6d6] w-[111.99px] outline-none '
                                disabled
                              />
                            </td>
                            <td
                              className={`px-2 py-[0.1rem] border-r border-b border-[#d6d6d6]  `}
                            >
                              19.80
                            </td>
                          </tr>
                          <tr className='text-start text-[13px]'>
                            <td
                              className={`pl-2 py-[0.1rem] bg-[#F7F7F7] border-l border-r border-b border-[#d6d6d6]  `}
                            >
                             2XL
                            </td>
                            <td className=' px-2 py-[0.1rem] bg-[#F7F7F7] border-r border-b border-[#d6d6d6]'>
                              21.00
                            </td>
                            <td className=' px-2 py-[0.1rem] border-r border-b border-[#d6d6d6]'>
                              <input
                                type='text'
                                placeholder='10%'
                                className='rounded-xl border px-5 border-[#d6d6d6] w-[111.99px] outline-none '
                                disabled
                              />
                            </td>
                            <td
                              className={`px-2 py-[0.1rem] border-r border-b border-[#d6d6d6]  `}
                            >
                              23.10
                            </td>
                          </tr>

                          <tr className='text-start text-[13px]'>
                            <td
                              className={`pl-2 py-[0.1rem] bg-[#F7F7F7] border-l border-r border-b border-[#d6d6d6] rounded-bl-[10px]`}
                            >
                             3XL
                            </td>
                            <td className=' px-2 py-[0.1rem] bg-[#F7F7F7] border-r border-b border-[#d6d6d6]'>
                             23.00
                            </td>
                            <td className=' px-2 py-[0.1rem] border-r border-b border-[#d6d6d6]'>
                              <input
                                type='text'
                                placeholder='10%'
                                className='rounded-xl border px-5 border-[#d6d6d6] w-[111.99px] outline-none '
                                disabled
                              />
                            </td>
                            <td
                              className={`px-2 py-[0.1rem] border-r border-b border-[#d6d6d6] rounded-br-[10px]`}
                            >
                              25.30
                            </td>
                          </tr>
                         
                        </table>
                      </div> */}
                    </div>

                    <div className='flex flex-col mt-[32px]'>
                      <AntSwitch
                        defaultChecked
                        inputProps={{ 'aria-label': 'ant design' }}
                      />

                      <p className='text-[11px] text-primaryGray'>
                        Product Activated
                      </p>
                    </div>
                    <div className='pt-[20px] flex gap-[8px]'>
                      <button onClick={onSave} className='blackBtn'>
                        Save
                      </button>
                      <button className='outlineBtn'>Reset</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className='w-full flex justify-center mt-[22px]'>
                <button className='greenBlueButton'>
                  See All Products
                </button>
              </div>
              <div className='w-full pt-[45px] flex flex-col text-center'>
                <p className='text-[15px] text-primaryBlack text-center font-medium'>
                  Artist Agreement for “Printable Wall Art Home Decor”
                </p>
                <div className='w-[930px] mx-[auto] text-sm11 text-primaryGray flex flex-col gap-[12px] pt-[12px]'>
                  <p>
                    I upload this file on artnstock.com. I acknowledge
                    and warrant that I have read and agree with
                    Artnstock Terms and Conditions. <br />
                    I have the right to display any person, character,
                    name, logo, image or quote contained in this
                    artwork. Any violation of
                    <br /> Artnstock's{' '}
                    <span className='text-orangeColor'>
                      Terms of Service, License Agreement, Privacy
                      Policy
                    </span>{' '}
                    and{' '}
                    <span className='text-orangeColor'>
                      Copyright and Trademark Policy
                    </span>{' '}
                    may result in your artwork <br /> being taken
                    down, your account being suspended or terminated,
                    and/or any other remedies or penalties under
                    applicable law. In <br /> addition, you will not
                    receive any Artist Share amounts related to such
                    artwork.
                  </p>
                  <div className=''>
                    <label
                      style={{
                        width: 'fit-content',
                      }}
                      className='containerCheckGray text-[12px] text-primaryGray'
                    >
                      <input
                        type='checkbox'
                        // name='typeOfContent'
                        // value='Original Art'
                        // onChange={handleInputChange}
                        checked={true}
                      />{' '}
                      I agree to the terms of service
                      <span className='checkmarkGray'></span>
                    </label>
                  </div>
                </div>
                <div className='flex gap-[15px] justify-center mt-[35px] pb-[30px]'>
                  <button className='blackBtn'>
                    Submit for Review
                  </button>
                  <button className='outlineBtn'>Cancel</button>
                </div>
              </div>
            </>
          ) : nestedLifeStyle === '2' ? (
            <></>
          ) : (
            <></>
          )}
        </>
      ) : ActivateProdTabsValue === 'Home & Living' ? (
        <div></div>
      ) : ActivateProdTabsValue === 'Tech' ? (
        <div></div>
      ) : ActivateProdTabsValue === 'Stationary' ? (
        <div></div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default ActivateProducts;
