const COLOR_DATA = [
  {
    title: 'LightBlue',
    colors: [
      {
        code: '#00374b',
      },
      {
        code: '#004b63',
      },
      {
        code: '#00718b',
      },
      {
        code: '#048ebc',
      },
      {
        code: '#00a2d8',
      },
      {
        code: '#00c7ff',
      },
      {
        code: '#55d5fe',
      },
      {
        code: '#90e4fe',
      },
      {
        code: '#caf2fe',
      },
    ],
  },
  {
    title: 'DarkBlue',
    colors: [
      {
        code: '#001e59',
      },
      {
        code: '#013078',
      },
      {
        code: '#0142a6',
      },
      {
        code: '#0057d5',
      },
      {
        code: '#0360ff',
      },
      {
        code: '#3689ff',
      },
      {
        code: '#75a7fe',
      },
      {
        code: '#aac4fd',
      },
      {
        code: '#d5e4fb',
      },
    ],
  },
  {
    title: 'Voilet',
    colors: [
      {
        code: '#12053d',
      },
      {
        code: '#180b52',
      },
      {
        code: '#2b1278',
      },
      {
        code: '#391b95',
      },
      {
        code: '#4924b7',
      },
      {
        code: '#5a33eb',
      },
      {
        code: '#854ffb',
      },
      {
        code: '#b28cfb',
      },
      {
        code: '#dacafa',
      },
    ],
  },
  {
    title: 'Purple',
    colors: [
      {
        code: '#2e0743',
      },
      {
        code: '#45105e',
      },
      {
        code: '#63177e',
      },
      {
        code: '#77239d',
      },
      {
        code: '#9b2bba',
      },
      {
        code: '#bc37f2',
      },
      {
        code: '#d456ff',
      },
      {
        code: '#e395fc',
      },
      {
        code: '#f1c9ff',
      },
    ],
  },
  {
    title: 'Pink',
    colors: [
      {
        code: '#3f051b',
      },
      {
        code: '#551126',
      },
      {
        code: '#7b193c',
      },
      {
        code: '#97274f',
      },
      {
        code: '#bb2a62',
      },
      {
        code: '#e73a79',
      },
      {
        code: '#ef6f9f',
      },
      {
        code: '#f6a3bf',
      },
      {
        code: '#f8d4e0',
      },
    ],
  },
  {
    title: 'Red',
    colors: [
      {
        code: '#5b0601',
      },
      {
        code: '#820d00',
      },
      {
        code: '#b51902',
      },
      {
        code: '#e02301',
      },
      {
        code: '#fd4310',
      },
      {
        code: '#fd634d',
      },
      {
        code: '#ff8d85',
      },
      {
        code: '#fdb5af',
      },
      {
        code: '#ffd8d9',
      },
    ],
  },
  {
    title: 'Orange',
    colors: [
      {
        code: '#591b00',
      },
      {
        code: '#792900',
      },
      {
        code: '#ac3f00',
      },
      {
        code: '#dc5100',
      },
      {
        code: '#fd6b00',
      },
      {
        code: '#ffa47e',
      },
      {
        code: '#ffa47e',
      },
      {
        code: '#ffc4a8',
      },
      {
        code: '#fee2d6',
      },
    ],
  },
  {
    title: 'LightOrange',
    colors: [
      {
        code: '#583400',
      },
      {
        code: '#794903',
      },
      {
        code: '#ac6803',
      },
      {
        code: '#d38306',
      },
      {
        code: '#ffa900',
      },
      {
        code: '#ffb23c',
      },
      {
        code: '#ffc878',
      },
      {
        code: '#ffd7a8',
      },
      {
        code: '#ffebd3',
      },
    ],
  },
  {
    title: 'Mango',
    colors: [
      {
        code: '#543d00',
      },
      {
        code: '#765600',
      },
      {
        code: '#a57c00',
      },
      {
        code: '#d5a20a',
      },
      {
        code: '#9a8e67',
      },
      {
        code: '#ffca40',
      },
      {
        code: '#fed374',
      },
      {
        code: '#fde5a7',
      },
      {
        code: '#fff4d5',
      },
    ],
  },
  {
    title: 'Yellow',
    colors: [
      {
        code: '#666002',
      },
      {
        code: '#888900',
      },
      {
        code: '#c3be00',
      },
      {
        code: '#f5ed02',
      },
      {
        code: '#b1b190',
      },
      {
        code: '#bebea6',
      },
      {
        code: '#d0d0be',
      },
      {
        code: '#fffcb6',
      },
      {
        code: '#fffbda',
      },
    ],
  },
  {
    title: 'YellowGreen',
    colors: [
      {
        code: '#505600',
      },
      {
        code: '#717406',
      },
      {
        code: '#9ca60c',
      },
      {
        code: '#737371',
      },
      {
        code: '#93938f',
      },
      {
        code: '#aaaaa7',
      },
      {
        code: '#c1c1c0',
      },
      {
        code: '#f1f7b7',
      },
      {
        code: '#f9f9dc',
      },
    ],
  },
  {
    title: 'Green',
    colors: [
      {
        code: '#293e0f',
      },
      {
        code: '#3c571d',
      },
      {
        code: '#4c7c29',
      },
      {
        code: '#659d31',
      },
      {
        code: '#75ba44',
      },
      {
        code: '#98d460',
      },
      {
        code: '#b0dc87',
      },
      {
        code: '#cee9b8',
      },
      {
        code: '#e2ebda',
      },
    ],
  },
];

export default COLOR_DATA;
