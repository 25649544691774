import React from 'react';

import { ReactComponent as LogoWithoutText } from '../../assets/images/ShoppingCart/logoWithoutText.svg';
import { ReactComponent as Paypal } from '../../assets/images/footer/Paypal.svg';
import { ReactComponent as Visa } from '../../assets/images/footer/Visa.svg';
import { ReactComponent as Mastercard } from '../../assets/images/footer/Mastercard.svg';
import { ReactComponent as AmerExpress } from '../../assets/images/footer/AmerExpress.svg';
import { ReactComponent as Discover } from '../../assets/images/footer/Discover.svg';
import Certificate1 from '../../assets/images/FooterSubComp/Certificate1.png';
import Certificate2 from '../../assets/images/FooterSubComp/Certificate2.png';
import Certificate3 from '../../assets/images/FooterSubComp/Certificate3.png';

import Thumbnail1 from '../../assets/images/Checkout/Thumbnail1.jpg';
import Footer from '../footer/Footer';

const CheckoutThanks = () => {
  return (
    <>
      <div className='w-[100%] mb-[200px]'>
        <div className='wrapper pt-[30px] mb-[45px]'>
          <p className='bullet text-center mb-1 leading-[1.2]'>
            Artnstock / Shopping Cart / Checkout
          </p>
          <p className='text-[38px] text-[#333333] font-medium text-center leading-[1]'>
            Thank You For Your Shopping
          </p>
        </div>

        {/* <div className='flex flex-col mx-auto mt-[10px] mb-[500px]'>
        <div className='h-[1px] w-[100%] bg-[#efefef] mt-[10px] mb-[28px]'></div> */}

        <div
          style={{
            width: 'fit-content',
          }}
          className='flex gap-[30px] mx-[auto] justify-center'
        >
          {/* div  1*/}
          <div className='w-[330px] flex flex-col'>
            <p className='text-[25px] font-medium text-[#333333] leading-[1.2]'>
              Order Number: ANS9876547
            </p>

            <div className='border-t-2 border-b-2 border-[#EFEFEF] mt-[5px]'>
              <div className='w-[100%]'>
                <div className='border-b border-[#EFEFEF] flex items-center h-[17px]'>
                  <p className='text-primaryGray text-sm12 font-medium leading-[15px]'>
                    Customer:
                  </p>
                  <p className='text-primaryGray text-sm12 font-normal  leading-[15px]'>
                    Khalid Shaikh
                  </p>
                </div>
                <div className='flex items-center h-[17px]'>
                  <p className='text-primaryGray text-sm12 font-medium leading-[15px]'>
                    Order Date:
                  </p>
                  <p className='text-primaryGray text-sm12 font-normal gap-[4px] leading-[15px] flex items-center'>
                    18 Jan 2024
                  </p>
                </div>
              </div>
            </div>

            <div className='flex flex-col'>
              <p className='text-[15px] font-medium text-[#333333] leading-[1] mt-[20px] mb-[5px]'>
                Payment Method
              </p>
              <div>
                <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
              </div>
              <p className='text-[12px] text-[#757575] mt-[4px] leading-[1] flex items-center'>
                Card Number, ************ 5248
              </p>
            </div>

            <div className='flex flex-col'>
              <p className='text-[15px] font-medium text-[#333333] leading-[1] mt-[20px] mb-[5px]'>
                Mailing Address
              </p>

              <p className='text-[12px] text-[#757575] mt-[4px] leading-[1] flex items-center'>
                Khalid Shaikh
              </p>
              <p className='text-[12px] text-[#757575] mt-[4px] leading-[1] flex items-center'>
                S.No. 203/2A, Plot 16A, Rajiv Nagar (South)
              </p>
              <p className='text-[12px] text-[#757575] mt-[4px] leading-[1] flex items-center'>
                Viman Nagar, Pune 411014
              </p>
              <p className='text-[12px] text-[#757575] mt-[4px] leading-[1] flex items-center'>
                Maharashtra
              </p>
              <p className='text-[12px] text-[#757575] mt-[4px] leading-[1] flex items-center'>
                India
              </p>
              <p className='text-[12px] text-[#757575] mt-[4px] leading-[1] flex items-center'>
                Mobile No.:+91 7378666093
              </p>
              <p className='text-[12px] text-[#757575] mt-[4px] leading-[1] flex items-center'>
                Email: ksgrafiks2012@gmail.com
              </p>
            </div>

            <div className='flex flex-col'>
              <p className='text-[15px] font-medium text-[#333333] leading-[1] mt-[20px] mb-[5px]'>
                Shipping Address
              </p>

              <p className='text-[12px] text-[#757575] mt-[4px] leading-[1] flex items-center'>
                Khalid Shaikh
              </p>
              <p className='text-[12px] text-[#757575] mt-[4px] leading-[1] flex items-center'>
                S.No. 203/2A, Plot 16A, Rajiv Nagar (South)
              </p>
              <p className='text-[12px] text-[#757575] mt-[4px] leading-[1] flex items-center'>
                Viman Nagar, Pune 411014
              </p>
              <p className='text-[12px] text-[#757575] mt-[4px] leading-[1] flex items-center'>
                Maharashtra
              </p>
              <p className='text-[12px] text-[#757575] mt-[4px] leading-[1] flex items-center'>
                India
              </p>
              <p className='text-[12px] text-[#757575] mt-[4px] leading-[1] flex items-center'>
                Mobile No.:+91 7378666093
              </p>
              <p className='text-[12px] text-[#757575] mt-[4px] leading-[1] flex items-center'>
                Email: ksgrafiks2012@gmail.com
              </p>
            </div>

            <div className='flex flex-col'>
              <p className='text-[15px] font-medium text-[#333333] leading-[1] mt-[20px] mb-[5px]'>
                Shipping Address
              </p>

              <p className='text-[12px] text-[#757575] mt-[4px] leading-[1] flex items-center'>
                Standard
              </p>
              <p className='text-[11px] text-[#757575] mt-[4px] leading-[1] flex items-center'>
                You will recieve your shipment in 10-12 buisness days
              </p>
            </div>

            <div className='flex flex-col border-b border-t border-t-[2px] border-b-[2px] border-[#EFEFEF] py-[10px] my-[20px]'>
              <p className='text-[12px] text-[#757575] leading-[1] flex items-center'>
                Standard
              </p>

              <div
                style={{
                  width: 'fit-content',
                }}
                className='flex gap-[4px] mt-[4px]'
              >
                <div>
                  <Paypal className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                </div>

                <div>
                  <Visa className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                </div>

                <div>
                  <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                </div>

                <div>
                  <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                </div>
                <div>
                  <Discover />
                </div>
              </div>

              <div className='flex gap-[10px]  mt-[10px]'>
                <div className='w-[60px]'>
                  <img src={Certificate1} alt='' />
                </div>

                <div className='w-[60px]'>
                  <img src={Certificate2} alt='' />
                </div>

                <div className='w-[60px]'>
                  <img src={Certificate3} alt='' />
                </div>
              </div>
            </div>

            <div>
              <div
                // style={{
                //   boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
                // }}
                className=' w-[300px] rounded-[16px] flex flex-col bg-[#ffffff]'
              >
                <div className='rounded-[20px] h-[40px] border-[#d6d6d6] border text-primaryBlack text-[12px] flex justify-center items-center px-[30px] gap-[5px]'>
                  <div>
                    <LogoWithoutText />
                  </div>
                  <span className='font-medium'>
                    Sign in to your Artnstock account
                  </span>
                </div>
                <p className='text-[15px] text-[#333333] leading-[19px] font-medium mt-[7px]'>
                  Need Help?
                </p>
                <p className='text-sm12 text-primaryGray leading-[15px]'>
                  Shipping
                </p>
                <p className='text-sm12 text-primaryGray leading-[15px]'>
                  Return & Exchanges
                </p>
                <p className='text-sm12 text-primaryGray leading-[15px]'>
                  Contact Us
                </p>
              </div>
            </div>
          </div>
          {/* div 1*/}

          <div className='w-[330px]'>
            <div className='h-[40px] bg-[#808080] flex justify-between items-center px-[15px] rounded-t-[16px]'>
              <p className='font-medium text-[18px] text-[#fff] leading-[1]'>
                Order Summary
              </p>
            </div>
            <div className='h-[40px] bg-[#eeeeee] flex gap-[4px] items-center px-[15px]'>
              <div className='h-[25px] w-[25px] rounded-[5000px] bg-[#333333] flex justify-center items-center'>
                <span className='text-[#fff] font-medium text-[13px] leading-[1]'>
                  0
                </span>
              </div>
              <p className='font-medium text-[15px] text-primaryBlack leading-[1]'>
                You have no items in your cart
              </p>
            </div>

            <div className='bg-[#f9f9f9] border border-[#eeeeee] rounded-b-[16px] px-[15px] py-[10px]'>
              <div>
                <div className='relative h-[40px] w-[300px] flex items-center justify-center text-[12px] text-primaryBlack leading-[1]'>
                  <div
                    style={{
                      background:
                        'linear-gradient(to right, #fd4b9e, #9794d0, #46d59a, #beed10)',
                    }}
                    className='w-[100%] h-[100%] absolute rounded-[5000px]  opacity-[40%]'
                  ></div>
                  <span className='z-[999] text-center font-medium'>
                    Promotional Discounts, Shipping charges, Taxes,{' '}
                    <br />
                    and GST are included.
                  </span>
                </div>
              </div>

              <div className='h-[2px] w-[100%] my-[20px] bg-[#808080]'></div>

              <div className='here flex flex-col'>
                <div className='flex gap-[8px]'>
                  <div>
                    <img
                      className='min-w-[80px] h-[80px]  rounded-[9px]'
                      src={Thumbnail1}
                      alt=''
                    />
                  </div>

                  <div className='flex flex-col'>
                    <p className='text-[15px] font-medium leading-[17px] text-primaryBlack'>
                      Office Merchandise Combo
                    </p>
                    <p className='text-[12px] leading-[15px] text-primaryGray'>
                      International Artist Azra's work dictates the
                      expression of man and beast.
                    </p>

                    <div className='flex flex-col mt-[7px]'>
                      <div className='flex gap-[5px]'>
                        <p className='text-orangeColor text-[15px] leading-[18px] font-medium'>
                          $250.00
                        </p>
                        <p className='text-[#808080] text-[15px] leading-[18px] font-medium line-through'>
                          $270.00
                        </p>
                      </div>
                      <p className='text-[11px] text-pinkColor'>
                        10% Discount
                      </p>
                    </div>
                  </div>
                </div>
                <div className='h-[1px] w-[100%] bg-[#efefef] mt-[25px] mb-[25px]'></div>
                <div className='flex gap-[8px]'>
                  <div>
                    <img
                      className='min-w-[80px] h-[80px] rounded-[9px]'
                      src={Thumbnail1}
                      alt=''
                    />
                  </div>

                  <div className='flex flex-col'>
                    <p className='text-[15px] font-medium leading-[17px] text-primaryBlack'>
                      Office Merchandise Combo
                    </p>
                    <p className='text-[12px] leading-[15px] text-primaryGray'>
                      International Artist Azra's work dictates the
                      expression of man and beast.
                    </p>

                    <div className='flex flex-col mt-[7px]'>
                      <div className='flex gap-[5px]'>
                        <p className='text-orangeColor text-[15px] leading-[18px] font-medium'>
                          $250.00
                        </p>
                        <p className='text-[#808080] text-[15px] leading-[18px] font-medium line-through'>
                          $270.00
                        </p>
                      </div>
                      <p className='text-[11px] text-pinkColor'>
                        10% Discount
                      </p>
                    </div>
                  </div>
                </div>
                {/* <div className='h-[1px] w-[100%] bg-[#efefef] mt-[25px] mb-[25px]'></div> */}
              </div>

              <div className='h-[2px] w-[100%] my-[20px] bg-[#808080]'></div>

              <div className=''>
                <div className='flex justify-between pb-[14px]'>
                  <div className='leading-[1.2]'>
                    <p className='text-[#333333] font-medium'>
                      Your Cart Items
                    </p>
                    <p className='text-[11px] text-[#757575]'>
                      includes $45 in items discounts
                    </p>
                  </div>
                  <p className='text-[#333333] font-medium leading-[1]'>
                    {/* ${addZeroes(cartData?.totalAmount)} */}$333.00
                  </p>
                </div>

                <div className='flex justify-between pb-[14px]'>
                  <div className='leading-[1.2]'>
                    <p className='text-[#333333] font-medium'>
                      Estimated Shipping
                    </p>
                    <p className='text-[11px] text-[#757575]'>
                      Your Order will be shipped in 1 package
                    </p>{' '}
                  </div>
                  <p className='text-[#333333] font-medium leading-[1]'>
                    {/* ${addZeroes(cartData?.estimateShipping)} */}
                    5.00
                  </p>
                </div>
                <div className='flex justify-between mb-[14px]'>
                  <div className='leading-[1.2]'>
                    <p className='text-[#333333] font-medium'>Tax</p>
                    <p className='text-[11px] text-[#757575]'>
                      Lorem Ipsum dolor set
                    </p>{' '}
                  </div>
                  <p className='text-[#333333] font-medium'>
                    {/* ${addZeroes(cartData?.taxAmount)} */}$230
                  </p>
                </div>
                <div className='h-[1px] w-[100%]  bg-[#808080]'></div>
                <div className='flex justify-between items-center  pt-[8px] pb-[8px]'>
                  <div>
                    <p className='text-[#333333] font-medium text-[15px] leading-[14px] '>
                      Estimated Total
                    </p>
                    <p className='text-[11px] text-[#757575] leading-[14px]'>
                      includes $45 in items discounts
                    </p>
                  </div>
                  <div className='text-[#F9944C] leading-[1.2] flex flex-col items-end'>
                    <p>
                      ${' '}
                      <span className='text-[35px]'>
                        {/* {addZeroes(cartData?.finalAmount)} */}
                        250.00
                      </span>
                    </p>
                    <p className='mt-0 pt-0 text-[#757575] text-[12px] tracking-tighter'>
                      Show Price Details
                    </p>
                  </div>
                </div>
              </div>

              <div className='h-[2px] w-[100%]  bg-[#808080]'></div>

              <div className='my-[15px]'>
                <div
                  style={{
                    width: 'fit-content',
                  }}
                  className='text-pinkColor mx-[auto] text-[12px] bg-[#fff] leading-[1.2] flex items-center font-medium  border border-pinkColor rounded-[5000px] px-[5px]'
                >
                  Promo Code Applied
                </div>
              </div>

              {/* <div
              style={{
                boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
              }}
              className=' w-[300px] rounded-[16px] flex flex-col bg-[#ffffff] p-[10px] '
            >
              <div className='rounded-[20px] h-[40px] border-[#d6d6d6] border text-primaryBlack text-[12px] flex justify-center items-center px-[30px] gap-[5px]'>
                <div>
                  <LogoWithoutText />
                </div>
                <span className='font-medium'>
                  Sign in to your Artnstock account
                </span>
              </div>
              <p className='text-[15px] text-[#333333] leading-[19px] font-medium mt-[7px]'>
                Need Help?
              </p>
              <p className='text-sm12 text-primaryGray leading-[15px]'>
                Shipping
              </p>
              <p className='text-sm12 text-primaryGray leading-[15px]'>
                Return & Exchanges
              </p>
              <p className='text-sm12 text-primaryGray leading-[15px]'>
                Contact Us
              </p>
            </div>

            <p className='text-sm11 text-primaryGray text-center mt-[20px]'>
              Accepted Payment Methods
            </p>
            <div
              style={{
                width: 'fit-content',
              }}
              className='flex gap-[4px] mx-[auto] mt-[4px]'
            >
              <div>
                <Paypal className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
              </div>

              <div>
                <Visa className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
              </div>

              <div>
                <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
              </div>

              <div>
                <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
              </div>
              <div>
                <Discover />
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {/* </div> */}
    </>
  );
};

export default CheckoutThanks;
