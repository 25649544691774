import React from 'react';
import pro from '../../assets/images/accountSettings/pro.png';
import profile from '../../assets/images/accountSettings/profile.png';
import IconInfo from '../../assets/images/accountSettings/IconInfo.png';
import coverImg from '../../assets/images/accountSettings/coverImg.png';
import { useState, useEffect } from 'react';
import { httpClient } from '../../axios';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { authSliceAction } from '../../store/authSlice';
import { ReactComponent as IIcon } from '../../assets/images/Icons/iIcon.svg';

import { ReactComponent as ProIcon } from '../../assets/images/art-details/Icon_Pro.svg';

const Contributor_Profile = () => {
  const userId = useSelector((state) => state.auth.userId);

  const [userDetail, setUserDetail] = useState({});

  const dispatch = useDispatch();

  const getUserRecord = async () => {
    try {
      const res = await httpClient.get(
        `/user_master/getUserRecord/${userId}`
      );
      setUserDetail(res.data);
      console.log(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    console.log(userDetail);
  }, [userDetail]);

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    // console.log('abc');
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const img = event.target.files[0];
    if (img) {
      let formData = new FormData();
      formData.append('file', img);

      const res = await httpClient.post(
        '/CloudinaryImageUpload?parameter=false',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setUserDetail({ ...userDetail, profileImage: res.data });
    }
  };

  // cover image
  const fileInputRefCover = useRef(null);

  const handleButtonClickCover = () => {
    console.log('abc');
    fileInputRefCover.current.click();
  };

  const handleFileChangeCover = async (event) => {
    const img = event.target.files[0];
    if (img) {
      let formData = new FormData();
      formData.append('file', img);

      const res = await httpClient.post(
        '/CloudinaryImageUpload?parameter=false',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setUserDetail({ ...userDetail, coverImage: res.data });
      // setProfileImageCover(res.data);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserDetail({ ...userDetail, [name]: value });
  };

  // useEffect(() => {
  //   console.log(userDetail);
  // }, [userDetail]);

  const updateUserMaster = async () => {
    try {
      const res = await httpClient.put(
        '/user_master/update',
        userDetail
      );

      if (res.data) {
        dispatch(authSliceAction.setUserDetails(userDetail));
        toast.success('Successfully Updated User Details');
      }

      console.log(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getUserRecord();
  }, []);

  return (
    <>
      <div className='flex justify-between'>
        <p>
          <p className='text-[25px] leading-[1] font-medium text-primaryBlack'>
            My Profile
          </p>
          <p className='text-sm11 text-pinkColor'>
            All fields are required.
          </p>
        </p>
        <div>
          <div className='border border-[#eaeaea] rounded-[7px] h-[41px] flex items-center p-[6px]'>
            <div className='flex flex-col gap-[3px]'>
              <ProIcon />
              <p className='text-[11px] leading-[1] text-primaryGray'>
                Member since 5 July 2020
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='mb-10'>
        <div className='flex gap-5 mt-[30px]'>
          <div>
            {userDetail?.profileImage !== null ? (
              <div
                style={{
                  backgroundImage: `url(${userDetail?.profileImage})`,
                }}
                className='w-[127px] h-[127px] bg-cover bg-center rounded-[50%]'
              ></div>
            ) : (
              <div className='w-[127px] h-[127px] bg-cover bg-center rounded-[50%]'>
                No Image Added
              </div>
            )}
          </div>

          {/* <img src={profile} alt='' /> */}
          <div className='flex items-center w-[100%]'>
            <input
              type='file'
              multiple
              onChange={handleFileChange}
              accept='image/*'
              style={{ display: 'none' }}
              ref={fileInputRef}
            />
            <div className='w-[100%]'>
              <div className='flex gap-[5px] items-center'>
                <button
                  onClick={handleButtonClick}
                  className='gray30HButton'
                >
                  Upload Profile Photo
                </button>

                <div className='inline'>
                  <IIcon />
                </div>
              </div>
              <p className='text-[#757575] text-sm11 mt-1'>
                <span className='font-medium'>Colour Mode:</span> RGB{' '}
                <span className='font-medium'>Format:</span> JPEG
              </p>
              <p className='text-[#757575] text-sm11'>
                <span className='font-medium'>Dimensions:</span>{' '}
                500x500 pixels{' '}
                <span className='font-medium'>File Size:</span> Under
                5 MB
              </p>
              <p className='text-[#757575] text-[12px] font-medium mt-[16px]'>
                75% Profile Strength
              </p>
              <div className='w-full h-[8px] bg-[#d6d6d6] rounded-[5000px]'>
                <div className='w-[75%] h-[8px] bg-[#01e6e6] rounded-[5000px]'></div>
              </div>
            </div>
          </div>
        </div>

        <div className='relative mt-[12px]'>
          <input
            type='text'
            placeholder='User ID'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            onChange={handleInputChange}
            value={userDetail?.userUniqueNo || ''}
            //  name='artName'
            disabled
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            User ID
          </p>
        </div>
        {/* <p className='text-[12px] text-[#757575] mt-3'>User ID</p>
        <input
          type='text'
          className='regInput my-0 bg-[#eeeeee] text-[#333333]'
          placeholder='ANS98765432'
          value={userDetail?.userUniqueNo || ''}
          disabled
        /> */}

        <div className='relative mt-[12px]'>
          <input
            type='text'
            placeholder='Azra Creations'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            onChange={handleInputChange}
            value={userDetail?.displayName || ''}
            name='displayName'
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            Display Name (Name you want people to see)
          </p>
        </div>

        {/* <p className='text-[12px] text-[#757575] mt-3'>
          Display Name (Name you want people to see)
        </p>
        <input
          onChange={handleInputChange}
          name='displayName'
          type='text'
          className='regInput my-0 text-[#333333]'
          placeholder='Azra Creations'
          value={userDetail?.displayName || ''}
        /> */}

        <div className='relative mt-[12px]'>
          <input
            type='text'
            placeholder='Select Contributor Type'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            // onChange={handleInputChange}
            // value={userDetail?.displayName || ''}
            // name='displayName'
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            Contributor Type
          </p>
        </div>

        {/* <p className='text-[12px] text-[#757575] mt-3'>
          Contributor Type
        </p>
        <input
          type='text'
          className='regInput my-0  text-[#333333]'
          placeholder='Select Contributor Type'
        /> */}

        <div className='relative mt-[12px]'>
          <input
            type='text'
            placeholder='Enter your Profession/Specialization/Skill'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            // onChange={handleInputChange}
            // value={userDetail?.displayName || ''}
            // name='displayName'
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            Profession/Specialization/Skills
          </p>
        </div>

        {/* <p className='text-[12px] text-[#757575] mt-3'>
          Profession/Specialization/Skills
        </p>
        <input
          type='text'
          className='regInput my-0 text-[#333333]'
          placeholder='Enter your Profession/Specialization/Skill'
        /> */}

        <div className='relative mt-[12px]'>
          <input
            type='text'
            placeholder='Select Styles'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            // onChange={handleInputChange}
            // value={userDetail?.displayName || ''}
            // name='displayName'
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            Style
          </p>
        </div>

        {/* <p className='text-[12px] text-[#757575] mt-3'>Style</p>
        <input
          type='text'
          className='regInput my-0 text-[#333333]'
          placeholder='Select Styles'
        /> */}

        <div className='relative mt-[12px]'>
          <input
            type='text'
            placeholder='Select Subjects'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            // onChange={handleInputChange}
            // value={userDetail?.displayName || ''}
            // name='displayName'
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            Subject
          </p>
        </div>

        {/* <p className='text-[12px] text-[#757575] mt-3'>Subject</p>
        <input
          type='text'
          className='regInput my-0 text-[#333333]'
          placeholder='Select Subjects'
        /> */}

        <div className='relative mt-[12px]'>
          <input
            type='text'
            placeholder='Equipments used for Art/Photos/Footage/Music'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            // onChange={handleInputChange}
            // value={userDetail?.displayName || ''}
            // name='displayName'
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            Equipment
          </p>
        </div>

        {/* <p className='text-[12px] text-[#757575] mt-3'>Equipment</p>
        <input
          type='text'
          className='regInput my-0 text-[#333333]'
          placeholder='Equipments used for Art/Photos/Footage/Music'
        /> */}

        <div className='relative mt-[12px]'>
          <input
            type='text'
            placeholder='http://www.artnstock.com/azracreations'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            // onChange={handleInputChange}
            // value={userDetail?.displayName || ''}
            // name='displayName'
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            Store/Portfolio URl (Your Store/Portfolio URl can only be
            changed once.)
          </p>
        </div>

        {/* <p className='text-[12px] text-[#757575] mt-3'>
          Store/Portfolio URl (Your Store/Portfolio URl can only be
          changed once.)
        </p>
        <input
          type='text'
          className='regInput my-0 text-[#333333]'
          placeholder='http://www.artnstock.com/ azracreations'
        /> */}

        <div className='relative mt-[12px]'>
          <textarea
            type='text'
            // placeholder='http://www.artnstock.com/azracreations'
            className='resize-none border border-[#d6d6d6] leading-[18px] h-[203px] w-[100%] text-primaryGray text-[13px] rounded-[16px] px-[16px] pt-[34px] outline-none '
            onChange={handleInputChange}
            value={userDetail?.useInfo || ''}
            name='useInfo'
          />

          <p className='absolute top-[16px] left-[16px] text-[11px] leading-[14px] text-[#bbbbbb]'>
            About Me
          </p>
          <p className='absolute bottom-[24px] left-[16px] text-[11px] text-[#bbbbbb]'>
            0/500 characters
          </p>
        </div>

        {/* <p className='text-[12px] text-[#757575] mt-3'>About me </p>
        <textarea
          onChange={handleInputChange}
          name='useInfo'
          x
          value={userDetail?.useInfo || ''}
          id=''
          cols=''
          rows=''
          className='border border-[#d6d6d6] w-[100%] rounded-2xl resize-none h-[110px] p-2 outline-none text-[#333333]'
        ></textarea> */}

        <p className='text-[12px] text-[#757575] mt-3'>
          Cover Image{' '}
        </p>

        {userDetail?.coverImage !== null && (
          <div
            style={{
              backgroundImage: `url(${userDetail?.coverImage})`,
            }}
            className='w-[450px] h-[112px] rounded-2xl bg-cover bg-center'
          ></div>
        )}

        {/* <img src={coverImg} alt='' /> */}
        {/* <img src={userDetail?.coverImage} alt='' /> */}
        <input
          type='file'
          multiple
          onChange={handleFileChangeCover}
          accept='image/*'
          style={{ display: 'none' }}
          ref={fileInputRefCover}
        />
        <button
          onClick={handleButtonClickCover}
          className='gray30HButton mt-[10px]'
        >
          Upload Cover Image
        </button>

        <div className='text-primaryGray text-sm11 mt-1'>
          <span className='font-medium'>Colour Mode:</span> RGB{' '}
          <span className='font-medium'>Format:</span> JPEG
        </div>
        <div className='text-primaryGray text-sm11 mb-[30px]'>
          <span className='font-medium'>Dimensions:</span> 500x500
          pixels <span className='font-medium'>File Size:</span> Under
          5 MB
        </div>

        <div className='flex gap-5 justify-center mt-[30px] mb-[509px]'>
          <button onClick={updateUserMaster} className='blackBtn'>
            Save
          </button>
          <button className='outlineBtn40h'>Cancel</button>
          {/* <button className=' text-[14px] text-medium border border-[#333333] rounded-full px-4 py-2'>
            Cancel
          </button> */}
        </div>
      </div>
    </>
  );
};

export default Contributor_Profile;
