import React from 'react';
import { httpClient } from '../../axios';
import { ReactComponent as BlueLike } from '../../assets/images/Icons/blue-like-icon.svg';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import likee from '../../assets/images/ComboPack/like.png';

import { ReactComponent as Like } from '../../assets/images/art-details/icons/Like.svg';

const ReviewLike = ({ id, sendDataToParent, triggerReviewLike }) => {
  const [like, setlike] = useState(false);
  const userId = useSelector((state) => state.auth.userId);

  const checkReviewLikeExist = async () => {
    try {
      let obj = {
        customerReviewId: id,
        userId,
      };
      const res = await httpClient.post(
        '/customer_review_master/checkUserLike',
        obj
      );
      // console.log('checkUserLike', res.data);
      if (res.data === true) {
        setlike(true);
      } else if (res.data === false) {
        setlike(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setLike = async () => {
    try {
      let obj = {
        customerReviewId: id,
        userId,
      };
      const res = await httpClient.post(
        '/customer_review_master/like',
        obj
      );
      // console.log(res.data);
      checkReviewLikeExist();
    } catch (error) {
      console.error(error);
    }
  };

  const minusLike = async () => {
    try {
      let obj = {
        customerReviewId: id,
        userId,
      };
      const res = await httpClient.post(
        '/customer_review_master/likeminus',
        obj
      );
      // console.log(res.data);
      setlike(false);
      checkReviewLikeExist();
    } catch (error) {
      console.error(error);
    }
  };

  const sendDataToParentHandler = () => {
    sendDataToParent(!triggerReviewLike);
  };

  useEffect(() => {
    checkReviewLikeExist();
  }, []);

  return (
    <div className='cursor-pointer'>
      {like === true ? (
        <div>
          <Like
            onClick={() => {
              minusLike();
              sendDataToParentHandler();
            }}
            className='fill-[#EC008C]'
          />
          {/* <p>true</p> */}
        </div>
      ) : like === false ? (
        <div>
          <Like
            className='fill-[#888888]'
            onClick={() => {
              setLike();
              sendDataToParentHandler();
            }}
          />

          {/* <p>false</p> */}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default ReviewLike;
