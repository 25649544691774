import React from 'react';
import { Tab } from '@headlessui/react';
import Contributor_Profile from './Contributor_Profile';
import Contributor_Contact from './Contributor_Contact';
import Contributor_ChangePassword from './Contributor_ChangePassword';
import Contributor_PaymentInfo from './Contributor_PaymentInfo';
import Contributor_SocialMedia from './Contributor_SocialMedia';
import Contributor_Preferences from './Contributor_Preferences';
import Contributor_DeleteAcc from './Contributor_DeleteAcc';
import { useState } from 'react';
import Footer from '../footer/Footer';

const Contributor = () => {
  const [value, setValue] = useState('My Profile');
  return (
    <>
      <div className='wrapper flex justify-center'>
        <main>
          <p className='text-[#757575] text-[12px] bullet text-center mt-[30px] '>
            Artnstock / Account Settings / {value}
          </p>
          <p className='text-center mt-1 mb-5 text-heading text-primaryBlack leading-[33px]'>
            Account Settings
          </p>

          <div className='flex gap-[4px] mb-[32px]'>
            <div
              onClick={() => setValue('My Profile')}
              className={`${
                value === 'My Profile'
                  ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                  : 'border-[#eaeaea] text-primaryGray font-medium'
              }  border rounded-[14px] cursor-pointer h-[28px] w-[91px] hover:text-primaryBlack hover:border-[#e6e6e6] hover:bg-[#e6e6e6] justify-center flex items-center text-[12px]`}
            >
              My Profile
            </div>

            <div
              onClick={() => setValue('Contact Information')}
              className={`${
                value === 'Contact Information'
                  ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                  : 'border-[#eaeaea] text-primaryGray font-medium'
              }  border rounded-[14px] h-[28px]  cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
            >
              Contact Information
            </div>
            <div
              onClick={() => setValue('Change Password')}
              className={`${
                value === 'Change Password'
                  ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                  : 'border-[#eaeaea] text-primaryGray font-medium'
              }  border rounded-[14px] h-[28px]  cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
            >
              Change Password
            </div>
            <div
              onClick={() => setValue('Payment Information')}
              className={`${
                value === 'Payment Information'
                  ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                  : 'border-[#eaeaea] text-primaryGray font-medium'
              }  border rounded-[14px] h-[28px] cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
            >
              Payment Information
            </div>

            <div
              onClick={() => setValue('Social Media')}
              className={`${
                value === 'Social Media'
                  ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                  : 'border-[#eaeaea] text-primaryGray  font-medium'
              }  border rounded-[14px] h-[28px] cursor-pointer  hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] flex items-center text-[12px]`}
            >
              Social Media
            </div>

            <div
              onClick={() => setValue('Preferences')}
              className={`${
                value === 'Preferences'
                  ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                  : 'border-[#eaeaea] text-primaryGray  font-medium'
              }  border rounded-[14px] h-[28px] cursor-pointer  hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] flex items-center text-[12px]`}
            >
              Preferences
            </div>

            <div
              onClick={() => setValue('Delete Account')}
              className={`${
                value === 'Delete Account'
                  ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                  : 'border-[#eaeaea] text-primaryGray  font-medium'
              }  border rounded-[14px] h-[28px] cursor-pointer  hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] flex items-center text-[12px]`}
            >
              Delete Account
            </div>
          </div>

          {/* <Tab.Group> */}
          {/* <Tab.List className='flex gap-[4px] justify-center h-[28px] mb-[30px]'>
              <Tab
                className={({ selected }) =>
                  selected
                    ? 'border-[#BBBBBB] bg-[#BBBBBB] border rounded-[14px] text-primaryBlack px-[13px] flex items-center text-[12px] font-medium outline-none'
                    : 'border-[#EAEAEA] border rounded-[14px] text-primaryGray px-[13px] flex items-center text-[12px] font-medium '
                }
                onClick={() => setValue('My Profile')}
              >
                My Profile
              </Tab>
              <Tab
                className={({ selected }) =>
                  selected
                    ? 'border-[#BBBBBB] bg-[#BBBBBB] border rounded-[14px] text-primaryBlack px-[13px] flex items-center text-[12px] font-medium outline-none'
                    : 'border-[#EAEAEA] border rounded-[14px] text-primaryGray px-[13px] flex items-center text-[12px] font-medium'
                }
                onClick={() => setValue('Contact Information')}
              >
                Contact Information
              </Tab>
              <Tab
                className={({ selected }) =>
                  selected
                    ? 'border-[#BBBBBB] bg-[#BBBBBB] border rounded-[14px] text-primaryBlack px-[13px] flex items-center text-[12px] font-medium outline-none'
                    : 'border-[#EAEAEA] border rounded-[14px] text-primaryGray px-[13px] flex items-center text-[12px] font-medium'
                }
                onClick={() => setValue('Change Password')}
              >
                Change Password
              </Tab>
              <Tab
                className={({ selected }) =>
                  selected
                    ? 'border-[#BBBBBB] bg-[#BBBBBB] border rounded-[14px] text-primaryBlack px-[13px] flex items-center text-[12px] font-medium outline-none'
                    : 'border-[#EAEAEA] border rounded-[14px] text-primaryGray px-[13px] flex items-center text-[12px] font-medium'
                }
                onClick={() => setValue('Payment Information')}
              >
                Payment Information
              </Tab>
              <Tab
                className={({ selected }) =>
                  selected
                    ? 'border-[#BBBBBB] bg-[#BBBBBB] border rounded-[14px] text-primaryBlack px-[13px] flex items-center text-[12px] font-medium outline-none'
                    : 'border-[#EAEAEA] border rounded-[14px] text-primaryGray px-[13px] flex items-center text-[12px] font-medium'
                }
                onClick={() => setValue('Social Media')}
              >
                Social Media
              </Tab>
              <Tab
                className={({ selected }) =>
                  selected
                    ? 'border-[#BBBBBB] bg-[#BBBBBB] border rounded-[14px] text-primaryBlack px-[13px] flex items-center text-[12px] font-medium outline-none'
                    : 'border-[#EAEAEA] border rounded-[14px] text-primaryGray px-[13px] flex items-center text-[12px] font-medium'
                }
                onClick={() => setValue('Preferences')}
              >
                Preferences
              </Tab>
              <Tab
                className={({ selected }) =>
                  selected
                    ? 'border-[#BBBBBB] bg-[#BBBBBB] border rounded-[14px] text-primaryBlack px-[13px] flex items-center text-[12px] font-medium outline-none'
                    : 'border-[#EAEAEA] border rounded-[14px] text-primaryGray px-[13px] flex items-center text-[12px] font-medium'
                }
                onClick={() => setValue('Delete Account')}
              >
                Delete Account
              </Tab>
            </Tab.List> */}

          {/*            
            <div className='w-[450px] mx-auto'>
              <Tab.Panels>
                <Tab.Panel>
                  <Contributor_Profile />
                </Tab.Panel>
                <Tab.Panel>
                  <Contributor_Contact />
                </Tab.Panel>
                <Tab.Panel>
                  <Contributor_ChangePassword />
                </Tab.Panel>
                <Tab.Panel>
                  <Contributor_PaymentInfo />
                </Tab.Panel>
                <Tab.Panel>
                  <Contributor_SocialMedia />
                </Tab.Panel>
                <Tab.Panel>
                  <Contributor_Preferences />
                </Tab.Panel>
                <Tab.Panel>
                  <Contributor_DeleteAcc />
                </Tab.Panel>
              </Tab.Panels>
            </div>
          </Tab.Group> */}

          <div className='w-[450px] mx-auto'>
            {value === 'My Profile' ? (
              <div>
                <Contributor_Profile />
              </div>
            ) : value === 'Contact Information' ? (
              <div>
                <Contributor_Contact />
              </div>
            ) : value === 'Change Password' ? (
              <div>
                <Contributor_ChangePassword />
              </div>
            ) : value === 'Payment Information' ? (
              <div>
                <Contributor_PaymentInfo />
              </div>
            ) : value === 'Social Media' ? (
              <div>
                <Contributor_SocialMedia />
              </div>
            ) : value === 'Preferences' ? (
              <div>
                <Contributor_Preferences />
              </div>
            ) : value === 'Delete Account' ? (
              <div>
                <Contributor_DeleteAcc />
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default Contributor;
