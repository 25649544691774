import pro from '../../../assets/images/contributor/pro.png';
import level3 from '../../../assets/images/contributor/level3.jpg';
import { ReactComponent as Level1 } from '../../../assets/images/contributor/activateProd/Level1.svg';
import { ReactComponent as Level2 } from '../../../assets/images/contributor/activateProd/Level2.svg';
import { ReactComponent as Level3 } from '../../../assets/images/contributor/activateProd/Level3.svg';
import { ReactComponent as Level4 } from '../../../assets/images/contributor/activateProd/Level4.svg';
import { ReactComponent as Level5 } from '../../../assets/images/contributor/activateProd/Level5.svg';

import { ReactComponent as Location } from '../../../assets/images/contributor/Icon_Location_6x8.svg';

import location from '../../../assets/images/contributor/locationPointer.png';
import dashimg from '../../../assets/images/contributor/dashimg.png';
import map from '../../../assets/images/contributor/Map.jpg';
import Slider from '@mui/material/Slider';
import { useState } from 'react';
import dropdown from '../../../assets/images/socials/dropdown.png';
import { useSelector } from 'react-redux';
import { setpath } from '../../../store/contriPathSlice';
import { useDispatch } from 'react-redux';
import { ReactComponent as IButton } from '../../../assets/images/Icons/iButton.svg';

import { ReactComponent as DropArrow } from '../../../assets/images/Icons/Down arrow.svg';

// tabs

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { useEffect } from 'react';

import { ReactComponent as ContriRecog1 } from '../../../assets/images/art-details/icons/ContriRecog1.svg';
import { ReactComponent as ContriRecog2 } from '../../../assets/images/art-details/icons/ContriRecog2.svg';
import { ReactComponent as ContriRecog3 } from '../../../assets/images/art-details/icons/ContriRecog3.svg';

import downimg1 from '../../../assets/images/contributor/downimg1.jpg';
import downimg2 from '../../../assets/images/contributor/downimg2.jpg';
import downimg3 from '../../../assets/images/contributor/downimg3.jpg';

// components
import BarChart from '../contri-components/BarChart';
import { SpaceBarTwoTone } from '@mui/icons-material';

import { httpClient } from '../../../axios';
// import { useSelector } from 'react-redux';

const images = [
  {
    id: '1',
    image:
      'https://images.pexels.com/photos/2245436/pexels-photo-2245436.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    uploaded: '26 Jan,2022',
    name: 'Spanish',
  },
  {
    id: '2',
    image:
      'https://images.pexels.com/photos/6791741/pexels-photo-6791741.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    uploaded: '26 Jan,2022',
    name: 'Italian',
  },
  {
    id: '3',
    image:
      'https://images.pexels.com/photos/2132126/pexels-photo-2132126.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    uploaded: '26 Jan,2022',
    name: 'Italian',
  },
  {
    id: '4',
    image:
      'https://images.pexels.com/photos/2827374/pexels-photo-2827374.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    uploaded: '26 Jan,2022',
    name: 'Italian',
  },
  {
    id: '5',
    image:
      'https://images.pexels.com/photos/2622179/pexels-photo-2622179.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    uploaded: '26 Jan,2022',
    name: 'Italian',
  },
  {
    id: '6',
    image:
      'https://images.pexels.com/photos/2245436/pexels-photo-2245436.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    uploaded: '26 Jan,2022',
    name: 'Italian',
  },
  {
    id: '7',
    image:
      'https://images.pexels.com/photos/6791741/pexels-photo-6791741.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    uploaded: '26 Jan,2022',
    name: 'Spanish',
  },
  {
    id: '8',
    image:
      'https://images.pexels.com/photos/2132126/pexels-photo-2132126.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    uploaded: '26 Jan,2022',
    name: 'Spanish',
  },
  {
    id: '9',
    image:
      'https://images.pexels.com/photos/2827374/pexels-photo-2827374.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    uploaded: '26 Jan,2022',
    name: 'Spanish',
  },
  {
    id: '10',
    image:
      'https://images.pexels.com/photos/2622179/pexels-photo-2622179.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    uploaded: '26 Jan,2022',
    name: 'Spanish',
  },
  {
    id: '11',
    image:
      'https://images.pexels.com/photos/2245436/pexels-photo-2245436.png?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    uploaded: '26 Jan,2022',
    name: 'Spanish',
  },
  {
    id: '12',
    image:
      'https://images.pexels.com/photos/6791741/pexels-photo-6791741.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    uploaded: '26 Jan,2022',
    name: 'Spanish',
  },
  {
    id: '13',
    image:
      'https://images.pexels.com/photos/2132126/pexels-photo-2132126.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    uploaded: '26 Jan,2022',
    name: 'Spanish',
  },
  {
    id: '14',
    image:
      'https://images.pexels.com/photos/2827374/pexels-photo-2827374.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    uploaded: '26 Jan,2022',
    name: 'Spanish',
  },
  {
    id: '15',
    image:
      'https://images.pexels.com/photos/2622179/pexels-photo-2622179.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    uploaded: '26 Jan,2022',
    name: 'Spanish',
  },
  {
    id: '16',
    image:
      'https://images.pexels.com/photos/2622179/pexels-photo-2622179.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1',
    uploaded: '26 Jan,2022',
    name: 'Spanish',
  },
];

const Dashboard = () => {
  const [value, setValue] = useState('1');
  const [nestedValue, setNestedValue] = useState('2.1');

  // dropdown states
  const [isOpenSummary1, setIsOpenSummary1] = useState(false);
  const [isOpenSummary2, setIsOpenSummary2] = useState(false);

  const [isOpenMonthly1, setIsOpenMonthly1] = useState(false);
  const [isOpenMonthly2, setIsOpenMonthly2] = useState(false);

  const userDetails = useSelector((state) => state.auth.userDetails);
  const userId = useSelector((state) => state.auth.userId);

  const dispatch = useDispatch();

  useEffect(() => {
    console.log(userDetails);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNestedChange = (event, newValue) => {
    setNestedValue(newValue);
  };

  const [ContentHovereTabsValue, setContentHovereTabsValue] =
    useState('Art');

  const [SelectedNestedArtTab, setSelectedNestedArtTab] =
    useState('UploadFiles');

  const [SelectedNestedPhotoTab, setSelectedNestedPhotoTab] =
    useState('UploadFiles');
  const [SelectedNestedFootageTab, setSelectedNestedFootageTab] =
    useState('UploadFiles');
  const [SelectedNestedMusicTab, setSelectedNestedMusicTab] =
    useState('UploadFiles');
  const [SelectedNestedTemplatesTab, setSelectedNestedTemplatesTab] =
    useState('UploadFiles');

  const [images, setImages] = useState();

  const getDraftSubmit = async () => {
    try {
      const res = await httpClient.get(
        `/draft_master/getContributorWiseDraftMasterList/${userId}`
      );
      console.log(res.data);
      // console.log(res.data[0].imageMaster);
      setImages(res.data[0].imageMaster);
    } catch (err) {
      console.error(err);
    }
  };

  const [ReviewList, setReviewList] = useState();

  const getForReviewList = async () => {
    const res = await httpClient.get(
      `/art_master/getUserIdAndStatusWiseUserMaster/${userId}/InReview`
    );
    console.log(res.data);

    setReviewList(res.data);
    // handleClick(res.data[0]);
  };
  const [ApprovedList, setApprovedList] = useState();

  const getForApprovedList = async () => {
    const res = await httpClient.get(
      `/art_master/getUserIdAndStatusWiseUserMaster/${userId}/Approved`
    );
    console.log(res.data);

    setApprovedList(res.data);
    // handleClick(res.data[0]);
  };
  const [RejectedList, setRejectedList] = useState();

  const getForRejectedList = async () => {
    const res = await httpClient.get(
      `/art_master/getUserIdAndStatusWiseUserMaster/${userId}/Rejected`
    );

    console.log(res.data);

    setRejectedList(res.data);
    // handleClick(res.data[0]);
  };

  useEffect(() => {
    // setImages(selectedImages);
    // dispatch(setSelectedImages([]));
    getDraftSubmit();
    getForReviewList();
    getForApprovedList();
    getForRejectedList();
  }, []);

  const grayBox = [
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
  ];

  const [grayNum, setgrayNum] = useState(grayBox?.length);

  useEffect(() => {
    if (images?.length < 16) {
      setgrayNum(grayBox?.length - images?.length);
    }

    console.log(images?.length);
  }, [images]);

  const grayBoxReview = [
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
  ];

  const [grayNumReview, setgrayNumReview] = useState(
    grayBoxReview?.length
  );

  useEffect(() => {
    if (ReviewList?.length < 11) {
      setgrayNumReview(grayBoxReview?.length - ReviewList?.length);
    }
    console.log(ReviewList?.length);
  }, [ReviewList]);

  const grayBoxApproved = [
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
  ];

  const [grayNumApproved, setgrayNumApproved] = useState(
    grayBoxApproved?.length
  );

  useEffect(() => {
    if (ApprovedList?.length < 11) {
      setgrayNumApproved(
        grayBoxApproved?.length - ApprovedList?.length
      );
    }

    console.log(ApprovedList?.length);
  }, [ApprovedList]);

  const grayBoxRejected = [
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
  ];

  const [grayNumRejected, setgrayNumRejected] = useState(
    grayBoxRejected?.length
  );

  useEffect(() => {
    if (RejectedList?.length < 11) {
      setgrayNumRejected(
        grayBoxRejected?.length - RejectedList?.length
      );
    }

    console.log(RejectedList?.length);
  }, [RejectedList]);

  return (
    <>
      <div className='w-full'>
        <div className='w-[1168px] mx-[auto] flex flex-col gap-[32px] pt-[16px]'>
          <div className='flex gap-[32px]'>
            <div
              className='w-[367px] h-[429px] rounded-[16px] px-[16px] py-[16px] '
              style={{
                boxShadow: '#f0f0f0 0px 0px 4.3px 4px',
              }}
            >
              <div className='flex gap-[12px]'>
                <div>
                  <div
                    className='w-[112px] h-[112px] rounded-[5000px] bg-no-repeat bg-center bg-cover'
                    style={{
                      backgroundImage: `url(${userDetails?.profileImage})`,
                    }}
                  ></div>
                </div>
                <div className='flex flex-col gap-[17px]'>
                  <div>
                    <p className='text-[11px] text-[#bbbbbb] leading-[1.2] mb-[4px]'>
                      Joined 5 July, 2024
                    </p>
                    <div className='mb-[3px]'>
                      <Level3 />
                    </div>
                    {/* <img src={level3} alt='' /> */}
                    <p className='text-[11px] text-primaryGray leading-[14px] mb-[1px]'>
                      Contributor ID: {userDetails?.userUniqueNo}
                    </p>
                    <p className='text-primaryBlack text-[18px] mb-[2px] font-medium leading-[1.2]'>
                      {/* Azra Creations */}{' '}
                      {userDetails?.displayName}
                    </p>
                    <p className='text-[11px] text-primaryGray leading-[1.2] font-medium mb-[6px]'>
                      Illustrator/Photographer
                    </p>
                    {/* <p className='text-[11px] text-primaryGray leading-[1.2]'>
                      Specialization in Illustration and Photography
                    </p> */}
                    <>
                      {/* <img
                        className='inline-block'
                        src={location}
                        alt=''
                      /> */}
                      <Location className='inline-block' />{' '}
                      <span className='text-[11px] text-primaryGray'>
                        {/* {userDetails?.residentialAddress?.cityName},
                        {userDetails?.residentialAddress?.countryName} */}
                        Pune, Maharashtra, India
                      </span>
                    </>
                  </div>
                </div>
              </div>

              <div className='mt-[12px]'>
                <p className='text-[11px] text-[#bbbbbb] leading-[1] relative top-[3.6px]'>
                  75% Profile Strength
                </p>
                <Slider
                  sx={{
                    '& .MuiSlider-thumb': {
                      display: 'none',
                    },
                    '& .MuiSlider-track': {
                      backgroundColor: '#01e6e6',
                      border: 'none',
                      // borderRadius: '0px!important',
                      borderTopRightRadius: '0px!important',
                      borderBottomRightRadius: '0px!important',
                      borderTopLeftRadius: '5000px!important',
                      borderBottomLeftRadius: '5000px!important',
                      background:
                        'linear-gradient(to right, #fd4b9e, #9794d0, #46d59a, #beed10)',
                    },
                    // '& .MuiSlider-track': {
                    //   opacity: '0.5',
                    //   border: 'none',
                    // },
                    height: '8px',
                    width: '100%',
                    borderRadius: '4px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                  }}
                  disabled
                  defaultValue={75}
                  aria-label='Default'
                  // valueLabelDisplay='auto'
                />
              </div>

              <div className='mb-[16px] mt-[16px]'>
                <button className='gray30HButton'>
                  Update Profile
                </button>
              </div>

              <div className=''>
                <div className='border-t-2 border-b-2 border-[#EFEFEF]'>
                  <div className='w-[100%]'>
                    <div className='border-b border-[#EFEFEF] flex items-center h-[17px]'>
                      <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[74px]'>
                        Subjects:
                      </p>
                      <p className='text-primaryGray text-sm12 font-normal  leading-[15px]'>
                        Nature, Travel, Lifestyle, Sports, People
                      </p>
                    </div>

                    <div className='border-b border-[#EFEFEF] flex items-center h-[17px]'>
                      <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[74px]'>
                        Styles:
                      </p>
                      <p className='text-primaryGray text-sm12 font-normal gap-[4px] leading-[15px] flex items-center'>
                        Abstract, Realistic, Modern
                      </p>
                    </div>

                    <div className='flex items-center h-[16px]'>
                      <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[74px]'>
                        Equipments:
                      </p>
                      <p className='text-primaryGray text-sm12 font-normal gap-[4px] leading-[15px] flex items-center'>
                        Canon 550D, Canon 5D Mark II
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='mt-[14px]'>
                <p className='text-[11px] text-[#bbbbbb] mb-[2px]'>
                  Recognitions
                </p>
                <div className='flex gap-[16px]'>
                  <div>
                    <ContriRecog1 />
                  </div>
                  <div>
                    <ContriRecog2 />
                  </div>
                  <div>
                    <ContriRecog3 />
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                boxShadow: '#f0f0f0 0px 0px 4.3px 4px',
              }}
              className='w-[769px] h-[429px] rounded-[16px] px-[16px] py-[16px] flex flex-col gap-[16px]'
            >
              <p className='text-[18px] font-medium text-primaryBlack leading-[1]'>
                Upload Content
              </p>

              <div className='flex'>
                <div
                  onClick={() => {
                    setContentHovereTabsValue('Art');
                  }}
                  className={`${
                    ContentHovereTabsValue === 'Art'
                      ? 'bg-[#bbbbbb] text-[#333333] border-y-[#bbbbbb] border-l-[#bbbbbb] '
                      : 'border-y-[#e9e9e9] bg-[#EEEEEE]  border-l-[#e9e9e9] text-[#757575]  '
                  } hover:bg-[#bbbbbb] hover:text-[#333333] hover:border-y-[#bbbbbb] hover:border-l-[#bbbbbb] cursor-pointer border-l border-y text-[13px] font-medium flex items-center leading-5 h-[36px] pl-[16px] pr-[12px]   rounded-l-3xl `}
                >
                  Art
                </div>
                <div
                  onClick={() => {
                    setContentHovereTabsValue('Photos');
                  }}
                  className={`${
                    ContentHovereTabsValue === 'Photos'
                      ? 'bg-[#bbbbbb] border-y-[#bbbbbb] text-[#333333]'
                      : 'text-[#757575]  bg-[#EEEEEE]  border-y-[#e9e9e9] '
                  } hover:bg-[#bbbbbb] hover:border-y-[#bbbbbb] hover:text-[#333333]  cursor-pointer text-[13px] font-medium flex items-center leading-5 h-[36px] px-[12px] border-r-[#D7D7D7] border-l-[#D7D7D7] border-r border-l border-y`}
                >
                  Photos
                </div>

                <div
                  onClick={() => {
                    setContentHovereTabsValue('Footage');
                  }}
                  className={`${
                    ContentHovereTabsValue === 'Footage'
                      ? 'border-y-[#bbbbbb] bg-[#bbbbbb] text-[#333333]'
                      : 'text-[#757575] border-y-[#e9e9e9] bg-[#EEEEEE]'
                  } hover:border-y-[#bbbbbb] hover:bg-[#bbbbbb] hover:text-[#333333]  cursor-pointer text-[13px] font-medium flex items-center leading-5 h-[36px] px-[12px] border-r border-r-[#D7D7D7]  border-y `}
                >
                  Footage
                </div>

                <div
                  onClick={() => {
                    setContentHovereTabsValue('Music');
                  }}
                  className={`${
                    ContentHovereTabsValue === 'Music'
                      ? 'border-y-[#bbbbbb] bg-[#bbbbbb] text-[#333333]'
                      : 'text-[#757575] bg-[#EEEEEE] border-y-[#e9e9e9]'
                  } hover:border-y-[#bbbbbb] hover:bg-[#bbbbbb] hover:text-[#333333]  cursor-pointer text-[13px] font-medium flex items-center leading-5 h-[36px] px-[12px] border-r border-r-[#D7D7D7]  border-y `}
                >
                  Music
                </div>
                <div
                  onClick={() => {
                    setContentHovereTabsValue('Templates');
                  }}
                  className={`${
                    ContentHovereTabsValue === 'Templates'
                      ? 'bg-[#bbbbbb] border-y-[#bbbbbb] border-r-[#bbbbbb] text-[#333333]'
                      : 'text-[#757575]  bg-[#EEEEEE]  border-y-[#e9e9e9]'
                  }
                    hover:bg-[#bbbbbb] hover:border-y-[#bbbbbb] hover:border-r-[#bbbbbb] hover:text-[#333333]  cursor-pointer text-[13px] font-medium flex items-center leading-5 h-[36px] pr-[16px] pl-[12px] rounded-r-3xl border-y border-r border-r-[#e9e9e9]`}
                >
                  Templates
                </div>
              </div>

              {ContentHovereTabsValue === 'Art' ? (
                <div>
                  <div className='flex gap-[4px] mb-[8px]'>
                    <div
                      onClick={() => {
                        setSelectedNestedArtTab('UploadFiles');
                      }}
                      className={`${
                        SelectedNestedArtTab === 'UploadFiles'
                          ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                          : 'border-[#eaeaea] text-primaryGray font-medium'
                      }  border rounded-[14px] cursor-pointer h-[28px] w-[91px] hover:text-primaryBlack hover:border-[#e6e6e6] hover:bg-[#e6e6e6] justify-center flex items-center text-[12px]`}
                    >
                      Upload Files
                    </div>
                    <div
                      onClick={() => {
                        setSelectedNestedArtTab('ToSubmit');
                      }}
                      className={`${
                        SelectedNestedArtTab === 'ToSubmit'
                          ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                          : 'border-[#eaeaea] text-primaryGray font-medium'
                      }  border rounded-[14px] h-[28px]  cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
                    >
                      To Submit
                    </div>
                    <div
                      onClick={() => {
                        setSelectedNestedArtTab('ForReview');
                      }}
                      className={`${
                        SelectedNestedArtTab === 'ForReview'
                          ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                          : 'border-[#eaeaea] text-primaryGray font-medium'
                      }  border rounded-[14px] h-[28px]  cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
                    >
                      For Review
                    </div>
                    <div
                      onClick={() => {
                        setSelectedNestedArtTab('ApprovedFiles');
                      }}
                      className={`${
                        SelectedNestedArtTab === 'ApprovedFiles'
                          ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                          : 'border-[#eaeaea] text-primaryGray font-medium'
                      }  border rounded-[14px] h-[28px] cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
                    >
                      Approved Files
                    </div>
                    <div
                      onClick={() => {
                        setSelectedNestedArtTab('RejectedFiles');
                      }}
                      className={`${
                        SelectedNestedArtTab === 'RejectedFiles'
                          ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                          : 'border-[#eaeaea] text-primaryGray  font-medium'
                      }  border rounded-[14px] h-[28px] cursor-pointer  hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] flex items-center text-[12px]`}
                    >
                      Rejected Files
                    </div>
                  </div>

                  {SelectedNestedArtTab === 'UploadFiles' ? (
                    <div>
                      <div
                        style={{
                          width: 'fit-content',
                        }}
                        className='flex border-t border-b border-t-[#efefef] border-b-[#efefef]  '
                      >
                        <div className='flex items-center gap-[5px]'>
                          <span className='contributorUploadCount text-pinkColor'>
                            {images?.length}
                          </span>
                          <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
                            <p className='contributorUploadtext pr-[8px]'>
                              File(s) to be
                              <br />
                              submitted to
                              <br />
                              Artnstock
                            </p>
                          </div>
                        </div>

                        <div className='flex items-center gap-[5px]'>
                          <span className='contributorUploadCount opacity-[20%] text-[#f5721a] opacity-[20%] pl-[5px]'>
                            {ReviewList?.length}
                          </span>
                          <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
                            <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                              File(s)
                              <br />
                              pending for
                              <br />
                              review
                            </p>
                          </div>
                        </div>

                        <div className='flex items-center gap-[5px]'>
                          <span className=' contributorUploadCount opacity-[20%] text-[#a9af20] opacity-[20%]  pl-[5px]'>
                            {ApprovedList?.length}
                          </span>
                          <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
                            <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                              File(s)
                              <br />
                              approved by
                              <br />
                              Artnstock
                            </p>
                          </div>
                        </div>

                        <div className='flex items-center gap-[5px]'>
                          <span className='contributorUploadCount opacity-[20%] text-[#e7233b] opacity-[20%]  pl-[5px]'>
                            {RejectedList?.length}
                          </span>
                          <div className=' h-[40px] flex items-center'>
                            <p className='contributorUploadtext opacity-[40%]'>
                              File(s)
                              <br />
                              rejected by
                              <br />
                              Artnstock
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          width: 'fit-content',
                        }}
                        className=''
                      >
                        {/* <p className='text-pinkColor text-[18px]'>
                              Upload your art files
                            </p>
                            <p className='text-primaryGray text-[12px]'>
                              Get started by uploading your art files
                              to submit.
                            </p> */}

                        <div className='mt-[16px]'>
                          <p className='text-primaryGray text-sm11 font-normal'>
                            Upload{' '}
                            <span className='font-medium'>
                              15 files
                            </span>{' '}
                            (maximum) in a single upload. <br />
                            <span className='text-orangeColor'>
                              Learn more
                            </span>
                          </p>
                          <button
                            onClick={() => {
                              dispatch(setpath('/ Upload'));
                            }}
                            className='gray30HButton mt-[16px]'
                          >
                            Upload Content
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : SelectedNestedArtTab === 'ToSubmit' ? (
                    <div>
                      <div
                        style={{
                          width: 'fit-content',
                        }}
                        className='flex border-t border-b border-t-[#efefef] border-b-[#efefef]'
                      >
                        <div className='flex items-center gap-[5px]'>
                          <span className='contributorUploadCount text-pinkColor'>
                            {images?.length}
                          </span>
                          <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
                            <p className='contributorUploadtext pr-[8px]'>
                              File(s) to be
                              <br />
                              submitted to
                              <br />
                              Artnstock
                            </p>
                          </div>
                        </div>

                        <div className='flex items-center gap-[5px]'>
                          <span className='contributorUploadCount opacity-[20%] text-[#f5721a] opacity-[20%] pl-[5px]'>
                            {ReviewList?.length}
                          </span>
                          <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
                            <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                              File(s)
                              <br />
                              pending for
                              <br />
                              review
                            </p>
                          </div>
                        </div>

                        <div className='flex items-center gap-[5px]'>
                          <span className=' contributorUploadCount opacity-[20%] text-[#a9af20] opacity-[20%]  pl-[5px]'>
                            {ApprovedList?.length}
                          </span>
                          <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
                            <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                              File(s)
                              <br />
                              approved by
                              <br />
                              Artnstock
                            </p>
                          </div>
                        </div>

                        <div className='flex items-center gap-[5px]'>
                          <span className='contributorUploadCount opacity-[20%] text-[#e7233b] opacity-[20%]  pl-[5px]'>
                            {RejectedList?.length}
                          </span>
                          <div className=' h-[40px] flex items-center'>
                            <p className='contributorUploadtext opacity-[40%]'>
                              File(s)
                              <br />
                              rejected by
                              <br />
                              Artnstock
                            </p>
                          </div>
                        </div>
                      </div>

                      {images?.length > 0 ? (
                        <>
                          <div className='flex flex-wrap gap-[8px] mt-[16px]'>
                            {images?.map((obj) => (
                              <div
                                style={{
                                  backgroundImage: `url(${obj?.secureUrl})`,
                                }}
                                className='w-[64px] h-[64px] bg-cover bg-center rounded-[10px]'
                              ></div>
                            ))}

                            {/* test */}
                            {grayBox
                              .slice(0, grayNum)
                              .map((card, index) => (
                                <div
                                  className={
                                    'h-[64px] w-[64px] bg-[#f7f7f7] rounded-[10px]'
                                  }
                                ></div>
                              ))}
                            {/* test */}
                          </div>
                          <div className='mt-[16px]'>
                            <button
                              onClick={() => {
                                dispatch(setpath('/ Upload'));
                              }}
                              className='gray30HButton'
                            >
                              Upload Content
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              width: 'fit-content',
                            }}
                            className='pt-[8px]'
                          >
                            {/* <p className='text-pinkColor text-[18px]'>
                              Upload your art files
                            </p>
                            <p className='text-primaryGray text-[12px]'>
                              Get started by uploading your art files
                              to submit.
                            </p> */}

                            <div className='mt-[16px]'>
                              <p className='text-primaryGray text-[11px] mt-[5px]'>
                                Upload{' '}
                                <span className='font-medium'>
                                  15 files
                                </span>{' '}
                                (maximum) in a single upload.{' '}
                                <span className='text-orangeColor'>
                                  Learn more
                                </span>
                              </p>
                              <button
                                onClick={() => {
                                  dispatch(setpath('/ Upload'));
                                }}
                                className='gray30HButton mt-[16px]'
                              >
                                Upload Content
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ) : SelectedNestedArtTab === 'ForReview' ? (
                    <div>
                      <div
                        style={{
                          width: 'fit-content',
                        }}
                        className='flex border-t border-b border-t-[#efefef] border-b-[#efefef]'
                      >
                        <div className='flex items-center gap-[5px]'>
                          <span className='contributorUploadCount  opacity-[20%] text-pinkColor'>
                            {images?.length}
                          </span>
                          <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
                            <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                              File(s) to be
                              <br />
                              submitted to
                              <br />
                              Artnstock
                            </p>
                          </div>
                        </div>

                        <div className='flex items-center gap-[5px]'>
                          <span className='contributorUploadCount text-[#f5721a] pl-[5px]'>
                            {ReviewList?.length}
                          </span>
                          <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
                            <p className='contributorUploadtext pr-[8px]'>
                              File(s)
                              <br />
                              pending for
                              <br />
                              review
                            </p>
                          </div>
                        </div>

                        <div className='flex items-center gap-[5px]'>
                          <span className=' contributorUploadCount opacity-[20%] text-[#a9af20] opacity-[20%]  pl-[5px]'>
                            {ApprovedList?.length}
                          </span>
                          <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
                            <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                              File(s)
                              <br />
                              approved by
                              <br />
                              Artnstock
                            </p>
                          </div>
                        </div>

                        <div className='flex items-center gap-[5px]'>
                          <span className='contributorUploadCount opacity-[20%] text-[#e7233b] opacity-[20%]  pl-[5px]'>
                            {RejectedList?.length}
                          </span>
                          <div className=' h-[40px] flex items-center'>
                            <p className='contributorUploadtext opacity-[40%]'>
                              File(s)
                              <br />
                              rejected by
                              <br />
                              Artnstock
                            </p>
                          </div>
                        </div>
                      </div>
                      {ReviewList.length > 0 ? (
                        <>
                          <div className='flex flex-wrap gap-[8px] mt-[16px]'>
                            {ReviewList?.map((obj) => (
                              <div
                                style={{
                                  backgroundImage: `url(${obj?.imageMaster?.secureUrl})`,
                                }}
                                className='w-[64px] h-[64px] bg-cover bg-center rounded-[10px]'
                              ></div>
                            ))}
                            {/* test */}
                            {grayBoxReview
                              .slice(0, grayNumReview)
                              .map((card, index) => (
                                <div
                                  className={
                                    'h-[64px] w-[64px] bg-[#f7f7f7] rounded-[10px]'
                                  }
                                ></div>
                              ))}
                            {/* test */}
                          </div>
                          <div className='mt-[16px]'>
                            <button
                              onClick={() => {
                                dispatch(setpath('/ Upload'));
                              }}
                              className='gray30HButton'
                            >
                              Upload Content
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              width: 'fit-content',
                            }}
                            className=''
                          >
                            {/* <p className='text-pinkColor text-[18px]'>
                              Upload your art files
                            </p>
                            <p className='text-primaryGray text-[12px]'>
                              Get started by uploading your art files
                              to submit.
                            </p> */}

                            <div className='mt-[16px]'>
                              <p className='text-primaryGray text-sm11 font-normal'>
                                You have no files pending for review
                              </p>
                              <p className='text-primaryGray text-sm11 font-normal '>
                                Upload your latest work and keep
                                earning.
                              </p>
                              <button
                                onClick={() => {
                                  dispatch(setpath('/ Upload'));
                                }}
                                className='gray30HButton mt-[16px]'
                              >
                                Upload Content
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ) : SelectedNestedArtTab === 'ApprovedFiles' ? (
                    <div>
                      <div
                        style={{
                          width: 'fit-content',
                        }}
                        className='flex border-t border-b border-t-[#efefef] border-b-[#efefef]'
                      >
                        <div className='flex items-center gap-[5px]'>
                          <span className='contributorUploadCount  opacity-[20%] text-pinkColor'>
                            {images?.length}
                          </span>
                          <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
                            <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                              File(s) to be
                              <br />
                              submitted to
                              <br />
                              Artnstock
                            </p>
                          </div>
                        </div>

                        <div className='flex items-center gap-[5px]'>
                          <span className='contributorUploadCount  opacity-[20%] text-[#f5721a] opacity-[20%] pl-[5px]'>
                            {ReviewList?.length}
                          </span>
                          <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
                            <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                              File(s)
                              <br />
                              pending for
                              <br />
                              review
                            </p>
                          </div>
                        </div>

                        <div className='flex items-center gap-[5px]'>
                          <span className=' contributorUploadCount text-[#a9af20] pl-[5px]'>
                            {ApprovedList?.length}
                          </span>
                          <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
                            <p className='contributorUploadtext pr-[8px]'>
                              File(s)
                              <br />
                              approved by
                              <br />
                              Artnstock
                            </p>
                          </div>
                        </div>

                        <div className='flex items-center gap-[5px]'>
                          <span className='contributorUploadCount opacity-[20%] text-[#e7233b] pl-[5px]'>
                            {RejectedList?.length}
                          </span>
                          <div className=' h-[40px] flex items-center'>
                            <p className='contributorUploadtext opacity-[40%]'>
                              File(s)
                              <br />
                              rejected by
                              <br />
                              Artnstock
                            </p>
                          </div>
                        </div>
                      </div>

                      {ApprovedList.length > 0 ? (
                        <>
                          <div className='flex flex-wrap gap-[8px] mt-[16px]'>
                            {ApprovedList?.map((obj) => (
                              <div
                                style={{
                                  backgroundImage: `url(${obj?.imageMaster?.secureUrl})`,
                                }}
                                className='w-[64px] h-[64px] bg-cover bg-center rounded-[10px]'
                              ></div>
                            ))}

                            {/* test */}
                            {grayBoxApproved
                              .slice(0, grayNumApproved)
                              .map((card, index) => (
                                <div
                                  className={
                                    'h-[64px] w-[64px] bg-[#f7f7f7] rounded-[10px]'
                                  }
                                ></div>
                              ))}
                            {/* test */}
                          </div>
                          <div className='mt-[16px]'>
                            <button
                              onClick={() => {
                                dispatch(setpath('/ Upload'));
                              }}
                              className='gray30HButton'
                            >
                              Upload Content
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              width: 'fit-content',
                            }}
                            className='pt-[8px]'
                          >
                            {/* <p className='text-pinkColor text-[18px]'>
                              Upload your art files
                            </p>
                            <p className='text-primaryGray text-[12px]'>
                              Get started by uploading your art files
                              to submit.
                            </p> */}

                            <div className='mt-[16px]'>
                              <p className='text-primaryGray text-sm11 font-normal'>
                                Upload your art files.
                              </p>
                              <p className='text-primaryGray text-sm11 font-normal '>
                                Get started by uploading your art
                                files to submit.
                              </p>
                              <button
                                onClick={() => {
                                  dispatch(setpath('/ Upload'));
                                }}
                                className='gray30HButton mt-[16px]'
                              >
                                Upload Content
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ) : SelectedNestedArtTab === 'RejectedFiles' ? (
                    <div>
                      <div
                        style={{
                          width: 'fit-content',
                        }}
                        className='flex border-t border-b border-t-[#efefef] border-b-[#efefef]'
                      >
                        <div className='flex items-center gap-[5px]'>
                          <span className='contributorUploadCount  opacity-[20%] text-pinkColor'>
                            {images?.length}
                          </span>
                          <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
                            <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                              File(s) to be
                              <br />
                              submitted to
                              <br />
                              Artnstock
                            </p>
                          </div>
                        </div>

                        <div className='flex items-center gap-[5px]'>
                          <span className='contributorUploadCount  opacity-[20%] text-[#f5721a] opacity-[20%] pl-[5px]'>
                            {ReviewList?.length}
                          </span>
                          <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
                            <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                              File(s)
                              <br />
                              pending for
                              <br />
                              review
                            </p>
                          </div>
                        </div>

                        <div className='flex items-center gap-[5px]'>
                          <span className=' contributorUploadCount  text-[#a9af20] opacity-[20%]  pl-[5px]'>
                            {ApprovedList?.length}
                          </span>
                          <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
                            <p className='contributorUploadtext pr-[8px] opacity-[40%]'>
                              File(s)
                              <br />
                              approved by
                              <br />
                              Artnstock
                            </p>
                          </div>
                        </div>

                        <div className='flex items-center gap-[5px]'>
                          <span className='contributorUploadCount text-[#e7233b] pl-[5px]'>
                            {RejectedList?.length}
                          </span>
                          <div className='h-[40px] flex items-center'>
                            <p className='contributorUploadtext'>
                              File(s)
                              <br />
                              rejected by
                              <br />
                              Artnstock
                            </p>
                          </div>
                        </div>
                      </div>

                      {RejectedList.length > 0 ? (
                        <>
                          <div className='flex flex-wrap gap-[8px] mt-[16px]'>
                            {RejectedList?.map((obj) => (
                              <div
                                style={{
                                  backgroundImage: `url(${obj?.imageMaster?.secureUrl})`,
                                }}
                                className='w-[64px] h-[64px] bg-cover bg-center rounded-[10px]'
                              ></div>
                            ))}

                            {/* test */}
                            {grayBoxRejected
                              .slice(0, grayNumRejected)
                              .map((card, index) => (
                                <div
                                  className={
                                    'h-[64px] w-[64px] bg-[#f7f7f7] rounded-[10px]'
                                  }
                                ></div>
                              ))}
                            {/* test */}
                          </div>
                          <div className='mt-[16px]'>
                            <button
                              onClick={() => {
                                dispatch(setpath('/ Upload'));
                              }}
                              className='gray30HButton'
                            >
                              Upload Content
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            style={{
                              width: 'fit-content',
                            }}
                            className=''
                          >
                            {/* <p className='text-pinkColor text-[18px]'>
                              Upload your art files
                            </p>
                            <p className='text-primaryGray text-[12px]'>
                              Get started by uploading your art files
                              to submit.
                            </p> */}

                            <div className='mt-[16px]'>
                              <p className='text-primaryGray text-sm11 font-normal'>
                                Upload your art files.
                              </p>
                              <p className='text-primaryGray text-sm11 font-normal '>
                                Get started by uploading your art
                                files to submit.
                              </p>
                              <button
                                onClick={() => {
                                  dispatch(setpath('/ Upload'));
                                }}
                                className='gray30HButton mt-[16px]'
                              >
                                Upload Content
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              ) : ContentHovereTabsValue === 'Photos' ? (
                <div>
                  <div className='flex gap-[4px] mb-[8px]'>
                    <div
                      onClick={() => {
                        setSelectedNestedPhotoTab('UploadFiles');
                      }}
                      className={`${
                        SelectedNestedPhotoTab === 'UploadFiles'
                          ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                          : 'border-[#eaeaea] text-primaryGray font-medium'
                      }  border rounded-[14px] cursor-pointer h-[28px] w-[91px] hover:text-primaryBlack hover:border-[#e6e6e6] hover:bg-[#e6e6e6] justify-center flex items-center text-[12px]`}
                    >
                      Upload Files
                    </div>
                    <div
                      onClick={() => {
                        setSelectedNestedPhotoTab('ToSubmit');
                      }}
                      className={`${
                        SelectedNestedPhotoTab === 'ToSubmit'
                          ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                          : 'border-[#eaeaea] text-primaryGray font-medium'
                      }  border rounded-[14px] h-[28px]  cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
                    >
                      To Submit
                    </div>
                    <div
                      onClick={() => {
                        setSelectedNestedPhotoTab('ForReview');
                      }}
                      className={`${
                        SelectedNestedPhotoTab === 'ForReview'
                          ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                          : 'border-[#eaeaea] text-primaryGray font-medium'
                      }  border rounded-[14px] h-[28px]  cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
                    >
                      For Review
                    </div>
                    <div
                      onClick={() => {
                        setSelectedNestedPhotoTab('ApprovedFiles');
                      }}
                      className={`${
                        SelectedNestedPhotoTab === 'ApprovedFiles'
                          ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                          : 'border-[#eaeaea] text-primaryGray font-medium'
                      }  border rounded-[14px] h-[28px] cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
                    >
                      Approved Files
                    </div>
                    <div
                      onClick={() => {
                        setSelectedNestedPhotoTab('RejectedFiles');
                      }}
                      className={`${
                        SelectedNestedPhotoTab === 'RejectedFiles'
                          ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                          : 'border-[#eaeaea] text-primaryGray  font-medium'
                      }  border rounded-[14px] h-[28px] cursor-pointer  hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] flex items-center text-[12px]`}
                    >
                      Rejected Files
                    </div>
                  </div>

                  <div
                    style={{
                      width: 'fit-content',
                    }}
                    className='flex border-t border-b border-t-[#efefef] border-b-[#efefef]'
                  >
                    <div className='flex items-center gap-[5px]'>
                      <span className='contributorUploadCount text-pinkColor'>
                        0
                      </span>
                      <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
                        <p className='contributorUploadtext pr-[8px]'>
                          File(s) to be
                          <br />
                          submitted to
                          <br />
                          Artnstock
                        </p>
                      </div>
                    </div>

                    <div className='flex items-center gap-[5px]'>
                      <span className='contributorUploadCount opacity-[20%] text-[#f5721a] opacity-[20%] pl-[5px]'>
                        0
                      </span>
                      <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
                        <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                          File(s)
                          <br />
                          pending for
                          <br />
                          review
                        </p>
                      </div>
                    </div>

                    <div className='flex items-center gap-[5px]'>
                      <span className=' contributorUploadCount opacity-[20%] text-[#a9af20] opacity-[20%]  pl-[5px]'>
                        0
                      </span>
                      <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
                        <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                          File(s)
                          <br />
                          approved by
                          <br />
                          Artnstock
                        </p>
                      </div>
                    </div>

                    <div className='flex items-center gap-[5px]'>
                      <span className='contributorUploadCount opacity-[20%] text-[#e7233b] opacity-[20%]  pl-[5px]'>
                        0
                      </span>
                      <div className=' h-[40px] flex items-center'>
                        <p className='contributorUploadtext opacity-[40%]'>
                          File(s)
                          <br />
                          rejected by
                          <br />
                          Artnstock
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='mt-[16px]'>
                    <p className='text-primaryGray text-sm11 font-normal'>
                      Upload your art files.
                    </p>
                    <p className='text-primaryGray text-sm11 font-normal '>
                      Get started by uploading your art files to
                      submit.
                    </p>
                    <button
                      onClick={() => {
                        dispatch(setpath('/ Upload'));
                      }}
                      className='gray30HButton mt-[16px]'
                    >
                      Upload Content
                    </button>
                  </div>
                </div>
              ) : ContentHovereTabsValue === 'Footage' ? (
                <div>
                  <div className='flex gap-[4px] mb-[8px]'>
                    <div
                      onClick={() => {
                        setSelectedNestedFootageTab('UploadFiles');
                      }}
                      className={`${
                        SelectedNestedFootageTab === 'UploadFiles'
                          ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                          : 'border-[#eaeaea] text-primaryGray font-medium'
                      }  border rounded-[14px] cursor-pointer h-[28px] w-[91px] hover:text-primaryBlack hover:border-[#e6e6e6] hover:bg-[#e6e6e6] justify-center flex items-center text-[12px]`}
                    >
                      Upload Files
                    </div>
                    <div
                      onClick={() => {
                        setSelectedNestedFootageTab('ToSubmit');
                      }}
                      className={`${
                        SelectedNestedFootageTab === 'ToSubmit'
                          ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                          : 'border-[#eaeaea] text-primaryGray font-medium'
                      }  border rounded-[14px] h-[28px]  cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
                    >
                      To Submit
                    </div>
                    <div
                      onClick={() => {
                        setSelectedNestedFootageTab('ForReview');
                      }}
                      className={`${
                        SelectedNestedFootageTab === 'ForReview'
                          ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                          : 'border-[#eaeaea] text-primaryGray font-medium'
                      }  border rounded-[14px] h-[28px]  cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
                    >
                      For Review
                    </div>
                    <div
                      onClick={() => {
                        setSelectedNestedFootageTab('ApprovedFiles');
                      }}
                      className={`${
                        SelectedNestedFootageTab === 'ApprovedFiles'
                          ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                          : 'border-[#eaeaea] text-primaryGray font-medium'
                      }  border rounded-[14px] h-[28px] cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
                    >
                      Approved Files
                    </div>
                    <div
                      onClick={() => {
                        setSelectedNestedFootageTab('RejectedFiles');
                      }}
                      className={`${
                        SelectedNestedFootageTab === 'RejectedFiles'
                          ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                          : 'border-[#eaeaea] text-primaryGray  font-medium'
                      }  border rounded-[14px] h-[28px] cursor-pointer  hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] flex items-center text-[12px]`}
                    >
                      Rejected Files
                    </div>
                  </div>

                  <div
                    style={{
                      width: 'fit-content',
                    }}
                    className='flex border-t border-b border-t-[#efefef] border-b-[#efefef]'
                  >
                    <div className='flex items-center gap-[5px]'>
                      <span className='contributorUploadCount text-pinkColor'>
                        0
                      </span>
                      <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
                        <p className='contributorUploadtext pr-[8px]'>
                          File(s) to be
                          <br />
                          submitted to
                          <br />
                          Artnstock
                        </p>
                      </div>
                    </div>

                    <div className='flex items-center gap-[5px]'>
                      <span className='contributorUploadCount opacity-[20%] text-[#f5721a] opacity-[20%] pl-[5px]'>
                        0
                      </span>
                      <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
                        <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                          File(s)
                          <br />
                          pending for
                          <br />
                          review
                        </p>
                      </div>
                    </div>

                    <div className='flex items-center gap-[5px]'>
                      <span className=' contributorUploadCount opacity-[20%] text-[#a9af20] opacity-[20%]  pl-[5px]'>
                        0
                      </span>
                      <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
                        <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                          File(s)
                          <br />
                          approved by
                          <br />
                          Artnstock
                        </p>
                      </div>
                    </div>

                    <div className='flex items-center gap-[5px]'>
                      <span className='contributorUploadCount opacity-[20%] text-[#e7233b] opacity-[20%]  pl-[5px]'>
                        0
                      </span>
                      <div className=' h-[40px] flex items-center'>
                        <p className='contributorUploadtext opacity-[40%]'>
                          File(s)
                          <br />
                          rejected by
                          <br />
                          Artnstock
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='mt-[16px]'>
                    <p className='text-primaryGray text-sm11 font-normal '>
                      Upload your art files.
                    </p>
                    <p className='text-primaryGray text-sm11 font-normal '>
                      Get started by uploading your art files to
                      submit.
                    </p>
                    <button
                      onClick={() => {
                        dispatch(setpath('/ Upload'));
                      }}
                      className='gray30HButton mt-[16px]'
                    >
                      Upload Content
                    </button>
                  </div>
                </div>
              ) : ContentHovereTabsValue === 'Music' ? (
                <div>
                  <div className='flex gap-[4px] mb-[8px]'>
                    <div
                      onClick={() => {
                        setSelectedNestedMusicTab('UploadFiles');
                      }}
                      className={`${
                        SelectedNestedMusicTab === 'UploadFiles'
                          ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                          : 'border-[#eaeaea] text-primaryGray font-medium'
                      }  border rounded-[14px] cursor-pointer h-[28px] w-[91px] hover:text-primaryBlack hover:border-[#e6e6e6] hover:bg-[#e6e6e6] justify-center flex items-center text-[12px]`}
                    >
                      Upload Files
                    </div>
                    <div
                      onClick={() => {
                        setSelectedNestedMusicTab('ToSubmit');
                      }}
                      className={`${
                        SelectedNestedMusicTab === 'ToSubmit'
                          ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                          : 'border-[#eaeaea] text-primaryGray font-medium'
                      }  border rounded-[14px] h-[28px]  cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
                    >
                      To Submit
                    </div>
                    <div
                      onClick={() => {
                        setSelectedNestedMusicTab('ForReview');
                      }}
                      className={`${
                        SelectedNestedMusicTab === 'ForReview'
                          ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                          : 'border-[#eaeaea] text-primaryGray font-medium'
                      }  border rounded-[14px] h-[28px]  cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
                    >
                      For Review
                    </div>
                    <div
                      onClick={() => {
                        setSelectedNestedMusicTab('ApprovedFiles');
                      }}
                      className={`${
                        SelectedNestedMusicTab === 'ApprovedFiles'
                          ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                          : 'border-[#eaeaea] text-primaryGray font-medium'
                      }  border rounded-[14px] h-[28px] cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
                    >
                      Approved Files
                    </div>
                    <div
                      onClick={() => {
                        setSelectedNestedMusicTab('RejectedFiles');
                      }}
                      className={`${
                        SelectedNestedMusicTab === 'RejectedFiles'
                          ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                          : 'border-[#eaeaea] text-primaryGray  font-medium'
                      }  border rounded-[14px] h-[28px] cursor-pointer  hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] flex items-center text-[12px]`}
                    >
                      Rejected Files
                    </div>
                  </div>

                  <div
                    style={{
                      width: 'fit-content',
                    }}
                    className='flex border-t border-b border-t-[#efefef] border-b-[#efefef]'
                  >
                    <div className='flex items-center gap-[5px]'>
                      <span className='contributorUploadCount text-pinkColor'>
                        0
                      </span>
                      <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
                        <p className='contributorUploadtext pr-[8px]'>
                          File(s) to be
                          <br />
                          submitted to
                          <br />
                          Artnstock
                        </p>
                      </div>
                    </div>

                    <div className='flex items-center gap-[5px]'>
                      <span className='contributorUploadCount opacity-[20%] text-[#f5721a] opacity-[20%] pl-[5px]'>
                        0
                      </span>
                      <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
                        <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                          File(s)
                          <br />
                          pending for
                          <br />
                          review
                        </p>
                      </div>
                    </div>

                    <div className='flex items-center gap-[5px]'>
                      <span className=' contributorUploadCount opacity-[20%] text-[#a9af20] opacity-[20%]  pl-[5px]'>
                        0
                      </span>
                      <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
                        <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                          File(s)
                          <br />
                          approved by
                          <br />
                          Artnstock
                        </p>
                      </div>
                    </div>

                    <div className='flex items-center gap-[5px]'>
                      <span className='contributorUploadCount opacity-[20%] text-[#e7233b] opacity-[20%]  pl-[5px]'>
                        0
                      </span>
                      <div className='h-[40px] flex items-center'>
                        <p className='contributorUploadtext opacity-[40%]'>
                          File(s)
                          <br />
                          rejected by
                          <br />
                          Artnstock
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='mt-[16px]'>
                    <p className='text-primaryGray text-sm11 font-normal'>
                      Upload your art files.
                    </p>
                    <p className='text-primaryGray text-sm11 font-normal '>
                      Get started by uploading your art files to
                      submit.
                    </p>
                    <button
                      onClick={() => {
                        dispatch(setpath('/ Upload'));
                      }}
                      className='gray30HButton mt-[16px]'
                    >
                      Upload Content
                    </button>
                  </div>
                </div>
              ) : ContentHovereTabsValue === 'Templates' ? (
                <div>
                  <div className='flex gap-[4px] mb-[8px]'>
                    <div
                      onClick={() => {
                        setSelectedNestedTemplatesTab('UploadFiles');
                      }}
                      className={`${
                        SelectedNestedTemplatesTab === 'UploadFiles'
                          ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                          : 'border-[#eaeaea] text-primaryGray font-medium'
                      }  border rounded-[14px] cursor-pointer h-[28px] w-[91px] hover:text-primaryBlack hover:border-[#e6e6e6] hover:bg-[#e6e6e6] justify-center flex items-center text-[12px]`}
                    >
                      Upload Files
                    </div>
                    <div
                      onClick={() => {
                        setSelectedNestedTemplatesTab('ToSubmit');
                      }}
                      className={`${
                        SelectedNestedTemplatesTab === 'ToSubmit'
                          ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                          : 'border-[#eaeaea] text-primaryGray font-medium'
                      }  border rounded-[14px] h-[28px]  cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
                    >
                      To Submit
                    </div>
                    <div
                      onClick={() => {
                        setSelectedNestedTemplatesTab('ForReview');
                      }}
                      className={`${
                        SelectedNestedTemplatesTab === 'ForReview'
                          ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                          : 'border-[#eaeaea] text-primaryGray font-medium'
                      }  border rounded-[14px] h-[28px]  cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
                    >
                      For Review
                    </div>
                    <div
                      onClick={() => {
                        setSelectedNestedTemplatesTab(
                          'ApprovedFiles'
                        );
                      }}
                      className={`${
                        SelectedNestedTemplatesTab === 'ApprovedFiles'
                          ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                          : 'border-[#eaeaea] text-primaryGray font-medium'
                      }  border rounded-[14px] h-[28px] cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
                    >
                      Approved Files
                    </div>
                    <div
                      onClick={() => {
                        setSelectedNestedTemplatesTab(
                          'RejectedFiles'
                        );
                      }}
                      className={`${
                        SelectedNestedTemplatesTab === 'RejectedFiles'
                          ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                          : 'border-[#eaeaea] text-primaryGray  font-medium'
                      }  border rounded-[14px] h-[28px] cursor-pointer  hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] flex items-center text-[12px]`}
                    >
                      Rejected Files
                    </div>
                  </div>

                  <div
                    style={{
                      width: 'fit-content',
                    }}
                    className='flex border-t border-b border-t-[#efefef] border-b-[#efefef] '
                  >
                    <div className='flex items-center gap-[5px]'>
                      <span className='contributorUploadCount text-pinkColor'>
                        0
                      </span>
                      <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
                        <p className='contributorUploadtext pr-[8px]'>
                          File(s) to be
                          <br />
                          submitted to
                          <br />
                          Artnstock
                        </p>
                      </div>
                    </div>

                    <div className='flex items-center gap-[5px]'>
                      <span className='contributorUploadCount opacity-[20%] text-[#f5721a] opacity-[20%] pl-[5px]'>
                        0
                      </span>
                      <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
                        <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                          File(s)
                          <br />
                          pending for
                          <br />
                          review
                        </p>
                      </div>
                    </div>

                    <div className='flex items-center gap-[5px]'>
                      <span className=' contributorUploadCount opacity-[20%] text-[#a9af20] opacity-[20%]  pl-[5px]'>
                        0
                      </span>
                      <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
                        <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                          File(s)
                          <br />
                          approved by
                          <br />
                          Artnstock
                        </p>
                      </div>
                    </div>

                    <div className='flex items-center gap-[5px]'>
                      <span className='contributorUploadCount opacity-[20%] text-[#e7233b] opacity-[20%]  pl-[5px]'>
                        0
                      </span>
                      <div className=' h-[40px] flex items-center'>
                        <p className='contributorUploadtext opacity-[40%]'>
                          File(s)
                          <br />
                          rejected by
                          <br />
                          Artnstock
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='mt-[16px]'>
                    <p className='text-primaryGray text-sm11 font-normal'>
                      Upload your art files.
                    </p>
                    <p className='text-primaryGray text-sm11 font-normal '>
                      Get started by uploading your art files to
                      submit.
                    </p>
                    <button
                      onClick={() => {
                        dispatch(setpath('/ Upload'));
                      }}
                      className='gray30HButton mt-[16px]'
                    >
                      Upload Content
                    </button>
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              {/* test */}

              {/* test */}
            </div>
          </div>

          {/* second row */}
          <div className='flex gap-[32px]'>
            {/* first box */}

            <div
              style={{
                boxShadow: '#f0f0f0 0px 0px 4.3px 4px',
              }}
              className='w-[367px] h-[495px] rounded-[16px] px-[16px] py-[16px] flex flex-col'
            >
              <p className='text-[18px] leading-[1] font-medium text-primaryBlack flex gap-[4px] items-center'>
                Monthly Earnings{' '}
                <div>
                  <IButton className='fill-[#bbbbbb]' />
                </div>
              </p>
              <div className='flex flex-col'>
                <div className='flex flex-col mb-[10px]'>
                  <div className='flex justify-between pt-[16px]'>
                    {/* test */}
                    <div>
                      <button
                        onClick={() => {
                          setIsOpenMonthly1(!isOpenMonthly1);
                        }}
                        className={`${
                          isOpenMonthly1 === true
                            ? 'dropdownTwoGlobalTrue'
                            : 'dropdownTwoGlobalFalse'
                        } dropdownTwoGlobalCommon w-[164px]`}

                        // className='w-[165px] border border-[#d6d6d6] text-[12px] h-[32px] rounded-[15px] flex items-center justify-between p-[10px]'
                      >
                        <div className='flex flex-col'>
                          <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px]'>
                            Select Category
                          </p>
                          <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                            All Earnings
                          </p>
                        </div>

                        <div>
                          <DropArrow
                            className={`${
                              isOpenMonthly1
                                ? 'transform rotate-180'
                                : ''
                            }`}
                            style={{
                              fill: '#878787',
                            }}
                          />
                        </div>
                      </button>
                      {isOpenMonthly1 && (
                        <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray w-[164px]'>
                          <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                            Digital
                          </li>
                          <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                            Digital
                          </li>
                          <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0]'>
                            Digital
                          </li>
                        </ul>
                      )}
                    </div>
                    {/* test */}

                    {/* test */}
                    <div>
                      <button
                        onClick={() => {
                          setIsOpenMonthly2(!isOpenMonthly2);
                        }}
                        className={`${
                          isOpenMonthly2 === true
                            ? 'dropdownTwoGlobalTrue'
                            : 'dropdownTwoGlobalFalse'
                        } dropdownTwoGlobalCommon w-[164px]`}

                        // className='w-[165px] border border-[#d6d6d6] text-[12px] h-[32px] rounded-[15px] flex items-center justify-between p-[10px]'
                      >
                        {/* <img
                          className='inline-block'
                          src={dropdown}
                          alt=''
                        /> */}

                        {/* test */}

                        <div className='flex flex-col'>
                          <p className='text-[#bbbbbb] flex text-[11px] leading-[1] mb-[1px]'>
                            Select Month
                          </p>
                          <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                            Past 12 Months
                          </p>
                        </div>

                        <div>
                          <DropArrow
                            className={`${
                              isOpenMonthly2
                                ? 'transform rotate-180'
                                : ''
                            }`}
                            style={{
                              fill: '#878787',
                            }}
                          />
                        </div>

                        {/* test */}
                      </button>
                      {isOpenMonthly2 && (
                        <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray w-[164px]'>
                          <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                            Digital
                          </li>
                          <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                            Digital
                          </li>
                          <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0]'>
                            Digital
                          </li>
                        </ul>
                      )}
                    </div>
                    {/* test */}
                  </div>
                </div>
                <div className='h-[275px] mb-[11px]'>
                  <BarChart />
                </div>
                <p className='text-primaryGray text-sm11 font-normal'>
                  This tool helps you compare your earnings from month
                  to month. You can filter results by image type, as
                  well as range or time.
                </p>
              </div>
            </div>

            {/* second box */}
            <div
              style={{
                boxShadow: '#f0f0f0 0px 0px 4.3px 4px',
              }}
              className='w-[367px] h-[495px] rounded-[16px] px-[16px] py-[16px] flex flex-col'
            >
              <p className='text-[18px] leading-[1] font-medium text-primaryBlack flex gap-[4px] items-center'>
                Earnings Summary
                <div>
                  <IButton className='fill-[#bbbbbb]' />
                </div>
              </p>
              <div className='flex flex-col gap-[16px]'>
                <div className='flex justify-between pt-[16px]'>
                  {/* test */}
                  <div>
                    <button
                      onClick={() => {
                        setIsOpenSummary1(!isOpenSummary1);
                      }}
                      className={`${
                        isOpenSummary1 === true
                          ? 'dropdownTwoGlobalTrue'
                          : 'dropdownTwoGlobalFalse'
                      } dropdownTwoGlobalCommon w-[164px]`}

                      // className='w-[165px] border border-[#d6d6d6] text-[12px] h-[32px] rounded-[15px] flex items-center justify-between p-[10px]'
                    >
                      <div className='flex flex-col'>
                        <p className='text-[#bbbbbb] flex text-[11px] leading-[1] mb-[1px]'>
                          Select Category
                        </p>
                        <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                          All Earnings
                        </p>
                      </div>

                      <div>
                        <DropArrow
                          className={`${
                            isOpenSummary1
                              ? 'transform rotate-180'
                              : ''
                          }`}
                          style={{
                            fill: '#878787',
                          }}
                        />
                      </div>
                    </button>
                    {isOpenSummary1 && (
                      <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray w-[164px]'>
                        <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                          Digital
                        </li>
                        <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                          Digital
                        </li>
                        <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0]'>
                          Digital
                        </li>
                      </ul>
                    )}
                  </div>
                  {/* test */}

                  <div>
                    <button
                      onClick={() => {
                        setIsOpenSummary2(!isOpenSummary2);
                      }}
                      className={`${
                        isOpenSummary2 === true
                          ? 'dropdownTwoGlobalTrue'
                          : 'dropdownTwoGlobalFalse'
                      } dropdownTwoGlobalCommon w-[164px]`}

                      // className='w-[165px] border border-[#d6d6d6] text-[12px] h-[32px] rounded-[15px] flex items-center justify-between p-[10px]'
                    >
                      <div className='flex flex-col'>
                        <p className='text-[#bbbbbb] flex text-[11px] leading-[1] mb-[1px]'>
                          Select Month
                        </p>
                        <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                          Past 12 Months
                        </p>
                      </div>

                      <div>
                        <DropArrow
                          className={`${
                            isOpenSummary2
                              ? 'transform rotate-180'
                              : ''
                          }`}
                          style={{
                            fill: '#878787',
                          }}
                        />
                      </div>
                    </button>
                    {isOpenSummary2 && (
                      <ul className='z-50 shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray w-[164px]'>
                        <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                          Digital
                        </li>
                        <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                          Digital
                        </li>
                        <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0]'>
                          Digital
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
                <div className='flex flex-col'>
                  <p className='text-[11px] text-[#bbbbbb] leading-[1]'>
                    Total Earnings
                  </p>
                  <p className='pinkAmount mt-[2px]'>
                    <span className='text-[20px]'>$</span>665.00
                  </p>
                </div>
                <div className='flex flex-col'>
                  <div className='flex justify-between border-t-[2px] border-b-[2px] border-[#efefef] py-[2px]'>
                    <span className='text-[12px] font-medium text-primaryBlack flex w-[108px]'>
                      Earning Types{' '}
                      <div className='bg-[#bbbbbb] w-[16px] h-[16px] text-[white] text-center rounded-[50%] ml-[5px]'>
                        ?
                      </div>
                    </span>
                    <span className='text-[12px] font-medium text-primaryBlack w-[90px]'>
                      Total Downloads
                    </span>
                    <span className='text-[12px] font-medium text-primaryBlack  text-end w-[70px]'>
                      Amount
                    </span>
                  </div>
                  <div className='flex justify-between border-b border-[#efefef] py-[2px] bg-[#f7f7f7] text-primaryGray'>
                    <span className='text-[12px] flex w-[108px] font-medium'>
                      Subscriptions
                    </span>
                    <span className='text-[12px] w-[90px] text-end font-medium'>
                      908
                    </span>
                    <span className='text-[12px] w-[70px] font-medium  text-end'>
                      $400.00
                    </span>
                  </div>
                  <div className='flex justify-between border-b border-[#efefef]  bg-[#f7f7f7] py-[2px] text-primaryGray'>
                    <span className='text-[12px] flex w-[108px] font-medium'>
                      Standard
                    </span>
                    <span className='text-[12px] w-[90px]  text-end font-medium'>
                      5
                    </span>
                    <span className='text-[12px] w-[70px] font-medium  text-end'>
                      $40.00
                    </span>
                  </div>
                  <div className='flex justify-between border-b border-[#efefef] py-[2px] text-primaryGray'>
                    <span className='text-[12px] flex w-[108px] '>
                      Enhanced
                    </span>
                    <span className='text-[12px] w-[90px]  text-end '>
                      7
                    </span>
                    <span className='text-[12px] w-[70px]   text-end'>
                      $88.00
                    </span>
                  </div>
                  <div className='flex justify-between border-b border-[#efefef] py-[2px] text-primaryGray'>
                    <span className='text-[12px] flex w-[108px] '>
                      Market-Freeze
                    </span>
                    <span className='text-[12px] w-[90px]  text-end '>
                      20
                    </span>
                    <span className='text-[12px] w-[70px]   text-end'>
                      $567.00
                    </span>
                  </div>
                  <div className='flex justify-between border-b border-[#efefef] py-[2px] text-primaryGray'>
                    <span className='text-[12px] flex w-[108px] '>
                      Referrals
                    </span>
                    <span className='text-[12px] w-[90px]  text-end '>
                      76
                    </span>
                    <span className='text-[12px] w-[70px]   text-end'>
                      $900.00
                    </span>
                  </div>
                  <p className='text-sm11 font-normal text-orangeColor pt-[2px]'>
                    View detailed Earnings Summary
                  </p>
                </div>
                <div className='flex flex-col'>
                  <p className='text-sm11 font-normal text-[#bbbbbb] leading-[1]'>
                    Unpaid Earnings
                  </p>
                  <p className='pinkAmount mt-[2px]'>
                    <span className='text-[20px]'>$</span>65.00
                  </p>
                  <p className='text-primaryGray text-sm11 font-normal'>
                    Updated approximately every 2 hours.
                  </p>
                </div>
                <p className='text-primaryGray text-sm11 font-normal'>
                  Payments are calculated at the end of every month
                  for contributors who meet their minimum payout
                  amount.{' '}
                  <span className='text-orangeColor'>Learn more</span>
                </p>
              </div>
            </div>
            {/* third box */}
            <div
              style={{
                boxShadow: '#f0f0f0 0px 0px 4.3px 4px',
              }}
              className='w-[367px] h-[495px] rounded-[16px] px-[16px] py-[16px] flex flex-col'
            >
              <div className='text-[18px] leading-[1] font-medium text-primaryBlack flex gap-[4px] items-center'>
                <span>Downloads</span>
                <div>
                  <IButton className='fill-[#bbbbbb]' />
                </div>
              </div>
              <div className='flex flex-col gap-[16px]'>
                <div>
                  <div className='flex justify-center'>
                    <img src={map} alt='' />
                  </div>
                  <p className='text-sm11 text-[#bbbbbb] mt-[14px] '>
                    {/* <span className='text-orangeColor text-[75px] leading-[0]'>
                      .
                    </span> */}
                    Latest Download
                  </p>
                  <p className='text-sm11 text-primaryGray'>
                    Pune, Maharashtra, India
                  </p>
                </div>
                <div className='flex flex-col'>
                  <p className='text-sm11 text-[#bbbbbb] '>
                    Total Downloads
                  </p>
                  <p className='pinkAmount mt-[2px]'>482</p>
                </div>
                {/* test */}
                <div className='flex flex-col'>
                  <div className='flex justify-between border-t-[2px] border-b-[2px] border-[#efefef] py-[2px]'>
                    <span className='text-[12px] font-medium text-primaryBlack flex w-[51px]'>
                      Photos{' '}
                    </span>
                    <span className='text-[12px]  font-medium text-primaryBlack flex w-[140px]'>
                      Location
                    </span>

                    <span className='text-[12px]  font-medium text-primaryBlack w-[70px] text-end'>
                      Date
                    </span>
                    <span className='text-[12px]  font-medium text-primaryBlack w-[75px]  text-end'>
                      Earnings
                    </span>
                  </div>

                  <div className='flex justify-between border-b border-[#efefef] py-[4px] text-primaryGray'>
                    <span className='text-[12px] flex  w-[51px]'>
                      <div
                        className='w-[32px] h-[32px] bg-no-repeat bg-center bg-cover rounded-[4px]'
                        style={{
                          backgroundImage: `url(${downimg1})`,
                        }}
                      ></div>
                    </span>
                    <span className='text-[12px] flex w-[140px] my-[auto]'>
                      Sydney, Australia
                    </span>

                    <span className='text-[12px] w-[70px] my-[auto]  text-end'>
                      09/08/2021
                    </span>
                    <span className='text-[12px] w-[75px] my-[auto]  text-end'>
                      $5000
                    </span>
                  </div>
                  <div className='flex justify-between border-b border-[#efefef] py-[4px] text-primaryGray'>
                    <span className='text-[12px] flex  w-[51px]'>
                      <div
                        className='w-[32px] h-[32px] bg-no-repeat bg-center bg-cover rounded-[4px]'
                        style={{
                          backgroundImage: `url(${downimg2})`,
                        }}
                      ></div>
                    </span>
                    <span className='text-[12px] flex w-[140px] my-[auto]'>
                      Pune, India
                    </span>

                    <span className='text-[12px] w-[70px] my-[auto]  text-end'>
                      08/09/2022
                    </span>
                    <span className='text-[12px] w-[75px] my-[auto]  text-end'>
                      $7000
                    </span>
                  </div>
                  <div className='flex justify-between border-b border-[#efefef] py-[4px] text-primaryGray'>
                    <span className='text-[12px] flex  w-[51px]'>
                      <div
                        className='w-[32px] h-[32px] bg-no-repeat bg-center bg-cover rounded-[4px]'
                        style={{
                          backgroundImage: `url(${downimg3})`,
                        }}
                      ></div>
                    </span>
                    <span className='text-[12px] flex w-[140px] my-[auto]'>
                      Ohio, USA
                    </span>

                    <span className='text-[12px] w-[70px] my-[auto]  text-end'>
                      08/02/2020
                    </span>
                    <span className='text-[12px] w-[75px] my-[auto]  text-end'>
                      $8908
                    </span>
                  </div>
                </div>
                {/* test */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
