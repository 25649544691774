import React from 'react';
import ViewMyStoreBanner from '../../assets/images/View My Store/ViewMyStoreBanner.png';
import Share from '../../assets/images/View My Store/Share.png';
import Img1 from '../../assets/images/View My Store/Img1.png';
import Img2 from '../../assets/images/View My Store/Img2.png';
import Img3 from '../../assets/images/View My Store/Img3.png';
import ProfilePhoto from '../../assets/images/View My Store/ProfilePhoto.png';
import location from '../../assets/images/View My Store/location.png';
import Pro from '../../assets/images/View My Store/Pro.png';
import searchIcon from '../../assets/images/View My Store/search.png';
import { useState } from 'react';
import dropdown from '../../assets/images/socials/dropdown.png';
import { ReactComponent as Grid1 } from '../../assets/images/Icons/grid1.svg';

// import { ReactComponent as DropArrow } from '../../assets/images/Icons/Down arrow.svg';

import { ReactComponent as Grid } from '../../assets/images/Icons/grid.svg';
import { ReactComponent as SmallCross } from '../../assets/images/Icons/smallCross.svg';

import { ReactComponent as FolderImage } from '../../assets/images/contributor/fileManager/Icon_Folder_48x48.svg';

import { ReactComponent as Level3 } from '../../assets/images/contributor/activateProd/Level3.svg';

import { ReactComponent as Location } from '../../assets/images/contributor/Icon_Location_6x8.svg';

import { useEffect } from 'react';
import { useRef } from 'react';

import { ReactComponent as SearchIcon } from '../../assets/images/Icons/searchDarkIcon.svg';
// test

import { ReactComponent as ContriRecog1 } from '../../assets/images/art-details/icons/ContriRecog1.svg';
import { ReactComponent as ContriRecog2 } from '../../assets/images/art-details/icons/ContriRecog2.svg';
import { ReactComponent as ContriRecog3 } from '../../assets/images/art-details/icons/ContriRecog3.svg';

import filterIcon from '../../assets/images/Icons/FilterIcon.svg';
import { httpClient } from '../../axios';
import closeIcon from '../../assets/images/Icons/crossIcon.svg';
import artIcon from '../../assets/images/Icons/artIcon.svg';
import photoIcon from '../../assets/images/Icons/photosIcon.svg';
import footageIcon from '../../assets/images/Icons/videoIcon.svg';
import musicIcon from '../../assets/images/Icons/music.svg';
import templatesIcon from '../../assets/images/Icons/templatesIcon.svg';
import productsIcon from '../../assets/images/Icons/productsIcon.svg';
import dropArrow from '../../assets/images/Icons/Down arrow.svg';
import { useDetectClickOutside } from 'react-detect-click-outside';
// import Footer from '../../components/footer/Footer';
import smallRightArrow from '../../assets/images/Icons/smallRightArrow.svg';
import smallLeftArrow from '../../assets/images/Icons/smallLeftArrow.svg';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useLocation } from 'react-router-dom';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
// images for art Hover

import save from '../../assets/images/artList/save.png';
import similar from '../../assets/images/artList/similar.png';
import profile from '../../assets/images/artList/profile.png';
import shopCart from '../../assets/images/artList/shopCart.png';
import enlarge from '../../assets/images/artList/enlarge.png';
import share from '../../assets/images/artList/Share.png';
import { ReactComponent as Wishlist } from '../../assets/images/artList/wishlistsvg.svg';

import ArtLogo from '../../assets/images/View My Store/artlogo.svg';
import FootageLogo from '../../assets/images/View My Store/footagelogo.svg';
import MusicLogo from '../../assets/images/View My Store/musiclogo.svg';
import PhotosLogo from '../../assets/images/View My Store/photoslogo.svg';
import ProductsLogo from '../../assets/images/View My Store/productslogo.svg';
import TemplatesLogo from '../../assets/images/View My Store/templateslogo.svg';
import view from '../../assets/images/View My Store/View.png';
import folderImg from '../../assets/images/View My Store/folderImg.png';
// import { ReactComponent as ArtLogo } from '../../assets/images/View My Store/artlogo.svg';
// import { ReactComponent as FootageLogo } from '../../assets/images/View My Store/footagelogo.svg';
// import { ReactComponent as MusicLogo } from '../../assets/images/View My Store/musiclogo.svg';
// import { ReactComponent as PhotosLogo } from '../../assets/images/View My Store/photoslogo.svg';
// import { ReactComponent as ProductsLogo } from '../../assets/images/View My Store/productslogo.svg';
// import { ReactComponent as TemplatesLogo } from '../../assets/images/View My Store/templateslogo.svg';

import artFiles from '../../assets/images/contributor/artFiles.png';
import photosFiles from '../../assets/images/contributor/photosFiles.png';
import footageFiles from '../../assets/images/contributor/footageFiles.png';
import musicFiles from '../../assets/images/contributor/musicFiles.png';
import templatesFiles from '../../assets/images/contributor/templatesFiles.png';
import productFiles from '../../assets/images/contributor/productFiles.png';
import folder from '../../assets/images/contributor/folder.png';
// import dropArrow from '../../assets/images/Icons/Down arrow.svg';
import backArrow from '../../assets/images/contributor/backArrow.png';
import nextArrow from '../../assets/images/contributor/nextArrow.png';
import folderOfImage from '../../assets/images/contributor/folderOfImage.png';
import allfiles from '../../assets/images/contributor/allfiles.png';

import { ReactComponent as DropArrow } from '../../assets/images/Icons/Down arrow.svg';

import { ReactComponent as SimilarIcon } from '../../assets/images/artList/SimilarIcon.svg';

import { ReactComponent as ArtIconSmall } from '../../assets/images/Icons/small_Icons/Icon_Art.svg';
import { ReactComponent as PhotosIconSmall } from '../../assets/images/Icons/small_Icons/Icon_Photos.svg';
import { ReactComponent as FootagesIconSmall } from '../../assets/images/Icons/small_Icons/Icon_Footages.svg';
import { ReactComponent as MusicIconSmall } from '../../assets/images/Icons/small_Icons/Icon_Music.svg';
import { ReactComponent as TemplatesIconSmall } from '../../assets/images/Icons/small_Icons/Icon_Templates.svg';
import { ReactComponent as ProductsIconSmall } from '../../assets/images/Icons/small_Icons/Icon_Products.svg';

import { ReactComponent as PlusIcon } from '../../assets/images/artList/PlusIcon.svg';
// import { ReactComponent as SimilarIcon } from '../../assets/images/artList/SimilarIcon.svg';
import { ReactComponent as ProfileIcon } from '../../assets/images/artList/ProfileIcon.svg';
import { ReactComponent as CartIcon } from '../../assets/images/artList/CartIcon.svg';
import { ReactComponent as ShareIcon } from '../../assets/images/artList/ShareIcon.svg';
import { ReactComponent as EnlargeIcon } from '../../assets/images/artList/EnlargeIcon.svg';

import { ReactComponent as Folder } from '../../assets/images/contributor/fileManager/folderIcon.svg';

import Footer from '../../components/footer/Footer';

const allfilesstaticModified = [
  {
    artId: '1',
    name: 'Abstract',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1710757174/artnstock/kqx8dhc9d4yrzzhernoi.jpg',
    },
  },
  {
    artId: '2',
    name: 'Modern',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1710757150/artnstock/f98ybc3z6y9mwcgjlow9.jpg',
    },
  },
  {
    artId: '3',
    name: 'Figurative',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709209271/artnstock/gijngx88eyuhnj4qmp4x.jpg',
    },
  },
  {
    artId: '4',
    name: 'Art for Kids',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709209271/artnstock/biduhp7b8tphzxkztb6k.jpg',
    },
  },
  {
    artId: '5',
    name: 'Humour',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709209278/artnstock/h28giwsqrml9glmghnhd.jpg',
    },
  },
  {
    artId: '6',
    name: 'Realism',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709209279/artnstock/b1fiwpa3hp5tbnb34r1c.jpg',
    },
  },
  {
    artId: '7',
    name: 'Abstract',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709286337/artnstock/gkkcaq6u2794an0qozvr.jpg',
    },
  },
  {
    artId: '8',
    name: 'Figurative',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709286394/artnstock/gkl6nw8h56qqq6fvuws2.jpg',
    },
  },
  {
    artId: '9',
    name: 'Abstract',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709623233/artnstock/d5xasbxcm2wu3dqbqz3p.jpg',
    },
  },
  {
    artId: '10',
    name: 'Modern',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709647310/artnstock/zz5wrgotncfe8vhir20n.jpg',
    },
  },
  {
    artId: '11',
    name: 'Abstract',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709209278/artnstock/lp6ztcrbordkvuuhrffc.jpg',
    },
  },
  {
    artId: '12',
    name: 'Figurative',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709647310/artnstock/w9lqreb1iisxizonnijl.jpg',
    },
  },
  // Add more objects as needed
];

const popularList = [
  {
    id: 1,
    name: 'Featured',
  },
  {
    id: 2,
    name: 'Newest',
  },
  {
    id: 3,
    name: 'Price:Low to High',
  },
  {
    id: 4,
    name: 'Price:High to Low',
  },
];
// test

const ContributorViewMyStore = () => {
  const [isOpenSortBy, setIsOpenSortBy] = useState(false);

  // test
  const navigate = useNavigate();
  // Dropdowns
  const [show, setShow] = useState(false);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [artsList, setArtsList] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const [sizeBtn, setSizeBtn] = useState();
  const [orientationBtn, setOrientationBtn] = useState();
  const [title, setTitle] = useState('');

  const subjectId = useSelector((state) => state.subjectId.subjectId);

  const userId = useSelector((state) => state.auth.userId);

  // useEffect(() => {
  //   console.log(subjectId);
  // }, [subjectId]);

  // api calls
  const getAllArtList = async () => {
    try {
      if (subjectId === null) {
        const res = await httpClient.get(
          '/art_master/getActiveArtMasterList'
        );
        setTitle('All Art');
        setArtsList(res.data);
        console.log(res.data);
      } else {
        const res = await httpClient.get(
          `/art_master/subjectNameWiseArtList/${subjectId.subjectName}`
        );
        // const res = await httpClient.get(
        //   `/art_master/getSubjectIdWiseSubjectMaster/${subjectId.subjectId}`
        // );
        setTitle(subjectId.subjectName);
        // console.log(res.data);
        setArtsList(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllArtList();
  }, []);

  const changeSizeFilter = (text) => {
    setSizeBtn(text);
  };

  const changeOrientationFilter = (text) => {
    setOrientationBtn(text);
  };

  const goToArtDetailsPage = (id) => {
    navigate(`/art/art-details`, { state: { id } });
  };

  const [popupArray, setPopupArray] = useState([]);

  const popupOfHover = (id) => {
    // console.log(typeof popupArray);
    const find = popupArray.find((obj) => obj.id === id.id);
    if (find === undefined) {
      setPopupArray((prev) => [...prev, id]);
    } else if (find !== undefined) {
      setPopupArray(popupArray.filter((obj) => obj.id !== id.id));
    }
  };
  const [popupArrayFolder, setPopupArrayFolder] = useState([]);

  const popupOfHoverFolder = (id) => {
    // console.log(typeof popupArray);
    const find = popupArrayFolder.find((obj) => obj.id === id.id);
    if (find === undefined) {
      setPopupArrayFolder((prev) => [...prev, id]);
    } else if (find !== undefined) {
      setPopupArrayFolder(
        popupArrayFolder.filter((obj) => obj.id !== id.id)
      );
    }
  };

  const [wishlist, setwishlist] = useState();

  useEffect(() => {
    getAllWishlistByUserId();
  }, []);

  const getAllWishlistByUserId = async () => {
    try {
      const res = await httpClient.get(
        `/wishlist_master/getByUserIdList/${userId}`
      );
      setwishlist(res.data);
      // console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  };
  // above code is for valdating if artId already exists in wishlist

  const addToWishlist = (artId) => {
    let findId;
    wishlist.forEach((obj) => {
      if (obj.artMaster !== null) {
        if (obj.artMaster.artId === artId) {
          findId = undefined;
        }
      }
    });

    if (!findId) {
      const object = {
        artId: artId,
        id: userId,
      };
      httpClient.post(`/wishlist_master/save`, object).then((res) => {
        // console.log(res.data);
        getAllWishlistByUserId();
      });
    }
  };

  const wishlistDelete = async (id) => {
    wishlist?.forEach(async (obj) => {
      if (obj.artMaster.artId === id) {
        try {
          const res = await httpClient.delete(
            `/wishlist_master/delete/${obj.wishListId}`
          );
          getAllWishlistByUserId();
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  // Image Optimization
  const [imageGrid, setImageGrid] = useState('grid');

  const imageLinkChange = (url) => {
    const str = url;
    const newStr = !showSidebar
      ? 'upload/c_fit,w_299/'
      : 'upload/c_fit,w_308/';
    const updatedStr = str.replace('upload/', newStr);
    return updatedStr;
  };

  const imageLinkChangeSquaregrid = (url) => {
    const str = url;
    const newStr = !showSidebar
      ? 'upload/c_scale,w_299,h_299/'
      : 'upload/c_scale,w_308,h_299/';
    const updatedStr = str.replace('upload/', newStr);
    return updatedStr;
  };

  const imageLinkChangeFolder = (url) => {
    const str = url;
    const newStr = !showSidebar
      ? 'upload/w_299,h_299,c_scale/'
      : 'upload/w_308,h_308,c_scale/';
    const updatedStr = str?.replace('upload/', newStr);
    return updatedStr;
  };
  // test

  const [artLogoHover, setArtLogoHover] = useState(false);
  const [photosLogoHover, setPhotosLogoHover] = useState(false);
  const [footagesLogoHover, setFootagesLogoHover] = useState(false);
  const [musicLogoHover, setMusicLogoHover] = useState(false);
  const [templatesLogoHover, setTemplatesLogoHover] = useState(false);
  const [productsLogoHover, setProductsLogoHover] = useState(false);

  // test

  const [categoriesFocus, setCategoriesFocus] = useState('allart');
  const [artTabFocus, setArtTabFocus] = useState(false);
  const [photosTabFocus, setPhotosTabFocus] = useState(false);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [isNameOpen, setIsNameOpen] = useState(false);
  const [category, setCategory] = useState('');
  const [folderName, setFolderName] = useState(null);

  const [categories, setCategories] = useState({
    all: [],
    art: [],
    photo: [],
    footage: [],
    music: [],
    templates: [],
    product: [],
  });

  // folder
  // const [folder, setFolder] = useState({});
  const [create, setCreate] = useState(false);
  const [name, setname] = useState('');
  // const [isOpenSortBy, setIsOpenSortBy] = useState(false);

  // const userId = useSelector((state) => state.auth.userId);

  // api calls

  const location = useLocation();

  const user = location.state;

  useEffect(() => {
    console.log(user);
  }, [location]);

  const [allArt, setallArt] = useState(null);

  const getUserIdWiseArts = async () => {
    try {
      const response = await httpClient.get(
        `/art_master/getUserIdWiseArtMasterList/${user.userId}`
      );
      setallArt(response.data);
      // setCategories((prevCategories) => ({
      //   ...prevCategories,
      //   all: response.data,
      // }));
      // console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const [userData, setuserData] = useState({});
  const getUserIdWiseUserData = async () => {
    try {
      const response = await httpClient.get(
        `/user_master/getUserRecord/${user.userId}`
      );
      setuserData(response.data);
      // setCategories((prevCategories) => ({
      //   ...prevCategories,
      //   all: response.data,
      // }));
      // console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getFolders = async () => {
    try {
      const response = await httpClient.get(
        `/file_manager_master/getUserIdWiseFileManagerList/${user.userId}`
      );
      const data = response.data;

      setCategories((prevCategories) => ({
        ...prevCategories,
        art: [],
        photo: [],
        footage: [],
        music: [],
        templates: [],
        product: [],
      }));

      data.forEach((obj) => {
        if (obj.category === 'art') {
          setCategories((prevCategories) => ({
            ...prevCategories,
            art: [...prevCategories.art, obj],
          }));
        } else if (obj.category === 'photos') {
          setCategories((prevCategories) => ({
            ...prevCategories,
            photo: [...prevCategories.photo, obj],
          }));
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUserIdWiseArts();
    getFolders();
    getUserIdWiseUserData();
  }, []);

  // pagination **dont change the sequence of the code below** else will give undefined error
  // const [currentPage, setCurrentPage] = useState(1);
  // const itemsPerPage = 18;

  // let abc = [];

  // if (categoriesFocus === 'all') {
  //   abc = categories.all || [];
  // } else if (categoriesFocus === 'art') {
  //   abc = categories.art || [];
  // }

  // const dataToRender = abc;
  // const start = (currentPage - 1) * itemsPerPage;
  // const end = start + itemsPerPage;
  // const currentData = dataToRender.slice(start, end);

  // const totalPages = Math.ceil(dataToRender.length / itemsPerPage);

  // const handlePrevPage = () => {
  //   if (currentPage > 1 && currentPage <= totalPages) {
  //     setCurrentPage((prevPage) => prevPage - 1);
  //   }
  // };

  // const handleNextPage = () => {
  //   if (currentPage >= 1 && currentPage < totalPages) {
  //     setCurrentPage((prevPage) => prevPage + 1);
  //   }
  // };

  //pagination ends

  const [imagesFolderArray, setImagesFolderArray] = useState(null);
  // mapping folders photos/arts
  const folderImages = (obj) => {
    // console.log(obj);
    setImagesFolderArray(obj);
    setCategoriesFocus('folderImages');
  };

  useEffect(() => {
    console.log(categories);
  }, [categories]);

  // test

  const lengthOfAllCategories = (cate) => {
    let count = 0;
    cate.forEach((obj) =>
      obj.artMaster.forEach((ob) => (count += 1))
    );
    return count;
  };

  return (
    <>
      <div className='wrapper'>
        <main>
          {/* <div
            className='hero'
            style={{
              backgroundImage: `url(${userData?.coverImage})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
           
          </div> */}
          <img src={userData?.coverImage} alt='' />

          <div className='flex mt-5 justify-center'>
            <div className='mr-[13px]'>
              <div
                style={{
                  backgroundImage: `url(${
                    user?.profileImage !== null
                      ? user?.profileImage
                      : profile
                  })`,

                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                className='w-[124px] h-[124px] rounded-[50%]'
              ></div>
              {/* <img
                src=
                alt=''
                className='w-[124px]  rounded-[20%]'
              /> */}
            </div>
            <div className='mr-[28px]'>
              {/* <img src={Pro} alt='' /> */}
              <Level3 />
              <p className='text-[25px] text-[#333333] leading-[1] mt-[4px] font-semibold'>
                {user?.displayName}
              </p>
              <div className='flex flex-col mt-[1px]'>
                <p className='text-sm11 text-[#757575]'>
                  Illustrator/Photographer
                </p>

                <div className='flex items-center gap-[3px]'>
                  <Location className='inline' />
                  <p className='text-sm11 text-[#757575]'>
                    {user?.shippingAddress.cityName},{' '}
                    {user?.shippingAddress.stateName},{' '}
                    {user?.shippingAddress.countryName}
                    {/* Pune, Maharashtra, India */}
                  </p>
                </div>
              </div>
              <div className='mt-[8px] flex items-center gap-[8px]'>
                <button className='greenBlueButton h-[25px]'>
                  About Contributor
                </button>
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect width='16' height='16' />
                  <g id='Artnstock Icons'>
                    <rect
                      width='2119'
                      height='5136'
                      transform='translate(-280 -413)'
                    />
                    <g id='Group 427'>
                      <g id='Icons - 16x16'>
                        <path
                          id='Icon - Share'
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M15.5 3C15.5 4.65685 14.1569 6 12.5 6C11.5891 6 10.773 5.59403 10.2228 4.95315L6.43329 7.3679C6.47699 7.57169 6.5 7.78316 6.5 8C6.5 8.21805 6.47674 8.43067 6.43256 8.63551L10.2207 11.0494C10.7709 10.407 11.5879 10 12.5 10C14.1569 10 15.5 11.3431 15.5 13C15.5 14.6569 14.1569 16 12.5 16C10.8431 16 9.5 14.6569 9.5 13C9.5 12.9865 9.50009 12.973 9.50027 12.9596L5.38243 10.336C4.8677 10.7513 4.21289 11 3.5 11C1.84315 11 0.5 9.65685 0.5 8C0.5 6.34315 1.84315 5 3.5 5C4.21349 5 4.8688 5.24907 5.38373 5.66501L9.50029 3.04226L9.5 3C9.5 1.34315 10.8431 0 12.5 0C14.1569 0 15.5 1.34315 15.5 3Z'
                          fill='#888888'
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <div>
              <div className='border-t-2 border-b-2 border-[#EFEFEF]'>
                <div className='w-[100%]'>
                  <div className='border-b border-[#EFEFEF] flex items-center h-[17px]'>
                    <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                      Styles:
                    </p>
                    <p className='text-primaryGray text-sm12 font-normal  leading-[15px]'>
                      Abstract, Realastic, Modern
                    </p>
                  </div>
                  <div className='border-b border-[#EFEFEF] flex items-center h-[17px]'>
                    <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                      Subject:
                    </p>
                    <p className='text-primaryGray text-sm12 font-normal gap-[4px] leading-[15px] flex items-center'>
                      Nature, Travel, Education, Lifestyle, Sports,
                      People
                    </p>
                  </div>

                  <div className='flex items-center h-[17px]'>
                    <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                      Equipments:
                    </p>
                    <p className='text-primaryGray text-sm12 font-normal gap-[4px] leading-[15px] flex items-center'>
                      Canon 550D, Canon 5D Mark II
                    </p>
                  </div>
                </div>
              </div>

              <p className='font-medium text-[#333333] text-[11px] mt-[10px] mb-1'>
                Recognitions
              </p>
              <div className='flex gap-4'>
                <ContriRecog1 />
                <ContriRecog2 />
                <ContriRecog3 />
              </div>
            </div>
          </div>
        </main>
      </div>

      {/* prathamesh */}

      <div className='flex flex-col mt-[30px]'>
        <div className='px-[20px]'>
          <p className='text-[12px] text-primaryGray leading-[1] mb-[8px]'>
            Artnstock / Contributor Store / All Collections / Art
          </p>
        </div>

        <div className='w-[100%] flex justify-between px-[20px]'>
          {/* title */}
          <div className='flex flex-col gap-[5px]'>
            <div className='flex gap-[3px]'>
              <p className='text-[38px] text-primaryBlack font-medium leading-[1]'>
                All Collections
              </p>
              <p className='text-[12px] text-primaryGray mt-[18px]'>
                0 Sets, 2830 Files
              </p>
            </div>
          </div>

          {/* search bar */}
          <div className='w-full w-[600px]'>
            <div className='flex items-center  w-[100%] mx-[auto]'>
              <input
                className='bg-[#EEEEEE] flex-1 outline-none h-[44px] px-5 placeholder:text-placeholderColor placeholder:font-medium placeholder:text-[14px] rounded-tl-3xl rounded-bl-3xl  border-[#DADADA]'
                type='text'
                placeholder='Search by Title or Tags'
              />

              <button className=' rounded-tr-3xl h-[44px] flex items-center rounded-br-3xl border-l-[2px] border-l-[#DADADA] bg-[#e6e6e6] pl-[8px] pr-[19px] text-primaryGray text-sm14 font-medium'>
                <SearchIcon className='inline-block mr-2 fill-[#888888]' />

                <span className='relative top-[1px]'>Search</span>
              </button>
            </div>
          </div>
          {/* search bar */}

          {/* sort by */}
          <div className='w-[330px] leading-[1] flex gap-[20px]  flex-row-reverse'>
            <div className='flex gap-[10px] pt-[3px]'>
              <Grid1
                style={{
                  fill: `${
                    imageGrid === 'grid' ? '#333333' : '#888888'
                  }`,
                }}
                onClick={() => {
                  setImageGrid('grid');
                }}
              />
              <Grid
                style={{
                  fill: `${
                    imageGrid === 'square' ? '#333333' : '#888888'
                  }`,
                }}
                onClick={() => {
                  setImageGrid('square');
                }}
              />
              {/* <img
              className='w-[23px] h-[23px]'
              src={gridBlack}
              alt=''
            />
            <img
              className='w-[23px] h-[23px]'
              src={gridGray}
              alt=''
            /> */}
            </div>

            <div className='flex gap-[10px]'>
              <div>
                <button
                  onClick={() => {
                    setIsOpenSortBy(!isOpenSortBy);
                  }}
                  className={`${
                    isOpenSortBy === true
                      ? 'dropdownTwoGlobalTrue'
                      : 'dropdownTwoGlobalFalse'
                  } dropdownTwoGlobalCommon  w-[120px]`}
                >
                  <div className='flex flex-col'>
                    <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                      Sort by
                    </p>
                    <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                      Year
                    </p>
                  </div>

                  <div>
                    <DropArrow
                      className={`${
                        isOpenSortBy ? 'transform rotate-180' : ''
                      }`}
                      style={{
                        fill: '#878787',
                      }}
                    />
                  </div>
                </button>
                {isOpenSortBy && (
                  <ul className='shadow-dropShadow rounded-b-2xl hover:overflow-hidden dropdown__menu absolute z-50 bg-[#ffffff] w-[120px] text-center text-[14px] text-primaryGray'>
                    <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                      month
                    </li>
                    <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                      date
                    </li>
                    <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                      year
                    </li>
                  </ul>
                )}
              </div>

              {/* <div>
                <button
                  onClick={() => {
                    setIsOpenSortBy(!isOpenSortBy);
                  }}
                  className={`${
                    isOpenSortBy === true
                      ? 'rounded-t-[15px] shadow-dropShadowButton border-b border-[#efefef]'
                      : 'rounded-[15px] border  border-[#d6d6d6]'
                  } cursor-pointer w-[120px] text-[12px] h-[30px] flex items-center justify-between p-[10px]`}
                  
                >
                  <span className='text-[#bbbbbb]'>Sort by</span>
                  <img
                    className='inline-block'
                    src={dropdown}
                    alt=''
                  />
                </button>
                {isOpenSortBy && (
                  <ul className='shadow-dropShadow rounded-b-2xl hover:overflow-hidden dropdown__menu absolute z-50 bg-[#ffffff] w-[120px] text-center text-[14px] text-primaryGray'>
                    <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                      month
                    </li>
                    <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                      date
                    </li>
                    <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                      year
                    </li>
                  </ul>
                )}
              </div> */}
            </div>
          </div>
          {/* sort by */}
        </div>
      </div>

      {/* test */}
      <div className='main py-[20px]'>
        <div
          className={`${showSidebar ? 'pr-6' : 'px-6'} flex gap-x-4`}
        >
          {showSidebar && (
            <div
              className='sidebar w-[17.313rem]  shadow-regCardShadow relative rounded-tr-2xl rounded-br-2xl py-12 pl-6 pr-4'
              style={{
                height: 'fit-content',
              }}
            >
              {/* <img
                onClick={() => setShowSidebar(false)}
                className='absolute top-4 right-4 cursor-pointer'
                src={closeIcon}
                alt=''
              /> */}
              <SmallCross
                onClick={() => setShowSidebar(false)}
                className='absolute top-[16px] right-[16px] cursor-pointer'
              />

              <div className='w-[213px]  flex flex-col text-primaryGray'>
                <div
                  onClick={() => {
                    // setCategoriesFocus('all');
                    setCategoriesFocus('allart');
                  }}
                  style={{
                    backgroundColor: `${
                      categoriesFocus === 'allart'
                        ? '#f0f0f0'
                        : '#ffffff'
                    }`,
                  }}
                  className={`flex h-[50px] gap-[8px]  border-b border-t border-[#efefef] pt-[8px] cursor-pointer`}
                >
                  <div className=''>
                    <SimilarIcon
                      style={{
                        fill: '#888888',
                      }}
                    />
                  </div>

                  <div className='flex w-[100%] justify-between'>
                    <div className='flex flex-col '>
                      <p className='text-[15px] text-primaryGray font-medium leading-[18px]'>
                        All Files
                      </p>
                      <p className='text-primaryGray text-sm11'>
                        Files uploaded by Contributor
                      </p>
                    </div>

                    <div>
                      <p className='text-[15px] font-medium leading-[18px]'>
                        2830
                      </p>
                    </div>
                  </div>
                </div>

                <div
                  className={`flex h-[50px] gap-[8px]  border-b border-t border-[#efefef] pt-[15px] cursor-pointer`}
                >
                  <p className='text-sm11 text-[#bbbbbb]'>
                    Manage all uploaded Files by Category <br />
                    using a Folder structure
                  </p>
                </div>

                <div
                  onClick={() => {
                    setCategoriesFocus('art');
                    setArtTabFocus(!artTabFocus);
                  }}
                  style={{
                    backgroundColor: `${
                      categoriesFocus === 'art'
                        ? '#f0f0f0'
                        : '#ffffff'
                    }`,
                  }}
                  className={`flex h-[50px] gap-[8px]  border-b border-[#efefef] pt-[8px] cursor-pointer`}
                >
                  <div className=''>
                    <ArtIconSmall />
                  </div>

                  <div className='flex w-[100%] justify-between'>
                    <div className='flex flex-col '>
                      <p className='text-[15px] text-primaryGray font-medium leading-[18px]'>
                        Art{' '}
                      </p>
                      <p className='text-primaryGray text-sm11'>
                        ({categories.art.length} Sets), 2214 Art
                      </p>
                    </div>
                    <div>
                      <DropArrow
                        className={`${
                          artTabFocus === true
                            ? 'transform rotate-180'
                            : ''
                        } mt-[5px]`}
                        style={{
                          fill: '#888888',
                        }}
                      />
                    </div>
                  </div>
                </div>

                {artTabFocus === true &&
                  categories?.art?.map((obj) => (
                    <div>
                      <div
                        onClick={() => {
                          folderImages(obj);
                        }}
                        style={{
                          backgroundColor: `${
                            categoriesFocus === 'folderImages' &&
                            imagesFolderArray.fileManagerId ===
                              obj.fileManagerId
                              ? '#f0f0f0'
                              : '#ffffff'
                          }`,
                        }}
                        className={`flex h-[27px] gap-[8px] bg-[] items-center border-b border-[#efefef] cursor-pointer`}
                      >
                        <div className='my-[auto]'>
                          <Folder className='fill-[#dddddd]' />
                        </div>
                        <div className='flex w-[184px] justify-between'>
                          <div className='flex flex-col justify-center'>
                            <p className='text-sm12 text-primaryGray'>
                              {obj.title}
                            </p>
                          </div>
                          <div>
                            <p className='text-[12px]'>
                              {obj.artMaster.length}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                {/* {categoriesFocus === 'art' && create === true ? (
                <div className='flex h-[27px] gap-[8px] bg-[#ffffff] py-[5px] border-b border-[#efefef] cursor-pointer'>
                  <div className='my-[auto]'>
                    <Folder className='fill-[#dddddd]' />
                  </div>
                  <div className='flex w-[184px] justify-between'>
                    <div className='flex flex-col justify-center'>
                      <input
                        onChange={namee}
                        onKeyDown={handleKeyDown}
                        ref={inputRef}
                        placeholder='Enter folder name'
                        className='text-[12px] text-primaryGray font-medium leading-[1] outline-none'
                        type='text'
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )} */}

                <div
                  onClick={() => {
                    setCategoriesFocus('photos');
                    setPhotosTabFocus(!photosTabFocus);
                  }}
                  className={`flex h-[50px] gap-[8px] bg-[${
                    categoriesFocus === 'photos'
                      ? '#f0f0f0'
                      : '#ffffff'
                  }]  border-b border-[#efefef] pt-[8px] cursor-pointer`}
                >
                  <div className=''>
                    {/* <img src={photosFiles} alt='' /> */}
                    <PhotosIconSmall />
                  </div>

                  <div className='flex w-[100%]  justify-between'>
                    <div className='flex flex-col '>
                      <p className='text-[15px] text-primaryGray font-medium leading-[18px]'>
                        Photos{' '}
                      </p>
                      <p className='text-primaryGray text-sm11'>
                        ({categories.photo.length} Sets), 2214 Photos
                      </p>
                    </div>
                    <div>
                      <DropArrow
                        className='mt-[5px]'
                        // className={`${
                        //   show === true ? 'transform rotate-180' : ''
                        // }`}
                        style={{
                          fill: '#888888',
                        }}
                      />
                      {/* {photosTabFocus === true ? (
                    <p className='text-[15px] font-medium'>-</p>
                  ) : (
                    <p className='text-[15px] font-medium'>+</p>
                  )} */}
                    </div>
                  </div>
                </div>
                {/* test */}

                {/* test */}
                {photosTabFocus === true &&
                  categories.photo.map((obj) => (
                    <div
                      onClick={() => {
                        folderImages(obj);
                      }}
                      className={`flex h-[27px] gap-[8px] bg-[${
                        categoriesFocus === 'folderImages' &&
                        imagesFolderArray.fileManagerId ===
                          obj.fileManagerId
                          ? '#f0f0f0'
                          : '#ffffff'
                      }] py-[5px] border-b border-[#efefef] cursor-pointer`}
                    >
                      <div className='my-[auto]'>
                        <Folder className='fill-[#dddddd]' />
                      </div>
                      <div className='flex w-[184px] justify-between'>
                        <div className='flex flex-col justify-center'>
                          <p className='text-[12px] text-primaryGray font-medium leading-[1]'>
                            {obj.title}
                          </p>
                        </div>
                        <div>
                          <p className='text-[12px]'>
                            {obj.artMaster.length}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}

                {categoriesFocus === 'photos' && create === true ? (
                  <div className='flex h-[27px] gap-[8px] bg-[#ffffff] py-[5px] border-b border-[#efefef] cursor-pointer'>
                    <div className='my-[auto]'>
                      <Folder className='fill-[#dddddd]' />
                    </div>
                    <div className='flex w-[184px] justify-between'>
                      <div className='flex flex-col justify-center'>
                        {/* <input
                        onChange={namee}
                        onKeyDown={handleKeyDown}
                        ref={inputRef}
                        placeholder='Enter folder name'
                        className='text-[12px] text-primaryGray font-medium leading-[1] outline-none'
                        type='text'
                      /> */}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                <div className='flex h-[50px] gap-[8px] pt-[8px] bg-[#ffffff] border-b border-[#efefef]'>
                  <div className=''>
                    <FootagesIconSmall />
                  </div>

                  <div className='flex w-[100%] justify-between'>
                    <div className='flex flex-col'>
                      <p className='text-[15px] text-primaryGray font-medium leading-[18px]'>
                        Footage{' '}
                      </p>
                      <p className='text-primaryGray text-sm11'>
                        ({categories.footage.length} Sets)
                      </p>
                    </div>
                    <div>
                      <DropArrow
                        className='mt-[5px]'
                        style={{
                          fill: '#888888',
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className='flex h-[50px] gap-[8px] pt-[8px] bg-[#ffffff] border-b border-[#efefef]'>
                  <div className=''>
                    <MusicIconSmall />
                  </div>

                  <div className='flex w-[100%] justify-between'>
                    <div className='flex flex-col'>
                      <p className='text-[15px] text-primaryGray font-medium leading-[18px]'>
                        Music{' '}
                      </p>
                      <p className='text-primaryGray text-sm11'>
                        ({categories.music.length} Sets)
                      </p>
                    </div>
                    <div>
                      <DropArrow
                        className='mt-[5px]'
                        style={{
                          fill: '#888888',
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className='flex h-[50px] gap-[8px] pt-[8px] bg-[#ffffff] border-b border-[#efefef]'>
                  <div className=''>
                    <TemplatesIconSmall />
                  </div>

                  <div className='flex w-[100%] justify-between'>
                    <div className='flex flex-col'>
                      <p className='text-[15px] text-primaryGray font-medium leading-[18px]'>
                        Templates{' '}
                      </p>
                      <p className='text-primaryGray text-sm11'>
                        ({categories.templates.length} Sets)
                      </p>
                    </div>
                    <div>
                      <DropArrow
                        className='mt-[5px]'
                        style={{
                          fill: '#888888',
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  // onClick={() => {
                  //   setCategoriesFocus('product');
                  //   setProductTabFocus(!productTabFocus);
                  // }}
                  className={`flex h-[50px] gap-[8px] bg-[${
                    categoriesFocus === 'product'
                      ? '#f0f0f0'
                      : '#ffffff'
                  }]  border-b border-[#efefef] pt-[8px] cursor-pointer`}
                >
                  <div className=''>
                    <ProductsIconSmall />
                    {/* <img src={productFiles} alt='' /> */}
                  </div>

                  <div className='flex w-[100%] justify-between'>
                    <div className='flex flex-col '>
                      <p className='text-[15px] text-primaryGray font-medium leading-[18px]'>
                        Product{' '}
                      </p>
                      <p className='text-primaryGray text-sm11'>
                        ({categories.product.length} Sets)
                      </p>
                    </div>
                    <div>
                      <DropArrow
                        className='mt-[5px]'
                        // className={`${
                        //   show === true ? 'transform rotate-180' : ''
                        // }`}
                        style={{
                          fill: '#888888',
                        }}
                      />
                      {/* {productTabFocus === true ? (
                    <p className='text-[15px] font-medium'>-</p>
                  ) : (
                    <p className='text-[15px] font-medium'>+</p>
                  )} */}
                    </div>
                  </div>
                </div>
                {/* test */}

                {/* test */}
              </div>
            </div>
          )}

          <div className='content w-[100%]'>
            <div className='flex'>
              {!showSidebar && (
                <img
                  className='cursor-pointer mr-3'
                  onClick={() => setShowSidebar(true)}
                  src={filterIcon}
                  alt=''
                />
              )}
              <div className='flex gap-1'>
                <div className='text-sm12 text-primaryGray py-1.5 border border-[#D6D6D6] rounded-2xl px-3'>
                  Architecture/Buildings
                </div>
                <div className='text-sm12 text-primaryGray py-1.5 border border-[#D6D6D6] rounded-2xl px-3'>
                  Backgrounds/Textures
                </div>
              </div>
            </div>

            {/* test */}

            {categoriesFocus === 'allart' && (
              <div>
                {allArt?.length > 0 ? (
                  <div>
                    <div className={`mainImagesCon mt-[15px] }`}>
                      <ResponsiveMasonry
                        columnsCountBreakPoints={
                          !showSidebar ? { 1920: 6 } : { 1920: 5 }
                        }
                      >
                        <Masonry gutter='15px'>
                          {allArt?.map((data) => {
                            return (
                              <div
                                onMouseEnter={() => {
                                  setPopupArray([]);
                                }}
                                key={data?.artId}
                                className={` ${
                                  showSidebar
                                    ? 'w-[19.25rem]'
                                    : 'w-[18.688rem]'
                                }`}
                                style={{ height: 'fit-content' }}
                              >
                                <div
                                  className={` w-full group rounded-2xl relative`}
                                  onClick={() =>
                                    goToArtDetailsPage(data?.artId)
                                  }
                                >
                                  <img
                                    className='rounded-2xl'
                                    style={{ height: '100%' }}
                                    src={
                                      imageGrid === 'grid'
                                        ? imageLinkChange(
                                            data?.imageMaster
                                              ?.secureUrl
                                          )
                                        : imageLinkChangeSquaregrid(
                                            data?.imageMaster
                                              ?.secureUrl
                                          )
                                    }
                                    alt=''
                                  />
                                  <div
                                    className='group-hover:flex hidden bg-blackRgba items-center justify-center absolute top-0 left-0 rounded-2xl'
                                    style={{
                                      height: '100%',
                                      width: '100%',
                                    }}
                                  >
                                    <p className='text-[25px] text-[#fff]'>
                                      {data.subjectMaster.subjectName}
                                    </p>
                                    <div className='absolute bottom-[10px] left-[10px] flex gap-[10px]'>
                                      <div>
                                        <PlusIcon
                                          style={{
                                            fill: '#e8e8e8',
                                          }}
                                        />
                                      </div>

                                      <div>
                                        <SimilarIcon
                                          style={{
                                            fill: '#e8e8e8',
                                          }}
                                        />
                                      </div>
                                      <div>
                                        <ProfileIcon />
                                      </div>
                                      <div>
                                        <CartIcon />
                                      </div>
                                      <div>
                                        <ShareIcon
                                          className='fill-[#e8e8e8]'
                                          onClick={(e) => {
                                            navigate(
                                              '/BuyerReferralProgram'
                                            );
                                            e.stopPropagation();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className='absolute right-[10px] bottom-[10px]'>
                                      <div>
                                        <EnlargeIcon
                                          style={{
                                            fill: '#e8e8e8',
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className='absolute right-[3px] top-[3px]'>
                                      {wishlist?.find(
                                        (obj) =>
                                          obj.artMaster?.artId ===
                                          data.artId
                                      ) === undefined ? (
                                        <Wishlist
                                          className='cursor-pointer'
                                          onMouseEnter={
                                            handleMouseEnter
                                          }
                                          onMouseLeave={
                                            handleMouseLeave
                                          }
                                          onClick={(e) => {
                                            addToWishlist(
                                              data?.artId
                                            );
                                            e.stopPropagation();
                                          }}
                                          style={{
                                            fill: '#fff',
                                            width: '100%',
                                          }}
                                        />
                                      ) : (
                                        <Wishlist
                                          className='cursor-pointer'
                                          onMouseEnter={
                                            handleMouseEnter
                                          }
                                          onMouseLeave={
                                            handleMouseLeave
                                          }
                                          onClick={(e) => {
                                            wishlistDelete(
                                              data?.artId
                                            );
                                            e.stopPropagation();
                                          }}
                                          style={{
                                            fill: 'red',
                                            width: '100%',
                                          }}
                                        />
                                      )}
                                      {/* test */}

                                      {/* <Wishlist
   className='cursor-pointer'
  onMouseEnter={handleMouseEnter}
  onMouseLeave={handleMouseLeave}
/> */}
                                    </div>
                                    {isHovered && (
                                      <button className='w-[164px] z-[99] mt-[3px] h-[20px] flex justify-center items-center text-[11px] bg-[#f7f7f7] rounded-[10px] text-primaryGray absolute top-[33px] left-[203px] border border-[#e4e4e4]'>
                                        <span className='leading-[1]'>
                                          Save to Wishlist
                                        </span>
                                      </button>
                                    )}
                                    {popupArray.find(
                                      (obj) => obj.id === data.artId
                                    ) && (
                                      <div
                                        className={`z-999 right-[117px] bottom-[15px] bg-[#fff] rounded-[16px] w-[266px] absolute bottom-[44px] left-[-117px]`}
                                        style={{
                                          boxShadow:
                                            '0px 0px 18px rgba(0, 0, 0, 0.2)',
                                        }}
                                      >
                                        <div className='flex gap-[5px] flex-col p-[14px] leading-[1.3] text-center'>
                                          <p className='font-medium text-primaryBlack text-[15px]'>
                                            Create Account
                                          </p>
                                          <p className='text-primaryGray text-[11px]'>
                                            To create and add to a
                                            collection, you must be a
                                            logged-in member
                                          </p>
                                          <button className='bg-[#8e8e8e] rounded-[14px] h-[28px] w-[108px] text-[12px] font-medium text-[white] mx-[auto]'>
                                            Create Account
                                          </button>
                                          <p className='text-orangeColor text-[11px]'>
                                            Already a member? Sign in
                                          </p>
                                          <p className='text-pinkColor text-[11px]'>
                                            Note: Downloaded images
                                            will be saved in
                                            ‘Collections’ folder
                                          </p>
                                        </div>
                                        <div className='absolute left-[47%] bottom-[-10px] w-[20px] h-[20px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <p className='w-[75%] truncate text-primaryBlack text-[15px] leading-[18px] font-medium mt-[4px]'>
                                  {data?.artName}
                                </p>

                                <p className='text-primaryGray text-[11px] leading-[14px]'>
                                  Artnstock <br />
                                  35.4” x 31.5” Multiple Sizes
                                </p>
                                <p className='text-primaryBlack text-[15px] leading-[18px] font-semibold mb-[3px]'>
                                  ${data?.price}
                                </p>
                              </div>
                            );
                          })}
                        </Masonry>
                      </ResponsiveMasonry>
                    </div>
                    {/* Pagination */}
                    {/* test */}
                    <div className='flex justify-center'>
                      <div className='flex flex-col justify-center'>
                        <div className='flex text-[13px] text-primaryGray mt-[30px] gap-[6px]'>
                          <p className='my-[auto]'>Page</p>
                          <div className='flex items-center w-[88px] h-[30px]'>
                            <div
                              // onClick={handlePrevPage}
                              // disabled={currentPage === 1}
                              className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-l-[15px] cursor-pointer'
                            >
                              {/* <img src={backArrow} alt='' /> */}
                              <DropArrow className='fill-[#888888] rotate-90' />
                            </div>
                            <div className='w-[29.33px] h-[30px] flex justify-center items-center border-t border-b border-[#e8e8e8]'>
                              {/* <span>{currentPage}</span> */}3
                            </div>
                            <div
                              // onClick={handleNextPage}
                              // disabled={currentPage === totalPages}
                              className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-r-[15px] cursor-pointer'
                            >
                              <div className='transform rotate-180 origin-center'>
                                <div className='transform rotate-90 origin-center'>
                                  <DropArrow className='fill-[#888888]' />
                                </div>{' '}
                              </div>
                            </div>
                          </div>
                          <p className='my-[auto]'>
                            of {/* {totalPages} */}18 &nbsp;
                          </p>
                        </div>
                        <div className='flex justify-center'>
                          {/* <button className='w-[88px] h-[40px] mt-[10px] bg-[#d6d6d6] rounded-[20px] text-[14px] font-medium text-[white] '>
                        Save
                      </button> */}
                          <button className='blackBtn mt-2.5 mb-24 mx-auto block'>
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* test */}
                  </div>
                ) : (
                  <div>
                    <div className='w-[100%]'>
                      <div className='mt-[30px] mx-[auto] text-center'>
                        <p className='text-[#ff369f] text-[18px]'>
                          It seems contributor have not have not added{' '}
                          <br />
                          any files yet.
                        </p>
                        <p className='mt-[10px] mb-[20px] text-[12px] text-[#757575]'>
                          After adding files by the contributor on
                          Artnstock will show up Here.
                        </p>
                        <button
                          onClick={() => {
                            navigate('/art-list');
                          }}
                          className='blackBtn text-[14px]'
                        >
                          Browse Artnstock
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {/* test */}

            {categoriesFocus === 'art' && (
              <div>
                {categories.art.length > 0 ? (
                  <div>
                    <div className={`mainImagesCon mt-[15px] }`}>
                      <ResponsiveMasonry
                        columnsCountBreakPoints={
                          !showSidebar ? { 1920: 6 } : { 1920: 5 }
                        }
                      >
                        <Masonry gutter='15px'>
                          {allfilesstaticModified.map((data) => {
                            return (
                              <div
                                onMouseEnter={() => {
                                  setPopupArray([]);
                                }}
                                // key={data?.artId}
                                className={` ${
                                  showSidebar
                                    ? 'w-[19.25rem]'
                                    : 'w-[18.688rem]'
                                }`}
                                style={{ height: 'fit-content' }}
                                onClick={() => {
                                  folderImages(data);
                                }}
                              >
                                <div
                                  className={` w-full group h-[308px] rounded-2xl relative`}
                                >
                                  {/* <img
                                    className='rounded-2xl'
                                    style={{ height: '308px' }}
                                    src={data?.imageMaster?.secureUrl}
                                   
                                    alt=''
                                  /> */}

                                  <div
                                    className='bg-[#3333334D]  flex items-center bg-cover bg-center justify-center relative top-0 left-0 rounded-2xl'
                                    // src={imageLinkChangeFolder(
                                    //   data?.artMaster[0]?.image
                                    // )}
                                    style={{
                                      height: '100%',
                                      width: '100%',
                                      // backgroundImage: `url(${data?.imageMaster?.secureUrl})`,
                                    }}
                                  >
                                    <div
                                      className={`h-full w-full bg-no-repeat bg-center bg-cover filter rounded-2xl brightness-100 absolute overflow-hidden inset-0 brightness-[80%]
                            `}
                                      style={{
                                        backgroundImage: `url(${data?.imageMaster?.secureUrl})`,
                                      }}
                                    ></div>
                                    {/* <div
                                      style={{
                                        backgroundImage: `url(${folderImg})`,
                                      }}
                                      className='bg-cover bg-center w-[64px] h-[57px] flex items-center justify-center pt-[5px]'
                                    >
                                      <p className='font-medium text-[18px] text-primaryBlack'>
                                       128
                                      </p>
                                    </div> */}

                                    <div>
                                      <FolderImage className='opacity-[90%]' />
                                    </div>
                                    <p className='text-[13.58px] leading-[1] absolute mt-[4px] text-primaryGray font-medium '>
                                      128
                                    </p>
                                    <div className='absolute bottom-[10px] left-[10px] flex gap-[10px]'>
                                      <div>
                                        <img src={view} alt='' />
                                      </div>
                                      <div>
                                        <img
                                          onClick={(e) => {
                                            navigate(
                                              '/BuyerReferralProgram'
                                            );
                                            e.stopPropagation();
                                          }}
                                          src={share}
                                          alt=''
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[4px]'>
                                  {data?.name}
                                </p>
                                <p className='text-primaryGray text-sm11'>
                                  {/* {data?.artMaster.length} Art */}
                                  248 Art{' '}
                                  <span className='text-pinkColor'>
                                    (23 New Art added)
                                  </span>
                                </p>
                              </div>
                            );
                          })}
                        </Masonry>
                      </ResponsiveMasonry>
                    </div>
                    {/* Pagination */}
                    {/* Pagination */}
                    {/* test */}
                    <div className='flex justify-center'>
                      <div className='flex flex-col justify-center'>
                        <div className='flex text-[13px] text-primaryGray mt-[30px] gap-[6px]'>
                          <p className='my-[auto]'>Page</p>
                          <div className='flex items-center w-[88px] h-[30px]'>
                            <div
                              // onClick={handlePrevPage}
                              // disabled={currentPage === 1}
                              className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-l-[15px] cursor-pointer'
                            >
                              {/* <img src={backArrow} alt='' /> */}
                              <DropArrow className='fill-[#888888] rotate-90' />
                            </div>
                            <div className='w-[29.33px] h-[30px] flex justify-center items-center border-t border-b border-[#e8e8e8]'>
                              {/* <span>{currentPage}</span> */}3
                            </div>
                            <div
                              // onClick={handleNextPage}
                              // disabled={currentPage === totalPages}
                              className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-r-[15px] cursor-pointer'
                            >
                              <div className='transform rotate-180 origin-center'>
                                <div className='transform rotate-90 origin-center'>
                                  <DropArrow className='fill-[#888888]' />
                                </div>{' '}
                              </div>
                            </div>
                          </div>
                          <p className='my-[auto]'>
                            of {/* {totalPages} */}18 &nbsp;
                          </p>
                        </div>
                        <div className='flex justify-center'>
                          {/* <button className='w-[88px] h-[40px] mt-[10px] bg-[#d6d6d6] rounded-[20px] text-[14px] font-medium text-[white] '>
                        Save
                      </button> */}
                          <button className='blackBtn mt-2.5 mb-24 mx-auto block'>
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* test */}
                  </div>
                ) : (
                  <div className='w-[100%]'>
                    <div className='mt-[30px] mx-[auto] text-center'>
                      <p className='text-[#ff369f] text-[18px]'>
                        This contributor have not uploaded any files
                        on ArtnStock yet.
                      </p>
                      <p className='mt-[10px] mb-[20px] text-[12px] text-[#757575]'>
                        After uploading files by the contributor on
                        Artnstock will show up Here.
                      </p>
                      <button
                        onClick={() => {
                          navigate('/art-list');
                        }}
                        className='blackBtn text-[14px]'
                      >
                        Start Here
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}

            {categoriesFocus === 'photos' && (
              <div className={`mainImagesCon mt-[15px] }`}>
                <ResponsiveMasonry
                  columnsCountBreakPoints={
                    !showSidebar ? { 1920: 6 } : { 1920: 5 }
                  }
                >
                  <Masonry gutter='15px'>
                    {categories.photo.map((data) => {
                      return (
                        <div
                          onMouseEnter={() => {
                            setPopupArray([]);
                          }}
                          // key={data?.artId}
                          className={` ${
                            showSidebar
                              ? 'w-[19.25rem]'
                              : 'w-[18.688rem]'
                          }`}
                          style={{ height: 'fit-content' }}
                          onClick={() => {
                            folderImages(data);
                          }}
                        >
                          <div
                            className={` w-full group rounded-2xl relative`}
                          >
                            <img
                              className='rounded-2xl'
                              style={{ height: '308px' }}
                              src={imageLinkChangeFolder(
                                data?.artMaster[0].image
                              )}
                              alt=''
                            />

                            <div
                              className='bg-[#3333334D] flex items-center justify-center absolute top-0 left-0 rounded-2xl'
                              style={{
                                height: '100%',
                                width: '100%',
                              }}
                            >
                              <div
                                style={{
                                  backgroundImage: `url(${folderImg})`,
                                }}
                                className='bg-cover bg-center w-[64px] h-[57px] flex items-center justify-center pt-[5px]'
                              >
                                <p className='font-medium text-[18px] text-primaryBlack'>
                                  {data?.artMaster.length}
                                </p>
                              </div>
                              <div className='absolute bottom-[10px] left-[10px] flex gap-[10px]'>
                                <div>
                                  <img src={view} alt='' />
                                </div>
                                <div>
                                  <img
                                    onClick={(e) => {
                                      navigate(
                                        '/BuyerReferralProgram'
                                      );
                                      e.stopPropagation();
                                    }}
                                    src={share}
                                    alt=''
                                  />
                                </div>
                              </div>
                              {/* <div className='absolute right-[10px] bottom-[10px]'>
                                  <img src={enlarge} alt='' />
                                </div> */}
                            </div>
                          </div>
                          <p className='text-primaryBlack text-[15px] leading-[18px] font-semibold mt-1.5'>
                            {data?.title}
                          </p>
                          <p className='text-primaryGray text-[12px]'>
                            {data?.artMaster.length} Photos
                          </p>
                        </div>
                      );
                    })}
                  </Masonry>
                </ResponsiveMasonry>
              </div>
            )}

            {/* test */}

            {categoriesFocus === 'folderImages' && (
              <div>
                {allArt?.length > 0 ? (
                  <div>
                    <div className={`mainImagesCon mt-[15px] }`}>
                      <ResponsiveMasonry
                        columnsCountBreakPoints={
                          !showSidebar ? { 1920: 6 } : { 1920: 5 }
                        }
                      >
                        <Masonry gutter='15px'>
                          {allArt?.map((data) => {
                            return (
                              <div
                                onMouseEnter={() => {
                                  setPopupArray([]);
                                }}
                                key={data?.artId}
                                className={` ${
                                  showSidebar
                                    ? 'w-[19.25rem]'
                                    : 'w-[18.688rem]'
                                }`}
                                style={{ height: 'fit-content' }}
                              >
                                <div
                                  className={` w-full group rounded-2xl relative`}
                                  onClick={() =>
                                    goToArtDetailsPage(data?.artId)
                                  }
                                >
                                  <img
                                    className='rounded-2xl'
                                    style={{ height: '100%' }}
                                    src={
                                      imageGrid === 'grid'
                                        ? imageLinkChange(
                                            data?.imageMaster
                                              ?.secureUrl
                                          )
                                        : imageLinkChangeSquaregrid(
                                            data?.imageMaster
                                              ?.secureUrl
                                          )
                                    }
                                    alt=''
                                  />
                                  <div
                                    className='group-hover:flex hidden bg-blackRgba items-center justify-center absolute top-0 left-0 rounded-2xl'
                                    style={{
                                      height: '100%',
                                      width: '100%',
                                    }}
                                  >
                                    <p className='text-[25px] text-[#fff]'>
                                      {data.subjectMaster.subjectName}
                                    </p>
                                    <div className='absolute bottom-[10px] left-[10px] flex gap-[10px]'>
                                      <div>
                                        <PlusIcon
                                          style={{
                                            fill: '#e8e8e8',
                                          }}
                                        />
                                      </div>

                                      <div>
                                        <SimilarIcon
                                          style={{
                                            fill: '#e8e8e8',
                                          }}
                                        />
                                      </div>
                                      <div>
                                        <ProfileIcon />
                                      </div>
                                      <div>
                                        <CartIcon />
                                      </div>
                                      <div>
                                        <ShareIcon
                                          onClick={(e) => {
                                            navigate(
                                              '/BuyerReferralProgram'
                                            );
                                            e.stopPropagation();
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className='absolute right-[10px] bottom-[10px]'>
                                      <div>
                                        <EnlargeIcon
                                          style={{
                                            fill: '#e8e8e8',
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className='absolute right-[3px] top-[3px]'>
                                      {wishlist?.find(
                                        (obj) =>
                                          obj.artMaster?.artId ===
                                          data.artId
                                      ) === undefined ? (
                                        <Wishlist
                                          className='cursor-pointer'
                                          onMouseEnter={
                                            handleMouseEnter
                                          }
                                          onMouseLeave={
                                            handleMouseLeave
                                          }
                                          onClick={(e) => {
                                            addToWishlist(
                                              data?.artId
                                            );
                                            e.stopPropagation();
                                          }}
                                          style={{
                                            fill: '#fff',
                                            width: '100%',
                                          }}
                                        />
                                      ) : (
                                        <Wishlist
                                          className='cursor-pointer'
                                          onMouseEnter={
                                            handleMouseEnter
                                          }
                                          onMouseLeave={
                                            handleMouseLeave
                                          }
                                          onClick={(e) => {
                                            wishlistDelete(
                                              data?.artId
                                            );
                                            e.stopPropagation();
                                          }}
                                          style={{
                                            fill: 'red',
                                            width: '100%',
                                          }}
                                        />
                                      )}
                                      {/* test */}

                                      {/* <Wishlist
  className='cursor-pointer'
 onMouseEnter={handleMouseEnter}
 onMouseLeave={handleMouseLeave}
/> */}
                                    </div>
                                    {isHovered && (
                                      <button className='w-[164px] z-[99] mt-[3px] h-[20px] flex justify-center items-center text-[11px] bg-[#f7f7f7] rounded-[10px] text-primaryGray absolute top-[33px] left-[203px] border border-[#e4e4e4]'>
                                        <span className='leading-[1]'>
                                          Save to Wishlist
                                        </span>
                                      </button>
                                    )}
                                    {popupArray.find(
                                      (obj) => obj.id === data.artId
                                    ) && (
                                      <div
                                        className={`z-999 right-[117px] bottom-[15px] bg-[#fff] rounded-[16px] w-[266px] absolute bottom-[44px] left-[-117px]`}
                                        style={{
                                          boxShadow:
                                            '0px 0px 18px rgba(0, 0, 0, 0.2)',
                                        }}
                                      >
                                        <div className='flex gap-[5px] flex-col p-[14px] leading-[1.3] text-center'>
                                          <p className='font-medium text-primaryBlack text-[15px]'>
                                            Create Account
                                          </p>
                                          <p className='text-primaryGray text-[11px]'>
                                            To create and add to a
                                            collection, you must be a
                                            logged-in member
                                          </p>
                                          <button className='bg-[#8e8e8e] rounded-[14px] h-[28px] w-[108px] text-[12px] font-medium text-[white] mx-[auto]'>
                                            Create Account
                                          </button>
                                          <p className='text-orangeColor text-[11px]'>
                                            Already a member? Sign in
                                          </p>
                                          <p className='text-pinkColor text-[11px]'>
                                            Note: Downloaded images
                                            will be saved in
                                            ‘Collections’ folder
                                          </p>
                                        </div>
                                        <div className='absolute left-[47%] bottom-[-10px] w-[20px] h-[20px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                                      </div>
                                    )}
                                  </div>
                                </div>

                                <p className='w-[75%] truncate text-primaryBlack text-[15px] leading-[18px] font-medium mt-[4px]'>
                                  {data?.artName}
                                </p>

                                <p className='text-primaryGray text-[11px] leading-[14px]'>
                                  Artnstock <br />
                                  35.4” x 31.5” Multiple Sizes
                                </p>
                                <p className='text-primaryBlack text-[15px] leading-[18px] font-semibold mb-[3px]'>
                                  ${data?.price}
                                </p>
                              </div>
                            );
                          })}
                        </Masonry>
                      </ResponsiveMasonry>
                    </div>
                    {/* Pagination */}
                    {/* test */}
                    <div className='flex justify-center'>
                      <div className='flex flex-col justify-center'>
                        <div className='flex text-[13px] text-primaryGray mt-[30px] gap-[6px]'>
                          <p className='my-[auto]'>Page</p>
                          <div className='flex items-center w-[88px] h-[30px]'>
                            <div
                              // onClick={handlePrevPage}
                              // disabled={currentPage === 1}
                              className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-l-[15px] cursor-pointer'
                            >
                              {/* <img src={backArrow} alt='' /> */}
                              <DropArrow className='fill-[#888888] rotate-90' />
                            </div>
                            <div className='w-[29.33px] h-[30px] flex justify-center items-center border-t border-b border-[#e8e8e8]'>
                              {/* <span>{currentPage}</span> */}3
                            </div>
                            <div
                              // onClick={handleNextPage}
                              // disabled={currentPage === totalPages}
                              className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-r-[15px] cursor-pointer'
                            >
                              <div className='transform rotate-180 origin-center'>
                                <div className='transform rotate-90 origin-center'>
                                  <DropArrow className='fill-[#888888]' />
                                </div>{' '}
                              </div>
                            </div>
                          </div>
                          <p className='my-[auto]'>
                            of {/* {totalPages} */}18 &nbsp;
                          </p>
                        </div>
                        <div className='flex justify-center'>
                          {/* <button className='w-[88px] h-[40px] mt-[10px] bg-[#d6d6d6] rounded-[20px] text-[14px] font-medium text-[white] '>
                        Save
                      </button> */}
                          <button className='blackBtn mt-2.5 mb-24 mx-auto block'>
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* test */}
                  </div>
                ) : (
                  <div>
                    <div className='w-[100%]'>
                      <div className='mt-[30px] mx-[auto] text-center'>
                        <p className='text-[#ff369f] text-[18px]'>
                          It seems contributor have not have not added{' '}
                          <br />
                          any files yet.
                        </p>
                        <p className='mt-[10px] mb-[20px] text-[12px] text-[#757575]'>
                          After adding files by the contributor on
                          Artnstock will show up Here.
                        </p>
                        <button
                          onClick={() => {
                            navigate('/art-list');
                          }}
                          className='blackBtn text-[14px]'
                        >
                          Browse Artnstock
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              // <div>
              //   {imagesFolderArray?.artMaster?.length > 0 ? (
              //     <div>
              //       <div className={`mainImagesCon mt-[15px] }`}>
              //         <ResponsiveMasonry
              //           columnsCountBreakPoints={
              //             !showSidebar ? { 1920: 6 } : { 1920: 5 }
              //           }
              //         >
              //           <Masonry gutter='15px'>
              //             {imagesFolderArray.artMaster.map((data) => {
              //               return (
              //                 <div
              //                   onMouseEnter={() => {
              //                     setPopupArray([]);
              //                   }}
              //                   key={data?.artId}
              //                   className={` ${
              //                     showSidebar
              //                       ? 'w-[19.25rem]'
              //                       : 'w-[18.688rem]'
              //                   }`}
              //                   style={{ height: 'fit-content' }}
              //                 >
              //                   <div
              //                     className={` w-full group rounded-2xl relative`}
              //                     onClick={() =>
              //                       goToArtDetailsPage(data?.artId)
              //                     }
              //                   >
              //                     <img
              //                       className='rounded-2xl'
              //                       style={{ height: '100%' }}
              //                       src={
              //                         imageGrid === 'grid'
              //                           ? imageLinkChange(data?.image)
              //                           : imageLinkChangeSquaregrid(
              //                               data?.image
              //                             )
              //                       }
              //                       alt=''
              //                     />
              //                     <div
              //                       className='group-hover:flex hidden bg-blackRgba items-center justify-center absolute top-0 left-0 rounded-2xl'
              //                       style={{
              //                         height: '100%',
              //                         width: '100%',
              //                       }}
              //                     >
              //                       <p className='text-[25px] text-[#fff]'>
              //                         {data.subjectMaster.subjectName}
              //                       </p>
              //                       <div className='absolute bottom-[10px] left-[10px] flex gap-[10px]'>
              //                         <div
              //                           onClick={(e) => {
              //                             popupOfHover({
              //                               id: data.artId,
              //                             });
              //                             e.stopPropagation();
              //                           }}
              //                         >
              //                           <img src={save} alt='' />
              //                         </div>
              //                         <div>
              //                           <img src={similar} alt='' />
              //                         </div>
              //                         <div>
              //                           <img src={profile} alt='' />
              //                         </div>
              //                         <div>
              //                           <img src={shopCart} alt='' />
              //                         </div>
              //                         <div>
              //                           <img
              //                             onClick={(e) => {
              //                               navigate(
              //                                 '/BuyerReferralProgram'
              //                               );
              //                               e.stopPropagation();
              //                             }}
              //                             src={share}
              //                             alt=''
              //                           />
              //                         </div>
              //                       </div>
              //                       <div className='absolute right-[10px] bottom-[10px]'>
              //                         <img src={enlarge} alt='' />
              //                       </div>
              //                       <div className='absolute right-[3px] top-[3px]'>

              //                         {wishlist?.find(
              //                           (obj) =>
              //                             obj.artMaster?.artId ===
              //                             data.artId
              //                         ) === undefined ? (
              //                           <Wishlist
              //                             className='cursor-pointer'
              //                             onMouseEnter={
              //                               handleMouseEnter
              //                             }
              //                             onMouseLeave={
              //                               handleMouseLeave
              //                             }
              //                             onClick={(e) => {
              //                               addToWishlist(
              //                                 data?.artId
              //                               );
              //                               e.stopPropagation();
              //                             }}
              //                             style={{
              //                               fill: '#fff',
              //                               width: '100%',
              //                             }}
              //                           />
              //                         ) : (
              //                           <Wishlist
              //                             className='cursor-pointer'
              //                             onMouseEnter={
              //                               handleMouseEnter
              //                             }
              //                             onMouseLeave={
              //                               handleMouseLeave
              //                             }
              //                             onClick={(e) => {
              //                               wishlistDelete(
              //                                 data?.artId
              //                               );
              //                               e.stopPropagation();
              //                             }}
              //                             style={{
              //                               fill: 'red',
              //                               width: '100%',
              //                             }}
              //                           />
              //                         )}

              //                       </div>
              //                       {isHovered && (
              //                         <button className='w-[164px] z-[99] mt-[3px] h-[20px] flex justify-center items-center text-[11px] bg-[#f7f7f7] rounded-[10px] text-primaryGray absolute top-[33px] left-[203px] border border-[#e4e4e4]'>
              //                           <span className='leading-[1]'>
              //                             Save to Wishlist
              //                           </span>
              //                         </button>
              //                       )}
              //                       {popupArray.find(
              //                         (obj) => obj.id === data.artId
              //                       ) && (
              //                         <div
              //                           className={`z-999 right-[117px] bottom-[15px] bg-[#fff] rounded-[16px] w-[266px] absolute bottom-[44px] left-[-117px]`}
              //                           style={{
              //                             boxShadow:
              //                               '0px 0px 18px rgba(0, 0, 0, 0.2)',
              //                           }}
              //                         >
              //                           <div className='flex gap-[5px] flex-col p-[14px] leading-[1.3] text-center'>
              //                             <p className='font-medium text-primaryBlack text-[15px]'>
              //                               Create Account
              //                             </p>
              //                             <p className='text-primaryGray text-[11px]'>
              //                               To create and add to a
              //                               collection, you must be a
              //                               logged-in member
              //                             </p>
              //                             <button className='bg-[#8e8e8e] rounded-[14px] h-[28px] w-[108px] text-[12px] font-medium text-[white] mx-[auto]'>
              //                               Create Account
              //                             </button>
              //                             <p className='text-orangeColor text-[11px]'>
              //                               Already a member? Sign in
              //                             </p>
              //                             <p className='text-pinkColor text-[11px]'>
              //                               Note: Downloaded images
              //                               will be saved in
              //                               ‘Collections’ folder
              //                             </p>
              //                           </div>
              //                           <div className='absolute left-[47%] bottom-[-10px] w-[20px] h-[20px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
              //                         </div>
              //                       )}
              //                     </div>
              //                   </div>
              //                   <p className='text-primaryBlack text-[15px] leading-[18px] font-semibold mt-1.5'>
              //                     {data?.artName}
              //                   </p>
              //                   <p className='text-primaryGray text-sm12 leading-[15px]'>
              //                     Artnstock <br />
              //                     35.4” x 31.5” Multiple Sizes
              //                   </p>
              //                   <p className='text-primaryBlack text-[15px] leading-[18px] font-semibold mt-1.5'>
              //                     ${data?.price}
              //                   </p>
              //                 </div>
              //               );
              //             })}
              //           </Masonry>
              //         </ResponsiveMasonry>
              //       </div>

              //       <div className='flex gap-[5px] mt-24 mx-auto items-center justify-center'>
              //         <p className='text-[13px] text-primaryGray leading-[15px] font-normal'>
              //           Page
              //         </p>
              //         <div className='flex w-[88px] border border-[#D6D6D6] rounded-2xl overflow-hidden'>
              //           <button className='bg-[#F7F7F7] py-2.5 px-3'>
              //             <img src={smallLeftArrow} alt='' />
              //           </button>
              //           <input
              //             className='w-[30px] text-[13px] leading-[15px] font-normal text-primaryGray text-center'
              //             type='text'
              //             value={1}
              //           />
              //           <button className='bg-[#F7F7F7] py-2.5 px-3'>
              //             <img src={smallRightArrow} alt='' />
              //           </button>
              //         </div>
              //         <p className='text-[13px] text-primaryGray leading-[15px] font-normal'>
              //           of 18
              //         </p>
              //       </div>
              //       <button className='blackBtn mt-2.5 mb-24 mx-auto block'>
              //         Next
              //       </button>
              //     </div>
              //   ) : (
              //     <div>
              //       <div className='w-[100%]'>
              //         <div className='mt-[30px] mx-[auto] text-center'>
              //           <p className='text-[#ff369f] text-[18px]'>
              //             It seems contributor have not have not added{' '}
              //             <br />
              //             any files yet.
              //           </p>
              //           <p className='mt-[10px] mb-[20px] text-[12px] text-[#757575]'>
              //             After adding files by the contributor on
              //             Artnstock will show up Here.
              //           </p>
              //           <button
              //             onClick={() => {
              //               navigate('/art-list');
              //             }}
              //             className='blackBtn text-[14px]'
              //           >
              //             Browse Artnstock
              //           </button>
              //         </div>
              //       </div>
              //     </div>
              //   )}
              // </div>
            )}
          </div>
        </div>
      </div>
      {/* test */}

      <Footer />
    </>
  );
};

export default ContributorViewMyStore;
