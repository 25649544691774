import { createSlice } from '@reduxjs/toolkit';

const wishlistSlice = createSlice({
  name: 'WishlistSlice',
  initialState: {
    wishlistCount: 0,
  },
  reducers: {
    setWishlistCount(state, action) {
      state.wishlistCount = action.payload;
    },
    // reduceWishlistCount(state, action) {
    //   state.cartCount = 0
    // },
  },
});

export const wishlistSliceAction = wishlistSlice.actions;
// export const { setUserRole } = authSlice.actions;
export default wishlistSlice;
