import React from 'react';
import Certificate1 from '../../../assets/images/FooterSubComp/Certificate1.png';
import Certificate2 from '../../../assets/images/FooterSubComp/Certificate2.png';
import Certificate3 from '../../../assets/images/FooterSubComp/Certificate3.png';
import Wallpaper from '../../../assets/images/FooterSubComp/Wallpaper.png';
import OnlineVougue from '../../../assets/images/FooterSubComp/OnlineVogue.png';
import FreshInflux from '../../../assets/images/FooterSubComp/FreshInflux.png';
import Auction from '../../../assets/images/FooterSubComp/Auction.png';

import Footer1 from '../../../assets/images/FooterSubComp/footerAd01.jpg';
import Footer2 from '../../../assets/images/FooterSubComp/footerAd02.jpg';
import Footer3 from '../../../assets/images/FooterSubComp/footerAd03.jpg';
import Footer4 from '../../../assets/images/FooterSubComp/footerAd04.jpg';

const Certificate = () => {
  return (
    <div className='mt-[128px]'>
      <div className='pb-[16px]'>
        <div className='flex justify-center'>
          <p className='mr-[32px]'>
            <img src={Footer1} alt='' />
          </p>
          <p className='border-x-2 border-[#efefef] px-[32px]'>
            <img src={Footer2} alt='' />
          </p>
          <p className='border-r-2 border-[#efefef] px-[32px]'>
            <img src={Footer3} alt='' />
          </p>
          <p className='ml-[32px]'>
            <img src={Footer4} alt='' />
          </p>
        </div>
      </div>
      <div className='bg-[#f5f5f7] py-[15px]'>
        <div className='flex justify-center gap-[18px]'>
          <p>
            <img src={Certificate1} alt='' />
          </p>
          <p>
            <img src={Certificate2} alt='' />
          </p>
          <p>
            <img src={Certificate3} alt='' />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Certificate;
