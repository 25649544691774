import React from 'react';
import mainLogo from '../../assets/images/header/mainLogo.svg';

import { ReactComponent as Fb } from '../../assets/images/socials/fb.svg';

import { ReactComponent as Linked } from '../../assets/images/socials/linked.svg';
import { ReactComponent as Insta } from '../../assets/images/socials/insta.svg';
import { ReactComponent as Twitter } from '../../assets/images/socials/twitter.svg';

import { ReactComponent as Paypal } from '../../assets/images/footer/Paypal.svg';
import { ReactComponent as Visa } from '../../assets/images/footer/Visa.svg';
import { ReactComponent as Mastercard } from '../../assets/images/footer/Mastercard.svg';
import { ReactComponent as AmerExpress } from '../../assets/images/footer/AmerExpress.svg';
import { ReactComponent as Discover } from '../../assets/images/footer/Discover.svg';

// import fb from '../../assets/images/socials/fb.png';
// import twitter from '../../assets/images/socials/twitter.png';
// import linked from '../../assets/images/socials/linked.png';
// import insta from '../../assets/images/socials/insta.png';

import { ReactComponent as DropArrow } from '../../assets/images/Icons/Down arrow.svg';

import { ReactComponent as Cards } from '../../assets/images/socials/Cards.svg';
// import cards from '../../assets/images/socials/Cards.png';
import signup from '../../assets/images/socials/signupart.png';

import globe from '../../assets/images/socials/globe.png';
import usflag from '../../assets/images/socials/usflag.jpg';

import { ReactComponent as Globe } from '../../assets/images/socials/globeIcon.svg';
import { ReactComponent as UsFlag } from '../../assets/images/socials/usFlag.svg';

import dropdown from '../../assets/images/socials/dropdown.png';
import signuptext from '../../assets/images/Icons/signup.svg';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const [isOpenLang, setIsOpenLang] = useState(false);
  const [isOpenCurr, setIsOpenCurr] = useState(false);

  const [FbIconHoverColor, setFbIconHoverColor] = useState('#888888');
  const [TwitterIconHoverColor, setTwitterIconHoverColor] =
    useState('#888888');
  const [LinkedIconHoverColor, setLinkedIconHoverColor] =
    useState('#888888');
  const [InstaIconHoverColor, setInstaIconHoverColor] =
    useState('#888888');

  const userRole = useSelector((state) => state.auth.userRole);

  const navigate = useNavigate();

  const handleClickOutside = () => {
    // Code to handle click outside
    setIsOpenLang(false);
  };
  const handleClickOutsideCurr = () => {
    // Code to handle click outside

    setIsOpenCurr(false);
  };

  const CurrClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideCurr,
  });

  const langClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutside,
  });

  // useEffect(() => {
  //   console.log(userRole);
  // }, []);

  return (
    <div className='footer'>
      <div className='footer-top text-center text-14px bg-[#EFEFEF] h-[38px] flex justify-center items-center'>
        <p className='font-normal text-[14px] leading-4 text-[#757575]'>
          Help us improve your
          <span className='text-[#333333]'>
            {' '}
            Artnstock experience.
          </span>
          <span
            onClick={() => navigate('/ContactUs')}
            className='text-[#F7941D] cursor-pointer'
          >
            {' '}
            Send feedback.
          </span>
        </p>
      </div>
      <div className='footer-bottom lg:pl-[255px] lg:pr-[255px] pt-[40px] pb-[23px] bg-[#CCCCCC]'>
        <img
          className='w-[217.92px] h-[38px]'
          src={mainLogo}
          alt=''
        />{' '}
        <br />
        {/* <hr className='-w[100%] border-[#BCBCBC] mt-[11px] mb-[11px]' /> */}
        {/* list elements  */}
        <div className='flex flex-wrap gap-[75px]'>
          <div className='flex gap-[54px] flex-wrap '>
            {/* Column 1 bg-gray-100 h-16 */}
            <div className='w-[120px] '>
              <p className='footerHeadings'>COMPANY</p>

              <p className='footerLinks'>
                <Link to='/AboutUs'>About Us</Link>
              </p>
              {/* <p className='footerLinks'>
                <Link to='/AboutUs'>About Us</Link>
              </p> */}

              <p className='footerLinks'>
                <Link to='/ArtnstockMagazine'>
                  Artnstock Magazine
                </Link>
              </p>

              <p className='footerLinks'>
                <Link to='/PressMedia'>Press/Media</Link>
              </p>

              <p className='footerLinks'>
                <Link to='/Blog'>Blog</Link>
              </p>

              <p className='footerLinks'>
                <Link to='/Careers'>Careers</Link>
              </p>

              <p className='footerLinks'>
                <Link to='/ContactUs'>Contact Us</Link>
              </p>
            </div>
            {/* Column 2 */}

            <div className=''>
              <p className='footerHeadings'>SHOP</p>

              <p className='footerLinks'>
                <Link to='/art-home'>Art</Link>
              </p>

              <p className='footerLinks'>Images</p>

              <p className='footerLinks'>Footage</p>

              <p className='footerLinks'>Music</p>

              <p className='footerLinks'>Templates</p>

              <p className='footerLinks'>
                <Link to='/product-home'>Products</Link>
              </p>
            </div>
            {/* Column 3 */}

            <div className=''>
              <p className='footerHeadings'>BUYERS</p>

              <p className='footerLinks'>
                <Link to='/PlansPricing'>Plans & Pricing</Link>
              </p>

              <p className='footerLinks'>
                <Link to='/Offers'>Offers</Link>
              </p>

              <p className='footerLinks'>
                <Link to='/PromoCode'>Promo Code</Link>
              </p>

              <p className='footerLinks'>
                <Link to='/BuyerReferralProgram'>
                  Buyer Referral Program
                </Link>
              </p>

              <p className='footerLinks'>
                <Link to='/TrackMyOrder'>Track My Order</Link>
              </p>

              <p className='footerLinks'>
                <Link to='/ShippingDelivery'>
                  Shipping & Delivery
                </Link>
              </p>

              <p className='footerLinks'>
                <Link to='/ReturnMyOrder'>Return My Order</Link>
              </p>

              <p className='footerLinks'>
                <Link to='/ReturnPolicy'>Return Policy</Link>
              </p>

              <p className='footerLinks'>
                <Link to='/BuyersSupportCenter'>
                  Buyers Support Center
                </Link>
              </p>
            </div>
            {/* Column 4 */}

            <div className=' '>
              <p className='footerHeadings'>CONTRIBUTORS</p>

              <p className='footerLinks'>
                <Link to='/BecomeContributor'>
                  Become A Contributor
                </Link>
              </p>

              <p className='footerLinks'>
                <Link to='/DownloadRealeaseForm'>
                  Download Realease Form
                </Link>
              </p>

              <p className='footerLinks'>
                <Link to='/ContributorReferralProgram'>
                  Contributor Referral Program
                </Link>
              </p>

              <p className='footerLinks'>
                <Link to='/ContributorSupportCenter'>
                  Contributor Support Center
                </Link>
              </p>
            </div>
            {/* Column 5 */}

            <div className=''>
              <p className='footerHeadings'>LEGAL</p>

              <p className='footerLinks'>
                <Link to='/TermsAndServices'>Terms of Service</Link>
              </p>

              <p className='footerLinks'>
                <Link to='/LicenseAgreement'>License Agreement</Link>
              </p>

              <p className='footerLinks'>
                <Link to='/PrivacyPolicy'>Privacy Policy</Link>
              </p>

              <p className='footerLinks'>
                <Link to='/CopyrightTrademarkPolicy'>
                  Copyright & Trademark Policy
                </Link>
              </p>

              <p className='footerLinks'>
                <Link to='/CookiesSetting'>Cookies Settings</Link>
              </p>

              <p className='footerLinks'>
                <Link to='/CommunityGuidelines'>
                  Community Guidelines
                </Link>
              </p>

              <p className='footerLinks'>
                <Link to='/DNSMyPersonalInformation'>
                  Do Not Sell My Personal Information
                </Link>
              </p>
            </div>
          </div>

          <div className='flex gap-[10px] flex-wrap '>
            {/* Column 6 */}
            <div className='flex-col '>
              <div ref={langClickOutside}>
                <button
                  onClick={() => {
                    setIsOpenLang(!isOpenLang);
                  }}
                  className={`${
                    isOpenLang === true
                      ? 'rounded-t-[20px] shadow-dropShadowButton border-b'
                      : 'rounded-[20px]'
                  } w-[220px] border-[#EFEFEF] px-[15px] text-sm14 font-medium text-[#BBBBBB] h-[40px] bg-[#fff] flex items-center justify-between z-50`}
                >
                  <div className='flex gap-[10px]'>
                    {/* <img src={globe} alt='' /> */}
                    <div>
                      <Globe />
                    </div>
                    <span>English</span>
                  </div>
                  <div>
                    <DropArrow
                      style={{
                        fill: '#878787',
                      }}
                    />
                  </div>
                </button>
                {isOpenLang && (
                  <ul
                    onClick={handleClickOutside}
                    className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray w-[220px]'
                  >
                    <li className='cursor-pointer h-[40px] flex items-center pl-[46px] hover:bg-[#f0f0f0] border-b border-[#EFEFEF]'>
                      English
                    </li>
                    <li className='cursor-pointer h-[40px] flex items-center pl-[46px] hover:bg-[#f0f0f0] border-b border-[#EFEFEF]'>
                      German
                    </li>
                    <li className='cursor-pointer h-[40px] flex items-center pl-[46px] hover:bg-[#f0f0f0]'>
                      French
                    </li>
                  </ul>
                )}
              </div>
              <div className='flex gap-[15px] mt-[16px] inline block'>
                <div>
                  <Fb
                    onClick={() =>
                      window.open(
                        'https://www.facebook.com',
                        '_blank'
                      )
                    }
                    className='cursor-pointer'
                    onMouseEnter={() => {
                      setFbIconHoverColor('#333333');
                    }}
                    onMouseLeave={() => {
                      setFbIconHoverColor('#888888');
                    }}
                    style={{
                      fill: FbIconHoverColor,
                    }}
                  />
                </div>
                <div className='mt-[3px]'>
                  <Twitter
                    onClick={() =>
                      window.open('https://twitter.com', '_blank')
                    }
                    className='cursor-pointer'
                    onMouseEnter={() => {
                      setTwitterIconHoverColor('#333333');
                    }}
                    onMouseLeave={() => {
                      setTwitterIconHoverColor('#888888');
                    }}
                    style={{
                      fill: TwitterIconHoverColor,
                    }}
                  />
                </div>
                <div>
                  <Linked
                    onClick={() =>
                      window.open('https://linkedin.com', '_blank')
                    }
                    className='cursor-pointer'
                    onMouseEnter={() => {
                      setLinkedIconHoverColor('#333333');
                    }}
                    onMouseLeave={() => {
                      setLinkedIconHoverColor('#888888');
                    }}
                    style={{
                      fill: LinkedIconHoverColor,
                    }}
                  />
                </div>
                <div>
                  <Insta
                    onClick={() =>
                      window.open('https://instagram.com', '_blank')
                    }
                    className='cursor-pointer'
                    onMouseEnter={() => {
                      setInstaIconHoverColor('#333333');
                    }}
                    onMouseLeave={() => {
                      setInstaIconHoverColor('#888888');
                    }}
                    style={{
                      fill: InstaIconHoverColor,
                    }}
                  />
                </div>
              </div>

              <div className='mt-[16px]'>
                <p className='text-sm11  text-[#757575] mb-[8px]'>
                  Accepted Payment Methods
                </p>

                <div className='flex gap-[4px]'>
                  <div>
                    <Paypal className='fill-[#F3F3F3]' />
                  </div>

                  <div>
                    <Visa className='fill-[#F3F3F3]' />
                  </div>

                  <div>
                    <Mastercard className='fill-[#F3F3F3]' />
                  </div>

                  <div>
                    <AmerExpress className='fill-[#F3F3F3]' />
                  </div>
                  <div>
                    <Discover className='fill-[#F3F3F3]' />
                  </div>
                </div>
              </div>
            </div>

            {/* Column 7 */}
            {/* Column 7 */}
            <div ref={CurrClickOutside}>
              <button
                onClick={() => {
                  setIsOpenCurr(!isOpenCurr);
                }}
                className={`${
                  isOpenCurr === true
                    ? 'rounded-t-[20px] shadow-dropShadowButton border-b'
                    : 'rounded-[20px]'
                } w-[220px] border-[#EFEFEF] px-[15px] text-sm14 font-medium text-[#BBBBBB] h-[40px] bg-[#fff] flex items-center justify-between z-50`}
              >
                <div className='flex gap-[10px]'>
                  <div>
                    <UsFlag />
                  </div>
                  {/* <img src={usflag} alt='' /> */}
                  <span>USD</span>
                </div>
                <div>
                  <DropArrow
                    style={{
                      fill: '#878787',
                    }}
                  />
                </div>
              </button>
              {isOpenCurr && (
                <ul
                  onClick={handleClickOutsideCurr}
                  className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray w-[220px]'
                >
                  <li className='cursor-pointer h-[40px] flex items-center pl-[46px] hover:bg-[#f0f0f0] border-b border-[#EFEFEF]'>
                    USD
                  </li>
                  <li className='cursor-pointer h-[40px] flex items-center pl-[46px] hover:bg-[#f0f0f0] border-b border-[#EFEFEF]'>
                    INR
                  </li>
                  <li className='cursor-pointer h-[40px] flex items-center pl-[46px] hover:bg-[#f0f0f0]'>
                    EUR
                  </li>
                </ul>
              )}
            </div>
            {/* end col7 */}
          </div>
        </div>
        <hr className='border-[#BCBCBC] mt-[20px] mb-[20px] h-[1px]' />
        <div className='flex justify-center flex-wrap'>
          <div
            style={{ backgroundImage: `url(${signup})` }}
            className='h-[208px] w-[224px] bg-no-repeat flex justify-center items-center rounded-bl-[16px]'
          >
            <div className='text-center'>
              <img className='' src={signuptext} alt='' />
              <span className=' text-[13px] leading-[15.86px] font-medium w-[100%]'>
                for exclusive offers
              </span>
            </div>
          </div>
          <div className='p-[16px] h-[208px] w-[224px] bg-[white] rounded-tr-[16px] rounded-br-[16px] flex justify-center items-center'>
            <div className='flex flex-col gap-[8px]'>
              <input
                className='border border-[#D6D6D6] outline-none rounded-[20px] w-[195px] placeholder:text-[13px] h-[32px] pl-[12px]'
                type='text'
                placeholder='Enter Email Address'
              />
              <div>
                <button className='bg-[#333333] hover:bg-[#000000] text-[white] rounded-[16px] px-[12px] h-[28px] text-[11px] leading-[20.56px] font-medium '>
                  Sign Up
                </button>
              </div>

              <div className=''>
                <p className='text-primaryGray text-sm11'>
                  Artnstock is an online platform for promoting
                  quality art created by artists worldwide.
                </p>
              </div>
            </div>
          </div>
        </div>
        <p className='flex justify-center text-[#757575] text-[11px] leading-[11px] font-normal mt-[11px]'>
          Copyright 2024. Artnstock Private Limited. All rights
          reserved.
        </p>
      </div>
    </div>
  );
};
export default Footer;
