import React, { useEffect, useState } from 'react';
import { httpClient } from '../../../axios';
import leftArrow from '../../../assets/images/Icons/Left Arrow.svg';
import rightArrow from '../../../assets/images/Icons/Right Arrow.svg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import percentImg from '../../../assets/images/static/percentImg.svg';
import { useNavigate } from 'react-router-dom';

const Banner = ({ type }) => {
  const [bannerList, setBannerList] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0); // Track the current slide index

  const navigate = useNavigate();

  useEffect(() => {
    const getBanner = async () => {
      await httpClient
        .get('/banner_master/getBannerTypeWiseList/' + type)
        .then((res) => {
          console.log(res.data);
          setBannerList(res.data);
        });
    };

    getBanner();
  }, [type]);

  const addLineBreakAfterWord = (text, word) => {
    const parts = text?.split(word);
    return parts?.reduce((result, part, index) => {
      result.push(part);
      if (index !== parts.length - 1) {
        result.push(<br key={index} />);
      }
      return result;
    }, []);
  };

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    nextArrow: <img src={rightArrow} alt='left' />,
    maxHeight: '500px',
    // prevArrow: <img src={leftArrow} alt='right' />,
    prevArrow: <img src={leftArrow} alt='right' />,
    // height: '495.56px',
    beforeChange: (current, next) => {
      // Update the current slide index when changing slides
      setCurrentSlide(next);
    },
  };

  return (
    <>
      <Slider {...settings} style={{ maxHeight: '500px' }}>
        {bannerList.map((data, index) => {
          return (
            <div
              style={{ maxHeight: '500px' }}
              className='relative outline-none'
              key={data?.bannerId}
            >
              <img
                alt='ted'
                style={{ width: '100%', maxHeight: '500px' }}
                src={data?.imagePath}
              />
              <div className='absolute left-24 z-10 top-[107px]'>
                <p className='text-[4.688rem] leading-[83px] font-semibold mb-[3px] text-primaryBlack'>
                  {data?.bannerLeftTitle}
                </p>
                <p className='text-[2.375rem] font-medium leading-[40px] w-[603px] text-primaryBlack'>
                  {addLineBreakAfterWord(
                    data?.bannerLeftDescription,
                    '~'
                  )}
                </p>
                <button
                  onClick={() => {
                    navigate('/art-list');
                  }}
                  className='blackBtn my-[16px]'
                >
                  Discover Collections
                </button>
                <p className='w-[300px] leading-[13px] text-primaryBlack text-sm11 font-normal'>
                  {/* {data?.copyRightText} */}
                  Artnstock has an live-up of{' '}
                  <span className='font-medium'>Art</span> ,{' '}
                  <span className='font-medium'>Music</span> and
                  cluster of
                  <span className='font-medium'>Templates</span>,{' '}
                  <span className='font-medium'>Photos</span> and{' '}
                  <span className='font-medium'>Footages</span>.
                  Explore our{' '}
                  <span className='font-medium'>Products</span> and
                  experience our excellence in all.
                </p>
              </div>
              <div className='absolute right-24 z-10 top-[102px]'>
                <img src={percentImg} alt='' />
                <p className='text-[15px] font-medium leading-[130%] text-primaryBlack mt-[3px]'>
                  {data?.offerText}
                </p>
                <p className='text-sm11 text-primaryBlack mb-[8px]'>
                  {data?.offerDesc}
                </p>
                <button
                  onClick={() => {
                    navigate('/Offers');
                  }}
                  className='bg-pinkColor text-sm12 text-primaryBlack font-semibold rounded-2xl flex items-center justify-center px-[12px] h-[28px]'
                >
                  Shop with 20% Off
                </button>
                <div className='bg-primaryBlack w-[10px] h-[6px] rounded-2xl my-[8px]'></div>

                <img
                  className='cursor-pointer'
                  onClick={() => navigate('/art-list')}
                  src={data?.rightBottomImg}
                  alt=''
                />
              </div>
            </div>
          );
        })}
      </Slider>
      {/* Add custom styles for the dots */}
      <style>
        {`

      .slick-prev {
        opacity: ${currentSlide === 0 ? 0.3 : 1};

      }
      .slick-next {
        opacity: ${currentSlide === bannerList.length - 1 ? 0.3 : 1};

      }

     

    `}
      </style>
    </>
  );
};

export default Banner;
