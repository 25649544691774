import React from 'react';
import pro from '../../assets/images/accountSettings/pro.png';

import { useState, useEffect } from 'react';

import { ReactComponent as ProIcon } from '../../assets/images/art-details/Icon_Pro.svg';

import { ReactComponent as DropArrow } from '../../assets/images/Icons/Down arrow.svg';

import { ReactComponent as Globe } from '../../assets/images/socials/globeIcon.svg';

const Contributor_DeleteAcc = () => {
  const [isOpenReason, setIsOpenReason] = useState(false);

  return (
    <>
      <div className='flex justify-between'>
        <div>
          <p className='text-[25px] leading-[1] font-medium text-primaryBlack'>
            Delete Account
          </p>
          <p className='text-sm11 text-pinkColor'>
            All fields are required.
          </p>
        </div>
        <div>
          <div className='border border-[#eaeaea] rounded-[7px] h-[41px] flex items-center p-[6px]'>
            <div className='flex flex-col gap-[3px]'>
              <ProIcon />
              <p className='text-[11px] leading-[1] text-primaryGray'>
                Member since 5 July 2020
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='flex flex-col'>
        <p className='text-sm12 text-[#333333] font-medium mt-[30px] mb-[8px]'>
          Before you delete your account, please note:
        </p>
        <div style={{ paddingLeft: '16px' }}>
          <ul
            className='text-sm12 text-[#757575] mb-9'
            style={{ listStyle: 'disc', paddingLeft: '0' }}
          >
            <li className=''>
              You don't need to deactivate your account just to change
              your display name, you can do this on your profile page.
            </li>
            <li className=''>
              Any content and/or Studios that you do not delete prior
              to deactivation may still show up on Artnstock.
            </li>
            <li className=''>
              We have no control over content indexed by search
              engines.
            </li>
            <li className=''>
              If you plan on creating a new account and want to use
              the same user name, or email address associated with
              this account, you must first change them on this account
              before you deactivate it. If you don't, the information
              will be tied to this account and unavailable to use.
            </li>
            <li className=''>
              Please be aware that this is a permanent action.
            </li>
            <li className=''>
              We cannot restore cancelled accounts.
            </li>
          </ul>
        </div>
        <p className='text-[#333333] text-[15px]'>
          Please tell us why you are deactivating
        </p>

        <div className='mb-[28px]'>
          <button
            onClick={() => {
              setIsOpenReason(!isOpenReason);
            }}
            className={`${
              isOpenReason === true
                ? 'dropdownTwoGlobalTrue'
                : 'dropdownTwoGlobalFalse'
            } dropdownTwoGlobalCommon  w-[100%] max-w-[450px]`}
          >
            <div className='flex flex-col'>
              <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                Select
              </p>
              <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                Reason
              </p>
            </div>

            <div>
              <DropArrow
                className={`${
                  isOpenReason ? 'transform rotate-180' : ''
                }`}
                style={{
                  fill: '#878787',
                }}
              />
            </div>
          </button>
          {isOpenReason && (
            <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray w-[100%] max-w-[450px]'>
              <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                English
              </li>
              <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                German
              </li>
              <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0]'>
                Hindi
              </li>
            </ul>
          )}
        </div>

        <div className='flex justify-center mb-[506px]'>
          <button className='blackBtn'>Delete My Account</button>
        </div>
      </div>
    </>
  );
};

export default Contributor_DeleteAcc;
