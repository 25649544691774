import React from 'react';
// import newArrivals from '../../assets/images/header/New Arrivals (1).svg';
// import visitArtnstock from '../../assets/images/header/Visit Artnstock Collectibles (2).svg';
// import { ReactComponent as SellYourPaintings } from '../../assets/images/header/SellYourPaintings.svg';
import { useNavigate } from 'react-router-dom';
import { httpClient } from '../../axios';
import { useState, useEffect } from 'react';

const Topbar = () => {
  const [images, setimages] = useState([]);

  const getImages = async () => {
    try {
      const res = await httpClient.get('TopBarMaster');
      console.log(res.data);
      setimages(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    getImages();
  }, []);

  return (
    <div className='bg-primaryBlack flex justify-center items-center py-1.5 max-h-[32px]'>
      <div className='leftCon'>
        <div className='flex justify-center items-center'>
          {/* <p className='text-lightGray font-semibold text-[13px] mr-1.5'>
            SELL YOUR PAINTINGS AND OTHER ART ONLINE
          </p>
          <button className='text-secGray text-[0.625rem] flex justify-center items-center font-medium leading-2 border border-solid border-secGray rounded-[10px] h-[16px] w-[67px]'>
            Learn more
          </button> */}
          {/* <SellYourPaintings
            onClick={() => {
              navigate('/SellOnArtnstock-Home');
            }}
            className='cursor-pointer'
          /> */}
          <img
            onClick={() => {
              navigate('/SellOnArtnstock-Home');
            }}
            className='cursor-pointer'
            src={images[0]?.link1}
            alt=''
          />
        </div>
      </div>
      <div className='w-[1px] bg-[#575757] mx-[15px] h-[17px]'></div>
      <div className='mt-[1px]'>
        <img
          onClick={() => {
            navigate('/art-list');
          }}
          className='cursor-pointer'
          src={images[0]?.link2}
          alt=''
        />
      </div>
      <div className='w-[1px] bg-[#575757] mx-[15px] h-[17px]'></div>

      <div className='mb-[1px]'>
        <img
          className='cursor-pointer'
          src={images[0]?.link3}
          alt=''
        />
      </div>
    </div>
  );
};

export default Topbar;
