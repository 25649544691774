import React from 'react';
import pro from '../../assets/images/accountSettings/pro.png';
import payment from '../../assets/images/accountSettings/payment.png';
import { ReactComponent as ProIcon } from '../../assets/images/art-details/Icon_Pro.svg';

import { ReactComponent as Paypal } from '../../assets/images/footer/Paypal.svg';
import { ReactComponent as Visa } from '../../assets/images/footer/Visa.svg';
import { ReactComponent as Mastercard } from '../../assets/images/footer/Mastercard.svg';
import { ReactComponent as AmerExpress } from '../../assets/images/footer/AmerExpress.svg';
import { ReactComponent as Discover } from '../../assets/images/footer/Discover.svg';

const Contributor_PaymentInfo = () => {
  return (
    <>
      <div className='flex justify-between'>
        <div>
          <p className='text-[25px] leading-[1] font-medium text-primaryBlack'>
            Payment Information
          </p>
          <p className='text-sm11 text-pinkColor'>
            All fields are required.
          </p>
        </div>
        <div>
          <div className='border border-[#eaeaea] rounded-[7px] h-[41px] flex items-center p-[6px]'>
            <div className='flex flex-col gap-[3px]'>
              <ProIcon />
              <p className='text-[11px] leading-[1] text-primaryGray'>
                Member since 5 July 2020
              </p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <p className='mt-[30px] text-sm12 text-[#757575]'>
          Payment Method (Credit/Debit Cards)
        </p>
        <div className='w-full h-[236px] border rounded-2xl flex justify-center items-center text-center border-[#d6d6d6]'>
          <div>
            <p className='text-pinkColor text-[18px] leading-[1.2]'>
              No Payment Methods on File
            </p>
            <p className='text-sm11 text-primaryGray'>
              Payment methods you add will appear here.
            </p>
            <div className='flex justify-center'>
              <button className='greenBlueButton mt-[10px] mb-[20px]'>
                Add Card
              </button>
            </div>
            <div>
              <div className='flex justify-center gap-[4px] mx-[auto] '>
                <div>
                  <Paypal className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                </div>

                <div>
                  <Visa className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                </div>

                <div>
                  <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                </div>

                <div>
                  <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                </div>
                <div>
                  <Discover className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className='gray30HButton mt-3 '>
          Remove Payment Method
        </button>
        <p className='text-[15px] font-medium text-primaryBlack mt-[32px]'>
          Payment Details for Referral Earnings
        </p>

        <div className='relative mt-[12px]'>
          <input
            type='text'
            placeholder='Enter Payee Name'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            // onChange={handleInputChange}
            // value={userDetail?.displayName || ''}
            // name='displayName'
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            Make Payment to
          </p>
        </div>

        {/* 
        <p className='text-[12px] text-[#757575] leading-[0] mb-1 mt-4'>
          Make Payment to
        </p>
        <input
          type='text'
          name=''
          className='regInput text-[#333333]'
          placeholder='Enter Payee Name'
          id=''
        /> */}

        <div className='relative mt-[12px]'>
          <input
            type='text'
            placeholder='None Selected'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            // onChange={handleInputChange}
            // value={userDetail?.displayName || ''}
            // name='displayName'
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            Payment Method
          </p>
        </div>

        {/* <p className='text-[12px] text-[#757575] leading-[0] mb-1 mt-3'>
          Payment Method
        </p>
        <input
          type='text'
          name=''
          className='regInput text-[#333333]'
          placeholder='None Selected'
          id=''
        /> */}

        <div className='relative mt-[12px]'>
          <input
            type='text'
            placeholder='Enter Payee Email'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            // onChange={handleInputChange}
            // value={userDetail?.displayName || ''}
            // name='displayName'
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            Payout Email
          </p>
        </div>

        {/* <p className='text-[12px] text-[#757575] leading-[0] mb-1 mt-3'>
          Payout Email
        </p>
        <input
          type='text'
          name=''
          className='regInput text-[#333333]'
          placeholder='Enter Payee Email'
          id=''
        /> */}

        <div className='relative mt-[12px]'>
          <input
            type='text'
            placeholder='$50'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            // onChange={handleInputChange}
            // value={userDetail?.displayName || ''}
            // name='displayName'
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            Minimum Payout
          </p>
        </div>

        {/* <p className='text-[12px] text-[#757575] leading-[0] mb-1 mt-3'>
          Minimum Payout
        </p>
        <input
          type='number'
          name=''
          className='regInput text-[#333333]'
          placeholder='$50'
          id=''
        /> */}

        <div className='flex gap-5 justify-center mt-[30px] mb-[509px]'>
          <button className='blackBtn text-[14px] text-medium'>
            Save
          </button>
          <button className=' outlineBtn40h'>Cancel</button>
        </div>
      </div>
    </>
  );
};

export default Contributor_PaymentInfo;
