import React, { useEffect, useState } from 'react';
import { httpClient } from '../../../axios';
import artIcon from '../../../assets/images/Icons/ArtWhite.svg';
import { useNavigate } from 'react-router-dom';
import { setSubjectId } from '../../../store/subjectidSlice';
import { useDispatch } from 'react-redux';

const MainGrid = () => {
  const [gridList, setGridList] = useState([]);

  // redux slice
  // const subjectId = useSelector((state) => state.subjectId.subjectId);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    httpClient
      .get(`/subject_master/getTypeWiseSubjectList/home`)
      .then((res) => {
        setGridList(res.data);
        console.log(res.data);
      });
  }, []);

  const artNextPage = (text) => {
    // console.log(text);
    // /art-list
    navigate('/art-list');
  };

  const addLineBreakAfterWord = (text, word) => {
    const parts = text?.split(word);
    return parts?.reduce((result, part, index) => {
      result.push(part);
      if (index !== parts.length - 1) {
        result.push(<br key={index} />);
      }
      return result;
    }, []);
  };

  return (
    <>
      <div className='w-w1168 mx-auto mt-[27px]'>
        <p
          style={{
            lineHeight: '0.825rem!important',
          }}
          className='mb-[3px] bullet'
        >
          Artnstock / Home
        </p>
        <p className='text-primaryBlack text-heading font-medium text-center'>
          Diversely sized, aesthetic pleasing <br />
          and styled art
        </p>
        <p className='text-sm14 font-normal mt-[12px] text-center text-primaryGray'>
          Every piece of art we sell is offered in a variety of
          products and sizes to suit any interior design aesthetic or
          price range. If you need high-quality <br />
          artwork at fair costs, our choice of works of art will
          expand the possibilities for your visual initiatives.
        </p>
        <div className='grid grid-cols-4 gap-4 mt-[22px] mb-[26px]'>
          {/* Grid 1 */}
          <div
            onClick={() => {
              dispatch(setSubjectId(gridList[0]));
              navigate('/art-list');
            }}
            className='row-span-4 rounded-2xl group overflow-hidden cursor-pointer'
            style={{
              backgroundImage: `url(${gridList[0]?.image})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `386px`,
            }}
          >
            <div
              className='group-hover:flex hidden bg-blackRgba items-center justify-center relative text-center'
              style={{ height: 'inherit' }}
            >
              <div>
                <p
                  style={{
                    fontSize: '25px!important',
                  }}
                  className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px]'
                >
                  {gridList[0]?.subjectName}
                </p>
                <p className='text-[#FFFFFF] text-[12px] leading-[120%] font-normal w-[13.125rem] mb-[2px]'>
                  {addLineBreakAfterWord(
                    gridList[0]?.subjectDescription,
                    '~'
                  )}
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <img
                className='absolute bottom-[12px] left-[12px]'
                src={artIcon}
                alt=''
              />
            </div>
          </div>
          {/* Grid 2 */}
          <div
            onClick={() => {
              dispatch(setSubjectId(gridList[1]));
              navigate('/art-list');
            }}
            className='row-span-2 rounded-2xl group overflow-hidden cursor-pointer'
            style={{
              backgroundImage: `url(${gridList[1]?.image})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `185px`,
            }}
          >
            <div
              className='group-hover:flex hidden bg-blackRgba items-center justify-center relative text-center'
              style={{ height: 'inherit' }}
            >
              <div>
                <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px]'>
                  {gridList[1]?.subjectName}
                </p>
                <p className='text-[#FFFFFF] text-[12px] leading-[120%] font-normal w-[13.125rem] mb-[2px]'>
                  {addLineBreakAfterWord(
                    gridList[1]?.subjectDescription,
                    '~'
                  )}
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <img
                className='absolute bottom-[12px] left-[12px]'
                src={artIcon}
                alt=''
              />
            </div>
          </div>
          {/* Grid 3 */}
          <div
            onClick={() => {
              dispatch(setSubjectId(gridList[2]));
              navigate('/art-list');
            }}
            className='row-span-3 rounded-2xl group overflow-hidden cursor-pointer'
            style={{
              backgroundImage: `url(${gridList[2]?.image})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `280px`,
            }}
          >
            <div
              className='group-hover:flex hidden bg-blackRgba items-center justify-center relative text-center'
              style={{ height: 'inherit' }}
            >
              <div>
                <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px]'>
                  {gridList[2]?.subjectName}
                </p>
                <p className='text-[#FFFFFF] text-[12px] leading-[120%] font-normal w-[13.125rem] mb-[2px]'>
                  {addLineBreakAfterWord(
                    gridList[2]?.subjectDescription,
                    '~'
                  )}
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <img
                className='absolute bottom-[12px] left-[12px]'
                src={artIcon}
                alt=''
              />
            </div>
          </div>
          {/* Grid 4 */}
          <div
            onClick={() => {
              dispatch(setSubjectId(gridList[3]));
              navigate('/art-list');
            }}
            className='row-span-2 rounded-2xl group overflow-hidden cursor-pointer'
            style={{
              backgroundImage: `url(${gridList[3]?.image})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `185px`,
            }}
          >
            <div
              className='group-hover:flex hidden bg-blackRgba items-center justify-center relative text-center'
              style={{ height: 'inherit' }}
            >
              <div>
                <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px]'>
                  {gridList[3]?.subjectName}
                </p>
                <p className='text-[#FFFFFF] text-[12px] leading-[120%] font-normal w-[13.125rem] mb-[2px]'>
                  {addLineBreakAfterWord(
                    gridList[3]?.subjectDescription,
                    '~'
                  )}
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <img
                className='absolute bottom-[12px] left-[12px]'
                src={artIcon}
                alt=''
              />
            </div>
          </div>
          {/* Grid 5 */}
          <div
            onClick={() => {
              dispatch(setSubjectId(gridList[4]));
              navigate('/art-list');
            }}
            className='row-span-3 rounded-2xl group overflow-hidden relative bottom-[2px] cursor-pointer'
            style={{
              backgroundImage: `url(${gridList[4]?.image})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `280px`,
            }}
          >
            <div
              className='group-hover:flex hidden bg-blackRgba items-center justify-center relative text-center'
              style={{ height: 'inherit' }}
            >
              <div>
                <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px]'>
                  {gridList[4]?.subjectName}
                </p>
                <p className='text-[#FFFFFF] text-[12px] leading-[120%] font-normal w-[13.125rem] mb-[2px]'>
                  {addLineBreakAfterWord(
                    gridList[4]?.subjectDescription,
                    '~'
                  )}
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <img
                className='absolute bottom-[12px] left-[12px]'
                src={artIcon}
                alt=''
              />
            </div>
          </div>
          {/* Grid 6 */}
          <div
            onClick={() => {
              dispatch(setSubjectId(gridList[5]));
              navigate('/art-list');
            }}
            className='row-span-3 rounded-2xl group overflow-hidden relative bottom-[2px] cursor-pointer'
            style={{
              backgroundImage: `url(${gridList[5]?.image})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `280px`,
            }}
          >
            <div
              className='group-hover:flex hidden bg-blackRgba items-center justify-center relative text-center'
              style={{ height: 'inherit' }}
            >
              <div>
                <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px]'>
                  {gridList[5]?.subjectName}
                </p>
                <p className='text-[#FFFFFF] text-[12px] leading-[120%] font-normal w-[13.125rem] mb-[2px]'>
                  {addLineBreakAfterWord(
                    gridList[5]?.subjectDescription,
                    '~'
                  )}
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <img
                className='absolute bottom-[12px] left-[12px]'
                src={artIcon}
                alt=''
              />
            </div>
          </div>
          {/* Grid 7 */}
          <div
            onClick={() => {
              dispatch(setSubjectId(gridList[6]));
              navigate('/art-list');
            }}
            className='row-span-4 rounded-2xl group overflow-hidden relative bottom-[6px] cursor-pointer'
            style={{
              backgroundImage: `url(${gridList[6]?.image})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `386px`,
            }}
          >
            <div
              className='group-hover:flex hidden bg-blackRgba items-center justify-center relative text-center'
              style={{ height: 'inherit' }}
            >
              <div>
                <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px]'>
                  {gridList[6]?.subjectName}
                </p>
                <p className='text-[#FFFFFF] text-[12px] leading-[120%] font-normal w-[13.125rem] mb-[2px]'>
                  {addLineBreakAfterWord(
                    gridList[6]?.subjectDescription,
                    '~'
                  )}
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <img
                className='absolute bottom-[12px] left-[12px]'
                src={artIcon}
                alt=''
              />
            </div>
          </div>
          {/* Grid 8 */}
          <div
            onClick={() => {
              dispatch(setSubjectId(gridList[7]));
              navigate('/art-list');
            }}
            className='row-span-3 rounded-2xl group overflow-hidden cursor-pointer'
            style={{
              backgroundImage: `url(${gridList[7]?.image})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `280px`,
            }}
          >
            <div
              className='group-hover:flex hidden bg-blackRgba items-center justify-center relative text-center'
              style={{ height: 'inherit' }}
            >
              <div>
                <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px]'>
                  {gridList[7]?.subjectName}
                </p>
                <p className='text-[#FFFFFF] text-[12px] leading-[120%] font-normal w-[13.125rem] mb-[2px]'>
                  {addLineBreakAfterWord(
                    gridList[7]?.subjectDescription,
                    '~'
                  )}
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <img
                className='absolute bottom-[12px] left-[12px]'
                src={artIcon}
                alt=''
              />
            </div>
          </div>
          {/* Grid 9 */}
          <div
            onClick={() => {
              dispatch(setSubjectId(gridList[8]));
              navigate('/art-list');
            }}
            className='row-span-2 rounded-2xl group overflow-hidden relative bottom-[5px] cursor-pointer'
            style={{
              backgroundImage: `url(${gridList[8]?.image})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `185px`,
            }}
          >
            <div
              className='group-hover:flex hidden bg-blackRgba items-center justify-center relative text-center'
              style={{ height: 'inherit' }}
            >
              <div>
                <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px]'>
                  {gridList[8]?.subjectName}
                </p>
                <p className='text-[#FFFFFF] text-[12px] leading-[120%] font-normal w-[13.125rem] mb-[2px]'>
                  {addLineBreakAfterWord(
                    gridList[8]?.subjectDescription,
                    '~'
                  )}
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <img
                className='absolute bottom-[12px] left-[12px]'
                src={artIcon}
                alt=''
              />
            </div>
          </div>
          {/* Grid 10 */}
          <div
            onClick={() => {
              dispatch(setSubjectId(gridList[9]));
              navigate('/art-list');
            }}
            className='row-span-2 rounded-2xl group overflow-hidden relative bottom-[5px] cursor-pointer'
            style={{
              backgroundImage: `url(${gridList[9]?.image})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `185px`,
            }}
          >
            <div
              className='group-hover:flex hidden bg-blackRgba items-center justify-center relative text-center'
              style={{ height: 'inherit' }}
            >
              <div>
                <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px]'>
                  {gridList[9]?.subjectName}
                </p>
                <p className='text-[#FFFFFF] text-[12px] leading-[120%] font-normal w-[13.125rem] mb-[2px]'>
                  {addLineBreakAfterWord(
                    gridList[9]?.subjectDescription,
                    '~'
                  )}
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <img
                className='absolute bottom-[12px] left-[12px]'
                src={artIcon}
                alt=''
              />
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            artNextPage('all');
            dispatch(setSubjectId(null));
          }}
          className='blackBtn mx-auto block'
        >
          Discover More
        </button>
      </div>
      {/* <div class='grid grid-cols-6 gap-10 bg-[yellow] p-10'>
        <div class='col-span-6 sm:col-span-6 md:col-span-6 lg:col-span-2 xl:col-span-6 bg-[white] bg-opacity-80 text-center py-20 text-2xl font-semibold'>
          Header
        </div>
        <div class='col-span-6 sm:col-span-6 md:col-span-6 lg:col-span-4 xl:col-span-2 xl:row-span-2 bg-[white] bg-opacity-80 text-center py-20 text-2xl font-semibold'>
          Menu
        </div>
        <div class='col-span-6 sm:col-span-6 md:col-span-6 lg:col-span-4 xl:col-span-2 bg-[white] bg-opacity-80 text-center py-20 text-2xl font-semibold'>
          Main
        </div>
        <div class='col-span-6 sm:col-span-6 md:col-span-6 lg:col-span-2 xl:col-span-4 bg-[white] bg-opacity-80 text-center py-20 text-2xl font-semibold'>
          Right
        </div>
        <div class='col-span-6 sm:col-span-6 md:col-span-6 lg:col-span-6 xl:col-span-6 bg-[white] bg-opacity-80 text-center py-20 text-2xl font-semibold'>
          Footer
        </div>
      </div> */}
    </>
  );
};

export default MainGrid;
