import React, { useEffect, useState } from 'react';
// import { httpClient } from '../../../axios';
import artIcon from '../../../assets/images/Icons/ArtWhite.svg';
import prodWhiteIcon from '../../../assets/images/Icons/productsIconWhite.svg';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSubjectId } from '../../../store/subjectidSlice';

const ArtGrid = ({ gridList, type }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  // useEffect(() => {
  //   httpClient
  //     .get(`/subject_master/getTypeWiseSubjectList/art`)
  //     .then((res) => {
  //       setGridList(res.data);
  //       console.log(res);
  //     });
  // }, []);

  const artNextPage = (text) => {
    // console.log(text);
    // /art-list
    navigate('/art-list');
  };

  const addLineBreakAfterWord = (text, word) => {
    const parts = text?.split(word);
    return parts?.reduce((result, part, index) => {
      result.push(part);
      if (index !== parts.length - 1) {
        result.push(<br key={index} />);
      }
      return result;
    }, []);
  };

  return (
    <div className='w-w1168 mx-auto mt-10'>
      <p className='mb-[2px] bullet'>
        <span
          className='cursor-pointer'
          onClick={() => navigate('/')}
        >
          Artnstock
        </span>{' '}
        /
        {type === 'art' ? (
          <span> Art </span>
        ) : (
          <span> Products </span>
        )}
        / Home
      </p>
      <p className='text-primaryBlack text-heading font-medium text-center'>
        Exciting additions to our <br />
        Art Collections
      </p>
      <p className='text-sm14 font-normal mt-[12px] text-center text-primaryGray'>
        We have a distinct taste in art we offer, in multiple products
        and sizes to fit any decor style or budget. Our art collection
        will open new horizons <br />
        for your visual projects and would be a perfect solution for
        anyone who needs high-quality art at affordable prices.
      </p>
      <div className=' grid grid-cols-4 gap-4 my-7'>
        {/* Grid 1 */}
        <div
          onClick={() => {
            console.log(gridList[0]);
            dispatch(setSubjectId(gridList[0]));
            if (type === 'product') {
              navigate('/product-list');
            } else {
              navigate('/art-list');
            }
          }}
          className='row-span-4 rounded-2xl group overflow-hidden'
          style={{
            backgroundImage: `url(${gridList[0]?.image})`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            height: `576px`,
          }}
        >
          <div
            className='group-hover:flex hidden bg-blackRgba items-center justify-center relative text-center'
            style={{ height: 'inherit' }}
          >
            <div>
              <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px] '>
                {type === 'art' ? (
                  <span>{gridList[0]?.subjectName}</span>
                ) : (
                  <span>Sipper Bottles</span>
                )}
              </p>
              <p className='gridDescription'>
                {type === 'art' ? (
                  <span>
                    {addLineBreakAfterWord(
                      gridList[0]?.subjectDescription,
                      '~'
                    )}
                  </span>
                ) : (
                  <span>
                    The most inspiring abstract art from Artnstock
                  </span>
                )}

                {/* {gridList[0]?.subjectDescription} */}
              </p>
              <span className='text-[#FFFFFF] text-heading font-thin'>
                745+
              </span>
            </div>
            <img
              className='absolute bottom-2.5 left-2.5'
              src={prodWhiteIcon}
              alt=''
            />
          </div>
        </div>
        {/* Grid 2 */}
        <div
          onClick={() => {
            dispatch(setSubjectId(gridList[1]));
            if (type === 'product') {
              navigate('/product-list');
            } else {
              navigate('/art-list');
            }
          }}
          className='row-span-2 rounded-2xl group overflow-hidden'
          style={{
            backgroundImage: `url(${gridList[1]?.image})`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            height: `280px`,
          }}
        >
          <div
            className='group-hover:flex hidden bg-blackRgba items-center justify-center relative text-center'
            style={{ height: 'inherit' }}
          >
            <div>
              <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px] '>
                {type === 'art' ? (
                  <span> {gridList[1]?.subjectName}</span>
                ) : (
                  <span>Mugs</span>
                )}
              </p>
              <p className='gridDescription'>
                {type === 'art' ? (
                  <span>
                    {addLineBreakAfterWord(
                      gridList[1]?.subjectDescription,
                      '~'
                    )}
                  </span>
                ) : (
                  <span>
                    The most inspiring abstract art from Artnstock
                  </span>
                )}
                {/* {gridList[1]?.subjectDescription} */}
              </p>
              <span className='text-[#FFFFFF] text-heading font-thin'>
                745+
              </span>
            </div>
            <img
              className='absolute bottom-2.5 left-2.5'
              src={prodWhiteIcon}
              alt=''
            />
          </div>
        </div>
        {/* Grid 3 */}
        <div
          onClick={() => {
            dispatch(setSubjectId(gridList[2]));
            if (type === 'product') {
              navigate('/product-list');
            } else {
              navigate('/art-list');
            }
          }}
          className='row-span-2 col-span-2 rounded-2xl group overflow-hidden'
          style={{
            backgroundImage: `url(${gridList[2]?.image})`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            height: `280px`,
          }}
        >
          <div
            className='group-hover:flex hidden bg-blackRgba items-center justify-center relative text-center'
            style={{ height: 'inherit' }}
          >
            <div>
              <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px] '>
                {type === 'art' ? (
                  <span> {gridList[2]?.subjectName}</span>
                ) : (
                  <span>Notepads</span>
                )}
              </p>
              <p className='gridDescription'>
                {type === 'art' ? (
                  <span>
                    {addLineBreakAfterWord(
                      gridList[2]?.subjectDescription,
                      '~'
                    )}
                  </span>
                ) : (
                  <span>
                    The most inspiring abstract art from Artnstock
                  </span>
                )}
                {/* {gridList[2]?.subjectDescription} */}
              </p>
              <span className='text-[#FFFFFF] text-heading font-thin'>
                745+
              </span>
            </div>
            <img
              className='absolute bottom-2.5 left-2.5'
              src={prodWhiteIcon}
              alt=''
            />
          </div>
        </div>
        {/* Grid 4 */}
        <div
          onClick={() => {
            dispatch(setSubjectId(gridList[3]));
            if (type === 'product') {
              navigate('/product-list');
            } else {
              navigate('/art-list');
            }
          }}
          className='row-span-2 col-span-2 rounded-2xl group overflow-hidden'
          style={{
            backgroundImage: `url(${gridList[3]?.image})`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            height: `280px`,
          }}
        >
          <div
            className='group-hover:flex hidden bg-blackRgba items-center justify-center relative text-center'
            style={{ height: 'inherit' }}
          >
            <div>
              <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px] '>
                {type === 'art' ? (
                  <span> {gridList[3]?.subjectName}</span>
                ) : (
                  <span>Shirts</span>
                )}
              </p>
              <p className='gridDescription'>
                {type === 'art' ? (
                  <span>
                    {addLineBreakAfterWord(
                      gridList[3]?.subjectDescription,
                      '~'
                    )}
                  </span>
                ) : (
                  <span>
                    The most inspiring abstract art from Artnstock
                  </span>
                )}
                {/* {gridList[3]?.subjectDescription} */}
              </p>
              <span className='text-[#FFFFFF] text-heading font-thin'>
                745+
              </span>
            </div>
            <img
              className='absolute bottom-2.5 left-2.5'
              src={prodWhiteIcon}
              alt=''
            />
          </div>
        </div>
        {/* Grid 5 */}
        <div
          onClick={() => {
            dispatch(setSubjectId(gridList[4]));
            if (type === 'product') {
              navigate('/product-list');
            } else {
              navigate('/art-list');
            }
          }}
          className='row-span-2 rounded-2xl group overflow-hidden'
          style={{
            backgroundImage: `url(${gridList[4]?.image})`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            height: `280px`,
          }}
        >
          <div
            className='group-hover:flex hidden bg-blackRgba items-center justify-center relative text-center'
            style={{ height: 'inherit' }}
          >
            <div>
              <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px] '>
                {type === 'art' ? (
                  <span> {gridList[4]?.subjectName}</span>
                ) : (
                  <span>Pillows</span>
                )}
              </p>
              <p className='gridDescription'>
                {type === 'art' ? (
                  <span>
                    {addLineBreakAfterWord(
                      gridList[4]?.subjectDescription,
                      '~'
                    )}
                  </span>
                ) : (
                  <span>
                    The most inspiring abstract art from Artnstock
                  </span>
                )}
                {/* {gridList[4]?.subjectDescription} */}
              </p>
              <span className='text-[#FFFFFF] text-heading font-thin'>
                745+
              </span>
            </div>
            <img
              className='absolute bottom-2.5 left-2.5'
              src={prodWhiteIcon}
              alt=''
            />
          </div>
        </div>
        {/* Grid 6 */}
        <div
          onClick={() => {
            dispatch(setSubjectId(gridList[5]));
            if (type === 'product') {
              navigate('/product-list');
            } else {
              navigate('/art-list');
            }
          }}
          className='row-span-2 rounded-2xl group overflow-hidden'
          style={{
            backgroundImage: `url(${gridList[5]?.image})`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            height: `280px`,
          }}
        >
          <div
            className='group-hover:flex hidden bg-blackRgba items-center justify-center relative text-center'
            style={{ height: 'inherit' }}
          >
            <div>
              <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px] '>
                {type === 'art' ? (
                  <span> {gridList[5]?.subjectName}</span>
                ) : (
                  <span>Coaster</span>
                )}
              </p>
              <p className='gridDescription'>
                {type === 'art' ? (
                  <span>
                    {addLineBreakAfterWord(
                      gridList[5]?.subjectDescription,
                      '~'
                    )}
                  </span>
                ) : (
                  <span>
                    The most inspiring abstract art from Artnstock
                  </span>
                )}
                {/* {gridList[5]?.subjectDescription} */}
              </p>
              <span className='text-[#FFFFFF] text-heading font-thin'>
                745+
              </span>
            </div>
            <img
              className='absolute bottom-2.5 left-2.5'
              src={prodWhiteIcon}
              alt=''
            />
          </div>
        </div>
        {/* Grid 7 */}
        <div
          onClick={() => {
            dispatch(setSubjectId(gridList[6]));
            if (type === 'product') {
              navigate('/product-list');
            } else {
              navigate('/art-list');
            }
          }}
          className='row-span-2 col-span-2 rounded-2xl group overflow-hidden'
          style={{
            backgroundImage: `url(${gridList[6]?.image})`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            height: `280px`,
          }}
        >
          <div
            className='group-hover:flex hidden bg-blackRgba items-center justify-center relative text-center'
            style={{ height: 'inherit' }}
          >
            <div>
              <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px] '>
                {type === 'art' ? (
                  <span> {gridList[6]?.subjectName}</span>
                ) : (
                  <span>Mobile Covers</span>
                )}
              </p>
              <p className='gridDescription'>
                {type === 'art' ? (
                  <span>
                    {addLineBreakAfterWord(
                      gridList[6]?.subjectDescription,
                      '~'
                    )}
                  </span>
                ) : (
                  <span>
                    The most inspiring abstract art from Artnstock
                  </span>
                )}
                {/* {gridList[6]?.subjectDescription} */}
              </p>
              <span className='text-[#FFFFFF] text-heading font-thin'>
                745+
              </span>
            </div>
            <img
              className='absolute bottom-2.5 left-2.5'
              src={prodWhiteIcon}
              alt=''
            />
          </div>
        </div>
        {/* Grid 8 */}
        <div
          onClick={() => {
            dispatch(setSubjectId(gridList[7]));
            if (type === 'product') {
              navigate('/product-list');
            } else {
              navigate('/art-list');
            }
          }}
          className='row-span-4 rounded-2xl group overflow-hidden'
          style={{
            backgroundImage: `url(${gridList[7]?.image})`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            height: `576px`,
          }}
        >
          <div
            className='group-hover:flex hidden bg-blackRgba items-center justify-center relative text-center'
            style={{ height: 'inherit' }}
          >
            <div>
              <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px] '>
                {type === 'art' ? (
                  <span> {gridList[7]?.subjectName}</span>
                ) : (
                  <span>Stickers</span>
                )}
              </p>
              <p className='gridDescription'>
                {type === 'art' ? (
                  <span>
                    {addLineBreakAfterWord(
                      gridList[7]?.subjectDescription,
                      '~'
                    )}
                  </span>
                ) : (
                  <span>
                    The most inspiring abstract art from Artnstock
                  </span>
                )}
                {/* {gridList[7]?.subjectDescription} */}
              </p>
              <span className='text-[#FFFFFF] text-heading font-thin'>
                745+
              </span>
            </div>
            <img
              className='absolute bottom-2.5 left-2.5'
              src={prodWhiteIcon}
              alt=''
            />
          </div>
        </div>
        {/* Grid 9 */}
        <div
          onClick={() => {
            dispatch(setSubjectId(gridList[8]));
            if (type === 'product') {
              navigate('/product-list');
            } else {
              navigate('/art-list');
            }
          }}
          className='row-span-2 col-span-2 rounded-2xl group overflow-hidden'
          style={{
            backgroundImage: `url(${gridList[8]?.image})`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            height: `280px`,
          }}
        >
          <div
            className='group-hover:flex hidden bg-blackRgba items-center justify-center relative text-center'
            style={{ height: 'inherit' }}
          >
            <div>
              <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px] '>
                {type === 'art' ? (
                  <span> {gridList[8]?.subjectName}</span>
                ) : (
                  <span>Mousepads</span>
                )}
              </p>
              <p className='gridDescription'>
                {type === 'art' ? (
                  <span>
                    {addLineBreakAfterWord(
                      gridList[8]?.subjectDescription,
                      '~'
                    )}
                  </span>
                ) : (
                  <span>
                    The most inspiring abstract art from Artnstock
                  </span>
                )}
                {/* {gridList[8]?.subjectDescription} */}
              </p>
              <span className='text-[#FFFFFF] text-heading font-thin'>
                745+
              </span>
            </div>
            <img
              className='absolute bottom-2.5 left-2.5'
              src={prodWhiteIcon}
              alt=''
            />
          </div>
        </div>
        {/* Grid 10 */}
        <div
          onClick={() => {
            dispatch(setSubjectId(gridList[9]));
            if (type === 'product') {
              navigate('/product-list');
            } else {
              navigate('/art-list');
            }
          }}
          className='row-span-2 rounded-2xl group overflow-hidden'
          style={{
            backgroundImage: `url(${gridList[9]?.image})`,
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat',
            height: `280px`,
          }}
        >
          <div
            className='group-hover:flex hidden bg-blackRgba items-center justify-center relative text-center'
            style={{ height: 'inherit' }}
          >
            <div>
              <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px]'>
                {type === 'art' ? (
                  <span> {gridList[9]?.subjectName}</span>
                ) : (
                  <span>Badges</span>
                )}
              </p>
              <p className='gridDescription'>
                {type === 'art' ? (
                  <span>
                    {addLineBreakAfterWord(
                      gridList[9]?.subjectDescription,
                      '~'
                    )}
                  </span>
                ) : (
                  <span>
                    The most inspiring abstract art from Artnstock
                  </span>
                )}
                {/* {gridList[9]?.subjectDescription} */}
              </p>
              <span className='text-[#FFFFFF] text-heading font-thin'>
                745+
              </span>
            </div>
            <img
              className='absolute bottom-2.5 left-2.5'
              src={prodWhiteIcon}
              alt=''
            />
          </div>
        </div>
      </div>

      {type === 'product' ? (
        <button
          onClick={() => {
            navigate('/product-list');
            dispatch(setSubjectId(null));
          }}
          className='blackBtn mx-auto block'
        >
          Discover More
        </button>
      ) : (
        <button
          onClick={() => {
            artNextPage('all');

            dispatch(setSubjectId(null));
          }}
          className='blackBtn mx-auto block'
        >
          Discover More
        </button>
      )}
    </div>
  );
};

export default ArtGrid;
