import React from 'react';

import { useNavigate } from 'react-router-dom';
import { ReactComponent as ProIcon } from '../../assets/images/art-details/Icon_Pro.svg';

const Contributor_ChangePassword = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className='flex justify-between'>
        <div>
          <p className='text-[25px] leading-[1] font-medium text-primaryBlack'>
            Change Password
          </p>
          <p className='text-sm11 text-pinkColor'>
            All fields are required.
          </p>
        </div>
        <div>
          <div className='border border-[#eaeaea] rounded-[7px] h-[41px] flex items-center p-[6px]'>
            <div className='flex flex-col gap-[3px]'>
              <ProIcon />
              <p className='text-[11px] leading-[1] text-primaryGray'>
                Member since 5 July 2020
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='relative mt-[24px]'>
        <input
          type='password'
          placeholder='**********'
          className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
          // onChange={handleInputChange}
          // value={userDetail?.displayName || ''}
          // name='displayName'
        />

        <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
          Password
        </p>
      </div>
      <p className='text-[11px] text-[#757575] mt-1'>
        Last Changed Nov 14 2019
      </p>

      <p className='flex justify-center  mb-[509px]'>
        <button
          onClick={() => navigate('/change-password-customer')}
          className='blackBtn'
        >
          Change Password
        </button>
      </p>

      {/* <div>
        <div className='my-[30px]'>
          <p className='text-[12px] text-[#757575] leading-[0] mb-1'>
            Password
          </p>
          <input
            type='password'
            name=''
            className='regInput text-[#333333]'
            placeholder='**********'
            id=''
            disabled
          />
          <p className='text-[11px] text-[#757575] mt-1'>
            Last Changed Nov 14 2019
          </p>
        </div>
        <p className='flex justify-center'>
          <button
            onClick={() => navigate('/change-password-customer')}
            className='blackBtn'
          >
            Change Password
          </button>
        </p>
      </div> */}
    </>
  );
};

export default Contributor_ChangePassword;
