import selectall from '../../../assets/images/contributor/selectall.png';
import deleteicon from '../../../assets/images/contributor/delete-icon.png';
import undo from '../../../assets/images/contributor/undo.png';
import redo from '../../../assets/images/contributor/redo.png';
import save from '../../../assets/images/contributor/save.png';
import keyword from '../../../assets/images/contributor/keyword.png';
// import GrayBox from './GrayBox';
import GrayBoxForSubmit from './GrayBoxForSubmit';
import { useState, useEffect } from 'react';
import { setSelectedImages } from '../../../store/imageSlice';
import { useSelector, useDispatch } from 'react-redux';
import { setNestedTabValueUpload } from '../../../store/nestedTabSlice';
import { setpath2 } from '../../../store/contriPathSlice';
import { httpClient } from '../../../axios';

import { ReactComponent as SelectAll } from '../../../assets/images/contributor/svg/Icon_SelectAll.svg';
import { ReactComponent as Deleteicon } from '../../../assets/images/contributor/svg/Icon_DeleteFiles.svg';
import { ReactComponent as Save } from '../../../assets/images/contributor/svg/Icon_SaveDraft.svg';
import { ReactComponent as Keyword } from '../../../assets/images/contributor/svg/Icon_KeywordManager.svg';

const Submit = () => {
  const selectedImages = useSelector(
    (state) => state.images.selectedImages
  );

  const [images, setImages] = useState([]);
  const [button, setButton] = useState(false);

  const userId = useSelector((state) => state.auth.userId);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setpath2('/ To Submit'));
  }, []);

  useEffect(() => {
    if (selectedImages.length > 0) {
      setButton(true);
    } else {
      setButton(false);
    }
  }, [selectedImages]);

  const [draftId, setdraftId] = useState('');

  const getDraftSubmit = async () => {
    try {
      const res = await httpClient.get(
        // `/draft_master/getContributorWiseDraftMasterList/${userId}
        `/draft_master/getDraftMasterByStatusAndActiveStatus/submit/false/${userId}`
      );
      // console.log(res.data[0].imageMaster);
      setImages(res.data.imageMaster);
      setdraftId(res.data.draftId);
      console.log(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  const addDetails = () => {
    dispatch(setNestedTabValueUpload('2.3'));
  };

  const sendToActivateProducts = async () => {
    let obj = {
      draftId,
      imageMasters: selectedImages,
      activeStatus: false,
      status: 'adddetails',
    };

    console.log(obj);

    try {
      const res = await httpClient.put(
        `/images/updateImageMaster`,
        obj
      );
      dispatch(setNestedTabValueUpload('2.3'));
      console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // setImages(selectedImages);
    dispatch(setSelectedImages([]));
    getDraftSubmit();
  }, []);

  useEffect(() => {
    console.log(selectedImages);
    // alterObjectAcoordToDeleteApi();
  }, [selectedImages]);

  const grayBox = [
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
  ];

  const [grayNum, setgrayNum] = useState(grayBox.length);

  useEffect(() => {
    if (images.length < 16) {
      setgrayNum(grayBox.length - images.length);
    }

    console.log(images.length);
  }, [images]);

  // const [deleteImagesObj, setdeleteImagesObj] = useState({
  //   draftId,
  //   imageMasterIds: [],
  // });

  const alterObjectAcoordToDeleteApi = () => {
    let attributeToKeep = 'imageId';
    let newArray = selectedImages?.map((object) => {
      // Get the value of the attribute to keep
      let valueToKeep = object[attributeToKeep];

      // Create a new object with only the attribute to keep
      let newObj = {
        [attributeToKeep]: valueToKeep,
      };

      return newObj;
    });

    return newArray;
    // console.log(newArray);
  };

  const deleteImages = async () => {
    const imagesId = alterObjectAcoordToDeleteApi();
    const obj = {
      draftId,
      imageMasterIds: imagesId,
    };

    console.log(obj);
    try {
      const res = await httpClient.post('/images/deleteImages', obj);
      getDraftSubmit();
      console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const SelectAllImages = () => {
    dispatch(setSelectedImages(images));
  };

  return (
    <>
      <div className='flex justify-center mb-[16px]'>
        <div
          style={{
            width: 'fit-content',
          }}
          className='flex border-t border-b border-t-[#efefef] border-b-[#efefef]'
        >
          <div className='flex items-center gap-[5px]'>
            <span className='contributorUploadCount text-pinkColor'>
              13
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='contributorUploadtext pr-[8px]'>
                File(s) to be
                <br />
                submitted to
                <br />
                Artnstock
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span className='contributorUploadCount text-[#f5721a] opacity-[20%] pl-[5px]'>
              0
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                File(s)
                <br />
                pending for
                <br />
                review
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span className=' contributorUploadCount text-[#a9af20] opacity-[20%]  pl-[5px]'>
              0
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                File(s)
                <br />
                approved by
                <br />
                Artnstock
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span className='contributorUploadCount text-[#e7233b] opacity-[20%]  pl-[5px]'>
              0
            </span>
            <div className='opacity-[40%] h-[40px] flex items-center'>
              <p className='contributorUploadtext'>
                File(s)
                <br />
                rejected by
                <br />
                Artnstock
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='contributorTitleStyling'>
        <div className='w-[1168px] flex justify-between'>
          <div className='flex flex-col gap-[2px] max-w-[320px] w-[100%]'>
            <div className='flex gap-[8px] items-baseline'>
              <p className='text-[25px] font-medium leading-[1]  text-primaryBlack'>
                To Submit
              </p>
              <p className='text-pinkColor text-[11px] leading-[13px] '>
                {images?.length}/13 File(s) selected
              </p>
            </div>
            <p className='text-[11px] text-primaryGray leading-[14px]'>
              Select up to 10 thumbnails (maximum) to Add Details.{' '}
              <br /> Your draft will be auto saved.
              <span className='text-orangeColor'> Learn more</span>
            </p>
          </div>

          <div className='flex'>
            <div className='contributorUploadIconsMainDiv  border-r border-r-[#efefef]'>
              <div
                onClick={SelectAllImages}
                className='flex flex-col  mt-[1px] gap-[8px] w-[52px] text-center cursor-pointer'
              >
                {/* <img
                  src={selectall}
                  alt=''
                  className='w-[16px] h-[16px] mx-[auto] bg-[#d7d7d7]'
                /> */}
                <div>
                  <SelectAll className='mx-[auto]' />
                </div>
                <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                  Select <br /> All
                </p>
              </div>
            </div>

            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef]'>
              {selectedImages.length > 0 ? (
                <div
                  onClick={deleteImages}
                  className='flex flex-col mt-[1px] cursor-pointer  gap-[8px] w-[52px] text-center '
                >
                  <div>
                    <Deleteicon className='mx-[auto]' />
                  </div>
                  <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                    Delete Image
                  </p>
                </div>
              ) : (
                <div className='flex flex-col  mt-[1px] opacity-[40%]  gap-[8px] w-[52px] text-center '>
                  <div>
                    <Deleteicon className='mx-[auto]' />
                  </div>
                  <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                    Delete Image
                  </p>
                </div>
              )}
            </div>

            {/* <div className='flex-column max-w-[34px] text-center'>
              <img
                src={undo}
                alt=''
                className='w-[16px] h-[16px] mx-[auto] mb-[6px]'
              />
              <p className='text-primaryGray text-[11px]'>
                Undo Changes
              </p>
            </div>
            <div className='flex-column max-w-[34px] text-center'>
              <img
                src={redo}
                alt=''
                className='w-[16px] h-[16px] mx-[auto] mb-[6px]'
              />
              <p className='text-primaryGray text-[11px]'>
                Redo Changes
              </p>
            </div> */}

            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef] border-r-[2px]'>
              <div className='flex flex-col  mt-[1px] opacity-[40%]  gap-[8px] w-[52px] text-center '>
                {/* <img
                  src={save}
                  alt=''
                  className='w-[16px] h-[16px] mx-[auto]'
                /> */}
                <div>
                  <Save className='mx-[auto]' />
                </div>
                <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                  Save <br /> Draft
                </p>
              </div>
            </div>

            {/* <div className=' w-[53px] text-center border-r border-r-[#efefef] '>
              <img
                src={save}
                alt=''
                className='w-[16px] h-[16px] mx-[auto] mb-[6px]'
              />
              <p className='text-primaryGray text-[11px] text-center'>
                Save <br /> Draft
              </p>
            </div> */}

            <div className='contributorUploadIconsMainDiv'>
              <div className='flex flex-col  mt-[1px] gap-[8px] w-[52px] text-center '>
                {/* <img
                  src={keyword}
                  alt=''
                  className='w-[16px] h-[16px] mx-[auto] '
                /> */}{' '}
                <div>
                  <Keyword className='mx-[auto]' />
                </div>
                <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                  Keyword Manager
                </p>
              </div>
            </div>
          </div>

          <div className='pl-[206px] leading-[1] '>
            <p className='text-[11px] text-primaryGray leading-[14px]'>
              Files uploaded this week
            </p>
            <p className='text-[25px] leading-[0.9] mt-[1px] text-pinkColor  font-medium'>
              84/250
              <span className='text-[11px] font-normal ml-[2px]'>
                File(s)
              </span>
            </p>
            <button className='bg-[#888888] text-[white] text-[10px]  font-normal leading-[1] flex items-center rounded-[20px] px-[8px] h-[17px]'>
              Files Upload Summary
            </button>
          </div>
        </div>
      </div>

      {images.length > 0 ? (
        <div>
          <div className='w-[100%] flex justify-center'>
            <div className='w-w1170 justify-center flex-column gap-[10px]'>
              <div className='flex justify-start flex-wrap gap-[16px]'>
                {/* dynamic gray box */}
                {images?.map((card, index) => (
                  <GrayBoxForSubmit key={index} card={card} />
                ))}

                {/* test */}
                {grayBox.slice(0, grayNum).map((card, index) => (
                  <div
                    className={
                      'h-[132px] w-[132px] bg-[#f7f7f7] rounded-[16px]'
                    }
                  ></div>
                ))}
                {/* test */}
              </div>
            </div>
          </div>
          <div className='w-[100%] text-center pt-[30px]'>
            {button == true ? (
              <button
                className='blackBtn mx-auto block'
                // onClick={addDetails}
                onClick={sendToActivateProducts}
              >
                Add Details
              </button>
            ) : (
              <button className='grayBtn mx-auto block'>
                Add Details
              </button>
            )}
            {/* hdcvbhvdc */}
          </div>
        </div>
      ) : (
        <div className='text-center pt-[8px]'>
          <p className='text-pinkColor text-[18px]'>
            Upload your art files
          </p>
          <p className='text-primaryGray text-[12px]'>
            Get started by uploading your art files to submit
          </p>
          <button className='blackBtn mt-[20px]'>Upload Files</button>
        </div>
      )}
    </>
  );
};

export default Submit;
