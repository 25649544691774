import selectall from '../../../assets/images/contributor/selectall.png';
import deleteicon from '../../../assets/images/contributor/delete-icon.png';

import keyword from '../../../assets/images/contributor/keyword.png';
import newSet from '../../../assets/images/contributor/newSet.png';
import renameSet from '../../../assets/images/contributor/renameSet.png';
import movetoSet from '../../../assets/images/contributor/movetoSet.png';
import dropdown from '../../../assets/images/socials/dropdown.png';
import gridBlack from '../../../assets/images/contributor/gridBlack.png';
import gridGray from '../../../assets/images/contributor/gridGray.png';
// import searchIcon from '../../assets/images/Icons/searchDarkIcon.svg';
import { ReactComponent as SearchIcon } from '../../../assets/images/Icons/searchDarkIcon.svg';

import { ReactComponent as Folder } from '../../../assets/images/contributor/fileManager/folderIcon.svg';

import { ReactComponent as FolderImage } from '../../../assets/images/contributor/fileManager/Icon_Folder_48x48.svg';

import { ReactComponent as ShareSvg } from '../../../assets/images/contributor/fileManager/shareIconSmall.svg';

import { ReactComponent as DeleteSvg } from '../../../assets/images/contributor/fileManager/deleteIconSmall.svg';

import { ReactComponent as SimilarIcon } from '../../../assets/images/artList/SimilarIcon.svg';

import { ReactComponent as DefaultPreview } from '../../../assets/images/contributor/fileManager/Icon_DefaultPreview_24x24.svg';
import { ReactComponent as SquarePreview } from '../../../assets/images/contributor/fileManager/Icon_SquarePreview_24x24.svg';

import { ReactComponent as Add } from '../../../assets/images/contributor/svg/Icon_Add.svg';

// update

import allfiles from '../../../assets/images/contributor/allfiles.png';

import editContentSet from '../../../assets/images/contributor/editContentSet.png';
import shareSet from '../../../assets/images/contributor/shareSet.png';
import imagesfolder from '../../../assets/images/contributor/imagesfolder.png';

// file types images

import artFiles from '../../../assets/images/contributor/artFiles.png';
import photosFiles from '../../../assets/images/contributor/photosFiles.png';
import footageFiles from '../../../assets/images/contributor/footageFiles.png';
import musicFiles from '../../../assets/images/contributor/musicFiles.png';
import templatesFiles from '../../../assets/images/contributor/templatesFiles.png';
import productFiles from '../../../assets/images/contributor/productFiles.png';
import folder from '../../../assets/images/contributor/folder.png';

import backArrow from '../../../assets/images/contributor/backArrow.png';
import nextArrow from '../../../assets/images/contributor/nextArrow.png';
import folderOfImage from '../../../assets/images/contributor/folderOfImage.png';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

import { ReactComponent as DropArrow } from '../../../assets/images/Icons/Down arrow.svg';

// hooks

import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import AllFiles from '../contri-components/AllFiles';
import FolderFiles from '../contri-components/FolderFiles';
import { httpClient } from '../../../axios';
import { authSliceAction } from '../../../store/authSlice';

import { ReactComponent as ArtIcon } from '../../../assets/images/contributor/fileManager/IconBG_Art_32x32.svg';

import { ReactComponent as PhotoIcon } from '../../../assets/images/contributor/fileManager/IconBG_Photos_32x32.svg';
import { ReactComponent as FootageIcon } from '../../../assets/images/contributor/fileManager/IconBG_Footage_32x32.svg';
import { ReactComponent as MusicIcon } from '../../../assets/images/contributor/fileManager/IconBG_Music_32x32.svg';
import { ReactComponent as TemplatesIcon } from '../../../assets/images/contributor/fileManager/IconBG_Templates_32x32.svg';
import { ReactComponent as ProductsIcon } from '../../../assets/images/contributor/fileManager/IconBG_Products_32x32.svg';

import { ReactComponent as ArtIconSmall } from '../../../assets/images/Icons/small_Icons/Icon_Art.svg';
import { ReactComponent as PhotosIconSmall } from '../../../assets/images/Icons/small_Icons/Icon_Photos.svg';
import { ReactComponent as FootagesIconSmall } from '../../../assets/images/Icons/small_Icons/Icon_Footages.svg';
import { ReactComponent as MusicIconSmall } from '../../../assets/images/Icons/small_Icons/Icon_Music.svg';
import { ReactComponent as TemplatesIconSmall } from '../../../assets/images/Icons/small_Icons/Icon_Templates.svg';
import { ReactComponent as ProductsIconSmall } from '../../../assets/images/Icons/small_Icons/Icon_Products.svg';

// import { ReactComponent as ProductsIconSmall } from '../../../assets/images/Icons/small_Icons/Icon_Products.svg';

import { ReactComponent as CreateFile } from '../../../assets/images/contributor/fileManager/Icon_CreateNewSet_16x16.svg';
import { ReactComponent as RenameFile } from '../../../assets/images/contributor/fileManager/Icon_RenameSet_16x16.svg';
import { ReactComponent as MoveFile } from '../../../assets/images/contributor/fileManager/Icon_MoveToSet_16x16.svg';
import { ReactComponent as SelectAllFile } from '../../../assets/images/contributor/fileManager/Icon_SelectAll_16x16.svg';
import { ReactComponent as DeleteFile } from '../../../assets/images/contributor/fileManager/Icon_DeleteSelected_16x16.svg';
import { ReactComponent as ShareFile } from '../../../assets/images/contributor/fileManager/Icon_SharePortfolio_16x16.svg';
import { ReactComponent as KeywordFile } from '../../../assets/images/contributor/fileManager/Icon_KeywordManager.svg';

// popuop

const StyledPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 50px;
    width: 490px;
    height: 585px;
  }
`;

const StyledPopupCreateSet = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 30px 50px 30px 50px;
    width: 438px;
    height: 299px;
  }
`;
const StyledPopupRenameSet = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 30px 50px 30px 50px;
    width: 438px;
    // height: 299px;
  }
`;

const StyledPopupDeleteSet = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 30px 50px 30px 50px;
    width: 438px;
    // height: 299px;
  }
`;

const allfilesstaticModified = [
  {
    artId: '1',
    name: 'Futuristic Abstract',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1710757174/artnstock/kqx8dhc9d4yrzzhernoi.jpg',
    },
  },
  {
    artId: '2',
    name: 'Colour Triangles',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1710757150/artnstock/f98ybc3z6y9mwcgjlow9.jpg',
    },
  },
  {
    artId: '3',
    name: 'Summer Evening',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709209271/artnstock/gijngx88eyuhnj4qmp4x.jpg',
    },
  },
  {
    artId: '4',
    name: 'Sky Scrapers',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709209271/artnstock/biduhp7b8tphzxkztb6k.jpg',
    },
  },
  {
    artId: '5',
    name: 'Sunset',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709209278/artnstock/h28giwsqrml9glmghnhd.jpg',
    },
  },
  {
    artId: '6',
    name: 'Colour Stripes',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709209279/artnstock/b1fiwpa3hp5tbnb34r1c.jpg',
    },
  },
  {
    artId: '7',
    name: 'Futuristic Abstract',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709286337/artnstock/gkkcaq6u2794an0qozvr.jpg',
    },
  },
  {
    artId: '8',
    name: 'Colour Triangles',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709286394/artnstock/gkl6nw8h56qqq6fvuws2.jpg',
    },
  },
  {
    artId: '9',
    name: 'Summer Evening',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709623233/artnstock/d5xasbxcm2wu3dqbqz3p.jpg',
    },
  },
  {
    artId: '10',
    name: 'Sky Scrapers',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709647310/artnstock/zz5wrgotncfe8vhir20n.jpg',
    },
  },
  {
    artId: '11',
    name: 'Sunset',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709209278/artnstock/lp6ztcrbordkvuuhrffc.jpg',
    },
  },
  {
    artId: '12',
    name: 'Colour Stripes',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709647310/artnstock/w9lqreb1iisxizonnijl.jpg',
    },
  },
  // Add more objects as needed
];

const FileManager = () => {
  const [categoriesFocus, setCategoriesFocus] = useState('all');
  const [artTabFocus, setArtTabFocus] = useState(false);
  const [photosTabFocus, setPhotosTabFocus] = useState(false);
  const [productTabFocus, setProductTabFocus] = useState(false);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);

  // create states for category
  const [isCategoryOpenCreate, setIsCategoryOpenCreate] =
    useState(false);
  const [categoryCreate, setCategoryCreate] = useState('');

  const [isNameOpen, setIsNameOpen] = useState(false);
  const [category, setCategory] = useState('');
  const [folderName, setFolderName] = useState(null);

  const [categories, setCategories] = useState({
    all: [],
    art: [],
    photo: [],
    footage: [],
    music: [],
    templates: [],
    product: [],
  });

  // folder
  // const [folder, setFolder] = useState({});
  const [create, setCreate] = useState(false);
  const [name, setname] = useState('');
  const [isOpenSortBy, setIsOpenSortBy] = useState(false);

  const userId = useSelector((state) => state.auth.userId);

  // api calls

  const getUserIdWiseArts = async () => {
    try {
      const response = await httpClient.get(
        `/art_master/getUserIdWiseArtMasterList/${userId}`
      );
      setCategories((prevCategories) => ({
        ...prevCategories,
        all: response.data,
      }));
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getFolders = async () => {
    try {
      const response = await httpClient.get(
        `/file_manager_master/getUserIdWiseFileManagerList/${userId}`
      );
      const data = response.data;

      setCategories((prevCategories) => ({
        ...prevCategories,
        art: [],
        photo: [],
        footage: [],
        music: [],
        templates: [],
        product: [],
      }));

      data.forEach((obj) => {
        if (obj.category === 'art') {
          setCategories((prevCategories) => ({
            ...prevCategories,
            art: [...prevCategories.art, obj],
          }));
        } else if (obj.category === 'photos') {
          setCategories((prevCategories) => ({
            ...prevCategories,
            photo: [...prevCategories.photo, obj],
          }));
        } else if (obj.category === 'product') {
          setCategories((prevCategories) => ({
            ...prevCategories,
            product: [...prevCategories.product, obj],
          }));
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    console.log(categories);
  }, [categories]);

  const createFolder = async () => {
    const newFolder = {
      artId: [],
      category: categoriesFocus,
      title: name,
      userId: userId,
    };
    console.log(newFolder);
    try {
      const response = await httpClient.post(
        '/file_manager_master/create',
        newFolder
      );
      console.log(response);
    } catch (error) {
      console.error(error);
    }
    setCreate(false);
    await getUserIdWiseArts();
    await getFolders();
  };

  useEffect(() => {
    onMountAllFiles();
  }, []);

  const onMountAllFiles = () => {
    getUserIdWiseArts();
    getFolders();
  };

  // useEffect(() => {
  //   console.log(categories);
  // }, [categories]);

  const namee = (event) => {
    const n = event.target.value;
    setname(n);
    // createFolder(n);
  };

  // const [editName, setEditName] = useState('');
  // const nameeEdit = (event) => {
  //   const n = event.target.value;
  //   setEditName(n);
  //   // createFolder(n);
  // };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      // Enter key
      createFolder(name);
    }
  };

  const inputRef = useRef(null);

  // redux slice
  const selectedAllFilesImages = useSelector(
    (state) => state.fileimages.selectedAllFilesImages
  );

  // pagination **dont change the sequence of the code below** else will give undefined error
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 18;

  let abc = [];

  if (categoriesFocus === 'all') {
    abc = categories.all || [];
  } else if (categoriesFocus === 'art') {
    abc = categories.art || [];
  }

  const dataToRender = abc;
  const start = (currentPage - 1) * itemsPerPage;
  const end = start + itemsPerPage;
  const currentData = dataToRender.slice(start, end);

  const totalPages = Math.ceil(dataToRender.length / itemsPerPage);

  const handlePrevPage = () => {
    if (currentPage > 1 && currentPage <= totalPages) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage >= 1 && currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  //pagination ends

  const addToFolder = () => {
    const object = {
      artId: selectedAllFilesImages[0].artId,
      fileManagerId: folderName.fileManagerId,
    };
    httpClient
      .post('/file_manager_master/fileManagerIdWiseAddArt', object)
      .then((res) => {
        // console.log(res.data);

        getUserIdWiseArts();
        getFolders();
      });
  };

  const [imagesFolderArray, setImagesFolderArray] = useState(null);
  // mapping folders photos/arts
  const folderImages = (obj) => {
    setEditName(obj.title);
    console.log(obj);
    setImagesFolderArray(obj);
    setCategoriesFocus('folderImages');
  };

  useEffect(() => {
    if (categoriesFocus === 'art' && create) {
      inputRef.current.focus();
    } else if (categoriesFocus === 'photos' && create) {
      inputRef.current.focus();
    } else if (categoriesFocus === 'product' && create) {
      inputRef.current.focus();
    }
  }, [categoriesFocus, create]);

  const deleteFolders = async () => {
    try {
      if (categoriesFocus === 'folderImages') {
        const res = await httpClient.delete(
          `file_manager_master/deleteFileManager/${imagesFolderArray.fileManagerId}`
        );
        getUserIdWiseArts();
        getFolders();
        setCategoriesFocus('all');
        console.log(res.status);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [editFolder, seteditFolder] = useState(false);

  const inputRefEdit = useRef(null);

  const editFolderFunc = async () => {
    seteditFolder(!editFolder);
  };

  const [editName, setEditName] = useState('');
  const nameeEdit = (event) => {
    const n = event.target.value;
    setEditName(n);
    // createFolder(n);
  };

  useEffect(() => {
    console.log(editName);
  }, [editName]);

  const handleKeyDownEdit = async () => {
    // if (event.keyCode === 13 && editName !== '') {
    const object = {
      fileManagerId: imagesFolderArray.fileManagerId,
      title: editName,
    };
    // Enter key

    try {
      const res = await httpClient.put(
        '/file_manager_master/updateIdWiseTitle',
        object
      );
      getUserIdWiseArts();
      getFolders();
      seteditFolder(!editFolder);

      console.log(res.data);
    } catch (error) {
      console.log(error);
    }

    // }
  };

  useEffect(() => {
    if (inputRefEdit.current) {
      inputRefEdit.current.focus();
    }
  }, [editFolder]);

  const [searchObj, setsearchObj] = useState({});

  const searchApi = async () => {
    if (searchObj.text === '') {
      // console.log('iff');
      getUserIdWiseArts();
    } else if (searchObj.text !== '') {
      try {
        const res = await httpClient.post(
          '/file_manager_master/FileManagerFilter',
          searchObj
        );
        // console.log('elseiff');
        // console.log(object);
        // console.log(res.data);
        setCategories((prevCategories) => ({
          ...prevCategories,
          all: res.data,
        }));
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleSearchBoxChange = async (event) => {
    let val = event.target.value;
    console.log(val);

    let object = {
      text: val,
      type: 'All',
      userId: userId,
    };

    setsearchObj(object);
  };

  useEffect(() => {
    console.log(searchObj);
  }, [searchObj]);

  const [isOpenSortByCategory, setIsOpenSortByCategory] =
    useState(false);

  const [isOpenSortByTime, setIsOpenSortByTime] = useState(false);

  return (
    <div>
      <div className='contributorTitleStyling'>
        {/* <div className='w-[100%] flex justify-center pt-[16px]'> */}
        <div className='w-[1408px] flex justify-between'>
          <div className='flex flex-col gap-[2px] max-w-[386px] w-[100%]'>
            <div className='flex gap-[8px] items-baseline'>
              <p className='text-[25px] font-medium leading-[1]  text-primaryBlack'>
                All Files
              </p>
              <p className='text-pinkColor text-[11px] leading-[13px] '>
                0 Set(s), 0 Art
              </p>
            </div>
            <p className='text-[11px] text-primaryGray leading-[14px]'>
              Refer a new customer to earn 20% of their <br /> first
              payment.
              <span className='text-orangeColor'> Learn more</span>
            </p>
          </div>

          <div className='flex'>
            <div className='h-[53px] flex items-center border-r border-r-[#efefef]'>
              <StyledPopupCreateSet
                trigger={
                  <div
                    onClick={() => {
                      setCreate(!create);
                    }}
                    className='flex flex-col  gap-[8px] w-[52px] text-center  cursor-pointer'
                  >
                    <div>
                      <CreateFile className='mx-[auto]' />
                    </div>
                    <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                      Create <br /> New Set
                    </p>
                  </div>
                }
                modal
                closeOnDocumentClick
              >
                {(close) => (
                  <div>
                    <p className='text-[38px] font-medium leading-[1] text-center mb-[10px]'>
                      Create New Set
                    </p>
                    <div className='flex flex-col'>
                      <p className='text-[12px] text-primaryGray mb-[3px]'>
                        Category
                      </p>
                      <div>
                        <button
                          onClick={() => {
                            setIsCategoryOpenCreate(
                              !isCategoryOpenCreate
                            );
                          }}
                          className={`${
                            isCategoryOpenCreate === true
                              ? 'rounded-t-[20px] shadow-dropShadowButton border-b border-[#efefef]'
                              : 'rounded-[20px] border  border-[#d6d6d6]'
                          } cursor-pointer w-[338px] text-[#bbbbbb] h-[40px] outline-none bg-[#FFFFFF] text-primaryGray text-[13px] font-medium flex items-center justify-between px-[15px]`}
                        >
                          {categoryCreate === '' ? (
                            <span className='text-[#bbbbbb]'>
                              Select Category
                            </span>
                          ) : (
                            <span className='text-[#bbbbbb]'>
                              {categoryCreate}
                            </span>
                          )}

                          <img
                            className='inline-block'
                            src={dropdown}
                            alt=''
                          />
                        </button>

                        {isCategoryOpenCreate && (
                          <ul className='shadow-dropShadow rounded-b-2xl z-50 cursor-pointer hover:overflow-hidden dropdown__menu absolute bg-[#ffffff] w-[338px] text-center text-[13px] text-[#bbbbbb]'>
                            <li
                              onClick={() => {
                                setCategoryCreate('Art');
                                setCategoriesFocus('art');
                                setIsCategoryOpenCreate(
                                  !isCategoryOpenCreate
                                );
                              }}
                              className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF] text-[13px] text-[#bbbbbb]'
                            >
                              Art
                            </li>
                            <li
                              onClick={() => {
                                setCategoryCreate('Product');
                                setCategoriesFocus('product');
                                setIsCategoryOpenCreate(
                                  !isCategoryOpenCreate
                                );
                              }}
                              className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF] text-[13px] text-[#bbbbbb]'
                            >
                              Product
                            </li>
                          </ul>
                        )}
                      </div>{' '}
                    </div>

                    <div className='flex flex-col mt-[12px]'>
                      <p className='text-[12px] text-primaryGray mb-[3px]'>
                        Set Name
                      </p>

                      <input
                        onChange={namee}
                        placeholder='Enter Set Name'
                        className='outline-none rounded-[20px] font-medium border  border-[#d6d6d6] h-[40px] text-[#bbbbbb] pl-[15px] text-[13px] text-[#bbbbbb]'
                        type='text'
                        name=''
                        id=''
                      />
                    </div>
                    <div className='flex gap-[10px] mt-[20px] justify-center'>
                      <button
                        onClick={() => {
                          createFolder();
                          close();
                        }}
                        className='blackBtn'
                      >
                        Create
                      </button>
                      <button onClick={close} className='whiteBtn'>
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </StyledPopupCreateSet>
            </div>

            <div className='h-[53px] flex items-center border-r border-r-[#efefef]'>
              <StyledPopupRenameSet
                trigger={
                  <div className='flex flex-col  gap-[8px] w-[52px] text-center cursor-pointer'>
                    <div>
                      <RenameFile className='mx-[auto]' />
                    </div>
                    <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                      Rename <br /> Set
                    </p>
                  </div>
                }
                modal
                closeOnDocumentClick
              >
                {(close) => (
                  <div>
                    <p className='text-[38px] font-medium leading-[1] text-center mb-[10px]'>
                      Rename Set
                    </p>

                    <div className='flex flex-col mt-[12px]'>
                      <input
                        value={editName}
                        onChange={nameeEdit}
                        placeholder='Enter Set Name'
                        className='outline-none rounded-[20px] font-medium border  border-[#d6d6d6] h-[40px] text-[#bbbbbb] pl-[15px] text-[13px] text-[#bbbbbb]'
                        type='text'
                        name=''
                        id=''
                      />
                    </div>
                    <div className='flex gap-[10px] mt-[20px] justify-center'>
                      <button
                        onClick={() => {
                          handleKeyDownEdit();
                          close();
                        }}
                        className='blackBtn'
                      >
                        Rename
                      </button>
                      <button onClick={close} className='whiteBtn'>
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </StyledPopupRenameSet>
            </div>

            <div className='h-[53px] flex items-center border-r border-r-[#efefef]'>
              {selectedAllFilesImages.length > 0 ? (
                <StyledPopup
                  trigger={
                    <div className='herenow  opacity-[40%] flex flex-col  gap-[8px] w-[52px] text-center'>
                      <div>
                        <MoveFile className='mx-[auto]' />
                      </div>
                      <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                        Move to <br /> Set
                      </p>
                    </div>
                  }
                  modal
                >
                  {(close) => (
                    <div className='flex flex-col gap-[21px]'>
                      {selectedAllFilesImages.length > 0 ? (
                        <div
                          style={{
                            backgroundImage: `url(${selectedAllFilesImages[0].image})`,
                          }}
                          className='w-[390px] h-[270px] bg-no-repeat bg-center bg-cover rounded-[16px]'
                        ></div>
                      ) : (
                        <div></div>
                      )}
                      <div>
                        <div className='flex flex-col'>
                          <p className='text-[15px] font-medium mb-[3px]'>
                            Select Category
                          </p>
                          <div>
                            <button
                              onClick={() => {
                                setIsCategoryOpen(!isCategoryOpen);
                              }}
                              className={`${
                                isCategoryOpen === true
                                  ? 'rounded-t-[20px] shadow-dropShadowButton border-b border-[#efefef]'
                                  : 'rounded-[20px] border  border-[#d6d6d6]'
                              } cursor-pointer w-[390px] h-[40px] bg-[#FFFFFF] text-primaryGray text-sm14 font-medium flex items-center justify-between px-[15px]`}
                            >
                              {category === '' ? (
                                <span>Select Category</span>
                              ) : (
                                <span>{category}</span>
                              )}

                              <img
                                className='inline-block'
                                src={dropdown}
                                alt=''
                              />
                            </button>

                            {isCategoryOpen && (
                              <ul className='shadow-dropShadow rounded-b-2xl z-50 cursor-pointer hover:overflow-hidden dropdown__menu absolute bg-[#ffffff] w-[390px] text-center text-[14px] text-primaryGray'>
                                <li
                                  onClick={() => {
                                    setCategory('Art');
                                    setIsCategoryOpen(
                                      !isCategoryOpen
                                    );
                                    setFolderName(null);
                                  }}
                                  className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'
                                >
                                  Art
                                </li>
                                <li
                                  onClick={() => {
                                    setCategory('Photos');
                                    setIsCategoryOpen(
                                      !isCategoryOpen
                                    );
                                    setFolderName(null);
                                  }}
                                  className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'
                                >
                                  Photos
                                </li>
                                <li
                                  onClick={() => {
                                    setCategory('Footage');
                                    setIsCategoryOpen(
                                      !isCategoryOpen
                                    );
                                    setFolderName(null);
                                  }}
                                  className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'
                                >
                                  Footage
                                </li>
                                <li
                                  onClick={() => {
                                    setCategory('Music');
                                    setIsCategoryOpen(
                                      !isCategoryOpen
                                    );
                                    setFolderName(null);
                                  }}
                                  className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'
                                >
                                  Music
                                </li>
                                <li
                                  onClick={() => {
                                    setCategory('Templates');
                                    setIsCategoryOpen(
                                      !isCategoryOpen
                                    );
                                    setFolderName(null);
                                  }}
                                  className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'
                                >
                                  Templates
                                </li>
                                <li
                                  onClick={() => {
                                    setCategory('Product');
                                    setIsCategoryOpen(
                                      !isCategoryOpen
                                    );
                                    setFolderName(null);
                                  }}
                                  className='py-1 px-3.5 hover:bg-[#F0F0F0]'
                                >
                                  Product
                                </li>
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className='flex flex-col'>
                          <p className='text-[15px] font-medium mb-[3px]'>
                            Set Name
                          </p>
                          <div>
                            <button
                              onClick={() => {
                                setIsNameOpen(!isNameOpen);
                              }}
                              // className={`flex items-center justify-between px-[15px] text-primaryGray text-sm14 font-medium cursor-pointer w-[390px] h-[40px] bg-[#FFFFFF] rounded-[20px] border border-[#d6d6d6]`}
                              className={`${
                                isNameOpen === true
                                  ? 'rounded-t-[20px] shadow-dropShadowButton border-b border-[#efefef]'
                                  : 'rounded-[20px] border  border-[#d6d6d6]'
                              } cursor-pointer w-[390px] h-[40px] bg-[#FFFFFF] text-primaryGray text-sm14 font-medium flex items-center justify-between px-[15px]`}
                            >
                              {folderName === null ? (
                                <span>Enter Set Name</span>
                              ) : (
                                <span>{folderName.title}</span>
                              )}
                              {}

                              <img
                                className='inline-block'
                                src={dropdown}
                                alt=''
                              />
                            </button>

                            {category === 'Art' ? (
                              <div>
                                {isNameOpen && (
                                  <ul className='cursor-pointer shadow-dropShadow rounded-b-2xl hover:overflow-hidden dropdown__menu absolute bg-[#ffffff] w-[390px] text-center text-[14px] text-primaryGray'>
                                    {categories.art.map(
                                      (items, index) => (
                                        <li
                                          onClick={() => {
                                            setFolderName(items);
                                            setIsNameOpen(
                                              !isNameOpen
                                            );
                                          }}
                                          className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'
                                        >
                                          {items.title}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                )}
                              </div>
                            ) : category === 'Photos' ? (
                              <div>
                                {isNameOpen && (
                                  <ul className='cursor-pointer shadow-dropShadow rounded-b-2xl hover:overflow-hidden dropdown__menu absolute bg-[#ffffff] w-[390px] text-center text-[14px] text-primaryGray'>
                                    {categories.photo.map(
                                      (items, index) => (
                                        <li
                                          onClick={() => {
                                            setFolderName(items);
                                            setIsNameOpen(
                                              !isNameOpen
                                            );
                                          }}
                                          className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'
                                        >
                                          {items.title}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                )}
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='flex gap-[10px] justify-center pb-[30px]'>
                        <button
                          onClick={addToFolder}
                          className='blackBtn h-[40px] w-[88px]'
                        >
                          Save
                        </button>
                        <button
                          onClick={close}
                          className='h-[40px] px-6 py-2 rounded-3xl text-sm14 text-primaryBlack border-[2px] w-[88px]'
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </StyledPopup>
              ) : (
                <div className='herenow flex flex-col  gap-[8px] w-[52px] text-center opacity-[40%]'>
                  <div>
                    <MoveFile className='mx-[auto]' />
                  </div>
                  <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                    Move to <br /> Set
                  </p>
                </div>
              )}
            </div>

            <div className='h-[53px] flex items-center border-r border-r-[#efefef]'>
              <div className='flex flex-col  gap-[8px] w-[52px] text-center'>
                <div>
                  <SelectAllFile className='mx-[auto]' />
                </div>
                <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                  Select <br /> All
                </p>
              </div>
            </div>

            <div className='h-[53px] flex items-center border-r border-r-[#efefef]'>
              <StyledPopupDeleteSet
                trigger={
                  <div className='flex flex-col  gap-[8px] w-[52px] text-center cursor-pointer'>
                    <div>
                      <DeleteFile className='mx-[auto]' />
                    </div>
                    <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                      Delete <br /> Selected
                    </p>
                  </div>
                }
                modal
                closeOnDocumentClick
              >
                {(close) => (
                  <div>
                    <p className='text-[38px] font-medium leading-[1] text-center mb-[10px]'>
                      Delete Set
                    </p>
                    <p className='text-[12px] text-primaryGray text-center'>
                      Are you sure you want to delete this{' '}
                      <span className='text-primaryBlack'>
                        {editName}{' '}
                      </span>
                      set? <br /> You catalogue will not be affected.
                    </p>

                    <div className='flex gap-[10px] mt-[20px] justify-center'>
                      <button
                        onClick={() => {
                          deleteFolders();
                          close();
                        }}
                        className='blackBtn'
                      >
                        Delete
                      </button>
                      <button onClick={close} className='whiteBtn'>
                        Close
                      </button>
                    </div>
                  </div>
                )}
              </StyledPopupDeleteSet>
            </div>

            <div className='h-[53px] flex items-center border-r border-r-[2px] border-r-[#efefef]'>
              <div className='flex flex-col  gap-[8px] w-[52px] text-center'>
                <div>
                  <ShareFile className='mx-[auto]' />
                </div>
                <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                  Share <br /> Portfolio
                </p>
              </div>
            </div>

            <div className='h-[53px] flex items-center'>
              <div className='flex flex-col  gap-[8px] w-[52px] text-center'>
                <div>
                  <KeywordFile className='mx-[auto]' />
                </div>
                <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                  Keyword <br /> Manager
                </p>
              </div>
            </div>
          </div>

          <div
            style={{
              height: 'fit-content',
            }}
            className='flex items-center gap-[16px] justify-end'
          >
            <div className='flex gap-[8px]'>
              {/* test */}
              <div>
                <button
                  onClick={() => {
                    setIsOpenSortByCategory(!isOpenSortByCategory);
                  }}
                  className={`${
                    isOpenSortByCategory === true
                      ? 'dropdownTwoGlobalTrue'
                      : 'dropdownTwoGlobalFalse'
                  } dropdownTwoGlobalCommon  w-[152px]`}

                  // className='w-[165px] border border-[#d6d6d6] text-[12px] h-[32px] rounded-[15px] flex items-center justify-between p-[10px]'
                >
                  <div className='flex flex-col'>
                    <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                      Sort by
                    </p>
                    <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                      All Files
                    </p>
                  </div>

                  <div>
                    <DropArrow
                      className={`${
                        isOpenSortByCategory
                          ? 'transform rotate-180'
                          : ''
                      }`}
                      style={{
                        fill: '#878787',
                      }}
                    />
                  </div>
                </button>
                {isOpenSortByCategory && (
                  <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray  w-[152px]'>
                    <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                      Digital
                    </li>
                    <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                      Digital
                    </li>
                    <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0]'>
                      Digital
                    </li>
                  </ul>
                )}
              </div>
              {/* test */}

              {/* test */}
              <div>
                <button
                  onClick={() => {
                    setIsOpenSortByTime(!isOpenSortByTime);
                  }}
                  className={`${
                    isOpenSortByTime === true
                      ? 'dropdownTwoGlobalTrue'
                      : 'dropdownTwoGlobalFalse'
                  } dropdownTwoGlobalCommon w-[152px]`}

                  // className='w-[165px] border border-[#d6d6d6] text-[12px] h-[32px] rounded-[15px] flex items-center justify-between p-[10px]'
                >
                  <div className='flex flex-col'>
                    <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                      Sort by
                    </p>
                    <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                      Newest
                    </p>
                  </div>

                  <div>
                    <DropArrow
                      className={`${
                        isOpenSortByTime ? 'transform rotate-180' : ''
                      }`}
                      style={{
                        fill: '#878787',
                      }}
                    />
                  </div>
                </button>
                {isOpenSortByTime && (
                  <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray  w-[152px]'>
                    <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                      Digital
                    </li>
                    <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                      Digital
                    </li>
                    <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0]'>
                      Digital
                    </li>
                  </ul>
                )}
              </div>
              {/* test */}

              {/* <button className='greenBlueButton w-[123px] h-[30px] text-[12px] font-medium px-[5px]'>
                Set Store Marksups
              </button> */}
            </div>

            <div
              style={{
                height: 'fit-content',
              }}
              className='flex gap-[12px] '
            >
              <div>
                <SquarePreview />
              </div>

              <div>
                <DefaultPreview />
              </div>
              {/* <img
                className='w-[23px] h-[23px]'
                src={gridBlack}
                alt=''
              />
              <img
                className='w-[23px] h-[23px]'
                src={gridGray}
                alt=''
              /> */}
            </div>
          </div>
        </div>
      </div>

      <div className='w-full'>
        {/* test */}

        <div className='mt-3.5 flex items-center max-w-[1408px] mx-[auto]'>
          <input
            onChange={(e) => {
              handleSearchBoxChange(e);
            }}
            className='bg-[#EEEEEE] flex-1 outline-0 h-[44px] px-5 w-fit placeholder:text-placeholderColor placeholder:font-medium placeholder:text-[14px] rounded-tl-3xl rounded-bl-3xl border-[#DADADA]'
            type='text'
            placeholder='Search by Title or Tags'
          />

          <button
            onClick={searchApi}
            className=' rounded-tr-3xl h-[44px] flex items-center rounded-br-3xl border-l-[2px] border-l-[#DADADA] bg-[#e6e6e6] pl-[8px] pr-[19px] text-primaryGray text-sm14 font-medium'
          >
            <SearchIcon className='inline-block mr-2 fill-[#888888]' />

            <span className='relative top-[1px]'>Search</span>
          </button>
        </div>

        {/* test */}
      </div>
      <div className='w-full pt-[16px]'>
        <div className='flex gap-[32px] max-w-[1408px] mx-[auto]'>
          <div className='w-[208px]  flex flex-col text-primaryGr ay'>
            <div
              onClick={() => {
                setCategoriesFocus('all');
              }}
              style={{
                backgroundColor: `${
                  categoriesFocus === 'all' ? '#f0f0f0' : '#ffffff'
                }`,
              }}
              className={`flex h-[50px] gap-[8px]  border-b border-t border-[#efefef] pt-[8px] cursor-pointer`}
            >
              <div className=''>
                <SimilarIcon
                  style={{
                    fill: '#888888',
                  }}
                />
              </div>

              <div className='flex w-[100%] justify-between'>
                <div className='flex flex-col '>
                  <p className='text-[15px] text-primaryGray font-medium leading-[18px]'>
                    All Files
                  </p>
                  <p className='text-primaryGray text-sm11 mt-[1px]'>
                    Files uploaded by Contributor
                  </p>
                </div>

                <div>
                  <p className='text-[15px] text-primaryGray font-medium leading-[18px]'>
                    2830
                  </p>
                </div>
              </div>
            </div>

            <div
              className={`flex h-[48px] gap-[8px]  border-b border-t border-[#efefef] pt-[17px] cursor-pointer`}
            >
              <p className='text-sm11 text-[#bbbbbb]'>
                Manage all uploaded Files by Category <br />
                using a Folder structure
              </p>
            </div>
            {/* </div> */}

            {/* test */}
            {/* <div className='topIconBar flex gap-[4px] mb-[15px] mt-[17px]'>
              <div
                onClick={() => {
                  setCategoriesFocus('all');
                }}
                className='flex flex-col gap-[8px]'
              >
                <ArtIcon className='fill-[#888888] hover:fill-[#333333] cursor-pointer' />
                <p className='text-sm11 text-primaryGray  text-center'>
                  2301
                </p>
              </div>
             
              <div className='flex flex-col gap-[8px] opacity-[40%]'>
                <PhotoIcon className='fill-[#888888] ' />
                <p className='text-sm11 text-primaryGray  text-center'>
                  284
                </p>
              </div>

              <div className='flex flex-col gap-[8px] opacity-[40%]'>
                <FootageIcon className='fill-[#888888] ' />
                <p className='text-sm11 text-primaryGray  text-center'>
                  0
                </p>
              </div>

              <div className='flex flex-col gap-[8px] opacity-[40%]'>
                <MusicIcon className='fill-[#888888] ' />
                <p className='text-sm11 text-primaryGray  text-center'>
                  0
                </p>
              </div>

              <div className='flex flex-col gap-[8px] opacity-[40%]'>
                <TemplatesIcon className='fill-[#888888] ' />
                <p className='text-sm11 text-primaryGray  text-center'>
                  0
                </p>
              </div>

              <div className='flex flex-col gap-[8px]'>
                <ProductsIcon className='fill-[#888888] hover:fill-[#333333] cursor-pointer' />
                <p className='text-sm11 text-primaryGray  text-center'>
                  332
                </p>
              </div>
            
            </div> */}
            {/* test */}

            {/* test */}
            <div
              onClick={() => {
                setCategoriesFocus('art');
                setArtTabFocus(!artTabFocus);
              }}
              style={{
                backgroundColor: `${
                  categoriesFocus === 'art' ? '#f0f0f0' : '#ffffff'
                }`,
              }}
              className={`flex h-[49px] gap-[8px]  border-b border-[#efefef] pt-[8px] cursor-pointer`}
            >
              <div className=''>
                <ArtIconSmall />
              </div>

              <div className='flex w-[100%] justify-between'>
                <div className='flex flex-col '>
                  <p className='text-[15px] text-primaryGray font-medium leading-[18px]'>
                    Art{' '}
                  </p>
                  <p className='text-primaryGray text-sm11 pt-[1px]'>
                    {categories.art.length} Sets, 2214 Art
                  </p>
                </div>

                <div>
                  <DropArrow
                    className={`${
                      artTabFocus === true
                        ? 'transform rotate-180'
                        : ''
                    } mt-[5px]`}
                    style={{
                      fill: '#888888',
                    }}
                  />
                </div>
              </div>
            </div>

            {/* test */}

            {artTabFocus === true &&
              categories?.art?.map((obj) => (
                <div>
                  <div
                    onClick={() => {
                      folderImages(obj);
                    }}
                    style={{
                      backgroundColor: `${
                        categoriesFocus === 'folderImages' &&
                        imagesFolderArray.fileManagerId ===
                          obj.fileManagerId
                          ? '#f0f0f0'
                          : '#ffffff'
                      }`,
                    }}
                    className={`flex h-[27px] gap-[8px] bg-[] items-center border-b border-[#efefef] cursor-pointer`}
                  >
                    <div className='my-[auto]'>
                      {/* <Folder className='fill-[#dddddd]'/> */}
                      <Folder className='fill-[#dddddd]' />
                    </div>
                    <div className='flex w-[184px] justify-between'>
                      <div className='flex flex-col justify-center'>
                        <p className='text-sm12 text-primaryGray'>
                          {obj.title}
                        </p>
                      </div>
                      <div>
                        <p className='text-[12px] text-primaryGray'>
                          {obj.artMaster.length}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* {imagesFolderArray?.fileManagerId ===
                    obj.fileManagerId && editFolder === true ? (
                    <div className='flex h-[27px] gap-[8px] bg-[#ffffff] py-[5px] border-b border-[#efefef] cursor-pointer'>
                      <div className='my-[auto]'>
                        <Folder className='fill-[#dddddd]'/>
                      </div>
                      <div className='flex w-[184px] justify-between'>
                        <div className='flex flex-col justify-center'>
                          <input
                            onChange={nameeEdit}
                            onKeyDown={handleKeyDownEdit}
                            ref={inputRefEdit}
                            placeholder='edit above folder name'
                            className='text-[12px] text-primaryGray font-medium leading-[1] outline-none'
                            type='text'
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )} */}
                </div>
                // test

                // test
              ))}

            {/* test */}

            {/* test */}
            {categoriesFocus === 'art' && create === true ? (
              <div className='flex h-[27px] gap-[8px] bg-[#ffffff] py-[5px] border-b border-[#efefef] cursor-pointer'>
                <div className='my-[auto]'>
                  <Folder className='fill-[#dddddd]' />
                </div>
                <div className='flex w-[184px] justify-between'>
                  <div className='flex flex-col justify-center'>
                    <input
                      onChange={namee}
                      onKeyDown={handleKeyDown}
                      ref={inputRef}
                      placeholder='Enter folder name'
                      className='text-[12px] text-primaryGray font-medium leading-[1] outline-none'
                      type='text'
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}

            {/* test */}

            <div
              onClick={() => {
                setCategoriesFocus('photos');
                setPhotosTabFocus(!photosTabFocus);
              }}
              className={`flex h-[49px] gap-[8px] bg-[${
                categoriesFocus === 'photos' ? '#f0f0f0' : '#ffffff'
              }]  border-b border-[#efefef] pt-[8px] cursor-pointer`}
            >
              <div className=''>
                {/* <img src={photosFiles} alt='' /> */}
                <PhotosIconSmall />
              </div>

              <div className='flex w-[100%]  justify-between'>
                <div className='flex flex-col '>
                  <p className='text-[15px] text-primaryGray font-medium leading-[18px]'>
                    Photos{' '}
                  </p>
                  <p className='text-primaryGray text-sm11 pt-[1px]'>
                    {categories.photo.length} Sets, 2214 Photos
                  </p>
                </div>
                <div>
                  <DropArrow
                    className='mt-[5px]'
                    // className={`${
                    //   show === true ? 'transform rotate-180' : ''
                    // }`}
                    style={{
                      fill: '#888888',
                    }}
                  />
                  {/* {photosTabFocus === true ? (
                    <p className='text-[15px] font-medium'>-</p>
                  ) : (
                    <p className='text-[15px] font-medium'>+</p>
                  )} */}
                </div>
              </div>
            </div>
            {/* test */}

            {/* test */}
            {photosTabFocus === true &&
              categories.photo.map((obj) => (
                <div
                  onClick={() => {
                    folderImages(obj);
                  }}
                  className={`flex h-[27px] gap-[8px] bg-[${
                    categoriesFocus === 'folderImages' &&
                    imagesFolderArray.fileManagerId ===
                      obj.fileManagerId
                      ? '#f0f0f0'
                      : '#ffffff'
                  }] py-[5px] border-b border-[#efefef] cursor-pointer`}
                >
                  <div className='my-[auto]'>
                    <Folder className='fill-[#dddddd]' />
                  </div>
                  <div className='flex w-[184px] justify-between'>
                    <div className='flex flex-col justify-center'>
                      <p className='text-[12px] text-primaryGray font-medium leading-[1]'>
                        {obj.title}
                      </p>
                    </div>
                    <div>
                      <p className='text-[12px]'>
                        {obj.artMaster.length}
                      </p>
                    </div>
                  </div>
                </div>
              ))}

            {/* test */}

            {/* test */}
            {categoriesFocus === 'photos' && create === true ? (
              <div className='flex h-[27px] gap-[8px] bg-[#ffffff] py-[5px] border-b border-[#efefef] cursor-pointer'>
                <div className='my-[auto]'>
                  <Folder className='fill-[#dddddd]' />
                </div>
                <div className='flex w-[184px] justify-between'>
                  <div className='flex flex-col justify-center'>
                    <input
                      onChange={namee}
                      onKeyDown={handleKeyDown}
                      ref={inputRef}
                      placeholder='Enter folder name'
                      className='text-[12px] text-primaryGray font-medium leading-[1] outline-none'
                      type='text'
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}

            {/* test */}

            {/* test */}
            <div className='flex  h-[49px] gap-[8px] pt-[8px] bg-[#ffffff] border-b border-[#efefef]'>
              <div className=''>
                {/* <img src={footageFiles} alt='' /> */}
                <FootagesIconSmall />
              </div>

              <div className='flex w-[100%] justify-between'>
                <div className='flex flex-col'>
                  <p className='text-[15px] text-primaryGray font-medium leading-[18px]'>
                    Footage{' '}
                  </p>
                  <p className='text-primaryGray  pt-[1px] text-sm11'>
                    {categories.footage.length} Sets
                  </p>
                </div>
                <div>
                  <DropArrow
                    className='mt-[5px]'
                    // className={`${
                    //   show === true ? 'transform rotate-180' : ''
                    // }`}
                    style={{
                      fill: '#888888',
                    }}
                  />
                  {/* <p className='text-[15px] font-medium'>+</p> */}
                </div>
              </div>
            </div>
            {/* test */}

            <div className='flex  h-[49px] gap-[8px] pt-[8px] bg-[#ffffff] border-b border-[#efefef]'>
              <div className=''>
                {/* <img src={footageFiles} alt='' /> */}
                <MusicIconSmall />
              </div>

              <div className='flex w-[100%] justify-between'>
                <div className='flex flex-col'>
                  <p className='text-[15px] text-primaryGray font-medium leading-[18px]'>
                    Music{' '}
                  </p>
                  <p className='text-primaryGray text-sm11 pt-[1px]'>
                    {categories.music.length} Sets
                  </p>
                </div>
                <div>
                  <DropArrow
                    className='mt-[5px]'
                    // className={`${
                    //   show === true ? 'transform rotate-180' : ''
                    // }`}
                    style={{
                      fill: '#888888',
                    }}
                  />
                  {/* <p className='text-[15px] font-medium'>+</p> */}
                </div>
              </div>
            </div>

            {/* test */}
            {/* <div className='flex h-[38px] gap-[8px] bg-[#ffffff] py-[5px] border-b border-[#efefef]'>
              <div className='my-[auto]'>
                <MusicIconSmall />
                
              </div>

              <div className='flex w-[184px] justify-between'>
                <div className='flex flex-col justify-center'>
                  <p className='text-[15px] text-primaryBlack font-medium leading-[1]'>
                    Music{' '}
                    <span className='text-primaryGray text-[11px] leading-[1]'>
                      ({categories.music.length} Sets)
                    </span>
                  </p>
                </div>
                <div>
                  <p className='text-[15px] font-medium'>+</p>
                </div>
              </div>
            </div> */}
            {/* test */}

            <div className='flex  h-[49px] gap-[8px] pt-[8px] bg-[#ffffff] border-b border-[#efefef]'>
              <div className=''>
                {/* <img src={footageFiles} alt='' /> */}
                <TemplatesIconSmall />
              </div>

              <div className='flex w-[100%] justify-between'>
                <div className='flex flex-col'>
                  <p className='text-[15px] text-primaryGray font-medium leading-[18px]'>
                    Templates{' '}
                  </p>
                  <p className='text-primaryGray text-sm11 pt-[1px]'>
                    {categories.templates.length} Sets
                  </p>
                </div>
                <div>
                  <DropArrow
                    className='mt-[5px]'
                    // className={`${
                    //   show === true ? 'transform rotate-180' : ''
                    // }`}
                    style={{
                      fill: '#888888',
                    }}
                  />
                  {/* <p className='text-[15px] font-medium'>+</p> */}
                </div>
              </div>
            </div>

            {/* test */}
            {/* <div className='flex h-[38px] gap-[8px] bg-[#ffffff] py-[5px] border-b border-[#efefef]'>
              <div className='my-[auto]'>
                <TemplatesIconSmall />
                
              </div>

              <div className='flex w-[184px] justify-between'>
                <div className='flex flex-col justify-center'>
                  <p className='text-[15px] text-primaryBlack font-medium leading-[1]'>
                    Templates{' '}
                    <span className='text-primaryGray text-[11px] leading-[1]'>
                      ({categories.templates.length} Sets)
                    </span>
                  </p>
                </div>
                <div>
                  <p className='text-[15px] font-medium'>+</p>
                </div>
              </div>
            </div> */}
            {/* test */}

            {/* test */}
            <div
              onClick={() => {
                setCategoriesFocus('product');
                setProductTabFocus(!productTabFocus);
              }}
              className={`flex  h-[49px] gap-[8px] bg-[${
                categoriesFocus === 'product' ? '#f0f0f0' : '#ffffff'
              }]  border-b border-[#efefef] pt-[8px] cursor-pointer`}
            >
              <div className=''>
                <ProductsIconSmall />
                {/* <img src={productFiles} alt='' /> */}
              </div>

              <div className='flex w-[100%] justify-between'>
                <div className='flex flex-col '>
                  <p className='text-[15px] text-primaryGray font-medium leading-[18px]'>
                    Product{' '}
                  </p>
                  <p className='text-primaryGray text-sm11 pt-[1px]'>
                    {categories.product.length} Sets
                  </p>
                </div>
                <div>
                  <DropArrow
                    className='mt-[5px]'
                    // className={`${
                    //   show === true ? 'transform rotate-180' : ''
                    // }`}
                    style={{
                      fill: '#888888',
                    }}
                  />
                  {/* {productTabFocus === true ? (
                    <p className='text-[15px] font-medium'>-</p>
                  ) : (
                    <p className='text-[15px] font-medium'>+</p>
                  )} */}
                </div>
              </div>
            </div>
            {/* test */}

            {/* test */}
            {productTabFocus === true &&
              categories.product.map((obj) => (
                <div
                  onClick={() => {
                    folderImages(obj);
                  }}
                  className={`flex h-[27px] gap-[8px] bg-[${
                    categoriesFocus === 'folderImages' &&
                    imagesFolderArray.collectionId ===
                      obj.collectionId
                      ? '#f0f0f0'
                      : '#ffffff'
                  }] py-[5px] border-b border-[#efefef] cursor-pointer`}
                >
                  <div className='my-[auto]'>
                    <Folder className='fill-[#dddddd]' />
                  </div>
                  <div className='flex w-[184px] justify-between'>
                    <div className='flex flex-col justify-center'>
                      <p className='text-[12px] text-primaryGray font-medium leading-[1]'>
                        {obj.title}
                      </p>
                    </div>
                    <div>
                      <p className='text-[12px]'>
                        {obj.artMaster.length}
                      </p>
                    </div>
                  </div>
                </div>
              ))}

            {/* test */}

            {/* test */}
            {categoriesFocus === 'product' && create === true ? (
              <div className='flex h-[27px] gap-[8px] bg-[#ffffff] py-[5px] border-b border-[#efefef] cursor-pointer'>
                <div className='my-[auto]'>
                  <Folder className='fill-[#dddddd]' />
                </div>
                <div className='flex w-[184px] justify-between'>
                  <div className='flex flex-col justify-center'>
                    <input
                      onChange={namee}
                      onKeyDown={handleKeyDown}
                      ref={inputRef}
                      placeholder='Enter folder name'
                      className='text-[12px] text-primaryGray font-medium leading-[1] outline-none'
                      type='text'
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}

            {/* test */}
          </div>

          <div className='max-w-[1168px] w-[100%]'>
            {categoriesFocus === 'all' && (
              <div>
                <div className='flex flex-wrap gap-[16px]'>
                  {categoriesFocus === 'all' &&
                    allfilesstaticModified?.map((image) => (
                      <AllFiles image={image} />
                    ))}
                  {/* {categoriesFocus === 'all' &&
                    currentData.map((image) => (
                      <AllFiles image={image} />
                    ))} */}
                </div>

                {/* test */}
                <div className='flex justify-center'>
                  <div className='flex flex-col justify-center'>
                    <div className='flex text-[13px] text-primaryGray mt-[30px] gap-[6px]'>
                      <p className='my-[auto]'>Page</p>
                      <div className='flex items-center w-[88px] h-[30px]'>
                        <div
                          onClick={handlePrevPage}
                          disabled={currentPage === 1}
                          className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-l-[15px] cursor-pointer'
                        >
                          <DropArrow className='fill-[#888888] rotate-90' />
                        </div>
                        <div className='w-[29.33px] h-[30px] flex justify-center items-center border-t border-b border-[#e8e8e8]'>
                          <span>{currentPage}</span>
                        </div>
                        <div
                          onClick={handleNextPage}
                          disabled={currentPage === totalPages}
                          className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-r-[15px] cursor-pointer'
                        >
                          <div className='transform rotate-180 origin-center'>
                            <div className='transform rotate-90 origin-center'>
                              <DropArrow className='fill-[#888888]' />
                            </div>{' '}
                          </div>
                        </div>
                      </div>
                      <p className='my-[auto]'>
                        of {totalPages} &nbsp;
                      </p>
                    </div>
                    <div className='flex justify-center'>
                      <button className=' mt-[10px] blackBtn w-[88px]'>
                        Next
                      </button>
                    </div>
                  </div>
                </div>
                {/* test */}
              </div>
            )}

            {categoriesFocus === 'art' && (
              <div>
                <div className='flex flex-wrap gap-[16px]'>
                  {/* {categoriesFocus === 'art' &&
                    categories.art.map((image) => (
                      <div
                        onClick={() => {
                          folderImages(image);
                        }}
                        className='flex flex-col w-[181px] text-center'
                      >
                        <div
                          style={{
                            backgroundImage: `url(https://res.cloudinary.com/artnstockimg/image/upload/v1701852445/artnstock/tlqrtajbcc8dj9c0shca.jpg)`,
                              backgroundColor:
                                image.artMaster === []
                                  ? 'transparent'
                                  : '#bbbaba',
                          }}
                          className='w-[181px] h-[181px] relative rounded-[16px] flex justify-center items-center bg-no-repeat bg-center bg-cover'
                        >
                          <div>
                            <FolderImage className='opacity-[90%]' />
                            <img
                              className='z-[999]'
                              src={FolderImage}
                              alt=''
                            />
                          </div>
                          <p className='text-[13.58px] leading-[1] absolute mt-[4px] text-primaryBlack font-medium '>
                            128
                          </p>
                          <div className='absolute top-0 right-0 p-2'>
                            <label className='containerCheckWhite p-2'>
                              <input
                                type='checkbox'
                                 checked={
                                   selectedAllFilesImages.length &&
                                   selectedAllFilesImages[0].artId == image.artId
                                 }
                                checked={isChecked}
                                 onChange={handleCheckboxChange}
                              />{' '}
                              {obj.width}cm x {obj.height}cm
                              <span className='checkmarkWhite'></span>
                            </label>
                          </div>

                          <div className='absolute bottom-0 left-0 p-[10px] flex items-center justify-center gap-[8px]'>
                            <svg
                              width='16'
                              height='16'
                              viewBox='0 0 16 16'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                fill-rule='evenodd'
                                clip-rule='evenodd'
                                d='M0 2C0.238159 0.747559 1.16492 0 2.62964 0H4.64734C6 0 6.57971 0.579712 7 1C7.02295 1.02527 7.10254 1.1051 7.19202 1.19421C7.31958 1.32129 7.46741 1.46704 7.5 1.5L8 2H13C14.6569 2 16 3.34314 16 5V13C16 14.6569 14.6569 16 13 16H3C1.34314 16 0 14.6569 0 13V2ZM9.67371 5.2196C9.96655 4.92676 10.4414 4.92676 10.7344 5.2196L11.7803 6.26562C12.0732 6.55859 12.0732 7.03345 11.7803 7.32629L11.2573 7.84924L9.15076 5.74268L9.67371 5.2196ZM8.79956 6.09363L10.9061 8.20032L7.04407 12.0624L4.9375 9.95581L8.79956 6.09363ZM6.69299 12.4136L4.58643 10.307L4.04126 11.9425C3.94507 12.2312 4.02014 12.5496 4.23535 12.7646C4.45056 12.9799 4.7688 13.0549 5.0575 12.9587L6.69299 12.4136Z'
                                fill='#e8e8e8'
                              />
                            </svg>

                            <ShareSvg />
                            <DeleteSvg />
                          </div>
                          
                        </div>

                        <img src={image.image} alt='' />
                        <p className='text-sm12 text-primaryBlack font-medium pt-[3px]'>
                          {image.title}
                        </p>
                        <p className='text-sm11 text-primaryGray'>
                          Image ID:{image.fileManagerId}
                          248 Art Files
                        </p>
                        <p className='text-sm11 text-primaryGray'>
                          Uploaded: {image.submitted}
                          Created: May 8, 2023
                        </p>
                      </div>
                    ))} */}
                  {categoriesFocus === 'art' ? (
                    <>
                      {allfilesstaticModified?.map((image) => (
                        <div
                          // onClick={() => {
                          //   folderImages(image);
                          // }}
                          className='flex flex-col w-[181px] text-center'
                        >
                          <div
                            // style={{
                            //   backgroundImage: `url(${image?.imageMaster?.secureUrl})`,
                            //   filter: 'brightness(20%)',
                            // }}
                            className='w-[181px] h-[181px] relative rounded-[16px] flex justify-center items-center '
                          >
                            <div
                              className={`h-full w-full bg-no-repeat bg-center bg-cover filter rounded-[16px] brightness-100 absolute overflow-hidden inset-0 brightness-[80%]
                            `}
                              style={{
                                backgroundImage: `url(${image?.imageMaster?.secureUrl})`,
                              }}
                            ></div>
                            <div>
                              <FolderImage className='opacity-[90%]' />
                            </div>
                            <p className='text-[13.58px] leading-[1] absolute mt-[4px] text-primaryGray font-medium '>
                              128
                            </p>
                            <div className='absolute top-0 right-0 p-2'>
                              <label className='containerCheckWhite p-2'>
                                <input
                                  type='checkbox'
                                  //  checked={
                                  //    selectedAllFilesImages.length &&
                                  //    selectedAllFilesImages[0].artId == image.artId
                                  //  }
                                  // checked={isChecked}
                                  //  onChange={handleCheckboxChange}
                                />{' '}
                                {/* {obj.width}cm x {obj.height}cm */}
                                <span className='checkmarkWhite'></span>
                              </label>
                            </div>

                            <div className='absolute bottom-0 left-0 p-[10px] flex items-center justify-center gap-[8px]'>
                              <svg
                                width='16'
                                height='16'
                                viewBox='0 0 16 16'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M0 2C0.238159 0.747559 1.16492 0 2.62964 0H4.64734C6 0 6.57971 0.579712 7 1C7.02295 1.02527 7.10254 1.1051 7.19202 1.19421C7.31958 1.32129 7.46741 1.46704 7.5 1.5L8 2H13C14.6569 2 16 3.34314 16 5V13C16 14.6569 14.6569 16 13 16H3C1.34314 16 0 14.6569 0 13V2ZM9.67371 5.2196C9.96655 4.92676 10.4414 4.92676 10.7344 5.2196L11.7803 6.26562C12.0732 6.55859 12.0732 7.03345 11.7803 7.32629L11.2573 7.84924L9.15076 5.74268L9.67371 5.2196ZM8.79956 6.09363L10.9061 8.20032L7.04407 12.0624L4.9375 9.95581L8.79956 6.09363ZM6.69299 12.4136L4.58643 10.307L4.04126 11.9425C3.94507 12.2312 4.02014 12.5496 4.23535 12.7646C4.45056 12.9799 4.7688 13.0549 5.0575 12.9587L6.69299 12.4136Z'
                                  fill='#e8e8e8'
                                />
                              </svg>

                              <ShareSvg />
                              <DeleteSvg />
                            </div>
                          </div>

                          <img src={image.image} alt='' />
                          <p className='text-sm12 text-primaryBlack font-medium pt-[7px]'>
                            {image.name}
                          </p>
                          <p className='text-sm11 text-primaryGray mt-[2px]'>
                            {/* Image ID:{image.fileManagerId} */}
                            248 Art Files
                          </p>
                          <p className='text-sm11 text-primaryGray'>
                            {/* Uploaded: {image.submitted} */}
                            Created: May 8, 2023
                          </p>
                        </div>
                      ))}

                      <div className='w-[181px] h-[181px] bg-[#f7f7f7] rounded-[16px] flex justify-center items-center cursor-pointer'>
                        <div className='flex flex-col'>
                          <Add className='fill-[#dddddd] mx-[auto]' />
                          <p className='text-sm12 text-primaryGray mt-[8px]'>
                            Create New Set
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                {/* test */}
                <div className='flex justify-center'>
                  <div className='flex flex-col justify-center'>
                    <div className='flex text-[13px] text-primaryGray mt-[30px] gap-[6px]'>
                      <p className='my-[auto]'>Page</p>
                      <div className='flex items-center w-[88px] h-[30px]'>
                        <div
                          onClick={handlePrevPage}
                          disabled={currentPage === 1}
                          className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-l-[15px] cursor-pointer'
                        >
                          {/* <img src={backArrow} alt='' /> */}
                          <DropArrow className='fill-[#888888] rotate-90' />
                        </div>
                        <div className='w-[29.33px] h-[30px] flex justify-center items-center border-t border-b border-[#e8e8e8]'>
                          <span>{currentPage}</span>
                        </div>
                        <div
                          onClick={handleNextPage}
                          disabled={currentPage === totalPages}
                          className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-r-[15px] cursor-pointer'
                        >
                          <div className='transform rotate-180 origin-center'>
                            <div className='transform rotate-90 origin-center'>
                              <DropArrow className='fill-[#888888]' />
                            </div>{' '}
                          </div>
                        </div>
                      </div>
                      <p className='my-[auto]'>
                        of {totalPages} &nbsp;
                      </p>
                    </div>
                    <div className='flex justify-center'>
                      <button className='w-[88px] h-[40px] mt-[10px] bg-[#d6d6d6] rounded-[20px] text-[14px] font-medium text-[white] '>
                        Save
                      </button>
                      {/* <button className=' mt-[10px] blackBtn w-[88px]'>
                        Next
                      </button> */}
                    </div>
                  </div>
                </div>
                {/* test */}
              </div>
            )}
            <div className='flex flex-wrap gap-[16px]'>
              {categoriesFocus === 'photos' &&
                categories.photo.map((image) => (
                  <div
                    onClick={() => {
                      folderImages(image);
                    }}
                    className='flex flex-col w-[181px] text-center'
                  >
                    <div
                      style={{
                        backgroundImage: `url(${image.artMaster[0]?.image})`,
                        backgroundColor:
                          image.artMaster === []
                            ? 'transparent'
                            : '#bbbaba',
                      }}
                      className='w-[181px] h-[181px] rounded-[16px] flex justify-center items-center bg-no-repeat bg-center bg-coverF'
                    >
                      <div
                        className='bg-no-repeat bg-center bg-cover w-[49px] h-[43px] flex justify-center items-center'
                        style={{
                          backgroundImage: `url(${folderOfImage})`,
                        }}
                      >
                        <div className='flex flex-col pt-[6px] text-[#fff]'>
                          <p className='text-[18px] font-medium leading-[0.8]'>
                            {image.artMaster.length}
                          </p>
                          <p className='text-[11px] leading-[0.8]'>
                            images
                          </p>
                        </div>
                      </div>
                    </div>

                    <img src={image.image} alt='' />
                    <p className='text-[11px] text-primaryBlack font-medium pt-[3px]'>
                      {image.title}
                    </p>
                    <p className='text-[11px] text-primaryGray'>
                      Image ID:{image.fileManagerId}
                    </p>
                    <p className='text-[11px] text-primaryGray'>
                      Uploaded: {image.uploaded}
                    </p>
                  </div>
                ))}
            </div>

            {categoriesFocus === 'photos' &&
            categories.photo.length > 0 ? (
              <div className='w-full flex justify-center pt-[10px]'>
                <button className='w-[88px] h-[40px] bg-[#d6d6d6] rounded-[20px] text-[14px] font-medium text-[white] '>
                  Save
                </button>
              </div>
            ) : (
              <div></div>
            )}

            <div className='flex flex-wrap gap-[16px]'>
              {categoriesFocus === 'folderImages' &&
                allfilesstaticModified?.map((image) => (
                  <FolderFiles image={image} />
                  //         <div className='flex flex-col text-center'>
                  //           <div

                  //             className='w-[181px] h-[181px] rounded-[16px] inset-0 relative'
                  //           >
                  //             <div
                  //               className={`h-[100%] w-[100%] bg-no-repeat bg-center bg-cover filter brightness-100 absolute rounded-[16px] overflow-hidden`}
                  //               style={{
                  //                 backgroundImage: `url("${image?.imageMaster?.secureUrl}")`,
                  //               }}
                  //             ></div>

                  //           </div>
                  //           <p className='text-[11px] text-primaryBlack font-medium pt-[3px]'>
                  //             {image.name}
                  //           </p>
                  //           <p className='text-[11px] text-primaryGray'>
                  //           Art ID:
                  // ANS9876543210
                  //           </p>
                  //           <p className='text-[11px] text-primaryGray'>
                  //             Uploaded: Jan 4, 2022
                  //           </p>
                  //         </div>
                ))}
              {/* {categoriesFocus === 'folderImages' &&
              imagesFolderArray.artMaster.length > 0 ? (
                imagesFolderArray.artMaster.map((image) => (
                  <div className='flex flex-col text-center'>
                    <div
                     
                      className='w-[181px] h-[181px] rounded-[16px] inset-0 relative'
                    >
                      <div
                        className={`h-[100%] w-[100%] bg-no-repeat bg-center bg-cover filter brightness-100 absolute rounded-[16px] overflow-hidden`}
                        style={{
                          backgroundImage: `url("${image.image}")`,
                        }}
                      ></div>

                    
                    </div>
                    <p className='text-[11px] text-primaryBlack font-medium pt-[3px]'>
                      {image.name}
                    </p>
                    <p className='text-[11px] text-primaryGray'>
                      Image ID:{image.id}
                    </p>
                    <p className='text-[11px] text-primaryGray'>
                      Uploaded {image.uploaded}
                    </p>
                  </div>
                ))
              ) : categoriesFocus === 'folderImages' &&
                imagesFolderArray.artMaster.length === 0 ? (
                <div className='text-center pt-[8px] pb-[1000px] mx-[auto]'>
                  <p className='text-pinkColor text-[18px]'>
                    Looks like you havn’t added any items yet
                  </p>
                  <p className='text-primaryGray text-[12px]'>
                    start by adding items you have recently viewed
                  </p>
                </div>
              ) : (
                <div></div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default FileManager;
