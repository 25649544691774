import { createSlice } from '@reduxjs/toolkit';

const loaderSlice = createSlice({
  name: 'LoaderSlice',
  initialState: {
    loader: false,
  },
  reducers: {
    setLoaderTrue(state, action) {
      state.loader = true;
    },
    setLoaderFalse(state, action) {
      state.loader = false;
    },
  },
});

export const loaderSliceAction = loaderSlice.actions;

export default loaderSlice;
