import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as Grid1 } from '../../assets/images/Icons/grid1.svg';
import { ReactComponent as Grid } from '../../assets/images/Icons/grid.svg';
import filterIcon from '../../assets/images/Icons/FilterIcon.svg';
import { httpClient } from '../../axios';
import closeIcon from '../../assets/images/Icons/crossIcon.svg';

import { ReactComponent as ArtIcon } from '../../assets/images/artList/Icons_LeftFilter_24x24/Icon_Art.svg';
import { ReactComponent as PhotoIcon } from '../../assets/images/artList/Icons_LeftFilter_24x24/Icon_Photos.svg';
import { ReactComponent as FootageIcon } from '../../assets/images/artList/Icons_LeftFilter_24x24/Icon_Footages.svg';
import { ReactComponent as MusicIcon } from '../../assets/images/artList/Icons_LeftFilter_24x24/Icon_Music.svg';
import { ReactComponent as TemplatesIcon } from '../../assets/images/artList/Icons_LeftFilter_24x24/Icon_Templates.svg';
import { ReactComponent as ProductsIcon } from '../../assets/images/artList/Icons_LeftFilter_24x24/Icon_Products.svg';
// import { ReactComponent as ArtIcon } from '../../assets/images/Icons/artIcon.svg';
// import { ReactComponent as PhotoIcon } from '../../assets/images/Icons/photosIcon.svg';
// import { ReactComponent as FootageIcon } from '../../assets/images/Icons/videoIcon.svg';
// import { ReactComponent as MusicIcon } from '../../assets/images/Icons/music.svg';
// import { ReactComponent as TemplatesIcon } from '../../assets/images/Icons/templatesIcon.svg';
// import { ReactComponent as ProductsIcon } from '../../assets/images/Icons/productsIcon.svg';

import { ReactComponent as ScaleArtlist } from '../../assets/images/artList/scaleArtlist.svg';
import SliderButton from '../../assets/images/artList/sliderButton1.svg';

import { ReactComponent as DropArrow } from '../../assets/images/Icons/Down arrow.svg';
import { useDetectClickOutside } from 'react-detect-click-outside';
import Footer from '../../components/footer/Footer';
import smallRightArrow from '../../assets/images/Icons/smallRightArrow.svg';
import smallLeftArrow from '../../assets/images/Icons/smallLeftArrow.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { filteredMasterModel } from '../../models/allModel';
import { useLocation } from 'react-router-dom';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
// images for art Hover
import scale from '../../assets/images/contributor/scale.png';
// import { ReactComponent as Wishlist } from '../../assets/images/artList/wishlistsvg.svg';
import Wishlist from '../../utils/wishlist';
import { setSubjectId } from '../../store/subjectidSlice';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

import { ReactComponent as SmallCross } from '../../assets/images/Icons/smallCross.svg';

import save from '../../assets/images/artList/save.png';

import similar from '../../assets/images/artList/similar.png';
import profile from '../../assets/images/artList/profile.png';
import shopCart from '../../assets/images/artList/shopCart.png';
import enlarge from '../../assets/images/artList/enlarge.png';
import share from '../../assets/images/artList/Share.png';

import { ReactComponent as PlusIcon } from '../../assets/images/artList/PlusIcon.svg';
import { ReactComponent as SimilarIcon } from '../../assets/images/artList/SimilarIcon.svg';
import { ReactComponent as ProfileIcon } from '../../assets/images/artList/ProfileIcon.svg';
import { ReactComponent as CartIcon } from '../../assets/images/artList/CartIcon.svg';
import { ReactComponent as ShareIcon } from '../../assets/images/artList/ShareIcon.svg';
import { ReactComponent as EnlargeIcon } from '../../assets/images/artList/EnlargeIcon.svg';

import { ReactComponent as ColorIconSmall } from '../../assets/images/Icons/ColorIconSmall.svg';
import { ReactComponent as BWIcons } from '../../assets/images/Icons/B&WIcon.svg';

import { ReactComponent as IconColor } from '../../assets/images/art-details/Icon_Colour.svg';
import { ReactComponent as IconBW } from '../../assets/images/art-details/Icon_BlackWhite.svg';

import COLOR_DATA from './ColorPicker';
import Slider from '@mui/material/Slider';
import { color } from '@mui/system';

const StyledPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
    ...;
  } */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 0;
    width: 88.063rem;
    height: 56.188rem;
    overflow: hidden;
    border: none;
  }
`;

const StyledPopupp = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 50px;
    width: 490px;
    height: 585px;
  }
`;

const popularList = [
  {
    id: 1,
    name: 'Featured',
  },
  {
    id: 2,
    name: 'Newest',
  },
  {
    id: 3,
    name: 'Price:Low to High',
  },
  {
    id: 4,
    name: 'Price:High to Low',
  },
];

const ArtList = () => {
  const navigate = useNavigate();
  // Dropdowns
  const [show, setShow] = useState(false);
  const [showStyles, setShowStyles] = useState(false);
  const [showSub, setShowSub] = useState(false);
  const [showMedium, setShowMedium] = useState(false);
  const [selectValue, setSelectValue] = useState();
  const [selectStylesValue, setSelectStylesValue] = useState();
  const [selectSubValue, setSelectSubValue] = useState('');
  const [selectMediumValue, setSelectMediumValue] = useState();
  const ref1 = useDetectClickOutside({ onTriggered: apps1 });
  const ref2 = useDetectClickOutside({ onTriggered: apps2 });
  const ref4 = useDetectClickOutside({ onTriggered: apps4 });
  const ref3 = useDetectClickOutside({ onTriggered: apps3 });

  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useDispatch();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  function apps1(e) {
    setShow(false);
  }

  function apps2(e) {
    setShowStyles(false);
  }

  function apps3(e) {
    setShowSub(false);
  }

  function apps4(e) {
    setShowMedium(false);
  }

  function changeValue1(e) {
    // console.log(e);
    setShow(false);
    setSelectValue(e);
  }

  function changeValue2(e) {
    // console.log(e);
    setShowStyles(false);
    setSelectStylesValue(e);
    setFilterObj({
      ...filterObj,
      styleName: e === 'All Styles' ? '' : e,
    });
  }

  function changeValue3(e) {
    // console.log(e);
    dispatch(setSubjectId(e));
    setShowSub(false);
    setSelectSubValue(e.subjectName);
    setFilterObj({
      ...filterObj,
      subjectName: e === 'All Subjects' ? '' : e.subjectName,
    });
  }

  function changeValue4(e) {
    // console.log(e);
    setShowMedium(false);
    setSelectMediumValue(e);
  }

  const [artsList, setArtsList] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const [sizeBtn, setSizeBtn] = useState();
  const [orientationBtn, setOrientationBtn] = useState();
  const [title, setTitle] = useState('');

  const subjectId = useSelector((state) => state.subjectId.subjectId);

  const userId = useSelector((state) => state.auth.userId);

  const [subDis, setSubDis] = useState(false);

  // console.log(subjectId);
  // api calls
  useEffect(() => {
    // handleChangeSlidermouse();
    getAllWishlistByUserId();
  }, []);

  const getAllArtList = async () => {
    try {
      // handleChangeSlidermouse();
      // console.log(filterObj);
      if (subjectId === null) {
        setSubDis(false);
        let o = Object.fromEntries(
          Object.entries(filterObj).filter(
            ([_, v]) => v !== '' && v !== 0
          )
        );

        const res = await httpClient.post(
          `/art_master/ArtFilter/${'All'}/${'All'}`,
          o
        );
        setTitle('All Art');
        setArtsList(res.data);
        console.log(res.data);
      } else {
        setSubDis(true);
        let o = Object.fromEntries(
          Object.entries(filterObj).filter(
            ([_, v]) => v !== '' && v !== 0
          )
        );

        const res = await httpClient.post(
          `/art_master/ArtFilter/${'subject'}/${
            subjectId.subjectName
          }`,
          o
        );
        // const res = await httpClient.get(
        //   `/art_master/getSubjectIdWiseSubjectMaster/${subjectId.subjectId}`
        // );
        setTitle(subjectId.subjectName);
        console.log(res.data);
        setArtsList(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllArtList();
  }, [subjectId]);

  const [styleList, setStyleList] = useState(null);

  const getActiveStyleList = async () => {
    try {
      const res = await httpClient.get(
        '/style_master/getActiveStyleMaster'
      );
      setStyleList(res.data);
      // console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const [subjectList, setSubjectList] = useState(null);

  const getActiveSubjectList = async () => {
    try {
      const res = await httpClient.get(
        '/subject_master/getActiveSubject'
      );
      setSubjectList(res.data);
      // console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getActiveStyleList();
    getActiveSubjectList();
  }, []);

  const changeSizeFilter = (text) => {
    setSizeBtn(text);
  };

  const changeOrientationFilter = (text) => {
    setOrientationBtn(text);
  };

  const goToArtDetailsPage = (id) => {
    navigate(`/art/art-details`, { state: { id } });
  };

  const [popupArray, setPopupArray] = useState([]);

  const popupOfHover = (id) => {
    // console.log(typeof popupArray);

    const find = popupArray.find((obj) => obj.id === id.id);

    if (find === undefined) {
      setPopupArray((prev) => [...prev, id]);
    } else if (find !== undefined) {
      setPopupArray(popupArray.filter((obj) => obj.id !== id.id));
    }
  };

  const [wishlist, setwishlist] = useState();

  const getAllWishlistByUserId = async () => {
    try {
      const res = await httpClient.get(
        `/wishlist_master/getByUserIdList/${userId}`
      );
      setwishlist(res.data);
      // console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  };
  // above code is for valdating if artId already exists in wishlist

  const addToWishlist = (artId) => {
    let findId;
    wishlist.forEach((obj) => {
      if (obj.artMaster !== null) {
        if (obj.artMaster.artId === artId) {
          findId = undefined;
        }
      }
    });

    if (!findId) {
      const object = {
        artId: artId,
        id: userId,
      };
      httpClient.post(`/wishlist_master/save`, object).then((res) => {
        // console.log(res.data);
        getAllWishlistByUserId();
      });
    }
  };

  const wishlistDelete = async (id) => {
    wishlist?.forEach(async (obj) => {
      if (obj.artMaster.artId === id) {
        try {
          const res = await httpClient.delete(
            `/wishlist_master/delete/${obj.wishListId}`
          );
          getAllWishlistByUserId();
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  // Image Optimization

  const [imageGrid, setImageGrid] = useState('grid');

  const imageLinkChange = (url) => {
    const str = url;
    const newStr = !showSidebar
      ? 'upload/c_fit,w_299/'
      : 'upload/c_fit,w_308/';
    const updatedStr = str.replace('upload/', newStr);

    return updatedStr;
  };

  const imageLinkChangeSquaregrid = (url) => {
    const str = url;
    const newStr = !showSidebar
      ? 'upload/c_scale,w_299,h_299/'
      : 'upload/c_scale,w_308,h_299/';
    const updatedStr = str.replace('upload/', newStr);

    return updatedStr;
  };

  // Pagination
  const [firstPage, setFirstPage] = useState(0);
  const [lastPage, setLastPage] = useState(18);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const nextPage = () => {
    if (currentPage < totalPage) {
      setCurrentPage(currentPage + 1);
      setFirstPage(firstPage + 18);
      setLastPage(lastPage + 18);
    }
  };
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setFirstPage(firstPage - 18);
      setLastPage(lastPage - 18);
    }
  };

  useEffect(() => {
    setTotalPage(Math.ceil(artsList.length / 18));
  }, [artsList]);

  // Filter

  const defaultFilterObj = {
    orientation: '',
    minPrice: 45,
    maxPrice: 2000,
    size: '',
    styleName: '',
    subjectName: '',
    userFirstName: '',
    colorCode: '',
  };
  const [filterObj, setFilterObj] = useState(defaultFilterObj);

  useEffect(() => {
    // console.log(filterObj);
    getAllArtList();
  }, [filterObj]);

  // collection popup

  const userAuth = useSelector((state) => state.auth);

  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [category, setCategory] = useState('');
  const [folderName, setFolderName] = useState(null);
  const [isNameOpen, setIsNameOpen] = useState(false);

  const [categories, setCategories] = useState({
    all: [],
    art: [],
    // photo: [],
    // footage: [],
    // music: [],
    // templates: [],
    // product: [],
  });

  const getFolders = async () => {
    try {
      const response = await httpClient.get(
        `/collection_master/getUserIdWiseCollectionMasterList/${userId}`
      );

      const data = response.data;

      console.log(data);

      setCategories((prevCategories) => ({
        ...prevCategories,
        all: [],
        art: [],
        // photo: [],
        // footage: [],
        // music: [],
        // templates: [],
        // product: [],
      }));

      data.forEach((obj) => {
        if (obj.category === 'art') {
          setCategories((prevCategories) => ({
            ...prevCategories,
            art: [...prevCategories.art, obj],
          }));
        }

        // else if (obj.category === 'photos') {
        //   setCategories((prevCategories) => ({
        //     ...prevCategories,
        //     photo: [...prevCategories.photo, obj],
        //   }));
        // } else if (obj.category === 'product') {
        //   setCategories((prevCategories) => ({
        //     ...prevCategories,
        //     photo: [...prevCategories.product, obj],
        //   }));
        // }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const addToFolder = (artId) => {
    const object = {
      artId: artId,
      collectionId: folderName.collectionId,
    };
    httpClient
      .post('/collection_master/CollectionIdWiseAddArt', object)
      .then((res) => {
        console.log(res.data);

        // getUserIdWiseArts();
        getFolders();
      });
  };

  useEffect(() => {
    getFolders();
  }, []);

  const [openBig, setOpenBig] = useState(false);

  const [isScrolling, setIsScrolling] = useState(false);
  const scrollableContentRef = useRef(null);

  const handleScroll = () => {
    setIsScrolling(true);
    showOrHideScrollbar();
  };

  const showOrHideScrollbar = () => {
    if (isScrolling) {
      scrollableContentRef.current.style.overflowY = 'scroll'; // Show scrollbar
      clearTimeout(timeoutId); // Clear previous timeout
      const timeoutId = setTimeout(() => {
        setIsScrolling(false);
        scrollableContentRef.current.style.overflowY = 'hidden'; // Hide scrollbar after a delay
      }, 1500); // Adjust the delay (in milliseconds) as needed
    }
  };

  const setColors = (color) => {
    setFilterObj({ ...filterObj, colorCode: color });
  };

  useEffect(() => {
    console.log(filterObj);
  }, [filterObj]);

  function valuetext(value) {
    return `${value}°C`;
  }

  const [value, setValue] = React.useState([45, 2000]);

  const handleChangeSlider = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeSlidermouse = (event, newValue) => {
    // console.log(value);
    setFilterObj({
      ...filterObj,
      minPrice: value[0],
      maxPrice: value[1],
    });
  };

  const [contriname, setcontriname] = useState('');

  // useEffect(() => {
  //   console.log(contriname);
  // }, [contriname]);

  const SetContributorName = () => {
    setFilterObj({ ...filterObj, userFirstName: contriname });
  };

  const [CustomOrientHover, setCustomOrientHover] = useState(false);
  const [HoriOrientHover, setHoriOrientHover] = useState(false);
  const [VertiOrientHover, setVertiOrientHover] = useState(false);
  const [SquareOrientHover, setSquareOrientHover] = useState(false);

  return (
    <>
      <div className='main py-[20px]'>
        <div className='px-[24px]'>
          <p className='mb-[3px] bullet text-left'>
            Artnstock / Art / {title}
          </p>
          <div className='flex justify-between mb-[9px]'>
            <div className='flex items-end'>
              <p className='text-heading text-primaryBlack leading-[33px]'>
                {title}
              </p>
              <p className='text-primaryGray text-sm12 ml-[8px]'>
                {artsList.length} Items
              </p>
            </div>
            <div className='flex items-center gap-[8px]'>
              <Grid1
                onClick={() => setImageGrid('grid')}
                fill={imageGrid === 'grid' ? '#333333' : '#757575'}
                className='w-[23px] h-[23px] cursor-pointer'
              />
              <Grid
                onClick={() => setImageGrid('sqaure')}
                fill={imageGrid === 'sqaure' ? '#333333' : '#757575'}
                className=' w-[23px] h-[23px] cursor-pointer'
              />
            </div>
          </div>
        </div>
        <div
          className={`${
            showSidebar ? 'pr-[24px]' : 'px-[24px]'
          } flex gap-x-4`}
        >
          {/* Sidebar */}
          {showSidebar && (
            // h-[64.375rem]
            <div className='sidebar shadow-regCardShadow relative rounded-tr-2xl rounded-br-2xl py-[52px] pl-6 pr-4'>
              <SmallCross
                onClick={() => setShowSidebar(false)}
                className='absolute top-[16px] right-[16px] cursor-pointer'
              />
              {/* <img
                onClick={() => setShowSidebar(false)}
                className='absolute top-4 right-4 cursor-pointer'
                src={closeIcon}
                alt=''
              /> */}
              <div className='topIconBar flex gap-[15px] mb-[24px]'>
                <ArtIcon className='fill-[#888888] hover:fill-[#333333] cursor-pointer' />
                <PhotoIcon className='fill-[#888888] hover:fill-[#333333] cursor-pointer' />
                <FootageIcon className='fill-[#888888] hover:fill-[#333333] cursor-pointer' />
                <MusicIcon className='fill-[#888888] hover:fill-[#333333] cursor-pointer' />
                <TemplatesIcon className='fill-[#888888] hover:fill-[#333333] cursor-pointer' />
                <ProductsIcon className='fill-[#888888] hover:fill-[#333333] cursor-pointer' />
                {/* <img src={ArtIcon} alt='' />
                <img src={PhotoIcon} alt='' />
                <img src={FootageIcon} alt='' />
                <img src={MusicIcon} alt='' />
                <img src={TemplatesIcon} alt='' />
                <img src={ProductsIcon} alt='' /> */}
              </div>

              <form action='' onSubmit={(e) => e.preventDefault()}>
                {/* Dropdown Filters */}
                <p
                  className='text-sm12 text-primaryGray font-medium leading-[15px] mb-[4px]'
                  htmlFor='mostPopular'
                >
                  Sort by
                </p>
                <div className='w-[100%] mb-[24px]' ref={ref1}>
                  <div
                    onClick={() => setShow((prev) => !prev)}
                    className={`${
                      show
                        ? 'rounded-tl-3xl rounded-tr-3xl shadow-topButtomShadow'
                        : 'rounded-3xl border-b border-t border-r border-l'
                    } bg-[#ffffff]  border-[#D6D6D6]   flex items-center h-[40px] px-4 justify-between  cursor-pointer w-[100%] `}
                  >
                    <p className='text-[#BBBBBB] text-[13px] leading-[13px] font-medium'>
                      {selectValue ? selectValue : 'Most Popular'}
                    </p>

                    <DropArrow
                      className={`${
                        show === true ? 'transform rotate-180' : ''
                      }`}
                      style={{
                        fill: '#878787',
                      }}
                    />
                    {/* <img
                      className={`${
                        show === true ? 'transform rotate-180' : ''
                      }`}
                      src={dropArrow}
                      alt=''
                    /> */}
                  </div>
                  {show && (
                    <div className='relative'>
                      {/* border border-[#D6D6D6] */}
                      <ul
                        style={{
                          boxShadow: '0 4px 6px rgba(32,33,36,.28)',
                        }}
                        className='w-[100%]  pr-[16px] border-t-0 shadow-bottomButtonShadow absolute rounded-bl-3xl rounded-br-3xl  bg-[#ffffff] max-h-[199.5px] overflow-hidden hover:overflow-y-auto hover:overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent'
                      >
                        <li
                          onClick={() => changeValue1('Most Popular')}
                          className='h-[25px] flex items-center px-4  cursor-pointer text-primaryGray text-[13px] leading-[13px] font-normal border-b border-t border-[#efefef]'
                        >
                          Most Popular
                        </li>
                        {popularList.map((item, index) => (
                          <li
                            key={index}
                            onClick={() => changeValue1(item.name)}
                            className={` flex items-center px-4  cursor-pointer text-primaryGray text-[13px] leading-[13px] font-normal ${
                              index === popularList.length - 1
                                ? 'h-[46px] pb-[21px]'
                                : 'border-b h-[25px]'
                            }   border-[#efefef]`}
                          >
                            {item.name}
                          </li>
                        ))}
                        {/* <li 
                          onClick={() => changeValue1('Ap2')}
                          className='py-3 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium'
                        >
                          Ap2
                        </li> */}
                      </ul>
                    </div>
                  )}
                </div>
                <p
                  className='text-sm12 text-primaryGray font-medium leading-[15px] mb-[4px]'
                  htmlFor='styles'
                >
                  Style
                </p>
                <div className='w-[100%] mb-[8px]' ref={ref2}>
                  <div
                    onClick={() => setShowStyles((prev) => !prev)}
                    className={`${
                      showStyles
                        ? 'rounded-tl-3xl rounded-tr-3xl'
                        : 'rounded-3xl'
                    } bg-[#ffffff] border border-[#D6D6D6]  flex items-center py-3 px-4 justify-between w-[100%] `}
                  >
                    <p className='text-[#BBBBBB] text-[13px] leading-[13px] font-medium'>
                      {selectStylesValue
                        ? selectStylesValue
                        : 'All Styles'}
                    </p>
                    <DropArrow
                      className={`${
                        showStyles === true
                          ? 'transform rotate-180'
                          : ''
                      }`}
                      style={{
                        fill: '#878787',
                      }}
                    />
                  </div>
                  {showStyles && (
                    <div className='relative'>
                      <ul
                        className={`w-[100%] border border-[#D6D6D6] border-t-0 absolute rounded-bl-3xl rounded-br-3xl  bg-[#ffffff] max-h-[199.5px] overflow-hidden hover:overflow-y-auto hover:overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                      >
                        <li
                          onClick={() => {
                            changeValue2('All Styles');
                          }}
                          className='h-[25px] flex items-center px-4  cursor-pointer text-primaryGray text-[13px] leading-[13px] font-normal border-b border-[#D6D6D6]'
                        >
                          All Styles
                        </li>
                        {/* binded already */}
                        {styleList.map((obj, index) => (
                          <li
                            key={obj.name}
                            onClick={() => {
                              changeValue2(obj.name);
                            }}
                            className={`h-[25px] flex items-center px-4  cursor-pointer text-primaryGray text-[13px] leading-[13px] font-normal ${
                              index === styleList.length - 1
                                ? ''
                                : 'border-b'
                            }  border-[#D6D6D6]`}
                          >
                            {obj.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                <p
                  className='text-sm12 text-primaryGray font-medium leading-[15px] mb-[4px]'
                  htmlFor='subject'
                >
                  Subject
                </p>
                <div className='w-[100%] mb-[8px]' ref={ref3}>
                  <div
                    onClick={() => setShowSub((prev) => !prev)}
                    className={`${
                      showSub
                        ? 'rounded-tl-3xl rounded-tr-3xl'
                        : 'rounded-3xl'
                    } bg-[#ffffff] border border-[#D6D6D6]  flex items-center py-3 px-4 justify-between w-[100%] `}
                  >
                    <p className='text-[#BBBBBB] text-[13px] leading-[13px] font-medium'>
                      {subDis
                        ? subjectId?.subjectName
                        : selectSubValue
                        ? selectSubValue
                        : 'All Subjects'}
                    </p>

                    <DropArrow
                      className={`${
                        showSub === true ? 'transform rotate-180' : ''
                      }`}
                      style={{
                        fill: '#878787',
                      }}
                    />
                    {/* <img
                      className={`${
                        showSub === true ? 'transform rotate-180' : ''
                      }`}
                      src={dropArrow}
                      alt=''
                    /> */}
                  </div>
                  {showSub && (
                    <div className='relative'>
                      <ul className='w-[100%] border border-[#D6D6D6] border-t-0 absolute rounded-bl-3xl rounded-br-3xl  bg-[#ffffff] max-h-[199.5px] overflow-hidden hover:overflow-y-auto hover:overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent'>
                        <li
                          onClick={() => {
                            changeValue3('All Subjects');
                          }}
                          className='h-[25px] flex items-center px-4  cursor-pointer text-primaryGray text-[13px] leading-[13px] font-normal border-b border-[#D6D6D6]'
                        >
                          All Subjects
                        </li>
                        {subjectList.map((obj, index) => (
                          <li
                            key={obj.subjectName}
                            onClick={() => {
                              changeValue3(obj);
                            }}
                            className={`h-[25px] flex items-center px-4  cursor-pointer text-primaryGray text-[13px] leading-[13px] font-normal ${
                              index === subjectList.length - 1
                                ? ''
                                : 'border-b'
                            } border-[#D6D6D6]`}
                          >
                            {obj.subjectName}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <p
                  className='text-sm12 text-primaryGray font-medium leading-[15px] mb-[4px]'
                  htmlFor='medium'
                >
                  Medium
                </p>
                <div className='w-[100%] ' ref={ref4}>
                  <div
                    onClick={() => setShowMedium((prev) => !prev)}
                    className={`${
                      showMedium
                        ? 'rounded-tl-3xl rounded-tr-3xl'
                        : 'rounded-3xl'
                    } bg-[#ffffff] border border-[#D6D6D6]  flex items-center py-3 px-4 justify-between w-[100%] `}
                  >
                    <p className='text-[#BBBBBB] text-[13px] leading-[13px] font-medium'>
                      {selectMediumValue
                        ? selectMediumValue
                        : 'All Mediums'}
                    </p>

                    <DropArrow
                      className={`${
                        showMedium === true
                          ? 'transform rotate-180'
                          : ''
                      }`}
                      style={{
                        fill: '#878787',
                      }}
                    />
                    {/* <img
                      className={`${
                        showMedium === true
                          ? 'transform rotate-180'
                          : ''
                      }`}
                      src={dropArrow}
                      alt=''
                    /> */}
                  </div>
                  {showMedium && (
                    <div className='relative'>
                      <ul className='w-[100%] border border-[#D6D6D6] absolute border-t-0 rounded-bl-3xl rounded-br-3xl bg-[#ffffff] max-h-[191px] overflow-hidden hover:overflow-y-auto hover:overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent'>
                        <li
                          onClick={() => changeValue4('All Mediums')}
                          className='h-[25px] flex items-center px-4  cursor-pointer text-primaryGray text-[13px] leading-[13px] font-normal border-b border-[#D6D6D6]'
                        >
                          All Mediums
                        </li>
                        <li
                          onClick={() => changeValue4('Ap2')}
                          className='h-[25px] flex items-center px-4  cursor-pointer text-primaryGray text-[13px] leading-[13px] font-normal '
                        >
                          Ap2
                        </li>
                      </ul>
                    </div>
                  )}
                </div>

                {/* Select Filters */}
                <div className='mb-6 mt-6'>
                  <p
                    className='text-sm12 text-primaryGray font-medium leading-[15px] mb-[4px] mt-6'
                    htmlFor='size'
                  >
                    Size
                  </p>

                  <div className='flex gap-2.5'>
                    <button
                      onClick={() => changeSizeFilter('S')}
                      className={`${
                        sizeBtn === 'S'
                          ? 'bg-[#BBBBBB] text-primaryBlack'
                          : 'bg-[#ffffff] text-primaryGray'
                      } w-[40px] h-[40px] hover:bg-[#BBBBBB] hover:text-primaryBlack border border-[#D6D6D6] rounded-lg  text-sm14  leading-4 font-medium`}
                    >
                      S
                    </button>
                    <button
                      onClick={() => changeSizeFilter('M')}
                      className={`${
                        sizeBtn === 'M'
                          ? 'bg-[#BBBBBB] text-primaryBlack'
                          : 'bg-[#ffffff] text-primaryGray'
                      } w-[40px] h-[40px] hover:bg-[#BBBBBB] hover:text-primaryBlack border border-[#D6D6D6] rounded-lg  text-sm14 leading-4 font-medium`}
                    >
                      M
                    </button>
                    <button
                      onClick={() => changeSizeFilter('L')}
                      className={`${
                        sizeBtn === 'L'
                          ? 'bg-[#BBBBBB] text-primaryBlack'
                          : 'bg-[#ffffff] text-primaryGray'
                      } w-[40px] h-[40px] hover:bg-[#BBBBBB] hover:text-primaryBlack border border-[#D6D6D6] rounded-lg  text-sm14 leading-4 font-medium`}
                    >
                      L
                    </button>
                  </div>

                  {/* Orientation */}
                  <p
                    className='text-sm12 text-primaryGray font-medium leading-[15px] mb-[4px] mt-2'
                    htmlFor='orientation'
                  >
                    Orientation
                  </p>
                  <div className='flex gap-2.5'>
                    {/* Custom */}
                    <svg
                      onClick={() =>
                        changeOrientationFilter('custom')
                      }
                      onMouseEnter={() => setCustomOrientHover(true)}
                      onMouseLeave={() => setCustomOrientHover(false)}
                      className={`${
                        orientationBtn === 'custom' ||
                        CustomOrientHover === true
                          ? 'fill-[#BBBBBB]'
                          : 'fill-[#ffffff]'
                      } cursor-pointer`}
                      width='40'
                      height='40'
                      viewBox='0 0 40 40'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        x='0.5'
                        y='0.5'
                        width='39'
                        height='39'
                        rx='3.5'
                        stroke='#D9D9D9'
                      />
                      <path
                        className={`${
                          orientationBtn === 'custom' ||
                          CustomOrientHover === true
                            ? 'fill-[#333333]'
                            : 'fill-[#BBBBBB]'
                        }`}
                        d='M8 10C8 8.89543 8.89543 8 10 8H18C19.1046 8 20 8.89543 20 10V15C20 16.1046 19.1046 17 18 17H10C8.89543 17 8 16.1046 8 15V10Z'
                        fill='#333333'
                      />
                      <path
                        className={`${
                          orientationBtn === 'custom' ||
                          CustomOrientHover === true
                            ? 'fill-[#333333]'
                            : 'fill-[#BBBBBB]'
                        }`}
                        d='M8 21C8 19.8954 8.89543 19 10 19H18C19.1046 19 20 19.8954 20 21V29C20 30.1046 19.1046 31 18 31H10C8.89543 31 8 30.1046 8 29V21Z'
                        fill='#333333'
                      />
                      <path
                        className={`${
                          orientationBtn === 'custom' ||
                          CustomOrientHover === true
                            ? 'fill-[#333333]'
                            : 'fill-[#BBBBBB]'
                        }`}
                        d='M22 10C22 8.89543 22.8954 8 24 8H29C30.1046 8 31 8.89543 31 10V29C31 30.1046 30.1046 31 29 31H24C22.8954 31 22 30.1046 22 29V10Z'
                        fill='#333333'
                      />
                    </svg>

                    {/* Horizontal */}
                    <svg
                      onMouseEnter={() => setHoriOrientHover(true)}
                      onMouseLeave={() => setHoriOrientHover(false)}
                      onClick={() =>
                        changeOrientationFilter('horizontal')
                      }
                      className={`${
                        orientationBtn === 'horizontal' ||
                        HoriOrientHover === true
                          ? 'fill-[#BBBBBB]'
                          : 'fill-[#ffffff]'
                      } cursor-pointer`}
                      width='40'
                      height='40'
                      viewBox='0 0 40 40'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        x='0.5'
                        y='0.5'
                        width='39'
                        height='39'
                        rx='3.5'
                        stroke='#D9D9D9'
                      />
                      <path
                        d='M8 14C8 12.8954 8.89543 12 10 12H30C31.1046 12 32 12.8954 32 14V26C32 27.1046 31.1046 28 30 28H10C8.89543 28 8 27.1046 8 26V14Z'
                        className={`${
                          orientationBtn === 'horizontal' ||
                          HoriOrientHover === true
                            ? 'fill-[#333333]'
                            : 'fill-[#BBBBBB]'
                        } `}
                        fill='#BBBBBB'
                      />
                    </svg>

                    {/* Verticle */}
                    <svg
                      onMouseEnter={() => setVertiOrientHover(true)}
                      onMouseLeave={() => setVertiOrientHover(false)}
                      onClick={() =>
                        changeOrientationFilter('verticle')
                      }
                      className={`${
                        orientationBtn === 'verticle' ||
                        VertiOrientHover === true
                          ? 'fill-[#BBBBBB]'
                          : 'fill-[#ffffff]'
                      } cursor-pointer`}
                      width='40'
                      height='40'
                      viewBox='0 0 40 40'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        x='0.5'
                        y='0.5'
                        width='39'
                        height='39'
                        rx='3.5'
                        stroke='#D9D9D9'
                      />
                      <path
                        d='M12 10C12 8.89543 12.8954 8 14 8H26C27.1046 8 28 8.89543 28 10V30C28 31.1046 27.1046 32 26 32H14C12.8954 32 12 31.1046 12 30V10Z'
                        className={`${
                          orientationBtn === 'verticle' ||
                          VertiOrientHover === true
                            ? 'fill-[#333333]'
                            : 'fill-[#BBBBBB]'
                        }`}
                        fill='#BBBBBB'
                      />
                    </svg>

                    {/* Square */}
                    <svg
                      onClick={() =>
                        changeOrientationFilter('square')
                      }
                      onMouseEnter={() => setSquareOrientHover(true)}
                      onMouseLeave={() => setSquareOrientHover(false)}
                      className={`${
                        orientationBtn === 'square' ||
                        SquareOrientHover === true
                          ? 'fill-[#BBBBBB]'
                          : 'fill-[#ffffff]'
                      } cursor-pointer`}
                      width='40'
                      height='40'
                      viewBox='0 0 40 40'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        x='0.5'
                        y='0.5'
                        width='39'
                        height='39'
                        rx='3.5'
                        stroke='#D9D9D9'
                      />
                      <path
                        d='M8 10C8 8.89543 8.89543 8 10 8H30C31.1046 8 32 8.89543 32 10V30C32 31.1046 31.1046 32 30 32H10C8.89543 32 8 31.1046 8 30V10Z'
                        className={`${
                          orientationBtn === 'square' ||
                          SquareOrientHover === true
                            ? 'fill-[#333333]'
                            : 'fill-[#BBBBBB]'
                        }`}
                        fill='#BBBBBB'
                      />
                    </svg>
                  </div>
                </div>

                {/* Price */}
                <div className='mb-[16px]'>
                  <p
                    className='text-sm12 text-primaryGray font-medium leading-[15px]'
                    htmlFor='price'
                  >
                    Price
                  </p>
                  <p className='text-sm11 font-normal text-primaryGray leading-[15px] mb-[4px]'>
                    Select minimum and maximum price range
                  </p>
                  <div>
                    <div className='w-full flex justify-center'>
                      <div>
                        <Slider
                          getAriaLabel={() => 'Temperature range'}
                          value={value}
                          onChange={handleChangeSlider}
                          onMouseUp={handleChangeSlidermouse}
                          valueLabelDisplay='auto'
                          getAriaValueText={valuetext}
                          min={45}
                          max={10000}
                          step={1}
                          sx={{
                            width: '236px',
                            height: '8px',
                            padding: '0',
                            '& .MuiSlider-rail': {
                              opacity: '1',
                              background: '#dddddd',
                            },
                            '& .MuiSlider-track': {
                              opacity: '0.5',
                              background:
                                'linear-gradient(to right, #fd4b9e, #9794d0, #46d59a, #beed10)',
                              border: 'none',
                            },
                            '& .MuiSlider-thumb': {
                              // width: '0px',
                              // height: '0px',
                              // borderStyle: 'solid',
                              // borderRadius: '1px',
                              // borderBottomLeftRadius: '21px',
                              // borderBottomRightRadius: '21px',
                              background: 'none', // Set background color to transparent
                              boxShadow: 'none!important', // Remove box-shadow if not required
                              '-webkit-box-shadow': 'none!important', // For compatibility with certain browser
                              backgroundImage: `url(${SliderButton})`,
                              // backgroundColor: '#888888',
                              boxShadow: '0 0 #0000!important',
                            },
                            '& .css-eg0mwd-MuiSlider-thumb:before': {
                              boxShadow: 'none!important', // Remove box-shadow if not required
                            },
                            '& .MuiSlider-thumb:before': {
                              boxShadow: 'none!important',
                            },
                          }}
                        />
                        <ScaleArtlist className='relative bottom-[6px]' />
                        {/* <img
                          className='relative bottom-[6px]'
                          src={scale}
                          alt=''
                        /> */}
                        <p className='text-primaryGray text-[11px] leading-[122%] '>
                          Price: ${value[0]} - ${value[1]}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Search */}
                <div className='mb-6'>
                  <p
                    className='text-sm12 text-primaryGray font-medium leading-[15px] mb-[4px]'
                    htmlFor='search'
                  >
                    Search by Artist/Contributor
                  </p>
                  <div className='relative'>
                    <input
                      value={contriname}
                      className='border border-[#D6D6D6] outline-none w-[100%] rounded-3xl placeholder:text-[#BBBBBB] placeholder:text-[13px] placeholder:leading-3 placeholder:font-medium py-2 px-4'
                      type='text'
                      placeholder='Contributor’s Name'
                      onChange={(e) => {
                        setcontriname(e.target.value);
                      }}
                    />
                    <button
                      onClick={SetContributorName}
                      className='absolute right-1.5 top-1.5 bg-[#EEEEEE] text-primaryBlack text-[10px] font-medium leading-2.5 py-2 px-3 rounded-2xl hover:text-[#333333]'
                    >
                      Search
                    </button>
                  </div>
                </div>

                {/* Colors */}
                <div className='mb-6'>
                  <p
                    className='text-sm12 text-primaryGray font-medium leading-[15px] mb-[4px]'
                    htmlFor='colors'
                  >
                    Colors
                  </p>
                  <div className='flex gap-[5px] color'>
                    <IconColor className='cursor-pointer ' />

                    <IconBW className='cursor-pointer ' />
                  </div>

                  <div className='flex gap-[4px] mt-[10px]'>
                    {COLOR_DATA.map((colors) => (
                      <div className='flex flex-col gap-[4px]'>
                        {colors?.colors.map((color) => (
                          <div
                            onClick={() => setColors(color?.code)}
                            style={{
                              backgroundColor: `${color?.code}`,
                            }}
                            className='h-[15px] w-[15px] rounded-[50%] cursor-pointer'
                          ></div>
                        ))}
                      </div>
                    ))}
                  </div>

                  <p
                    onClick={() => setColors('')}
                    className='text-orangeColor text-[11px] font-normal'
                  >
                    Clear Colour Filter
                  </p>
                </div>
                <button
                  onClick={() => setFilterObj(defaultFilterObj)}
                  className='bg-primaryBlack px-5 rounded-3xl text-sm14 text-[#FFFFFF] hover:bg-[#000000] font-medium  h-[30px] cursor-pointer'
                >
                  Clear All Filters
                </button>
              </form>
            </div>
          )}

          {/* Main Content */}
          <div className='content w-[100%]'>
            <div className='flex'>
              {!showSidebar && (
                <img
                  className='cursor-pointer mr-[16px]'
                  onClick={() => setShowSidebar(true)}
                  src={filterIcon}
                  alt=''
                />
              )}
              <div className='flex gap-[4px]'>
                <div className='text-sm12 text-primaryGray cursor-pointer py-1.5 border border-[#D6D6D6] hover:text-[#333333] text-[#757575] rounded-2xl px-[12px]'>
                  Architecture/Buildings
                </div>
                <div className='text-sm12 text-primaryGray cursor-pointer py-1.5 border border-[#D6D6D6] hover:text-[#333333] rounded-2xl text-[#757575] px-[12px]'>
                  Backgrounds/Textures
                </div>
                <div className='text-sm12 text-primaryGray cursor-pointer py-1.5 border border-[#D6D6D6] hover:text-[#333333] rounded-2xl text-[#757575] px-[12px]'>
                  Beauty/Fashion
                </div>
                <div className='text-sm12 text-primaryGray cursor-pointer py-1.5 border border-[#D6D6D6] hover:text-[#333333] rounded-2xl text-[#757575] px-[12px]'>
                  Business/Finance
                </div>
                <div className='text-sm12 text-primaryGray cursor-pointer py-1.5 border border-[#D6D6D6] hover:text-[#333333] rounded-2xl text-[#757575] px-[12px]'>
                  Computer/Communication
                </div>
                <div className='text-sm12 text-primaryGray cursor-pointer py-1.5 border border-[#D6D6D6] hover:text-[#333333] rounded-2xl text-[#757575] px-[12px]'>
                  Education
                </div>
                <div className='text-sm12 text-primaryGray cursor-pointer py-1.5 border border-[#D6D6D6] hover:text-[#333333] rounded-2xl text-[#757575] px-[12px]'>
                  Emotions
                </div>
                <div className='text-sm12 text-primaryGray cursor-pointer py-1.5 border border-[#D6D6D6] hover:text-[#333333] rounded-2xl text-[#757575] px-[12px]'>
                  Health/Medical
                </div>
                <div className='text-sm12 text-primaryGray cursor-pointer py-1.5 border border-[#D6D6D6] hover:text-[#333333] rounded-2xl text-[#757575] px-[12px]'>
                  Industry/Craft
                </div>
                <div className='text-sm12 text-primaryGray cursor-pointer py-1.5 border border-[#D6D6D6] hover:text-[#333333] rounded-2xl text-[#757575] px-[12px]'>
                  Music
                </div>
                <div className='text-sm12 text-primaryGray cursor-pointer py-1.5 border border-[#D6D6D6] hover:text-[#333333] rounded-2xl text-[#757575] px-[12px]'>
                  Nature/Landscapes
                </div>
                <div className='text-sm12 text-primaryGray cursor-pointer py-1.5 border border-[#D6D6D6] hover:text-[#333333] rounded-2xl text-[#757575] px-[12px]'>
                  People
                </div>
                <div className='text-sm12 text-primaryGray cursor-pointer py-1.5 border border-[#D6D6D6] hover:text-[#333333] rounded-2xl text-[#757575] px-[12px]'>
                  Computer/Communication
                </div>
                {/* <div className='text-sm12 text-primaryGray py-1.5 border border-[#D6D6D6] rounded-2xl text-[#757575] px-[12px]'>
                  Education
                </div>
                <div className='text-sm12 text-primaryGray py-1.5 border border-[#D6D6D6] rounded-2xl text-[#757575] px-[12px]'>
                  Industry/Craft
                </div>
                <div className='text-sm12 text-primaryGray py-1.5 border border-[#D6D6D6] rounded-2xl text-[#757575] px-[12px]'>
                  Music
                </div> */}
              </div>
            </div>

            <div className={`mainImagesCon mt-[16px]`}>
              <ResponsiveMasonry
                columnsCountBreakPoints={
                  !showSidebar ? { 1920: 6 } : { 1920: 5 }
                }
              >
                <Masonry gutter='16px'>
                  {artsList.map((data, i) => {
                    if (i >= firstPage && i < lastPage) {
                      return (
                        <div
                          onMouseEnter={() => {
                            setPopupArray([]);
                          }}
                          key={data?.artId}
                          className={` ${
                            // showSidebar
                            //   ? 'w-[19.25rem]'
                            //   : 'w-[18.688rem]'
                            'w-[100%] '
                          }`}
                          style={{ height: 'fit-content' }}
                        >
                          <div
                            className={` w-full group  rounded-[16px] relative cursor-pointer`}
                            onClick={() =>
                              goToArtDetailsPage(data?.artId)
                            }
                          >
                            <img
                              style={{
                                height: '100%',
                                width: '100%',
                              }}
                              src={
                                imageGrid === 'grid'
                                  ? imageLinkChange(
                                      data?.imageMaster.secureUrl
                                    )
                                  : imageLinkChangeSquaregrid(
                                      data?.imageMaster.secureUrl
                                    )
                              }
                              alt=''
                              className='rounded-2xl'
                            />
                            <div
                              className='group-hover:flex hidden  bg-blackRgba items-center justify-center absolute top-0 left-0 rounded-[16px]'
                              style={{
                                height: '100%',
                                width: '100%',
                              }}
                            >
                              <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%]'>
                                {data.subjectMaster.subjectName}
                              </p>
                              <div className='absolute bottom-[12px] left-[12px] flex gap-[10px]'>
                                {userAuth.login === true ? (
                                  <StyledPopupp
                                    trigger={
                                      <div>
                                        {/* <img src={save} alt='' /> */}
                                        <PlusIcon
                                          style={{
                                            fill: '#e8e8e8',
                                          }}
                                        />
                                      </div>
                                    }
                                    modal
                                  >
                                    {(close) => (
                                      <div className='flex flex-col gap-[21px]'>
                                        {/* {selectedAllFilesImages.length > 0 ? ( */}
                                        <div
                                          style={{
                                            backgroundImage: `url(${data?.imageMaster.secureUrl})`,
                                          }}
                                          className='w-[390px] h-[270px] bg-no-repeat bg-center bg-cover rounded-[16px]'
                                        ></div>

                                        <div>
                                          <div className='flex flex-col'>
                                            <p className='text-[15px] font-medium mb-[3px]'>
                                              Select Category
                                            </p>
                                            <div>
                                              <button
                                                onClick={() => {
                                                  setIsCategoryOpen(
                                                    !isCategoryOpen
                                                  );
                                                }}
                                                className={`${
                                                  isCategoryOpen ===
                                                  true
                                                    ? 'rounded-t-[20px] shadow-dropShadowButton border-b border-[#efefef]'
                                                    : 'rounded-[20px] border  border-[#d6d6d6]'
                                                } cursor-pointer w-[390px] h-[40px] bg-[#FFFFFF] text-primaryGray text-sm14 font-medium flex items-center justify-between px-[15px]`}
                                              >
                                                {category === '' ? (
                                                  <span>
                                                    Select Category
                                                  </span>
                                                ) : (
                                                  <span>
                                                    {category}
                                                  </span>
                                                )}

                                                <DropArrow
                                                  style={{
                                                    fill: '#878787',
                                                  }}
                                                  className='inline-block'
                                                />

                                                {/* <img
                                                  className='inline-block'
                                                  src={dropArrow}
                                                  alt=''
                                                /> */}
                                              </button>

                                              {isCategoryOpen && (
                                                <ul className='shadow-dropShadow rounded-b-2xl z-50 cursor-pointer hover:overflow-hidden dropdown__menu absolute bg-[#ffffff] w-[390px] text-center text-[14px] text-primaryGray'>
                                                  <li
                                                    onClick={() => {
                                                      setCategory(
                                                        'Art'
                                                      );
                                                      setIsCategoryOpen(
                                                        !isCategoryOpen
                                                      );
                                                      setFolderName(
                                                        null
                                                      );
                                                    }}
                                                    className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'
                                                  >
                                                    Art
                                                  </li>
                                                  <li
                                                    onClick={() => {
                                                      setCategory(
                                                        'Photos'
                                                      );
                                                      setIsCategoryOpen(
                                                        !isCategoryOpen
                                                      );
                                                      setFolderName(
                                                        null
                                                      );
                                                    }}
                                                    className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'
                                                  >
                                                    Photos
                                                  </li>
                                                  <li
                                                    onClick={() => {
                                                      setCategory(
                                                        'Footage'
                                                      );
                                                      setIsCategoryOpen(
                                                        !isCategoryOpen
                                                      );
                                                      setFolderName(
                                                        null
                                                      );
                                                    }}
                                                    className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'
                                                  >
                                                    Footage
                                                  </li>
                                                  <li
                                                    onClick={() => {
                                                      setCategory(
                                                        'Music'
                                                      );
                                                      setIsCategoryOpen(
                                                        !isCategoryOpen
                                                      );
                                                      setFolderName(
                                                        null
                                                      );
                                                    }}
                                                    className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'
                                                  >
                                                    Music
                                                  </li>
                                                  <li
                                                    onClick={() => {
                                                      setCategory(
                                                        'Templates'
                                                      );
                                                      setIsCategoryOpen(
                                                        !isCategoryOpen
                                                      );
                                                      setFolderName(
                                                        null
                                                      );
                                                    }}
                                                    className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'
                                                  >
                                                    Templates
                                                  </li>
                                                  <li
                                                    onClick={() => {
                                                      setCategory(
                                                        'Product'
                                                      );
                                                      setIsCategoryOpen(
                                                        !isCategoryOpen
                                                      );
                                                      setFolderName(
                                                        null
                                                      );
                                                    }}
                                                    className='py-1 px-3.5 hover:bg-[#F0F0F0]'
                                                  >
                                                    Product
                                                  </li>
                                                </ul>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <div className='flex flex-col'>
                                            <p className='text-[15px] font-medium mb-[3px]'>
                                              Set Name
                                            </p>
                                            <div>
                                              <button
                                                onClick={() => {
                                                  setIsNameOpen(
                                                    !isNameOpen
                                                  );
                                                }}
                                                // className={`flex items-center justify-between px-[15px] text-primaryGray text-sm14 font-medium cursor-pointer w-[390px] h-[40px] bg-[#FFFFFF] rounded-[20px] border border-[#d6d6d6]`}
                                                className={`${
                                                  isNameOpen === true
                                                    ? 'rounded-t-[20px] shadow-dropShadowButton border-b border-[#efefef]'
                                                    : 'rounded-[20px] border  border-[#d6d6d6]'
                                                } cursor-pointer w-[390px] h-[40px] bg-[#FFFFFF] text-primaryGray text-sm14 font-medium flex items-center justify-between px-[15px]`}
                                              >
                                                {folderName ===
                                                null ? (
                                                  <span>
                                                    Enter Set Name
                                                  </span>
                                                ) : (
                                                  <span>
                                                    {folderName.title}
                                                  </span>
                                                )}

                                                <DropArrow
                                                  style={{
                                                    fill: '#878787',
                                                  }}
                                                  className='inline-block'
                                                />
                                                {/* <img
                                                  className='inline-block'
                                                  src={dropArrow}
                                                  alt=''
                                                /> */}
                                              </button>

                                              {category === 'Art' ? (
                                                <div>
                                                  {isNameOpen && (
                                                    <ul className='cursor-pointer shadow-dropShadow rounded-b-2xl hover:overflow-hidden dropdown__menu absolute bg-[#ffffff] w-[390px] text-center text-[14px] text-primaryGray'>
                                                      {categories.art.map(
                                                        (
                                                          items,
                                                          index
                                                        ) => (
                                                          <li
                                                            onClick={() => {
                                                              setFolderName(
                                                                items
                                                              );
                                                              setIsNameOpen(
                                                                !isNameOpen
                                                              );
                                                            }}
                                                            className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'
                                                          >
                                                            {
                                                              items.title
                                                            }
                                                          </li>
                                                        )
                                                      )}
                                                    </ul>
                                                  )}
                                                </div>
                                              ) : category ===
                                                'Photos' ? (
                                                <div>
                                                  {isNameOpen && (
                                                    <ul className='cursor-pointer shadow-dropShadow rounded-b-2xl hover:overflow-hidden dropdown__menu absolute bg-[#ffffff] w-[390px] text-center text-[14px] text-primaryGray'>
                                                      {categories.photo.map(
                                                        (
                                                          items,
                                                          index
                                                        ) => (
                                                          <li
                                                            onClick={() => {
                                                              setFolderName(
                                                                items
                                                              );
                                                              setIsNameOpen(
                                                                !isNameOpen
                                                              );
                                                            }}
                                                            className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'
                                                          >
                                                            {
                                                              items.title
                                                            }
                                                          </li>
                                                        )
                                                      )}
                                                    </ul>
                                                  )}
                                                </div>
                                              ) : (
                                                <div></div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className='flex gap-[10px] justify-center pb-[30px]'>
                                          <button
                                            onClick={() => {
                                              addToFolder(
                                                data?.artId
                                              );
                                            }}
                                            className='blackBtn h-[40px] w-[88px]'
                                          >
                                            Save
                                          </button>
                                          <button
                                            onClick={close}
                                            className='h-[40px] px-6 py-2 rounded-3xl text-sm14 text-primaryBlack border-[2px] w-[88px]'
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                  </StyledPopupp>
                                ) : (
                                  <div
                                    onClick={(e) => {
                                      popupOfHover({
                                        id: data.artId,
                                      });
                                      e.stopPropagation();
                                    }}
                                  >
                                    <PlusIcon
                                      style={{
                                        fill: '#e8e8e8',
                                      }}
                                    />
                                  </div>
                                )}

                                <div>
                                  <SimilarIcon
                                    style={{
                                      fill: '#e8e8e8',
                                    }}
                                  />
                                </div>
                                <div>
                                  <ProfileIcon
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      navigate('/view-my-store', {
                                        state: data?.userMaster,
                                      });
                                    }}
                                  />
                                </div>
                                <div>
                                  <CartIcon />
                                </div>
                                <div>
                                  <ShareIcon
                                    className='fill-[#e8e8e8]'
                                    onClick={(e) => {
                                      navigate(
                                        '/BuyerReferralProgram'
                                      );
                                      e.stopPropagation();
                                    }}
                                  />
                                </div>
                              </div>
                              <div className='absolute right-[12px] bottom-[12px]'>
                                <StyledPopup
                                  trigger={
                                    <div>
                                      <EnlargeIcon
                                        onClick={(e) => {
                                          setOpenBig(true);
                                        }}
                                        style={{
                                          fill: '#e8e8e8',
                                        }}
                                      />
                                    </div>
                                  }
                                  modal
                                >
                                  {(close) => (
                                    <div className='h-[100%]'>
                                      <img
                                        src={imageLinkChange(
                                          data?.imageMaster.secureUrl
                                        )}
                                        className='h-[100%] mx-[auto]'
                                        alt=''
                                      />
                                    </div>
                                  )}
                                </StyledPopup>
                              </div>
                              <div className='absolute right-[10px] top-[10px]'>
                                <Wishlist
                                  id={data?.artId}
                                  type='large'
                                  prodType='art'
                                />
                              </div>
                              {isHovered && (
                                <button className='w-[164px] z-[99] mt-[3px] h-[20px] flex justify-center items-center text-[11px] bg-[#f7f7f7] rounded-[10px] text-primaryGray absolute top-[33px] left-[203px] border border-[#e4e4e4]'>
                                  <span className='leading-[1]'>
                                    Save to Wishlist
                                  </span>
                                </button>
                              )}
                              {popupArray.find(
                                (obj) => obj.id === data.artId
                              ) && (
                                <div
                                  className={`z-999 right-[117px] bottom-[15px] bg-[#fff] rounded-[16px] w-[266px] absolute bottom-[44px] left-[-117px]`}
                                  style={{
                                    boxShadow:
                                      '0px 0px 18px rgba(0, 0, 0, 0.2)',
                                  }}
                                >
                                  <div className='flex gap-[5px] flex-col p-[14px] leading-[1.3] text-center'>
                                    <p className='font-medium text-primaryBlack text-[15px]'>
                                      Create Account
                                    </p>
                                    <p className='text-primaryGray text-[11px]'>
                                      To create and add to a
                                      collection, you must be a
                                      logged-in member
                                    </p>
                                    <button
                                      onClick={(e) => {
                                        navigate('/join');
                                        e.stopPropagation();
                                      }}
                                      className='bg-[#8e8e8e] rounded-[14px] h-[28px] w-[108px] text-[12px] font-medium text-[white] mx-[auto]'
                                    >
                                      Create Account
                                    </button>
                                    <p
                                      onClick={(e) => {
                                        navigate('/login');
                                        e.stopPropagation();
                                      }}
                                      className='text-orangeColor text-[11px]'
                                    >
                                      Already a member? Sign in
                                    </p>
                                    <p className='text-pinkColor text-[11px]'>
                                      Note: Downloaded images will be
                                      saved in ‘Collections’ folder
                                    </p>
                                  </div>
                                  <div className='absolute left-[47%] bottom-[-10px] w-[20px] h-[20px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                                </div>
                              )}
                            </div>
                          </div>

                          <p className='w-[75%] truncate text-primaryBlack text-[15px] leading-[18px] font-medium mt-[4px]'>
                            {data?.artName}
                          </p>

                          <p className='text-primaryGray text-[11px] leading-[14px]'>
                            Artnstock <br />
                            35.4” x 31.5” Multiple Sizes
                          </p>
                          <p className='text-primaryBlack text-[15px] leading-[18px] font-semibold mb-[3px]'>
                            ${data?.price}
                          </p>
                        </div>
                      );
                    }
                  })}
                </Masonry>
              </ResponsiveMasonry>
            </div>

            {/* Pagination */}
            <div className='flex gap-[5px] mt-[32px] mx-auto items-center justify-center'>
              <p className='text-[13px] text-primaryGray leading-[15px] font-normal'>
                Page
              </p>
              <div className='flex w-[88px] border border-[#D6D6D6] rounded-2xl overflow-hidden'>
                <button
                  onClick={prevPage}
                  className='bg-[#F7F7F7] py-2.5 px-3'
                >
                  <img src={smallLeftArrow} alt='' />
                </button>
                <input
                  className='w-[30px] text-[13px] leading-[15px] font-normal text-primaryGray text-center'
                  type='text'
                  value={currentPage}
                />
                <button
                  onClick={nextPage}
                  className='bg-[#F7F7F7] py-2.5 px-3'
                >
                  <img src={smallRightArrow} alt='' />
                </button>
              </div>
              <p className='text-[13px] text-primaryGray leading-[15px] font-normal'>
                of {totalPage}
              </p>
            </div>
            <button
              // disabled={true}
              onClick={nextPage}
              className='opacity-[40%] cursor-default bg-primaryBlack px-5 py-2.5 rounded-3xl text-sm14 text-[#FFFFFF] font-medium mt-[12px] mb-24 mx-auto block '
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArtList;
