import { useState, useMemo, useEffect } from 'react';
import { setSelectedImages } from '../../../store/imageSlice';
import { useDispatch, useSelector } from 'react-redux';

const GrayBoxForSubmit = ({ card, style }) => {
  const [hovered, setHovered] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const dispatch = useDispatch();

  const selectedImages = useSelector(
    (state) => state.images.selectedImages
  );

  // useEffect(() => {
  //   console.log(selectedImages);
  // }, [selectedImages]);

  // const backgroundImageStyle = useMemo(
  //   () => ({
  //     backgroundImage: `url(${URL.createObjectURL(card)})`,
  //   }),
  //   [card]
  // );

  const handleButtonClick = () => {
    // setIsChecked(!isChecked);
    const findImage = selectedImages.find(
      (item) => item.imageId === card.imageId
    );

    if (!findImage) {
      dispatch(setSelectedImages([...selectedImages, card]));
    } else {
      dispatch(
        setSelectedImages(
          selectedImages.filter(
            (image) => image.imageId !== card.imageId
          )
        )
      );
    }
  };

  const handleCheckboxChange = () => {
    // setIsChecked(!isChecked);
    const findImage = selectedImages.find(
      (item) => item.imageId === card.imageId
    );

    if (!findImage) {
      dispatch(setSelectedImages([...selectedImages, card]));
    } else {
      dispatch(
        setSelectedImages(
          selectedImages.filter(
            (image) => image.imageId !== card.imageId
          )
        )
      );
    }
  };

  const [checked, setchecked] = useState(false);

  useEffect(() => {
    const abc = checkCheckboxTrueOrNot();
    setchecked(abc);
  }, [selectedImages]);

  const checkCheckboxTrueOrNot = () => {
    const bool = selectedImages.find(
      (item) => item.imageId === card.imageId
    );
    return bool;
  };

  return (
    <div
      className={`box-border h-[132px] w-[132px] rounded-[16px] relative`}
      // h-[127px] w-[126.99px]
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={style}
    >
      <div
        className={`h-full w-full bg-no-repeat bg-center bg-cover filter rounded-[16px] brightness-100 absolute overflow-hidden inset-0 ${
          hovered ? 'brightness-[70%]' : ''
        }`}
        style={{
          backgroundImage: `url(${card.secureUrl})`,
        }}
      ></div>
      {selectedImages.find((item) => item.imageId === card.imageId) ||
      hovered ? (
        <div className='absolute inset-0 flex items-center justify-center'>
          <button
            className='imageHoverButtonSmall'
            onClick={handleButtonClick}
          >
            {selectedImages.find(
              (item) => item.imageId === card.imageId
            ) ? (
              <span>Deselect</span>
            ) : (
              <span>Select</span>
            )}
          </button>
        </div>
      ) : (
        <div></div>
      )}
      {selectedImages.find((item) => item.imageId === card.imageId) ||
      hovered ? (
        // <div className='absolute top-0 right-0 p-2'>
        //   <input
        //     type='checkbox'
        //     className='w-6 h-6'
        //     checked={isChecked}
        //     onChange={handleCheckboxChange}
        //   />
        // </div>

        <label className='containerCheckWhite p-2'>
          <input
            type='checkbox'
            checked={checked}
            onChange={handleCheckboxChange}
          />{' '}
          {/* {obj.width}cm x {obj.height}cm */}
          <span className='checkmarkWhite'></span>
        </label>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default GrayBoxForSubmit;
