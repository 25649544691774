import selectall from '../../../assets/images/contributor/selectall.png';
import deleteicon from '../../../assets/images/contributor/delete-icon.png';
import undo from '../../../assets/images/contributor/undo.png';
import redo from '../../../assets/images/contributor/redo.png';
import save from '../../../assets/images/contributor/save.png';
import keyword from '../../../assets/images/contributor/keyword.png';

import iconart from '../../../assets/images/contributor/icon-art.png';
import iconphotos from '../../../assets/images/contributor/icon-photos.png';
import iconfootage from '../../../assets/images/contributor/icon-footage.png';
import iconmusic from '../../../assets/images/contributor/icon-music.png';
import icontemplates from '../../../assets/images/contributor/icon-templates.png';
import { useDispatch } from 'react-redux';
import { setpath2 } from '../../../store/contriPathSlice';
import { useSelector } from 'react-redux';
import { httpClient } from '../../../axios';

import { setNestedTabValueUpload } from '../../../store/nestedTabSlice';

import { useState, useEffect } from 'react';

import { setpath } from '../../../store/contriPathSlice';

import { ReactComponent as SelectAll } from '../../../assets/images/contributor/svg/Icon_SelectAll.svg';
import { ReactComponent as Deleteicon } from '../../../assets/images/contributor/svg/Icon_DeleteFiles.svg';
import { ReactComponent as Save } from '../../../assets/images/contributor/svg/Icon_SaveDraft.svg';
import { ReactComponent as Keyword } from '../../../assets/images/contributor/svg/Icon_KeywordManager.svg';

import view from '../../../assets/images/contributor/view.png';
import edit from '../../../assets/images/contributor/edit.png';
import deletee from '../../../assets/images/contributor/delete.png';

import Exclusive from '../../../assets/images/contributor/ForReview/Exclusive.jpg';
import Featured from '../../../assets/images/contributor/ForReview/Featured.jpg';

import { ReactComponent as ViewIcon } from '../../../assets/images/contributor/svg/view.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/contributor/svg/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/contributor/svg/delete.svg';

import { ReactComponent as Exclusive_Icon28 } from '../../../assets/images/contributor/add_details/Icon_Exclusive_28x28.svg';
import { ReactComponent as Featured_Icon28 } from '../../../assets/images/contributor/add_details/Icon_Featured_28x28.svg';

const RejectedFiles = () => {
  const [hoveredId, setHoveredId] = useState(null);
  const [checkedId, setCheckedId] = useState(null);
  const [object, setObject] = useState(null); //Store ID temporary
  const path = useSelector((state) => state.contriPath.path);
  const dispatch = useDispatch();

  const userId = useSelector((state) => state.auth.userId);

  useEffect(() => {
    dispatch(setpath2('/ Rejected Files'));
    getForRejectedList();
  }, []);

  useEffect(() => {
    console.log(path);
  }, [path]);

  const handleMouseEnter = (id) => {
    setHoveredId(id);
  };

  const handleMouseLeave = () => {
    setHoveredId(null);
  };

  const handleClick = (card) => {
    if (card?.artId === checkedId) {
      setCheckedId(null);
      setObject(null);
    } else {
      setCheckedId(card?.artId);
      setObject(card);
    }
  };

  const [image, setImage] = useState(null);

  const getForRejectedList = async () => {
    const res = await httpClient.get(
      `/art_master/getUserIdAndStatusWiseUserMaster/${userId}/Rejected`
    );
    console.log(res.data);
    setImage(res.data);
    handleClick(res.data[0]);
  };

  const datePipeReact = (obj) => {
    // Input date string
    const dateString = obj;

    // Step 1: Parse the input string into a Date object
    const date = new Date(dateString);

    // Step 2: Extract the day, month, and year from the Date object
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    // Step 3: Format the date components into the desired format
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate; // Output: "08/06/2023"
  };

  const grayBox = ['a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a'];

  const [grayNum, setgrayNum] = useState(grayBox?.length);

  useEffect(() => {
    if (image?.length < 11) {
      setgrayNum(grayBox?.length - image?.length);
    }

    console.log(image?.length);
  }, [image]);

  return (
    <>
      <div className='flex justify-center mb-[16px]'>
        <div
          style={{
            width: 'fit-content',
          }}
          className='flex border-t border-b border-t-[#efefef] border-b-[#efefef]'
        >
          <div className='flex items-center gap-[5px]'>
            <span className='contributorUploadCount text-pinkColor'>
              13
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='contributorUploadtext pr-[8px]'>
                File(s) to be
                <br />
                submitted to
                <br />
                Artnstock
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span className='contributorUploadCount text-[#f5721a] opacity-[20%] pl-[5px]'>
              0
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                File(s)
                <br />
                pending for
                <br />
                review
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span className=' contributorUploadCount text-[#a9af20] opacity-[20%]  pl-[5px]'>
              0
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                File(s)
                <br />
                approved by
                <br />
                Artnstock
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span className='contributorUploadCount text-[#e7233b] opacity-[20%]  pl-[5px]'>
              0
            </span>
            <div className='opacity-[40%] h-[40px] flex items-center'>
              <p className='contributorUploadtext'>
                File(s)
                <br />
                rejected by
                <br />
                Artnstock
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='contributorTitleStyling'>
        <div className='w-[1168px] flex justify-between'>
          <div className='flex flex-col gap-[2px] max-w-[320px] w-[100%]'>
            <div className='flex gap-[8px] items-baseline'>
              <p className='text-[25px] font-medium leading-[1] text-primaryBlack'>
                Rejected Files
              </p>
              <p className='text-pinkColor text-[11px] leading-[13px] '>
                {checkedId === null ? <span>0</span> : <span>1</span>}
                /1 File(s) selected
              </p>
            </div>
            <p className='text-[11px] text-primaryGray leading-[14px]'>
              Refer a new customer to earn 20% of their
              <br /> first payment.
              <span className='text-orangeColor'> Learn more</span>
            </p>
          </div>

          <div className='flex'>
            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef]'>
              <div className='flex flex-col mt-[1px] gap-[8px] w-[52px] text-center opacity-[40%]'>
                {/* <img
                  src={selectall}
                  alt=''
                  className='w-[16px] h-[16px] mx-[auto] bg-[#d7d7d7]'
                /> */}
                <div>
                  <SelectAll className='mx-[auto]' />
                </div>
                <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                  Select <br /> All
                </p>
              </div>
            </div>

            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef]'>
              <div className='flex flex-col  mt-[1px] opacity-[40%]  gap-[8px] w-[52px] text-center '>
                {/* <img
                  src={deleteicon}
                  alt=''
                  className='w-[16px] h-[16px] mx-[auto] '
                /> */}
                <div>
                  <Deleteicon className='mx-[auto]' />
                </div>
                <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                  Delete Image
                </p>
              </div>
            </div>

            {/* <div className='flex-column max-w-[34px] text-center'>
              <img
                src={undo}
                alt=''
                className='w-[16px] h-[16px] mx-[auto] mb-[6px]'
              />
              <p className='text-primaryGray text-[11px]'>
                Undo Changes
              </p>
            </div>
            <div className='flex-column max-w-[34px] text-center'>
              <img
                src={redo}
                alt=''
                className='w-[16px] h-[16px] mx-[auto] mb-[6px]'
              />
              <p className='text-primaryGray text-[11px]'>
                Redo Changes
              </p>
            </div> */}

            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef] border-r-[2px]'>
              <div className='flex flex-col  mt-[1px] opacity-[40%]  gap-[8px] w-[52px] text-center '>
                {/* <img
                  src={save}
                  alt=''
                  className='w-[16px] h-[16px] mx-[auto]'
                /> */}
                <div>
                  <Save className='mx-[auto]' />
                </div>
                <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                  Save <br /> Draft
                </p>
              </div>
            </div>

            {/* <div className=' w-[53px] text-center border-r border-r-[#efefef] '>
              <img
                src={save}
                alt=''
                className='w-[16px] h-[16px] mx-[auto] mb-[6px]'
              />
              <p className='text-primaryGray text-[11px] text-center'>
                Save <br /> Draft
              </p>
            </div> */}

            <div className='contributorUploadIconsMainDiv '>
              <div className='flex flex-col  mt-[1px]  gap-[8px] w-[52px] text-center '>
                {/* <img
                  src={keyword}
                  alt=''
                  className='w-[16px] h-[16px] mx-[auto] '
                /> */}{' '}
                <div>
                  <Keyword className='mx-[auto]' />
                </div>
                <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                  Keyword Manager
                </p>
              </div>
            </div>
          </div>
          <div className='pl-[206px] leading-[1] '>
            <p className='text-[11px] text-primaryGray leading-[14px]'>
              Files uploaded this week
            </p>
            <p className='text-[25px] leading-[0.9] text-pinkColor  font-medium'>
              84/250
              <span className='text-[11px] font-normal ml-[2px]'>
                File(s)
              </span>
            </p>
            <button className='bg-[#888888] text-[white] text-[10px]  font-normal leading-[1] flex items-center rounded-[20px] px-[8px] h-[17px]'>
              Files Upload Summary
            </button>
          </div>
        </div>
      </div>
      {/* images to selct */}
      {image?.length > 0 ? (
        <div>
          <div className='w-[100%] flex justify-center pb-[28px]'>
            <div className='w-w1170 justify-center flex-column gap-[10px]'>
              <div className='flex justify-start flex-wrap gap-[12px]'>
                {image?.map((card, index) => (
                  <div
                    key={card.artId}
                    className='h-[106px] w-[106px] rounded-[16px] relative'
                    onMouseEnter={() => handleMouseEnter(card.artId)}
                    onMouseLeave={() => handleMouseLeave()}
                  >
                    <div
                      className={` rounded-[16px] h-full w-full bg-no-repeat bg-center bg-cover filter brightness-[${
                        hoveredId === card.artId ? '70%' : '100%'
                      }] absolute overflow-hidden inset-0`}
                      style={{
                        backgroundImage: `url(${card?.imageMaster?.secureUrl})`,
                      }}
                    ></div>

                    {hoveredId === card.artId ||
                    checkedId === card.artId ? (
                      <>
                        <div className='absolute inset-0 flex items-center justify-center'>
                          <button
                            onClick={() => handleClick(card)}
                            className='imageHoverButtonSmall'
                          >
                            {checkedId === card.artId ? (
                              <span>Deselect</span>
                            ) : (
                              <span>Select</span>
                            )}
                          </button>
                        </div>

                        <label className='containerCheckWhite p-2'>
                          <input
                            type='checkbox'
                            checked={checkedId === card.artId}
                            onChange={() => handleClick(card)}
                          />{' '}
                          {/* {obj.width}cm x {obj.height}cm */}
                          <span className='checkmarkWhite'></span>
                        </label>
                        {/* <div className='absolute top-0 right-0 p-2'>
                          <input
                            type='checkbox'
                            className='w-6 h-6'
                            checked={checkedId === card.artId}
                            onChange={() => handleClick(card)}
                          />
                        </div> */}
                      </>
                    ) : (
                      <div></div>
                    )}
                  </div>
                ))}

                {/* test */}
                {grayBox?.slice(0, grayNum).map((card, index) => (
                  <div
                    className={
                      'h-[106px] w-[106px] rounded-[16px] bg-[#f7f7f7]'
                    }
                  ></div>
                ))}
                {/* test */}
              </div>
            </div>
          </div>

          <div className='w-[100%] text-center '>
            <div
              className='flex flex-col justify-center w-w690 h-[27.813rem] mx-[auto] bg-[#f7f7f7] rounded-[32px] h-full w-full bg-no-repeat bg-center bg-cover'
              style={{
                backgroundImage: `url(${object?.imageMaster?.secureUrl})`,
              }}
            >
              {!object?.imageMaster?.secureUrl && (
                <div>
                  <p className='text-pinkColor text-[18px] my-[auto]'>
                    Select thumbnail to view the image.
                  </p>
                  <p className='text-primaryGray text-[11px] my-[auto]'>
                    Select an item to view the image.{' '}
                    <span className='text-orangeColor text-[11px]'>
                      Learn more
                    </span>
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className='w-[100%]'>
            <div className='w-w690 mx-[auto]'>
              <div className='w-w690'>
                {/* <div className='flex flex-col pt-[30px]'>
                  <p className='text-[15px] text-primaryBlack font-medium'>
                    Select Category
                  </p>
                  <div className='flex gap-[10px] pt-[10px]'>
                    <img src={iconart} />
                    <img src={iconphotos} />
                    <img src={iconfootage} />
                    <img src={iconmusic} />
                    <img src={icontemplates} />
                  </div>
                </div> */}
                <div className='pt-[25px]'>
                  <p className='text-sm11 text-pinkColor'>
                    Your pending items will be reviewed within 5 days
                  </p>
                  <p className='text-sm11 text-primaryGray'>
                    You will be able to edit your content after it has
                    been approved
                  </p>
                </div>
                {/* table */}
                <div className='mt-[30px]'>
                  {/* <div className='h-[220px] w-full rounded-2xl bg-gradient-to-r from-[#DC4C9A] via-[#9593CD] via-[#84D49C] via-[#CCEC3B] to-[#F7941D] p-[1px]'> */}
                  <div
                    style={{
                      borderRadius: '32px!important',
                    }}
                    className='w-[100%] bg-[#ffffff] rounded-gradient-border borderRadius32px pt-[15px] pb-[24px] px-[16px] '
                  >
                    {/* test */}
                    <p className='text-[#bbbbbb] text-[11px] leading-[14px]'>
                      You have selected
                    </p>

                    <div className='flex mt-[7px]'>
                      <div className='w-[42px] flex flex-col mr-[11px]'>
                        <div className='flex w-[100%] justify-center'>
                          <div>
                            <Exclusive_Icon28 />
                          </div>
                        </div>
                        <p className=' text-[10px] text-primaryGray mt-[5px]'>
                          Exclusive
                        </p>
                      </div>
                      <div className='w-[1px] h-[52px] bg-[#efefef]'></div>
                      <div className='w-[68px] flex flex-col'>
                        <div className='flex w-[100%]  justify-center'>
                          <div>
                            <Featured_Icon28 />
                          </div>
                        </div>
                        <p className='text-center text-[10px] text-primaryGray mt-[5px]'>
                          Featured
                        </p>
                      </div>
                    </div>

                    <div className='mt-[15px]'>
                      <div
                        className='w-[64px] h-[64px] rounded-[10px] bg-cover bg-center'
                        style={{
                          backgroundImage: `url(${object?.imageMaster?.secureUrl})`,
                        }}
                      ></div>
                    </div>
                    <div className='mt-5 border-t border-b border-[#EFEFEF] w-[100%] flex '>
                      <div
                        style={{
                          width: 'fit-content',
                        }}
                        className='flex flex-col '
                      >
                        <p className='text-primaryGray text-sm12 py-[1px] font-medium leading-4  border-b border-[#EFEFEF] whitespace-no-wrap'>
                          Category:
                        </p>

                        {/* {topMatSelect > 0 && ( */}
                        <p className='text-primaryGray text-sm12 py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'>
                          Art Title:
                        </p>
                        {/* )} */}

                        {/* {bottomMatSelect > 0 && ( */}
                        <p className='text-primaryGray text-sm12 py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'>
                          Subject:
                        </p>
                        {/* )} */}

                        {/* {includeFrame && ( */}
                        <p className='text-primaryGray text-sm12 py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'>
                          Style:
                        </p>
                        {/* )} */}

                        <p className='text-primaryGray text-sm12 py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'>
                          Medium:
                        </p>
                        <p
                          style={{
                            whiteSpace: 'nowrap',
                          }}
                          className='text-primaryGray text-sm12 py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'
                        >
                          Type of Content:
                        </p>
                        <p
                          style={{
                            whiteSpace: 'nowrap',
                          }}
                          className='text-primaryGray text-sm12 py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-now-wrap'
                        >
                          Case Number:
                        </p>
                        <p
                          style={{
                            whiteSpace: 'nowrap',
                          }}
                          className='text-primaryGray text-sm12 py-[1px] font-medium leading-4  border-b border-[#EFEFEF] whitespace-no-wrap whitespace-no-wrap'
                        >
                          Commercial Usage:
                        </p>
                        <p
                          style={{
                            whiteSpace: 'nowrap',
                          }}
                          className='text-primaryGray text-sm12 py-[1px] font-medium leading-4  whitespace-no-wrap whitespace-no-wrap'
                        >
                          Date Submitted:
                        </p>
                      </div>

                      <div className='flex flex-col w-[100%] '>
                        <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                          Art
                        </p>

                        <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                          Abstract Blue Sky and Nature
                        </p>

                        <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                          Nature
                        </p>

                        <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                          Abstract
                        </p>

                        <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                          Digital
                        </p>

                        <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                          Art with Reference, Exclusive Art, Art
                          Re-Submission
                        </p>
                        <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                          ANS7459309542
                        </p>
                        <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4 border-b border-[#EFEFEF]'>
                          Web Media, Print Media, Print on Products
                        </p>
                        <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4 '>
                          05/06/2023
                        </p>
                      </div>
                    </div>
                    {/* test */}
                  </div>
                </div>
                {/* table */}
                {/* <table className='table border-[#dddddd] mt-[20px] w-[570px] h-[197px] border-separate border-spacing-0'>
                  <tbody>
                    <tr>
                      <td className='border rounded-tl-[8px] text-[13px] pl-[6px] pr-[75px] border-[#dddddd] bg-[#f7f7f7] text-primaryBlack font-medium'>
                        Image Title
                      </td>
                      <td className='border-b border-t border-r rounded-tr-[8px] text-[13px] text-primaryGray border-[#dddddd] pl-[6px]'>
                        {object?.artName}
                      </td>
                    </tr>
                    <tr>
                      <td className='border-b border-l border-r text-[13px]  pl-[6px] border-[#dddddd] bg-[#f7f7f7] text-primaryBlack font-medium'>
                        Subject
                      </td>
                      <td className='border-b border-r text-[13px] text-primaryGray border-[#dddddd] pl-[6px]'>
                        {object?.subjectMaster?.subjectName}
                      </td>
                    </tr>
                    <tr>
                      <td className='border-b border-r border-l text-[13px]  pl-[6px] border-[#dddddd] bg-[#f7f7f7] text-primaryBlack font-medium'>
                        Style
                      </td>
                      <td className='border-b border-r text-[13px] pr-[197px] text-primaryGray border-[#dddddd] pl-[6px]'>
                        {object?.styleMaster?.name}
                      </td>
                    </tr>
                    <tr>
                      <td className='border-b border-r border-l text-[13px]  pl-[6px] border-[#dddddd] bg-[#f7f7f7] text-primaryBlack font-medium'>
                        Medium
                      </td>
                      <td className='border-b border-r text-[13px] text-primaryGray border-[#dddddd] pl-[6px]'>
                        {object?.artMedium}
                      </td>
                    </tr>
                    <tr>
                      <td className='border-b border-r border-l text-[13px]  pl-[6px] border-[#dddddd] bg-[#f7f7f7] text-primaryBlack font-medium'>
                        Usage
                      </td>
                      <td className='border-b border-r text-[13px] text-primaryGray border-[#dddddd] pl-[6px]'>
                       
                      </td>
                    </tr>
                    <tr>
                      <td className='border-b border-r border-l text-[13px]  pl-[6px] border-[#dddddd] bg-[#f7f7f7] text-primaryBlack font-medium'>
                        Type of Content
                      </td>
                      <td className='border-b border-r text-[13px] text-primaryGray border-[#dddddd] pl-[6px]'>
                        {object?.typeOfContent.map((obj) => (
                          <div>
                            <span>{obj}</span>{' '}
                          </div>
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <td className='border-r border-l border-b rounded-bl-[8px] text-[13px]  pl-[6px] border-[#dddddd] bg-[#f7f7f7] text-primaryBlack font-medium'>
                        Date Submitted
                      </td>
                      <td className='border-b border-r rounded-br-[8px] text-[13px] text-primaryGray border-[#dddddd] pl-[6px]'>
                        {datePipeReact(object?.submittedDate)}
                      </td>
                    </tr>
                  </tbody>
                </table> */}

                <div className='pt-[22px]'>
                  <p className='text-[18px] text-primaryBlack font-medium'>
                    Art Description
                  </p>
                  <p className='text-[13px] text-primaryGray leading-[18px] mt-[11px] max-w-[500px] w-[100%]'>
                    {object?.description}
                  </p>
                </div>

                {/* test */}

                {/* release test */}
                <div className='pt-[25px]'>
                  <p className='text-[18px] text-primaryBlack font-medium text-primaryBlack mb-[11px]'>
                    Release(s) Attached
                  </p>
                  <p className='text-[11px] text-primaryGray leading-[13px] mb-[15px]'>
                    The release form is a legal document signed to
                    protect copyrights. It could be a work of any{' '}
                    <br />
                    type of art: Music, Visual/Graphic Arts,
                    Literature, Performing Arts, and Decorative Arts.
                  </p>
                  <p className='text-[11px] text-[#bbbbbb]'>
                    Acceptable File: .PDF/JPEG, 300dpi, 4 MB Maximum
                  </p>
                  <div></div>
                  {/* {tempRelese.length > 0 && ( */}
                  <div className='text-[0.750rem] border-t border-b border-t-[2px] border-b-[1px] border-t-[#efefef] border-b-[#efefef] '>
                    {/* {tempRelese.map((obj, index) => ( */}
                    <div
                      // key={index}
                      className={`flex justify-between h-[48px] items-center border-b border-[#efefef]`}
                    >
                      <div className='flex gap-[8px] items-center'>
                        {/* <input
                          type='checkbox'
                         
                        /> */}
                        <div
                          style={{
                            backgroundImage: `url(${object?.imageMaster?.secureUrl})`,
                          }}
                          className='w-[32px] h-[32px] bg-center bg-cover rounded-[8px]'
                        ></div>
                        <div>
                          <p className='text-sm12 leading-[13px] font-medium text-primaryGray'>
                            {/* {obj.cards.name} */}Sky with blue and
                            pink colours.pdf
                          </p>
                          <p className='text-[11px] leading-[13px]  text-primaryGray'>
                            File size: 520 KB
                            {/* {formatFileSize(obj.cards.size)} */}
                          </p>
                        </div>
                      </div>
                      <div className='flex gap-[1.188rem]'>
                        <div className='pt-[0.188rem]'>
                          {/* <img src={view} alt='' /> */}
                          <ViewIcon />
                        </div>
                        <div>
                          {/* <img src={edit} alt='' /> */}
                          <EditIcon />
                        </div>
                        <div>
                          {/* <img src={deletee} alt='' /> */}
                          <DeleteIcon />
                        </div>
                      </div>
                    </div>
                    <div
                      // key={index}
                      className={`flex justify-between h-[48px] items-center border-b border-[#efefef]`}
                    >
                      <div className='flex gap-[8px] items-center'>
                        {/* <input
                          type='checkbox'
                          
                        /> */}
                        <div
                          style={{
                            backgroundImage: `url(${object?.imageMaster?.secureUrl})`,
                          }}
                          className='w-[32px] h-[32px] bg-center bg-cover rounded-[8px]'
                        ></div>
                        <div>
                          <p className='text-sm12 leading-[13px] font-medium text-primaryGray'>
                            {/* {obj.cards.name} */}Sky with blue and
                            pink colours.pdf
                          </p>
                          <p className='text-[11px] leading-[13px]  text-primaryGray'>
                            File size: 520 KB
                            {/* {formatFileSize(obj.cards.size)} */}
                          </p>
                        </div>
                      </div>
                      <div className='flex gap-[1.188rem]'>
                        <div className='pt-[0.188rem]'>
                          {/* <img src={view} alt='' /> */}
                          <ViewIcon />
                        </div>
                        <div>
                          {/* <img src={edit} alt='' /> */}
                          <EditIcon />
                        </div>
                        <div>
                          {/* <img src={deletee} alt='' /> */}
                          <DeleteIcon />
                        </div>
                      </div>
                    </div>
                    <div
                      // key={index}
                      className={`flex justify-between h-[48px] items-center border-b border-[#efefef]`}
                    >
                      <div className='flex gap-[8px] items-center'>
                        {/* <input type='checkbox' /> */}
                        <div
                          style={{
                            backgroundImage: `url(${object?.imageMaster?.secureUrl})`,
                          }}
                          className='w-[32px] h-[32px] bg-center bg-cover rounded-[8px]'
                        ></div>
                        <div>
                          <p className='text-sm12 leading-[13px] font-medium text-primaryGray'>
                            {/* {obj.cards.name} */}Sky with blue and
                            pink colours.pdf
                          </p>
                          <p className='text-[11px] leading-[13px]  text-primaryGray'>
                            File size: 520 KB
                            {/* {formatFileSize(obj.cards.size)} */}
                          </p>
                        </div>
                      </div>
                      <div className='flex gap-[1.188rem]'>
                        <div className='pt-[0.188rem]'>
                          {/* <img src={view} alt='' /> */}
                          <ViewIcon />
                        </div>
                        <div>
                          {/* <img src={edit} alt='' /> */}
                          <EditIcon />
                        </div>
                        <div>
                          {/* <img src={deletee} alt='' /> */}
                          <DeleteIcon />
                        </div>
                      </div>
                    </div>
                    {/* ))} */}
                  </div>
                  {/* )} */}
                </div>

                <div className='pt-[22px]'>
                  <p className='text-[18px] text-primaryBlack font-medium mb-[11px]'>
                    Keywords Added
                  </p>
                  <div className='flex flex-wrap gap-[4px]'>
                    {object?.keywords.map((keyword) => (
                      <div className='text-[11px] flex items-center text-primaryGray border border-[#dddddd] rounded-[13px] px-[8px] h-[28px]'>
                        <span>{keyword}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='text-center pt-[30px] pb-[30px]'>
                <button className='blackBtn'>
                  Delete Selected Image
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='text-center pt-[8px]'>
          <p className='text-pinkColor text-[18px]'>
            Upload your art files
          </p>
          <p className='text-primaryGray text-[12px]'>
            Get started by uploading your art files to submit
          </p>
          <button
            onClick={() => {
              // console.log('Upload');
              dispatch(setpath2('/ Upload Files'));
              dispatch(setNestedTabValueUpload('2.1'));
            }}
            className='blackBtn mt-[20px]'
          >
            Upload Files
          </button>
        </div>
      )}
    </>
  );
};

export default RejectedFiles;
