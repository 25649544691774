import pro from '../../assets/images/accountSettings/pro.png';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { httpClient } from '../../axios';
import { toast } from 'react-toastify';

import { ReactComponent as ProIcon } from '../../assets/images/art-details/Icon_Pro.svg';

const Contributor_SocialMedia = () => {
  const userId = useSelector((state) => state.auth.userId);

  const [userDetail, setUserDetail] = useState({});

  const getUserRecord = async () => {
    try {
      const res = await httpClient.get(
        `/user_master/getUserRecord/${userId}`
      );
      setUserDetail(res.data);
      console.log(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleInputChangeNested = (topName, lowName, value) => {
    setUserDetail({
      ...userDetail,
      [topName]: { ...userDetail[topName], [lowName]: value },
    });
  };

  // useEffect(() => {
  //   console.log(userDetail.socialMedia);
  // }, [userDetail]);

  const updateUserMaster = async () => {
    try {
      const res = await httpClient.put(
        '/user_master/update',
        userDetail
      );
      toast.success('Successfully Updated User Details');
      console.log(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getUserRecord();
  }, []);

  return (
    <>
      <div className='flex justify-between'>
        <div>
          <p className='text-[25px] leading-[1] font-medium text-primaryBlack'>
            Social Media
          </p>
          <p className='text-sm11 text-pinkColor'>
            All fields are required.
          </p>
        </div>
        <div>
          <div className='border border-[#eaeaea] rounded-[7px] h-[41px] flex items-center p-[6px]'>
            <div className='flex flex-col gap-[3px]'>
              <ProIcon />
              <p className='text-[11px] leading-[1] text-primaryGray'>
                Member since 5 July 2020
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className=''>
        <div className='relative mt-[12px] w-[100%]'>
          <input
            type='text'
            // placeholder='Shaikh'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            placeholder='@'
            name='twitterLink'
            value={userDetail?.socialMedia?.twitterLink || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'socialMedia',
                e.target.name,
                e.target.value
              )
            }
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            Twitter
          </p>
        </div>

        {/* <p className='text-[12px] text-[#757575]'>Twitter</p>
        <input
          type='text'
          className='regInput my-0 text-[#333333]'
          placeholder='@'
          name='twitterLink'
          value={userDetail?.socialMedia?.twitterLink || ''}
          onChange={(e) =>
            handleInputChangeNested(
              'socialMedia',
              e.target.name,
              e.target.value
            )
          }
        /> */}

        <div className='relative mt-[12px] w-[100%]'>
          <input
            type='text'
            // placeholder='Shaikh'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            placeholder='http://linkedin.com/in/'
            name='linkedinLink'
            value={userDetail?.socialMedia?.linkedinLink || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'socialMedia',
                e.target.name,
                e.target.value
              )
            }
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            LinkedIn
          </p>
        </div>

        {/* <p className='text-[12px] text-[#757575] mt-3'>LinkedIn</p>
        <input
          type='text'
          className='regInput my-0 text-[#333333]'
          placeholder='http://linkedin.com/in/'
          name='linkedinLink'
          value={userDetail?.socialMedia?.linkedinLink || ''}
          onChange={(e) =>
            handleInputChangeNested(
              'socialMedia',
              e.target.name,
              e.target.value
            )
          }
        /> */}

        <div className='relative mt-[12px] w-[100%]'>
          <input
            type='text'
            // placeholder='Shaikh'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            placeholder='http://facebook.com/'
            name='facebookLink'
            value={userDetail?.socialMedia?.facebookLink || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'socialMedia',
                e.target.name,
                e.target.value
              )
            }
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            Facebook
          </p>
        </div>

        {/* <p className='text-[12px] text-[#757575] mt-3'>Facebook</p>
        <input
          type='text'
          className='regInput my-0 text-[#333333]'
          placeholder='http://facebook.com/'
          name='facebookLink'
          value={userDetail?.socialMedia?.facebookLink || ''}
          onChange={(e) =>
            handleInputChangeNested(
              'socialMedia',
              e.target.name,
              e.target.value
            )
          }
        /> */}

        <div className='relative mt-[12px] w-[100%]'>
          <input
            type='text'
            // placeholder='Shaikh'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            placeholder='http://plus.google.com/'
            name='googleLink'
            value={userDetail?.socialMedia?.googleLink || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'socialMedia',
                e.target.name,
                e.target.value
              )
            }
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            Google+
          </p>
        </div>

        {/* <p className='text-[12px] text-[#757575] mt-3'>Google+</p>
        <input
          type='text'
          className='regInput my-0 text-[#333333]'
          placeholder='http://plus.google.com/'
          name='googleLink'
          value={userDetail?.socialMedia?.googleLink || ''}
          onChange={(e) =>
            handleInputChangeNested(
              'socialMedia',
              e.target.name,
              e.target.value
            )
          }
        /> */}

        <div className='relative mt-[12px] w-[100%]'>
          <input
            type='text'
            // placeholder='Shaikh'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            placeholder='http://pinterest.com/'
            name='pinterestLink'
            value={userDetail?.socialMedia?.pinterestLink || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'socialMedia',
                e.target.name,
                e.target.value
              )
            }
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            Pinterest
          </p>
        </div>

        {/* <p className='text-[12px] text-[#757575] mt-3'>Pinterest</p>
        <input
          type='text'
          className='regInput my-0 text-[#333333]'
          placeholder='http://pinterest.com/'
          name='pinterestLink'
          value={userDetail?.socialMedia?.pinterestLink || ''}
          onChange={(e) =>
            handleInputChangeNested(
              'socialMedia',
              e.target.name,
              e.target.value
            )
          }
        /> */}
      </div>

      <div className='flex gap-5 justify-center mt-[30px] mb-[509px]'>
        <button
          onClick={updateUserMaster}
          className='blackBtn text-[14px] text-medium'
        >
          Save
        </button>
        <button className=' outlineBtn40h'>Cancel</button>
      </div>
    </>
  );
};

export default Contributor_SocialMedia;
