// import contribanner from '../../../../src/assets/images/contributor/contri-banner.png';
import contribanner from '../../../../src/assets/images/contributor/Bannernew.jpg';
// import { ReactComponent as BannerUp } from '../../../../src/assets/images/contributor/svg/bannerUp.svg';
import { ReactComponent as BannerUp } from '../../../../src/assets/images/contributor/add_details/Icon_BannerClose.svg';
import { ReactComponent as BannerDown } from '../../../../src/assets/images/contributor/add_details/Icon_BannerOpen.svg';

import { useState } from 'react';

const ContriBanner = () => {
  const [showBanner, setshowBanner] = useState(true);
  return (
    <div
      style={{
        backgroundImage: `url(${contribanner})`,
      }}
      className={`relative w-[100%]  bg-cover flex justify-center ${
        showBanner ? 'h-[240px]' : ''
      }`}
    >
      <div
        onClick={() => setshowBanner(!showBanner)}
        className={`absolute cursor-pointer  ${
          showBanner ? 'bottom-[0px]' : ' '
        }`}
      >
        {showBanner ? (
          <div>
            <BannerUp />
          </div>
        ) : (
          <div>
            <BannerDown />
          </div>
        )}
      </div>
    </div>

    // <div
    //   className='h-[100%] max-h-[15.5rem] flex items-center justify-center w-[100%]'
    //   style={{
    //     backgroundImage: `url(${contribanner}) !important`,
    //     backgroundSize: 'cover !important',
    //   }}
    // >

    //  </div>
    // <div>
    //       <p className='sm:text-[12px] md:text-[20px] lg:text-[25px] leading-[29px]   w-[100%] text-[#ffffff] text-center'>
    //         Earn 20% Customer Referral Bonus
    //       </p>
    //       <p className='text-[7px] sm:text-[8px] md:text-[8px] lg:text-[13px] text-[#ffffff] w-[100%] text-center leading-tight'>
    //         Refer a new customer to Artnstock to earn 20% of their first
    //         purchase <br /> instead of the usual 10% referral bonus.
    //       </p>
    //     </div>
  );
};

export default ContriBanner;
