// import React from 'react';
// import Why from '../../assets/images/SellOnArtnstock/Why.png';
// import ArtIcon from '../../assets/images/footer/ArtIcon.png';
// import FootageIcon from '../../assets/images/footer/FootageIcon.png';
// import MusicIcon from '../../assets/images/footer/MusicIcon.png';
// import PhotosIcon from '../../assets/images/footer/PhotosIcon.png';
// import ProductsIcon from '../../assets/images/footer/ProductsIcon.png';
// import TemplatesIcon from '../../assets/images/footer/TemplatesIcon.png';
// import ICreate from '../../assets/images/Royalty/ICreate.jpg';
// import IUpload from '../../assets/images/Royalty/IUpload.jpg';
// import IEarn from '../../assets/images/Royalty/IEarn.jpg';
// import IRefer from '../../assets/images/Royalty/IRefer.jpg';
// import ArtHomeFaq from '../faq/ArtHomeFaq';
// import SellOnArtnstock_HomeBanner from '../../assets/images/SellOnArtnstock/SellOnArtnstock_HomeBanner.png';
// import Offer from '../../assets/images/SellOnArtnstock/Offer.png';
// import Divider from '../../assets/images/SellOnArtnstock/Divider.png';
// import Wallpaper from '../../assets/images/SellOnArtnstock/Wallpaper.png';
// import Copyright from '../../assets/images/SellOnArtnstock/Copyright.png';
// import { httpClient } from '../../axios';
// import { useEffect, useState } from 'react';
// import Footer from '../footer/Footer';
// // import ArtHomeFaq from '../faq/ArtHomeFaq';

// const earn = [
//   {
//     img: `${ArtIcon}`,
//     title: 'Art',
//     description:
//       ' For the first year after a referred contributor signs up, you will earn 10% of the final sale price each time one of their videos is downloaded. For the first two years after',
//   },
//   {
//     img: `${PhotosIcon}`,
//     title: 'Photos',
//     description:
//       ' For the first year after a referred contributor signs up, you will earn 10% of the final sale price each time one of their videos is downloaded. For the first two years after',
//   },
//   {
//     img: `${FootageIcon}`,
//     title: 'Footage',
//     description:
//       ' For the first year after a referred contributor signs up, you will earn 10% of the final sale price each time one of their videos is downloaded. For the first two years after',
//   },
//   {
//     img: `${MusicIcon}`,
//     title: 'Music',
//     description:
//       ' For the first year after a referred contributor signs up, you will earn 10% of the final sale price each time one of their videos is downloaded. For the first two years after',
//   },
//   {
//     img: `${TemplatesIcon}`,
//     title: 'Templates',
//     description:
//       ' For the first year after a referred contributor signs up, you will earn 10% of the final sale price each time one of their videos is downloaded. For the first two years after',
//   },
//   {
//     img: `${ProductsIcon}`,
//     title: 'Products',
//     description:
//       ' For the first year after a referred contributor signs up, you will earn 10% of the final sale price each time one of their videos is downloaded. For the first two years after',
//   },
// ];

// const SellOnArtnstock_Home = () => {
//   const [faq, setfaq] = useState([]);
//   const getArtWiseFaqList = () => {
//     httpClient
//       .get(`/frequently_asked_master/getTypeWiseFaq/art`)
//       .then((res) => {
//         setfaq(res.data);
//         // console.log(res);
//       });
//   };

//   useEffect(() => {
//     getArtWiseFaqList();
//   }, []);

//   return (
//     <>
//       <div className='wrapper'>
//         <main>
//           <div
//             className='hero '
//             style={{
//               backgroundImage: `url(${SellOnArtnstock_HomeBanner})`,
//               backgroundRepeat: 'no-repeat',
//               backgroundSize: 'cover',
//             }}
//           >
//             <div className='flex justify-between py-[110px] mx-[100px]'>
//               <div>
//                 <p className='text-[75px] leading-[1] font-medium text-[#333333]'>
//                   Art{' '}
//                   <span className='font-thin text-[40px]'>
//                     [Wall Skin]
//                   </span>
//                 </p>
//                 <p className='text-[38px] mt-[20px] text-medium text-[#333333] leading-[41px]'>
//                   Creatively tailored art in all <br /> forms, media
//                   and colours
//                 </p>
//                 <p className='mt-[17px] mb-[11px]'>
//                   <button className='blackBtn'>
//                     Discover Collections
//                   </button>
//                 </p>
//                 <p className='text-[11px] text-[#333333] font-medium'>
//                   <img src={Copyright} className='inline' alt='' />{' '}
//                   Copyright 2022. Artnstock Private Limited.
//                 </p>
//                 <p className='text-[11px] text-[#333333]'>
//                   All content included in the website (including, but
//                   not limited to, logos, images, <br /> photo,
//                   designs, graphics and text) is the property of
//                   Artnstock Pvt. Ltd.
//                 </p>
//               </div>
//               <div>
//                 <img src={Offer} alt='' />
//                 <p className='leading-[1.2] mt-[10px] text-[12px] '>
//                   <span className='font-medium'>
//                     An exclusive welcome offer on your first order
//                   </span>{' '}
//                   <br />{' '}
//                   <span>
//                     *Offer is available to new customers only.
//                   </span>
//                 </p>
//                 <p>
//                   <button className='blackBtn mt-2 bg-[#a4e154] text-[#333333] text-[12px]'>
//                     Shop with 20% Off
//                   </button>
//                 </p>
//                 <p className='my-3'>
//                   <img src={Divider} alt='' />
//                 </p>
//                 <p>
//                   <img src={Wallpaper} alt='' />
//                 </p>
//               </div>
//             </div>
//             <p className='text-[#333333] text-[15px] text-end pr-3 pb-3'>
//               Wall Skin by{' '}
//               <span className='font-medium'>Artnstock</span>
//             </p>
//           </div>

//           <div className='text-center w-w1168 mx-auto'>
//             <p className='text-[#757575] text-[12px] text-center mt-[30px] mb-5 leading-[0]'>
//               Artnstock / Sell on Artnstock
//             </p>
//             <p className='text-[38px] leading-[1.1] font-medium text-[#333333]'>
//               Lucrative approch with your Art, Photos, <br /> Music,
//               Footage and Templates
//             </p>
//             <p className='text-[14px] mt-4 mb-[30px] text-[#757575]'>
//               Avail our exclusive multiple products and sizes to fit
//               any decor style or budget. Our Creative collections will
//               artistically sensitise your visual projects <br />
//               and would be a perfect solution for anyone who needs
//               high-quality art at affordable prices.
//             </p>
//             <p className=''>
//               <button className='blackBtn mb-[30px]'>
//                 Become a Contributor
//               </button>
//             </p>
//             <div className='hrLine'></div>
//             <img src={Why} className=' w-[1920px]' alt='' />
//             <p className='text-[14px] text-[#757575]'>
//               Join us! We are a thriving artist community dedicated to
//               supporting one another <br /> with inVougue contributors
//               which matches our mission <br />{' '}
//               <span className='font-medium text-[#333333]'>
//                 "Passion is Vision"
//               </span>
//             </p>
//           </div>

//           <div className='hrLine w-w1168 bg-[#efefef]'></div>
//           <div className=' mx-auto w-w930'>
//             <p className='text-center mt-[90px] text-[38px] font-medium text-[#333333]'>
//               How much you will earn
//             </p>
//             <p className='text-center mt-5 mb-2 leading-[1.2]'>
//               On-Demand
//             </p>

//             {/* Table */}

//             <div className='flex justify-center'>
//               <table className='table mx-auto border-spacing-0 border-separate rounded-2xl'>
//                 <thead>
//                   <tr className='text-center text-[13px] border-t-[1] py-[12px] border-[#dddddd] rounded-tl-[10px]'>
//                     <th
//                       className='border px-10 rounded-tl-2xl py-[6px] pl-[6px] font-medium text-[#333333] bg-[#ececec] border-[#dddddd]'
//                       rowSpan={2}
//                     >
//                       Levels
//                     </th>
//                     <th
//                       className='border px-[37px] font-medium text-[#333333] bg-[#f3f3f3] border-[#dddddd]'
//                       rowSpan={2}
//                     >
//                       Royalties
//                     </th>
//                     <th
//                       className='border px-[26px] font-medium text-[#333333] bg-[#ececec] border-[#dddddd]'
//                       colSpan={2}
//                     >
//                       Photos
//                     </th>
//                     <th
//                       className='border px-[26px] font-medium text-[#333333] bg-[#ececec] border-[#dddddd]'
//                       colSpan={2}
//                     >
//                       Footage
//                     </th>
//                     <th
//                       className='border px-[30px] font-medium text-[#333333] bg-[#ececec] border-[#dddddd]'
//                       colSpan={2}
//                     >
//                       Music
//                     </th>
//                     <th
//                       className='border px-[21px] font-medium text-[#333333] bg-[#ececec] border-[#dddddd]'
//                       colSpan={2}
//                     >
//                       Templates
//                     </th>
//                     <th
//                       className='border rounded-tr-2xl px-[37px] font-medium text-[#333333] bg-[#f3f3f3] border-[#dddddd]'
//                       rowSpan={2}
//                     >
//                       Downloads
//                     </th>
//                   </tr>
//                   <tr className='text-[13px]'>
//                     <th className='border px-[26px] font-medium text-[#333333] bg-[#f3f3f3] border-[#dddddd]'>
//                       Standard License
//                     </th>
//                     <th className='border px-[26px] font-medium text-[#333333] bg-[#f3f3f3] border-[#dddddd]'>
//                       Enhanced License
//                     </th>
//                     <th className='border px-[30px] font-medium text-[#333333] bg-[#f3f3f3] border-[#dddddd]'>
//                       Standard License
//                     </th>
//                     <th className='border px-[21px] font-medium text-[#333333] bg-[#f3f3f3] border-[#dddddd]'>
//                       Enhanced License
//                     </th>
//                     <th className='border px-[37px] font-medium text-[#333333] bg-[#f3f3f3] border-[#dddddd]'>
//                       Standard License
//                     </th>
//                     <th className='border px-[26px] font-medium text-[#333333] bg-[#f3f3f3] border-[#dddddd]'>
//                       Enhanced License
//                     </th>
//                     <th className='border px-[26px] font-medium text-[#333333] bg-[#f3f3f3] border-[#dddddd]'>
//                       Standard License
//                     </th>
//                     <th className='border px-[30px] font-medium text-[#333333] bg-[#f3f3f3] border-[#dddddd]'>
//                       Enhanced License
//                     </th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td className='border font-medium text-[#333333] text-[13px] py-[6px] pl-[6px] border-[#dddddd]'>
//                       Level 1
//                     </td>
//                     <td className='border text-center text-primaryGray border-[#dddddd]'>
//                       30%
//                     </td>
//                     <td className='border text-center text-primaryGray border-[#dddddd]'>
//                       $0.25
//                     </td>
//                     <td className='border text-center text-primaryGray border-[#dddddd]'>
//                       $0.25
//                     </td>
//                     <td className='border text-center text-primaryGray border-[#dddddd]'>
//                       $0.25
//                     </td>
//                     <td className='border text-center text-primaryGray border-[#dddddd]'>
//                       $0.25
//                     </td>
//                     <td className='border text-center text-primaryGray border-[#dddddd]'>
//                       $0.35
//                     </td>
//                     <td className='border text-center text-primaryGray border-[#dddddd]'>
//                       $0.35
//                     </td>
//                     <td className='border text-center text-primaryGray border-[#dddddd]'>
//                       $0.35
//                     </td>
//                     <td className='border text-center text-primaryGray border-[#dddddd]'>
//                       $0.35
//                     </td>
//                     <td className='border text-center text-primaryGray border-[#dddddd]'>
//                       Up to 499
//                     </td>
//                   </tr>
//                   <tr>
//                     <td className='border font-medium text-[#333333] text-[13px] py-[6px] pl-[6px] bg-[#f7f7f7] border-[#dddddd]'>
//                       Level 2
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       32%
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.27
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.27
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.27
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.27
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.37
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.37
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.37
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.37
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       500 - 4,999
//                     </td>
//                   </tr>
//                   <tr>
//                     <td className='border font-medium text-[#333333] text-[13px] py-[6px] pl-[6px] bg-[#f7f7f7] border-[#dddddd]'>
//                       Level 3
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       34%
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.29
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.29
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.29
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.29
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.39
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.39
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.39
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.39
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       5,000-24,999
//                     </td>
//                   </tr>
//                   <tr>
//                     <td className='border font-medium text-[#333333] text-[13px] py-[6px] pl-[6px] bg-[#f7f7f7] border-[#dddddd]'>
//                       Level 4
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       36%
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.31
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.31
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.31
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.31
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.41
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.41
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.41
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.41
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       25,000-149,999 downloads
//                     </td>
//                   </tr>
//                   <tr>
//                     <td className='border font-medium text-[#333333] text-[13px] py-[6px] pl-[6px] bg-[#f7f7f7] border-[#dddddd] rounded-bl-2xl'>
//                       Level 5
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       38%
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.33
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.33
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.33
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.33
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.43
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.43
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.43
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.43
//                     </td>
//                     <td className='border rounded-br-2xl text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       150,000+
//                     </td>
//                   </tr>
//                 </tbody>
//               </table>
//             </div>

//             <p className='text-center mt-8 mb-2 leading-[1.2]'>
//               Subscription
//             </p>

//             {/* Table */}

//             <div className='flex justify-center'>
//               <table className='table mx-auto border-spacing-0 border-separate rounded-2xl'>
//                 <thead>
//                   <tr className='text-center text-[13px] border-t-[1] py-[12px] border-[#dddddd] rounded-tl-[10px]'>
//                     <th
//                       className='border px-10 rounded-tl-2xl py-[6px] pl-[6px] font-medium text-[#333333] bg-[#ececec] border-[#dddddd]'
//                       rowSpan={2}
//                     >
//                       Levels
//                     </th>
//                     <th
//                       className='border px-[37px] font-medium text-[#333333] bg-[#f3f3f3] border-[#dddddd]'
//                       rowSpan={2}
//                     >
//                       Royalties
//                     </th>
//                     <th
//                       className='border px-[26px] font-medium text-[#333333] bg-[#ececec] border-[#dddddd]'
//                       colSpan={2}
//                     >
//                       Photos
//                     </th>
//                     <th
//                       className='border px-[26px] font-medium text-[#333333] bg-[#ececec] border-[#dddddd]'
//                       colSpan={2}
//                     >
//                       Footage
//                     </th>
//                     <th
//                       className='border px-[30px] font-medium text-[#333333] bg-[#ececec] border-[#dddddd]'
//                       colSpan={2}
//                     >
//                       Music
//                     </th>
//                     <th
//                       className='border px-[21px] font-medium text-[#333333] bg-[#ececec] border-[#dddddd]'
//                       colSpan={2}
//                     >
//                       Templates
//                     </th>
//                     <th
//                       className='border rounded-tr-2xl px-[37px] font-medium text-[#333333] bg-[#f3f3f3] border-[#dddddd]'
//                       rowSpan={2}
//                     >
//                       Downloads
//                     </th>
//                   </tr>
//                   <tr className='text-[13px]'>
//                     <th className='border px-[26px] font-medium text-[#333333] bg-[#f3f3f3] border-[#dddddd]'>
//                       Standard License
//                     </th>
//                     <th className='border px-[26px] font-medium text-[#333333] bg-[#f3f3f3] border-[#dddddd]'>
//                       Enhanced License
//                     </th>
//                     <th className='border px-[30px] font-medium text-[#333333] bg-[#f3f3f3] border-[#dddddd]'>
//                       Standard License
//                     </th>
//                     <th className='border px-[21px] font-medium text-[#333333] bg-[#f3f3f3] border-[#dddddd]'>
//                       Enhanced License
//                     </th>
//                     <th className='border px-[37px] font-medium text-[#333333] bg-[#f3f3f3] border-[#dddddd]'>
//                       Standard License
//                     </th>
//                     <th className='border px-[26px] font-medium text-[#333333] bg-[#f3f3f3] border-[#dddddd]'>
//                       Enhanced License
//                     </th>
//                     <th className='border px-[26px] font-medium text-[#333333] bg-[#f3f3f3] border-[#dddddd]'>
//                       Standard License
//                     </th>
//                     <th className='border px-[30px] font-medium text-[#333333] bg-[#f3f3f3] border-[#dddddd]'>
//                       Enhanced License
//                     </th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr>
//                     <td className='border font-medium text-[#333333] text-[13px] py-[6px] pl-[6px] border-[#dddddd]'>
//                       Level 1
//                     </td>
//                     <td className='border text-center text-primaryGray border-[#dddddd]'>
//                       30%
//                     </td>
//                     <td className='border text-center text-primaryGray border-[#dddddd]'>
//                       $0.25
//                     </td>
//                     <td className='border text-center text-primaryGray border-[#dddddd]'>
//                       $0.25
//                     </td>
//                     <td className='border text-center text-primaryGray border-[#dddddd]'>
//                       $0.25
//                     </td>
//                     <td className='border text-center text-primaryGray border-[#dddddd]'>
//                       $0.25
//                     </td>
//                     <td className='border text-center text-primaryGray border-[#dddddd]'>
//                       $0.35
//                     </td>
//                     <td className='border text-center text-primaryGray border-[#dddddd]'>
//                       $0.35
//                     </td>
//                     <td className='border text-center text-primaryGray border-[#dddddd]'>
//                       $0.35
//                     </td>
//                     <td className='border text-center text-primaryGray border-[#dddddd]'>
//                       $0.35
//                     </td>
//                     <td className='border text-center text-primaryGray border-[#dddddd]'>
//                       Up to 499
//                     </td>
//                   </tr>
//                   <tr>
//                     <td className='border font-medium text-[#333333] text-[13px] py-[6px] pl-[6px] bg-[#f7f7f7] border-[#dddddd]'>
//                       Level 2
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       32%
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.27
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.27
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.27
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.27
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.37
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.37
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.37
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.37
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       500 - 4,999
//                     </td>
//                   </tr>
//                   <tr>
//                     <td className='border font-medium text-[#333333] text-[13px] py-[6px] pl-[6px] bg-[#f7f7f7] border-[#dddddd]'>
//                       Level 3
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       34%
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.29
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.29
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.29
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.29
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.39
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.39
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.39
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.39
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       5,000-24,999
//                     </td>
//                   </tr>
//                   <tr>
//                     <td className='border font-medium text-[#333333] text-[13px] py-[6px] pl-[6px] bg-[#f7f7f7] border-[#dddddd]'>
//                       Level 4
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       36%
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.31
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.31
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.31
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.31
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.41
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.41
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.41
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.41
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       25,000-149,999 downloads
//                     </td>
//                   </tr>
//                   <tr>
//                     <td className='border font-medium text-[#333333] text-[13px] py-[6px] pl-[6px] bg-[#f7f7f7] border-[#dddddd] rounded-bl-2xl'>
//                       Level 5
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       38%
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.33
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.33
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.33
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.33
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.43
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.43
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.43
//                     </td>
//                     <td className='border text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       $ 0.43
//                     </td>
//                     <td className='border rounded-br-2xl text-center text-primaryGray bg-[#ffffff] border-[#dddddd]'>
//                       150,000+
//                     </td>
//                   </tr>
//                 </tbody>
//               </table>
//             </div>

//             <ul className='list-disc list-outside pl-3.5 mt-6 text-[#757575]'>
//               <li>
//                 New levels are based on the number of times your
//                 content is licensed
//               </li>
//               <li>
//                 Contributors reset to level 1 for Photos, Footage,
//                 Music and templates every year on January 1st
//               </li>
//               <li>
//                 Each file downloaded using On-Demand Downloads counts
//                 as download. A file purchased via subscription plan
//                 counts as one-third download; Le, 3 files downloaded
//                 via subscription plan count as 1 download.
//               </li>
//               <li>
//                 {' '}
//                 If a Photos, Footage, Music and templates is purchased
//                 with On-Demand downloads under a Standard or Enhanced
//                 License, your royalty rate depends solely upon your
//                 Contributor's Level.
//               </li>
//               <li>
//                 If a Photos, Footage, Music and templates is purchased
//                 with any of our Subscription Plans under a Standard or
//                 Enhanced License, your royalty rate depends solely
//                 upon
//               </li>
//               <li>
//                 your Contributor's Level Royalties for files
//                 downloaded under the Program's are aggregated and paid
//                 start of the month,
//               </li>
//               <li>
//                 Royalty calculations are based on the actual amount
//                 paid by the buyer not on a "standard" value of a file.
//                 If a Buyer gets a discount on the purchase, the
//                 royalties received by the Contributor for the file
//                 will be based on the amount paid by the Buyer,
//                 incorporating the discount, and not the "standard"
//                 value of the file.
//               </li>
//             </ul>
//           </div>

//           <div className='w-w1168 mx-auto'>
//             <div className='hrLine w-[100%] bg-[#efefef]'></div>
//             <p className='text-[38px] font-medium text-[#333333] text-center'>
//               Referral Program
//             </p>
//             <div className='flex gap-[30px] mt-3'>
//               <div className='w-[570px] h-[530px] bg-[#f5f5f7] rounded-2xl'></div>
//               <div className=' flex justify-center'>
//                 <div className=''>
//                   {earn.map((item) => {
//                     return (
//                       <div className='flex gap-[17px] w-[570px] py-[10px]'>
//                         <img src={item.img} alt='' />
//                         <p>
//                           <p className='text-[18px]'>{item.title}</p>
//                           <p className='text-[13px] text-[#757575] leading-[1.3]'>
//                             {item.description}
//                           </p>
//                         </p>
//                       </div>
//                     );
//                   })}
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div>
//             <div className='hrLine w-[100%] bg-[#efefef]'></div>
//             <p className='text-[38px] font-medium text-[#333333] text-center'>
//               How it works
//             </p>
//             <p className='text-[#757575] text-center text-[14px]'>
//               To become a Artnstock contributor, signup and submit
//               images for reviewing. On approval, start uploading files
//               to collections, <br /> add the right keywords and upload
//               on the website.
//             </p>
//             <div className='flex gap-4 justify-center mt-[24px] mx-auto w-w1168'>
//               <div className='border border-[#EFEFEF] w-[280px] h-[280px] p-[50px] rounded-2xl'>
//                 <div className='text-center'>
//                   <img
//                     src={ICreate}
//                     alt=''
//                     className='w-[72px] mx-auto'
//                   />
//                   <div className='mt-4'>
//                     <p className='font-medium text-[18px]'>Create</p>
//                     <p className='text-[12px] text-[#757575]'>
//                       Produce exclusive Art, Photos, Footage, Music
//                       and Templates for customers to buy
//                     </p>
//                   </div>
//                 </div>
//               </div>
//               <div className='border border-[#EFEFEF] w-[280px] h-[280px] p-[50px] rounded-2xl'>
//                 <div className='text-center'>
//                   <img
//                     src={IUpload}
//                     alt=''
//                     className='w-[72px] mx-auto'
//                   />
//                   <div className='mt-4'>
//                     <p className='font-medium text-[18px]'>Submit</p>
//                     <p className='text-[12px] text-[#757575]'>
//                       Synchronise your content with our easy-to-use
//                       platform, and spike your success
//                     </p>
//                   </div>
//                 </div>
//               </div>
//               <div className='border border-[#EFEFEF] w-[280px] h-[280px] p-[50px] rounded-2xl'>
//                 <div className='text-center'>
//                   <img
//                     src={IEarn}
//                     alt=''
//                     className='w-[72px] mx-auto'
//                   />
//                   <div className='mt-4'>
//                     <p className='font-medium text-[18px]'>
//                       Get Paid
//                     </p>
//                     <p className='text-[12px] text-[#757575]'>
//                       Be profitable every time your content is
//                       downloaded by our customers
//                     </p>
//                   </div>
//                 </div>
//               </div>
//               <div className='border border-[#EFEFEF] w-[280px] h-[280px] p-[50px] rounded-2xl '>
//                 <div className='text-center'>
//                   <img
//                     src={IRefer}
//                     alt=''
//                     className='w-[72px] mx-auto'
//                   />
//                   <div className='mt-4'>
//                     <p className='font-medium text-[18px]'>Refer</p>
//                     <p className='text-[12px] text-[#757575]'>
//                       Gain or earn on your referral of a Customer or
//                       Contributor
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className='hrLine w-w1168 bg-[efefef]'></div>

//           <ArtHomeFaq type={faq} />
//         </main>
//       </div>
//       <Footer />
//       {/* <ArtHomeFaq /> */}
//     </>
//   );
// };

// export default SellOnArtnstock_Home;

import React from 'react';
import SellBanner from '../../assets/images/SellOnArtnstock/SellBanner.jpg';
import Offer from '../../assets/images/SellOnArtnstock/Offer.png';
import wallpaper from '../../assets/images/SellOnArtnstock/Wallpaper.svg';
import button from '../../assets/images/SellOnArtnstock/button.jpg';
import { ReactComponent as ICreate } from '../../assets/images/Royalty/ICreate.svg';
import { ReactComponent as IUpload } from '../../assets/images/Royalty/IUpload.svg';
import { ReactComponent as IEarn } from '../../assets/images/Royalty/IEarn.svg';
import { ReactComponent as IRefer } from '../../assets/images/Royalty/IRefer.svg';

import { httpClient } from '../../axios';
import { useEffect, useState } from 'react';
import Footer from '../footer/Footer';

// import SellOnartnstockdata from './Sellonartnstockdata';
import ArtIcon from '../../assets/images/SellOnArtnstock/ArtIcon.png';
import PhotosIcon from '../../assets/images/SellOnArtnstock/PhotosIcon.png';
import FootageIcon from '../../assets/images/SellOnArtnstock/FootageIcon.png';
import MusicIcon from '../../assets/images/SellOnArtnstock/MusicIcon.png';
import ProductsIcon from '../../assets/images/SellOnArtnstock/ProductsIcon.png';
import TemplatesIcon from '../../assets/images/SellOnArtnstock/TemplatesIcon.png';
// import Faqmaping from './FooterPages/faq/Faqmaping';
import bckimage2 from '../../assets/images/SellOnArtnstock/bckimage2.jpg';
import hr from '../../assets/images/SellOnArtnstock/hr.jpg';
import Wallpaper from '../../assets/images/SellOnArtnstock/Wallpaper.jpg';
import vogue from '../../assets/images/SellOnArtnstock/vogue.jpg';
import Fresh from '../../assets/images/SellOnArtnstock/Fresh Influx.jpg';
import Certificate from '../contributor/contri-components/Certificate';

import ArtHomeFaq from '../faq/ArtHomeFaq';

const SellOnartnstockdata = [
  {
    id: 1,
    level: 'Level 1',
    royalti: '30%',
    sl: '$ 0.25',
    el: '$ 0.25',
    s2: '$ 0.25',
    e2: '$ 0.25',
    s3: '$ 0.35',
    e3: '$ 0.35',
    s4: '$ 0.35',
    e4: '$ 0.35',
    download: 'Up to 499',
  },
  {
    id: 2,
    level: 'Level 2',
    royalti: '32%',
    sl: '$ 0.27',
    el: '$ 0.27',
    s2: '$ 0.27',
    e2: '$ 0.27',
    s3: '$ 0.37',
    e3: '$ 0.37',
    s4: '$ 0.37',
    e4: '$ 0.37',
    download: '500-4,999',
  },
  {
    id: 3,
    level: 'Level 3',
    royalti: '34%',
    sl: '$ 0.29',
    el: '$ 0.29',
    s2: '$ 0.29',
    e2: '$ 0.29',
    s3: '$ 0.39',
    e3: '$ 0.39',
    s4: '$ 0.39',
    e4: '$ 0.39',
    download: '5000-24,999',
  },
  {
    id: 4,
    level: 'Level 4',
    royalti: '36%',
    sl: '$ 0.31',
    el: '$ 0.31',
    s2: '$ 0.31',
    e2: '$ 0.31',
    s3: '$ 0.41',
    e3: '$ 0.41',
    s4: '$ 0.41',
    e4: '$ 0.41',
    download: '25000-149,999 downloads',
  },
  {
    id: 5,
    level: 'Level 5',
    royalti: '38%',
    sl: '$ 0.33',
    el: '$ 0.33',
    s2: '$ 0.33',
    e2: '$ 0.33',
    s3: '$ 0.43',
    e3: '$ 0.43',
    s4: '$ 0.43',
    e4: '$ 0.43',
    download: '150,000+  ',
  },
];
const SellOnArtnstock_Home = () => {
  const earn = [
    {
      img: `${ArtIcon}`,
      title: 'Art',
      description:
        ' For the first year after a referred contributor signs up, you will earn 10% of the final sale price each time one of their videos is downloaded. For the first two years after',
    },
    {
      img: `${PhotosIcon}`,
      title: 'Photos',
      description:
        ' For the first year after a referred contributor signs up, you will earn 10% of the final sale price each time one of their videos is downloaded. For the first two years after',
    },
    {
      img: `${FootageIcon}`,
      title: 'Footage',
      description:
        ' For the first year after a referred contributor signs up, you will earn 10% of the final sale price each time one of their videos is downloaded. For the first two years after',
    },
    {
      img: `${MusicIcon}`,
      title: 'Music',
      description:
        ' For the first year after a referred contributor signs up, you will earn 10% of the final sale price each time one of their videos is downloaded. For the first two years after',
    },
    {
      img: `${TemplatesIcon}`,
      title: 'Templates',
      description:
        ' For the first year after a referred contributor signs up, you will earn 10% of the final sale price each time one of their videos is downloaded. For the first two years after',
    },
    {
      img: `${ProductsIcon}`,
      title: 'Products',
      description:
        ' For the first year after a referred contributor signs up, you will earn 10% of the final sale price each time one of their videos is downloaded. For the first two years after',
    },
  ];

  const [faq, setfaq] = useState([]);
  const getArtWiseFaqList = () => {
    httpClient
      .get(`/frequently_asked_master/getTypeWiseFaq/art`)
      .then((res) => {
        setfaq(res.data);
        // console.log(res);
      });
  };

  useEffect(() => {
    getArtWiseFaqList();
  }, []);

  return (
    <>
      <div
        style={{ maxHeight: '1275px' }}
        className='relative outline-none'
        //   key={data?.bannerId}
      >
        <img
          alt='ted'
          style={{ width: '100%', maxHeight: '1275px' }}
          src={SellBanner}
        />
        <div className='absolute left-24 z-10 top-[107px] text-left'>
          <p className='text-[4.688rem] leading-[83px] font-semibold mb-[3px] text-primaryBlack'>
            Art{' '}
          </p>
          <p className='text-[2.375rem] font-semibold  leading-[40px] w-[603px] text-primaryBlack'>
            Creatively tailored art in all <br /> forms, media and
            colours{' '}
          </p>
          <button
            //   onClick={() => {
            //     navigate('/art-list');
            //   }}
            className='blackBtn my-[16px]'
          >
            Discover Collections
          </button>
          <p className='text-[11px] text-[#333333] font-Regular'>
            Copyright 2022. Artnstock Private Limited.
          </p>
          <p className='text-[11px] text-[#333333]'>
            All content included in the website (including, but not
            limited to, logos, images, <br /> photo, designs, graphics
            and text) is the property of Artnstock Pvt. Ltd.
          </p>
        </div>
        <div className='absolute right-24 z-10 top-[102px] text-left'>
          <img src={Offer} alt='' />
          <p className='text-[15px] font-medium leading-[130%] text-primaryBlack mt-[3px]'>
            A special welcome bonus on your purchase{' '}
          </p>
          <p className='text-sm11 text-primaryBlack mb-[8px]'>
            *Offer is available to new customers only.{' '}
          </p>
          <button
            //   onClick={() => {
            //     navigate('/Offers');
            //   }}
            className='bg-[#a4e154] text-sm12 text-primaryBlack font-semibold rounded-2xl flex items-center justify-center px-[12px] h-[28px]'
          >
            Shop with 20% Off
          </button>

          <div className='bg-primaryBlack w-[10px] h-[6px] rounded-2xl my-[8px]'></div>

          <img
            className='cursor-pointer'
            //   onClick={() => navigate('/art-list')}
            src={wallpaper}
            alt=''
          />
        </div>
      </div>
      <div className='w-w1168 mx-auto mt-10'>
        <p className='mb-[2px] bullet'>
          <span
            className='cursor-pointer'
            //   onClick={() => navigate('/')}
          >
            Artnstock
          </span>{' '}
          <span>/Sell on Artnstock</span>
        </p>
        <p className='text-primaryBlack text-[38px] font-medium text-center'>
          Lucrative approch with your Art, Photos, <br /> Music,
          Footage and Templates
        </p>

        <p className='text-sm14 font-normal mt-[12px] text-center text-primaryGray'>
          Avail our exclusive multiple products and sizes to fit any
          decor style or budget. Our Creative collections will
          artistically sensitise your visual projects <br />
          and would be a perfect solution for anyone who needs
          high-quality art at affordable prices.
        </p>
        <button className='blackBtn mx-auto block mt-[32px] '>
          Become a Contributor
        </button>
      </div>
      <div className='hrLine'></div>
      <div className='   mx-[auto]'>
        <img
          src={bckimage2}
          className='w-[1250px] mx-[auto]'
          alt=''
        />
        <div className='flex w-[776px] mx-[auto] h-[100%]'>
          <div className='flex flex-col h-[128px] w-[215px] mt-[24px]'>
            <p className='mx-[auto] text-[48px] text-[pink] leading-none'>
              1
            </p>
            <p className='text-[15px]  text-center font-Regular  leading-[15px]'>
              Showcase array of your
              <br />
              creative pursuits
            </p>
            <p className='text-[12px] mt-[2px] text-[#757575]  text-center font-Regular  leading-[15px]'>
              Let your Imaginative
              <br />
              output be displayed on our platform and
              <br />
              be discovered as your extra earning!
            </p>
          </div>
          <img src={hr} className='h-[128px] mt-[24px] ml-[26px]' />
          <div className='flex h-[128px] w-[215px] ml-[26px] mt-[24px]'>
            <div className='flex flex-col h-[128px] w-[215px] '>
              <p className='mx-[auto] text-[48px] text-[pink] leading-none'>
                2
              </p>
              <p className='text-[15px]  text-center font-Regular  leading-[15px]'>
                Assorted printed products
                <br />
                line-up
              </p>
              <p className='text-[12px] mt-[2px] text-[#757575]  text-center font-Regular  leading-[15px]'>
                Checkout our range of
                <br />
                home decor, life style, tech, apparel and
                <br />
                stationary products for you to select.
              </p>
            </div>
          </div>
          <img src={hr} className='h-[128px] mt-[24px] ml-[26px]' />
          <div className='flex h-[128px] w-[218px] ml-[26px] mt-[24px]'>
            <div className='flex flex-col h-[128px] w-[220px] '>
              <p className='mx-[auto] text-[48px] text-[#87CEFA] leading-none'>
                3
              </p>
              <p className='text-[15px]  text-center font-Regular  leading-[15px]'>
                Find your type like-minded
                <br />
                people
              </p>
              <p className='text-[12px] mt-[2px] text-[#757575]  text-center font-Regular  leading-[15px]'>
                We allure millions of diverse
                <br />
                art-lovers from across the globe who crave genuine
                Art, Photos, and so on…
              </p>
            </div>
          </div>
        </div>
        <p className='text-sm14 font-normal mt-[38px] text-center text-primaryGray'>
          Join us! We are a thriving artist community dedicated to
          supporting one another
          <br />
          with InVogue contributors which matches our mission
        </p>
        <p className='text-[15px]  text-center font-Regular  leading-[15px]'>
          “Passion is Vision”.
        </p>
      </div>

      <div className='hrLine'></div>

      <p className='text-primaryBlack   text-heading font-medium text-center'>
        How much you will earn
      </p>
      <p className='text-center font-medium mt-5 mb-2 text-[12px] leading-[1.2]'>
        On-Demand
      </p>
      <table className='w-[100%] mx-[auto] rounded-bl-[10px] max-w-[1168px]  border-separate border-spacing-0'>
        <tr
          height='29'
          className=' border text-[14px]   font-medium text-primaryBlack '
        >
          <td
            rowSpan='2'
            className='w-[81px] border border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#e7e7e7] rounded-tl-[10px]   text-center  pl-[8px]'
          >
            Levels
          </td>
          <td
            rowSpan='2'
            className=' w-[70px] border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#ececec] border-r border-b leading-[1.2] text-center'
          >
            Royalties
          </td>

          <td
            colspan='2'
            className='border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#ececec] border-r border-b leading-[1.2] text-center'
          >
            Photos
          </td>
          <td
            colspan='2'
            className='border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#ececec] border-r border-b leading-[1.2] text-center'
          >
            Vector
          </td>

          <td
            colspan='2'
            className='border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#ececec] border-r border-b leading-[1.2] text-center'
          >
            Music
          </td>
          <td
            colspan='2'
            className='border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#ececec] border-r border-b leading-[1.2] text-center'
          >
            Videos
          </td>

          <td
            rowSpan='2'
            className='w-[203px] border-t border-[#dddddd] bg-[#ececec]  border-r border-b border-b-[2px] rounded-tr-[10px] text-center pl-[8px]'
          >
            Downloads
          </td>
        </tr>

        <tr className='text-[13px]' height='30'>
          <td className='w-[77px] border-r border-b border-[#dddddd] border-r-[2px] border-b-[2px] bg-[#f3f3f3]   text-center pr-[8px]'>
            Standard License
          </td>
          <td className='w-[77px] border-r border-b border-[#dddddd] border-r-[2px] border-b-[2px] bg-[#f3f3f3]  text-center pr-[8px]'>
            Enhanced License
          </td>
          <td className='w-[77px] border-r border-b border-[#dddddd] border-r-[2px] border-b-[2px] bg-[#f3f3f3] text-Black  text-center pr-[8px]'>
            Standard License
          </td>
          <td className='w-[77px] border-r border-b border-[#dddddd] border-r-[2px] border-b-[2px] bg-[#f3f3f3]   text-center pr-[8px]'>
            Enhanced License
          </td>
          <td className='w-[77px] border-r border-b border-[#dddddd] border-r-[2px] border-b-[2px] bg-[#f3f3f3] t  text-center pr-[8px]'>
            Standard License
          </td>
          <td className='w-[77px] border-r border-b border-[#dddddd] border-r-[2px] border-b-[2px] bg-[#f3f3f3]   text-center pr-[8px]'>
            Enhanced License
          </td>
          <td className='w-[77px] border-r border-b border-[#dddddd] border-r-[2px] border-b-[2px] bg-[#f3f3f3]   text-center pr-[8px]'>
            Standard License
          </td>
          <td className='w-[77px] border-r border-b border-[#dddddd] border-r-[2px] border-b-[2px] bg-[#f3f3f3]   text-center pr-[8px]'>
            Enhanced License
          </td>
        </tr>
        {SellOnartnstockdata.map((item, index) => (
          <tr
            key={item.id}
            className='text-[13px] font-medium text-primaryGray '
            height='30'
          >
            <td
              className={`w-[81px] ${
                index === SellOnartnstockdata.length - 1
                  ? 'rounded-bl-[10px]'
                  : ''
              }  border border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#e7e7e7] r text-center pl-[8px]`}
            >
              {item.level}
            </td>
            <td className='w-[70px] border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#f3f3f3] border-r border-b leading-[1.2] text-center'>
              {item.royalti}
            </td>
            <td className='w-[70px] border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#f3f3f3] border-r border-b leading-[1.2] text-center'>
              {item.sl}
            </td>
            <td className='w-[70px] border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#f3f3f3] border-r border-b leading-[1.2] text-center'>
              {item.el}
            </td>
            <td className='w-[70px] border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#f3f3f3] border-r border-b leading-[1.2] text-center'>
              {item.s2}
            </td>
            <td className='w-[70px] border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#f3f3f3] border-r border-b leading-[1.2] text-center'>
              {item.e2}
            </td>
            <td className='w-[70px] border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#f3f3f3] border-r border-b leading-[1.2] text-center'>
              {item.s3}
            </td>
            <td className='w-[70px] border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#f3f3f3] border-r border-b leading-[1.2] text-center'>
              {item.e3}
            </td>
            <td className='w-[70px] border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#f3f3f3] border-r border-b leading-[1.2] text-center'>
              {item.s4}
            </td>
            <td className='w-[70px] border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#f3f3f3] border-r border-b leading-[1.2] text-center'>
              {item.e4}
            </td>
            <td
              className={`w-[70px]  ${
                index === SellOnartnstockdata.length - 1
                  ? 'rounded-br-[10px]'
                  : ''
              }  border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#f3f3f3]   border-r border-b leading-[1.2] text-center`}
            >
              {item.download}
            </td>
          </tr>
        ))}
      </table>
      <p className='text-center font-medium mt-[30px] mb-2 text-[12px] leading-[1.2]'>
        Subscription
      </p>
      <table className='w-[100%] mx-[auto] rounded-bl-[10px] max-w-[1168px]  border-separate border-spacing-0'>
        <tr
          height='29'
          className=' border text-[14px]   font-medium text-primaryBlack '
        >
          <td
            rowSpan='2'
            className='w-[81px] border border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#e7e7e7] rounded-tl-[10px]   text-center  pl-[8px]'
          >
            Levels
          </td>
          <td
            rowSpan='2'
            className=' w-[70px] border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#ececec] border-r border-b leading-[1.2] text-center'
          >
            Royalties
          </td>

          <td
            colspan='2'
            className='border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#ececec] border-r border-b leading-[1.2] text-center'
          >
            Photos
          </td>
          <td
            colspan='2'
            className='border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#ececec] border-r border-b leading-[1.2] text-center'
          >
            Vector
          </td>

          <td
            colspan='2'
            className='border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#ececec] border-r border-b leading-[1.2] text-center'
          >
            Music
          </td>
          <td
            colspan='2'
            className='border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#ececec] border-r border-b leading-[1.2] text-center'
          >
            Videos
          </td>

          <td
            rowSpan='2'
            className='w-[203px] border-t border-[#dddddd] bg-[#ececec]  border-r border-b border-b-[2px] rounded-tr-[10px] text-center pl-[8px]'
          >
            Downloads
          </td>
        </tr>

        <tr className='text-[13px]' height='30'>
          <td className='w-[77px] border-r border-b border-[#dddddd] border-r-[2px] border-b-[2px] bg-[#f3f3f3]   text-center pr-[8px]'>
            Standard License
          </td>
          <td className='w-[77px] border-r border-b border-[#dddddd] border-r-[2px] border-b-[2px] bg-[#f3f3f3]  text-center pr-[8px]'>
            Enhanced License
          </td>
          <td className='w-[77px] border-r border-b border-[#dddddd] border-r-[2px] border-b-[2px] bg-[#f3f3f3] text-Black  text-center pr-[8px]'>
            Standard License
          </td>
          <td className='w-[77px] border-r border-b border-[#dddddd] border-r-[2px] border-b-[2px] bg-[#f3f3f3]   text-center pr-[8px]'>
            Enhanced License
          </td>
          <td className='w-[77px] border-r border-b border-[#dddddd] border-r-[2px] border-b-[2px] bg-[#f3f3f3] t  text-center pr-[8px]'>
            Standard License
          </td>
          <td className='w-[77px] border-r border-b border-[#dddddd] border-r-[2px] border-b-[2px] bg-[#f3f3f3]   text-center pr-[8px]'>
            Enhanced License
          </td>
          <td className='w-[77px] border-r border-b border-[#dddddd] border-r-[2px] border-b-[2px] bg-[#f3f3f3]   text-center pr-[8px]'>
            Standard License
          </td>
          <td className='w-[77px] border-r border-b border-[#dddddd] border-r-[2px] border-b-[2px] bg-[#f3f3f3]   text-center pr-[8px]'>
            Enhanced License
          </td>
        </tr>
        {SellOnartnstockdata.map((item, index) => (
          <tr
            key={item.id}
            className='text-[13px] font-medium text-primaryGray '
            height='30'
          >
            <td
              className={`w-[81px] ${
                index === SellOnartnstockdata.length - 1
                  ? 'rounded-bl-[10px]'
                  : ''
              }  border border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#e7e7e7] r text-center pl-[8px]`}
            >
              {item.level}
            </td>
            <td className='w-[70px] border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#f3f3f3] border-r border-b leading-[1.2] text-center'>
              {item.royalti}
            </td>
            <td className='w-[70px] border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#f3f3f3] border-r border-b leading-[1.2] text-center'>
              {item.sl}
            </td>
            <td className='w-[70px] border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#f3f3f3] border-r border-b leading-[1.2] text-center'>
              {item.el}
            </td>
            <td className='w-[70px] border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#f3f3f3] border-r border-b leading-[1.2] text-center'>
              {item.s2}
            </td>
            <td className='w-[70px] border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#f3f3f3] border-r border-b leading-[1.2] text-center'>
              {item.e2}
            </td>
            <td className='w-[70px] border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#f3f3f3] border-r border-b leading-[1.2] text-center'>
              {item.s3}
            </td>
            <td className='w-[70px] border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#f3f3f3] border-r border-b leading-[1.2] text-center'>
              {item.e3}
            </td>
            <td className='w-[70px] border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#f3f3f3] border-r border-b leading-[1.2] text-center'>
              {item.s4}
            </td>
            <td className='w-[70px] border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#f3f3f3] border-r border-b leading-[1.2] text-center'>
              {item.e4}
            </td>
            <td
              className={`w-[70px]  ${
                index === SellOnartnstockdata.length - 1
                  ? 'rounded-br-[10px]'
                  : ''
              }  border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#f3f3f3]   border-r border-b leading-[1.2] text-center`}
            >
              {item.download}
            </td>
          </tr>
        ))}
      </table>
      <div className='flex justify-center'>
        <div className='w-[1170px]'>
          <p className='text-[13px] mt-[17px] text-[#757575]'>
            • New levels are based on the number of times your content
            is licensed.
          </p>

          <p className='text-[13px]  text-[#757575]'>
            • Contributors reset to level 1 for Photos, Footage, Music
            and templates every year on January 1st.
          </p>
          <p className='text-[13px]  text-[#757575]'>
            • Each file downloaded using On-Demand Downloads counts as
            1 download. A file purchased via subscription plan counts
            as one-third download; i.e., 3 files downloaded via
            <br />
            subscription plan count as 1 download.{' '}
          </p>
          <p className='text-[13px]  text-[#757575]'>
            • If a Photos, Footage, Music and templates is purchased
            with On-Demand downloads under a Standard or Enhanced
            License, your royalty rate depends solely upon your
            <br />
            Contributor's Level.{' '}
          </p>

          <p className='text-[13px]  text-[#757575]'>
            • Royalties for files downloaded under the Program’s are
            aggregated and paid start of the month.
          </p>
          <p className='text-[13px] text-[#757575]'>
            • Royalty calculations are based on the actual amount paid
            by the buyer not on a "standard" value of a file. If a
            Buyer gets a discount on the purchase, the royalties
            received by
            <br />
            the Contributor for the file will be based on the amount
            paid by the Buyer, incorporating the discount, and not the
            “standard” value of the file.
          </p>
        </div>
      </div>
      <div className='hrLine'></div>

      <div className='w-w1168 mx-auto'>
        <p className='text-primaryBlack text-[38px] font-medium text-center'>
          Referral Program
        </p>
        <div className='flex gap-[30px] mt-3'>
          <div className='w-[570px] h-[530px] bg-[#f5f5f7] rounded-2xl'></div>
          <div className=' flex justify-center'>
            <div className=''>
              {earn.map((item) => {
                return (
                  <div className='flex gap-[17px] w-[570px] py-[8px]'>
                    <img src={item.img} alt='' />
                    <p>
                      <p className='text-[18px] text-bold'>
                        {item.title}
                      </p>
                      <p className='text-[13px] text-[#757575] leading-[1.3]'>
                        {item.description}
                      </p>
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className='hrLine'></div>

      <div className='w-w1168 mx-auto'>
        <p className='text-primaryBlack text-heading font-medium text-center'>
          How it works
        </p>
        <p className='text-sm14 font-normal mt-[9px] text-center text-primaryGray'>
          To become a Depositphotos contributor, you need to sign up
          and submit images for review.
          <br />
          Once approved, you can start uploading files to your
          portfolio right away. Be sure to add the right keywords so
          that
          <br />
          your images are discoverable on our website.
        </p>

        <div className='flex gap-4 justify-center mt-[24px] w-w1168'>
          <div className='border border-[#EFEFEF] w-[280px] h-[280px] pt-[68px] px-[33px] rounded-2xl'>
            <div className='text-center'>
              <div className='h-[72px] flex justify-center items-center'>
                <ICreate className='mx-auto' />
              </div>
              <div className='mt-[4px]'>
                <p className='font-medium text-[18px] leading-[22px]'>
                  Create
                </p>
                <p className='text-[11px] text-[#757575]   leading-[15px]'>
                  Lorem ipsum dolor sit amet labore consectetur
                  adipiscing elit sed do eiusmod tempor incididunt ut
                  labore
                </p>
              </div>
            </div>
          </div>
          <div className='border border-[#EFEFEF] w-[280px] h-[280px] pt-[68px] px-[33px] rounded-2xl'>
            <div className='text-center'>
              <div className='h-[72px] flex justify-center items-center'>
                <IUpload className='mx-auto' />
              </div>

              <div className='mt-4'>
                <p className='font-medium text-[18px] leading-[22px]'>
                  Submit
                </p>
                <p className='text-[11px] text-[#757575]   leading-[15px]'>
                  Lorem ipsum dolor sit amet labore consectetur
                  adipiscing elit sed do eiusmod tempor incididunt ut
                  labore
                </p>
              </div>
            </div>
          </div>

          <div className='border border-[#EFEFEF] w-[280px] h-[280px] pt-[68px] px-[33px] rounded-2xl'>
            <div className='text-center'>
              {/* <img src={IEarn} alt='' className='w-[72px] mx-auto' /> */}
              <div className='h-[72px] flex justify-center items-center'>
                <IEarn className='mx-auto' />
              </div>

              <div className='mt-4'>
                <p className='font-medium text-[18px] leading-[22px]'>
                  Get Paid
                </p>
                <p className='text-[11px] text-[#757575]   leading-[15px]'>
                  Lorem ipsum dolor sit amet labore consectetur
                  adipiscing elit sed do eiusmod tempor incididunt ut
                  labore
                </p>
              </div>
            </div>
          </div>
          <div className='border border-[#EFEFEF] w-[280px] h-[280px] pt-[68px] px-[33px] rounded-2xl '>
            <div className='text-center'>
              {/* <img src={IRefer} alt='' className='w-[72px] mx-auto' /> */}

              <div className='h-[72px] flex justify-center items-center'>
                <IRefer className='mx-auto' />
              </div>

              <div className='mt-4'>
                <p className='font-medium text-[18px] leading-[22px]'>
                  Refer
                </p>
                <p className='text-[11px] text-[#757575]   leading-[15px]'>
                  Lorem ipsum dolor sit amet labore consectetur
                  adipiscing elit sed do eiusmod tempor incididunt ut
                  labore
                </p>
              </div>
            </div>
          </div>
        </div>

        <button className='blackBtn mx-auto block mt-[32px] '>
          Get Started
        </button>
      </div>

      <div className='hrLine'></div>
      {/* <Faqmaping /> */}

      <Certificate />
      <ArtHomeFaq type={faq} />
      <Footer />
    </>
  );
};

export default SellOnArtnstock_Home;
