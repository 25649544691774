import { createSlice } from '@reduxjs/toolkit';

const nestedTabSlice = createSlice({
  name: 'nestedTab',
  initialState: {
    nestedUpload: '2.1',
    nestedReleases: '4.1',
    nestedEarnings: '/ Earning Summary',
    nestedEarningsCust: '/ Referral Earnings Summary',
  },
  reducers: {
    setNestedTabValueUpload: (state, action) => {
      //   console.log(action);
      state.nestedUpload = action.payload;
    },
    setNestedTabValueReleases: (state, action) => {
      //   console.log(action);
      state.nestedReleases = action.payload;
    },
    setNestedTabValueEarnings: (state, action) => {
      //   console.log(action);
      state.nestedEarnings = action.payload;
    },
    setNestedTabValueEarningsCust: (state, action) => {
      //   console.log(action);
      state.nestedEarningsCust = action.payload;
    },
  },
});

export const {
  setNestedTabValueUpload,
  setNestedTabValueReleases,
  setNestedTabValueEarnings,
  setNestedTabValueEarningsCust,
} = nestedTabSlice.actions;

export default nestedTabSlice;
