import selectall from '../../../assets/images/contributor/selectall.png';
import deleteicon from '../../../assets/images/contributor/delete-icon.png';
import undo from '../../../assets/images/contributor/undo.png';
import redo from '../../../assets/images/contributor/redo.png';
import save from '../../../assets/images/contributor/save.png';
import keyword from '../../../assets/images/contributor/keyword.png';
import { useSelector } from 'react-redux';
import { useState, useEffect, useRef } from 'react';
import GrayBoxforDetails from './GrayBoxforDetails';
import iconart from '../../../assets/images/contributor/icon-art.png';
import iconphotos from '../../../assets/images/contributor/icon-photos.png';
import iconfootage from '../../../assets/images/contributor/icon-footage.png';
import iconmusic from '../../../assets/images/contributor/icon-music.png';
import icontemplates from '../../../assets/images/contributor/icon-templates.png';
import addsmall from '../../../assets/images/contributor/addsmall.png';
import { httpClient } from '../../../axios';
import { artMasterModel } from '../../../models/allModel';

import { setpath2 } from '../../../store/contriPathSlice';
import { useDispatch } from 'react-redux';
import view from '../../../assets/images/contributor/view.png';
import edit from '../../../assets/images/contributor/edit.png';
import deletee from '../../../assets/images/contributor/delete.png';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { toast } from 'react-toastify';

import { ReactComponent as SelectAll } from '../../../assets/images/contributor/svg/Icon_SelectAll.svg';
import { ReactComponent as Deleteicon } from '../../../assets/images/contributor/svg/Icon_DeleteFiles.svg';
import { ReactComponent as Save } from '../../../assets/images/contributor/svg/Icon_SaveDraft.svg';
import { ReactComponent as Keyword } from '../../../assets/images/contributor/svg/Icon_KeywordManager.svg';
import { ReactComponent as Plus24Icon } from '../../../assets/images/Icons/plus24px.svg';

import { setNestedTabValueUpload } from '../../../store/nestedTabSlice';

import { ReactComponent as DropArrow } from '../../../assets/images/Icons/Down arrow.svg';

import { ReactComponent as ArtIcon } from '../../../assets/images/contributor/add_details/IconAD_Art.svg';
import { ReactComponent as PhotoIcon } from '../../../assets/images/contributor/add_details/IconAD_Photos.svg';
import { ReactComponent as FootageIcon } from '../../../assets/images/contributor/add_details/IconAD_Footage.svg';
import { ReactComponent as MusicIcon } from '../../../assets/images/contributor/add_details/IconAD_Music.svg';
import { ReactComponent as TemplatesIcon } from '../../../assets/images/contributor/add_details/IconAD_Templates.svg';

import { ReactComponent as IconColor } from '../../../assets/images/art-details/Icon_Colour.svg';

import Exclusive from '../../../assets/images/contributor/ForReview/Exclusive.jpg';
import Featured from '../../../assets/images/contributor/ForReview/Featured.jpg';

// import AddHori from '../../../assets/images/Icons/Icon_Add_Hori.jpg';
// import AddVert from '../../../assets/images/Icons/Icon_Add_Vert.jpg';
// import AddSqua from '../../../assets/images/Icons/Icon_Add_Squa.jpg';
import { setSelectedImages } from '../../../store/imageSlice';

import { ReactComponent as Exclusive_Icon28 } from '../../../assets/images/contributor/add_details/Icon_Exclusive_28x28.svg';
import { ReactComponent as Featured_Icon28 } from '../../../assets/images/contributor/add_details/Icon_Featured_28x28.svg';

import { ReactComponent as Exclusive_Icon24 } from '../../../assets/images/contributor/add_details/Icon_Exclusive_24x24.svg';
import { ReactComponent as Featured_Icon24 } from '../../../assets/images/contributor/add_details/Icon_Featured_24x24.svg';

import { ReactComponent as Icon_Edit } from '../../../assets/images/contributor/add_details/Icon_Edit.svg';

import { ReactComponent as Exclusive_Icon16 } from '../../../assets/images/contributor/add_details/Icon_Exclusive_16x16.svg';
import { ReactComponent as Featured_Icon16 } from '../../../assets/images/contributor/add_details/Icon_Featured_16x16.svg';

import { ReactComponent as AddHori } from '../../../assets/images/contributor/add_details/Icon_HorizontalOrientation.svg';
import { ReactComponent as AddVert } from '../../../assets/images/contributor/add_details/Icon_VerticalOrientation.svg';
import { ReactComponent as AddSqua } from '../../../assets/images/contributor/add_details/Icon_SquareOrientation.svg';

import { ReactComponent as Cross8 } from '../../../assets/images/contributor/add_details/cross8x8.svg';

import { ReactComponent as IconBW } from '../../../assets/images/art-details/Icon_BlackWhite.svg';

import { ReactComponent as ViewIcon } from '../../../assets/images/contributor/svg/view.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/contributor/svg/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/contributor/svg/delete.svg';

import Cropper from 'react-easy-crop';
const releaseCards = [
  { id: '1', name: 'jn jencj jbnejne eudnedn' },
  { id: '1', name: 'jn jencj jbnejne eudnedn' },
  { id: '1', name: 'jn jencj jbnejne eudnedn' },
];

const AddDetails = () => {
  const [images, setImages] = useState([]);

  const [selectedCard, setSelectedCard] = useState(null);
  const [thumbnail, setThumbnail] = useState('');
  const [description, setDescription] = useState('');
  const [charCountt, setCharCount] = useState(0);
  const [cards, setCards] = useState([]);
  const [textWord, setTextWord] = useState('');

  // drop down menu states
  const [isOpenSubject, setIsOpenSubject] = useState(false);
  const [isOpenStyle, setIsOpenStyle] = useState(false);
  const [isOpenMedium, setIsOpenMedium] = useState(false);

  const [subjectMenu, setSubjectMenu] = useState(null);
  const [styleMenu, setStyleMenu] = useState(null);
  const [mediumMenu, setMediumMenu] = useState(null);

  const [subjectMenuList, setSubjectMenuList] = useState([]);
  const [styleMenuList, setStyleMenuList] = useState([]);
  const [mediumMenuList, setMediumMenuList] = useState([]);

  const dispatch = useDispatch();

  const userId = useSelector((state) => state.auth.userId);

  // useEffect(() => {
  //   console.log(selectedCard);
  // }, [selectedCard]);

  useEffect(() => {
    dispatch(setpath2('/ Add Details'));
  }, []);

  // api calls start
  useEffect(() => {
    const getSubjectList = async () => {
      const response = await httpClient.get('/subject_master');
      setSubjectMenuList(response.data);
      // console.log(response.data);
    };

    const getStyleList = async () => {
      const response = await httpClient.get('/style_master');
      setStyleMenuList(response.data);
      console.log(response.data);
    };
    const getMediumList = async () => {
      const response = await httpClient.get('/medium_Master');
      setMediumMenuList(response.data);
      console.log(response.data);
    };
    getSubjectList();
    getStyleList();
    getMediumList();
  }, []);

  const submitForm = async (event) => {
    event.preventDefault();

    try {
      const response = await httpClient.post(
        '/art_master/create',
        formData
      );

      toast.success('Successfull Uploaded');
      // console.log(response);
    } catch (error) {
      toast.error('Something Went Wrong');
      console.log(error);
    }
  };

  const [draftId, setdraftId] = useState('');

  // useEffect(() => {
  //   console.log(subjectMenu);
  // }, [subjectMenu]);

  const addToActivateProd = async () => {
    let obj = {
      draftId,
      imageMasters: selectedImages,
      activeStatus: true,
      status: 'adddetails',
    };

    try {
      const res = await httpClient.put(
        `/images/updateImageMaster`,
        obj
      );
      dispatch(setNestedTabValueUpload('2.4'));
      console.log(res.data);
      toast.success('Successfully Moved to Activate Products');
    } catch (error) {
      toast.error('Something Went Wrong');
      console.log(error);
    }
  };

  // api calls end

  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newCards = Array.from(files);

    setCards((prevCards) => [...prevCards, ...newCards]);

    // console.log('new card', newCards);
    newCards.forEach((cards) => {
      let formData = new FormData();
      formData.append('file', cards);
      httpClient
        .post('/CloudinaryImageUpload?parameter=false', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          console.log('file uploaded successfully');
          // console.log(res);
          setFormData((prevFormData) => ({
            ...prevFormData,
            referenceFile: [...prevFormData.referenceFile, res.data],
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const selectedImages = useSelector(
    (state) => state.images.selectedImages
  );

  const toggleMenuSubject = () => {
    setIsOpenSubject(!isOpenSubject);
  };

  const toggleMenuStyle = () => {
    setIsOpenStyle(!isOpenStyle);
  };

  const toggleMenuMedium = () => {
    setIsOpenMedium(!isOpenMedium);
  };

  const handleCardSelect = (card) => {
    // console.log('card selected', card);
    setSelectedCard(card);

    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   image: card,
    // }));
  };

  // dropdown select func
  const handleSubjectMenuClick = (subject) => {
    setSubjectMenu(subject);
    setFormData((prevFormData) => ({
      ...prevFormData,
      ['subjectId']: subject.subjectId,
    }));
  };

  const handleStyleMenuClick = (style) => {
    setStyleMenu(style);
    setFormData((prevFormData) => ({
      ...prevFormData,
      ['styleId']: style.styleId,
    }));
  };

  const handleMediumMenuClick = (value) => {
    setMediumMenu(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      ['mediumId']: value.mediumId,
    }));
  };

  // button click
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // description onchange func
  const handleDescriptionChange = (event) => {
    const char = event.target.value.slice(0, 500);
    setDescription(char);
    const CharCount = char.length;
    setCharCount(CharCount);
    handleInputChange(event);
    // console.log(charCountt);
  };

  const keywordText = (e) => {
    const text = e.target.value;
    setTextWord(text);
  };

  const addKeyword = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ['keywords']: [...prevFormData.keywords, textWord],
    }));
    setTextWord('');
  };

  const deleteKeyword = (keyword) => {
    const updatedKeyword = formData.keywords.filter(
      (obje) => obje !== keyword
    );
    setFormData((prevFormData) => ({
      ...prevFormData,
      ['keywords']: updatedKeyword,
    }));
  };

  const handleInputChangePrice = (event) => {
    const { name, value } = event.target;
    const parsedValue = parseFloat(value);
    setFormData({ ...formData, [name]: parsedValue });
  };

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    console.log(event.target.name);
    console.log(event.target.value);
    console.log(typeof event.target.value);

    if (type === 'checkbox' && name == 'commercialUser') {
      if (checked) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          ['commercialUser']: [...prevFormData.commercialUser, value],
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          ['commercialUser']: prevFormData.commercialUser.filter(
            (usage) => usage !== value
          ),
        }));
      }
    } else if (type === 'checkbox' && name == 'typeOfContent') {
      if (checked) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          ['typeOfContent']: [...prevFormData.typeOfContent, value],
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          ['typeOfContent']: prevFormData.typeOfContent.filter(
            (usage) => usage !== value
          ),
        }));
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  useEffect(() => {
    // setImages(selectedImages);
    getActivateProductList();
    // setFormData((prevFormData) => ({
    //   ...prevFormData,
    //   userId: userId,
    // }));
  }, []);

  const setExclusiveCheck = (value) => {
    if (!typeOfContentCheckoxesTick('Exclusive Art')) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ['typeOfContent']: [...prevFormData.typeOfContent, value],
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ['typeOfContent']: prevFormData.typeOfContent.filter(
          (usage) => usage !== value
        ),
      }));
    }
  };

  const setFeaturedCheck = (value) => {
    if (!typeOfContentCheckoxesTick('Featured Art')) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ['typeOfContent']: [...prevFormData.typeOfContent, value],
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        ['typeOfContent']: prevFormData.typeOfContent.filter(
          (usage) => usage !== value
        ),
      }));
    }
  };

  // useEffect(() => {
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     referenceFile: cards,
  //   }));
  // }, [cards]);

  // releasefileInputRefRelease

  const fileInputRefRelease = useRef(null);

  const handleButtonClickRelease = () => {
    fileInputRefRelease.current.click();
  };

  const [tempRelese, settempRelese] = useState([]);

  const handleFileChangeRelease = (event) => {
    const files = event.target.files;
    const newImages = Array.from(files);

    // upload images
    newImages.forEach((cards) => {
      let formData = new FormData();
      formData.append('file', cards);
      httpClient
        .post('/CloudinaryImageUpload?parameter=false', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          console.log('file uploaded successfully');
          console.log(res);
          const dataa = res.data;

          settempRelese((prevImages) => [
            ...prevImages,
            { dataa, cards },
          ]);
        })

        .catch((err) => {
          console.log(err);
        });
    });
    // upload images
  };

  const formatFileSize = (bytes) => {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;

    if (bytes < kilobyte) {
      return bytes + ' B';
    } else if (bytes < megabyte) {
      return (bytes / kilobyte).toFixed(2) + ' KB';
    } else {
      return (bytes / megabyte).toFixed(2) + ' MB';
    }
  };

  useEffect(() => {
    console.log(tempRelese);
  }, [tempRelese]);

  const handleCheckboxChangeRelease = (obj) => {
    const array = formData.releases.find(
      (item) => item === obj.dataa
    );
    if (!array) {
      setFormData((prevImages) => ({
        ...prevImages,
        ['releases']: [...prevImages.releases, obj.dataa],
      }));
    } else {
      setFormData((prevImages) => ({
        ...prevImages,
        ['releases']: prevImages.releases.filter(
          (item) => item !== obj.dataa
        ),
      }));
    }
  };

  const scrollbarStyle = `
  ::-webkit-scrollbar {
    background: white;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 5px;
  }
    /* Add any other scrollbar styles here */
  `;

  // handle outside click

  const handleClickOutsideSubject = () => {
    // Code to handle click outside
    setIsOpenSubject(false);
  };

  const subjectClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideSubject,
  });

  const handleClickOutsideStyle = () => {
    // Code to handle click outside
    setIsOpenStyle(false);
  };

  const styleClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideStyle,
  });

  const handleClickOutsideMedium = () => {
    // Code to handle click outside
    setIsOpenMedium(false);
  };

  const mediumClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideMedium,
  });

  const grayBox = ['a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a'];

  const [grayNum, setgrayNum] = useState(grayBox.length);

  useEffect(() => {
    if (images.length < 11) {
      setgrayNum(grayBox.length - images.length);
    }

    console.log(images.length);
  }, [images]);

  // const postAddDetailsForm = async () => {
  //   try {
  //     const res = await httpClient.post(
  //       '/Add_Details_Master',
  //       formData
  //     );

  //     console.log(res.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const updateAddDetailsForm = async () => {
    console.log(formData);
    try {
      const res = await httpClient.put(
        '/Add_Details_Master',
        formData
      );
      // setFormData(res.data);
      toast.success('Successfully Art Details saved to Draft');
      console.log(res.data);
    } catch (error) {
      toast.error('Something Went Wrong');
      console.log(error);
    }
  };

  const checkAddDetailExists = async () => {
    const obj = {
      userId: userId,
      imageId: selectedImages[0]?.imageId,
    };

    try {
      const res = await httpClient.post(
        '/Add_Details_Master/checkExistOrNot',
        obj
      );
      if (res.data === true) {
        updateAddDetailsForm();
      } else {
        // postAddDetailsForm();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // main state of formData

  // artName: '',
  // commercialUser: [],
  // typeOfContent: [],
  // referenceFile: [],
  // description: '',
  // keywords: [],
  // artMedium: '',
  // subjectId: '',
  // styleId: '',
  // image: '',
  // userId: '',
  // releases: [],
  // price: 0,
  // notes: '',
  // imageId: selectedImages[0]?.imageId,
  // draftId,

  const [formData, setFormData] = useState({});

  const getActivateProductList = async () => {
    dispatch(setSelectedImages([]));
    try {
      const res = await httpClient.get(
        `/draft_master/getDraftMasterByStatus/adddetails/${userId}`
      );

      // console.log(res.data);
      console.log(res.data);
      setImages(res.data.imageMaster);
      // setFormData({});

      setdraftId(res.data.draftId);

      dispatch(setSelectedImages([res.data.imageMaster[0]]));
      setSelectedCard(res.data.imageMaster[0]);
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   console.log(selectedImages);
  // }, [selectedImages]);

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const [userUniqueNo, setuserUniqueNo] = useState('');

  const [StyleName, setStyleName] = useState('');
  const [SubjectName, setSubjectName] = useState('');

  const getSingleImageDataOnSelect = async () => {
    const obj = {
      userId,
      imageId: selectedCard?.imageId,
    };
    try {
      const res = await httpClient.post(
        '/Add_Details_Master/getArtDetails',
        obj
      );

      // herenow
      console.log('res: ', res.data);

      setuserUniqueNo(res.data.userMaster.userUniqueNo);

      // setStyleName(res.data.styleMaster.styleName);
      // setSubjectName(res.data.subjectMaster.subjectName);

      // settingConvertedFormDataObject(res.data);

      const temp = convertAddDetailsObj(res.data);

      setFormData(temp);

      if (res.data.subjectMaster?.subjectId === null) {
        setSubjectMenu(null);
      } else {
        setSubjectMenu(res.data.subjectMaster);
      }

      if (res.data.styleMaster?.styleId === null) {
        setStyleMenu(null);
      } else {
        setStyleMenu(res.data.styleMaster);
      }
      // if (res.data.mediumMaster?.mediumId === null) {
      //   setMediumMenu(null);
      // } else {
      //   setMediumMenu(res.data.mediumMaster);
      // }

      if (selectedImages && selectedImages.length > 0) {
        const firstImg = selectedImages[0];

        // setFormData((prevFormData) => ({
        //   ...prevFormData,
        //   ['imageId']: firstImg?.imageId,
        // }));

        const url = firstImg;
        setThumbnail(url);
      }
      // setObjToForm(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const convertAddDetailsObj = (objj) => {
    const obj = { ...objj };

    // console.log('obj: ', obj);
    // console.log('objj: ', objj);
    if (obj.styleMaster?.styleId !== null) {
      obj.styleId = obj.styleMaster?.styleId;

      delete obj.styleMaster;
    } else {
      delete obj.styleMaster;
      obj.styleId = '';
    }

    if (obj.subjectMaster?.subjectId !== null) {
      obj.subjectId = obj.subjectMaster?.subjectId;

      delete obj.subjectMaster;
    } else {
      delete obj.subjectMaster;
      obj.subjectId = '';
    }
    // if (obj.mediumMaster?.mediumId !== null) {
    //   obj.mediumId = obj.mediumMaster?.mediumId;

    //   delete obj.mediumMaster;
    // } else {
    //   delete obj.mediumMaster;
    //   obj.mediumId = '';
    // }

    if (obj.imageMaster?.imageId !== null) {
      obj.imageId = obj.imageMaster?.imageId;

      delete obj.imageMaster;
    } else {
      delete obj.imageMaster;
      obj.imageId = '';
    }

    obj.userId = obj.userMaster?.userId;

    delete obj.userMaster;

    console.log(obj);
    return obj;
  };

  const setObjToForm = (obj) => {
    // setFormData(obj);
  };

  useEffect(() => {
    console.log(subjectMenu);
  }, [subjectMenu]);

  useEffect(() => {
    getSingleImageDataOnSelect();
  }, [selectedCard]);

  const handleChildFunctionCall = (message) => {
    // This function is called from the child component
    console.log(message);
  };

  // crop image code
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(2);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCropChange = (crop) => {
    setCrop(crop);
  };

  const handleZoomChange = (zoom) => {
    // if (zoom > 2) {
    setZoom(zoom);
    // }
  };

  const [imageFile, setimageFile] = useState();

  const handleCropImage = async () => {
    try {
      const croppedImageFile = await getCroppedImg(
        selectedImages[0]?.secureUrl,
        croppedAreaPixels
      );
      // Handle cropped image file (e.g., save it or display it)
      console.log('Cropped image file:', croppedImageFile);
      setimageFile(croppedImageFile);
      // You can now upload or save the file, or perform any other actions with it

      // Read the file as data URL
      const reader = new FileReader();
      reader.onloadend = () => {
        setimageFile(reader.result);
      };
      reader.readAsDataURL(croppedImageFile);
    } catch (error) {
      console.error('Error cropping image:', error);
    }
  };

  const getCroppedImg = (imageSrc, crop) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.crossOrigin = 'anonymous';
      image.src = imageSrc;
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
        canvas.toBlob((blob) => {
          if (!blob) {
            console.error('Canvas is empty');
            reject(new Error('Canvas is empty'));
            return;
          }
          const fileName = 'cropped_image.jpg'; // Set the desired file name
          const file = new File([blob], fileName, {
            type: 'image/jpeg',
          });
          resolve(file);
        }, 'image/jpeg');
      };
      image.onerror = (error) => {
        console.error('Error loading image:', error);
        reject(error);
      };
    });
  };

  const [cropHeight, setCropHeight] = useState(445);
  const [cropWidth, setCropWidth] = useState(690);

  const SetHorizontalOri = () => {
    setCropHeight(445);
    setCropWidth(690);
  };

  const SetVerticalOri = () => {
    setCropHeight(445);
    setCropWidth(281);
  };

  const SetSquareOri = () => {
    setCropHeight(445);
    setCropWidth(445);
  };
  // crop image code

  const [Exclusive, setExclusive] = useState(true);
  const [Featured, setFeatured] = useState(true);

  // checkboxes check valid function

  const typeOfContentCheckoxesTick = (value) => {
    return formData.typeOfContent?.some((str) => str === value);
  };
  const commercialUsageCheckoxesTick = (value) => {
    return formData.commercialUser?.some((str) => str === value);
  };

  useEffect(() => {
    console.log(styleMenu);
  }, [styleMenu]);

  const [HoriOrientHover, setHoriOrientHover] = useState(false);
  const [VertOrientHover, setVertOrientHover] = useState(false);
  const [SquaOrientHover, setSquaOrientHover] = useState(false);

  const deleteReferenceImage = (obj) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      ['referenceFile']: prevFormData.referenceFile.filter(
        (usage) => usage !== obj
      ),
    }));
  };

  return (
    <>
      <div className='flex justify-center  mb-[16px]'>
        <div
          style={{
            width: 'fit-content',
          }}
          className='flex border-t border-b border-t-[#efefef] border-b-[#efefef]'
        >
          <div className='flex items-center gap-[5px]'>
            <span className='contributorUploadCount text-pinkColor'>
              13
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='contributorUploadtext pr-[8px]'>
                File(s) to be
                <br />
                submitted to
                <br />
                Artnstock
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span className='contributorUploadCount text-[#f5721a] opacity-[20%] pl-[5px]'>
              0
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                File(s)
                <br />
                pending for
                <br />
                review
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span className=' contributorUploadCount text-[#a9af20] opacity-[20%]  pl-[5px]'>
              0
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                File(s)
                <br />
                approved by
                <br />
                Artnstock
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span className='contributorUploadCount text-[#e7233b] opacity-[20%]  pl-[5px]'>
              0
            </span>
            <div className='opacity-[40%] h-[40px] flex items-center'>
              <p className='contributorUploadtext'>
                File(s)
                <br />
                rejected by
                <br />
                Artnstock
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='contributorTitleStyling'>
        <div className='w-[1168px] flex justify-between'>
          <div className='flex flex-col gap-[2px] max-w-[320px] w-[100%]'>
            <div className='flex gap-[8px] items-baseline'>
              <p className='text-[25px] font-medium leading-[1]  text-primaryBlack'>
                Add Details
              </p>
              <p className='text-pinkColor text-[11px] leading-[13px] '>
                0/1 File(s) selected
              </p>
            </div>
            <p className='text-[11px] text-primaryGray leading-[14px]'>
              Select thumbnail(s) to add details, and click Continue
              <br /> to Activate Products.
              <span className='text-orangeColor'> Learn more</span>
            </p>
          </div>

          <div className='flex'>
            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef]'>
              <div className='flex flex-col  gap-[8px] w-[52px] mt-[1px] text-center opacity-[40%]'>
                {/* <img
                  src={selectall}
                  alt=''
                  className='w-[16px] h-[16px] mx-[auto] bg-[#d7d7d7]'
                /> */}
                <div>
                  <SelectAll className='mx-[auto]' />
                </div>
                <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                  Select <br /> All
                </p>
              </div>
            </div>

            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef]'>
              <div className='flex flex-col  opacity-[40%] mt-[1px] gap-[8px] w-[52px] text-center '>
                {/* <img
                  src={deleteicon}
                  alt=''
                  className='w-[16px] h-[16px] mx-[auto] '
                /> */}
                <div>
                  <Deleteicon className='mx-[auto]' />
                </div>
                <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                  Delete Image
                </p>
              </div>
            </div>

            {/* <div className='flex-column max-w-[34px] text-center'>
              <img
                src={undo}
                alt=''
                className='w-[16px] h-[16px] mx-[auto] mb-[6px]'
              />
              <p className='text-primaryGray text-[11px]'>
                Undo Changes
              </p>
            </div>
            <div className='flex-column max-w-[34px] text-center'>
              <img
                src={redo}
                alt=''
                className='w-[16px] h-[16px] mx-[auto] mb-[6px]'
              />
              <p className='text-primaryGray text-[11px]'>
                Redo Changes
              </p>
            </div> */}

            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef] border-r-[2px]'>
              {selectedImages?.length > 0 ? (
                <div
                  onClick={updateAddDetailsForm}
                  className={`flex flex-col cursor-pointer  mt-[1px]  gap-[8px] w-[52px] text-center `}
                >
                  <div>
                    <Save className='mx-[auto]' />
                  </div>
                  <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                    Save <br /> Draft
                  </p>
                </div>
              ) : (
                <div
                  onClick={updateAddDetailsForm}
                  className={`flex flex-col  opacity-[40%] mt-[1px] gap-[8px] w-[52px] text-center `}
                >
                  {/* <img
                      src={save}
                      alt=''
                      className='w-[16px] h-[16px] mx-[auto]'
                    /> */}
                  <div>
                    <Save className='mx-[auto]' />
                  </div>
                  <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                    Save <br /> Draft
                  </p>
                </div>
              )}
            </div>

            {/* <div className=' w-[53px] text-center border-r border-r-[#efefef] '>
              <img
                src={save}
                alt=''
                className='w-[16px] h-[16px] mx-[auto] mb-[6px]'
              />
              <p className='text-primaryGray text-[11px] text-center'>
                Save <br /> Draft
              </p>
            </div> */}

            <div className='contributorUploadIconsMainDiv '>
              <div className='flex flex-col mt-[1px] gap-[8px] w-[52px] text-center '>
                {/* <img
                  src={keyword}
                  alt=''
                  className='w-[16px] h-[16px] mx-[auto] '
                /> */}{' '}
                <div>
                  <Keyword className='mx-[auto]' />
                </div>
                <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                  Keyword Manager
                </p>
              </div>
            </div>
          </div>
          <div className='pl-[206px] leading-[1] '>
            <p className='text-[11px] text-primaryGray leading-[14px]'>
              Files uploaded this week
            </p>
            <p className='text-[25px] leading-[0.9] text-pinkColor  font-medium'>
              84/250
              <span className='text-[11px] font-normal ml-[2px]'>
                File(s)
              </span>
            </p>
            <button className='bg-[#888888] text-[white] text-[10px]  font-normal leading-[1] flex items-center rounded-[20px] px-[8px] h-[17px]'>
              Files Upload Summary
            </button>
          </div>
        </div>
      </div>

      {images?.length > 0 ? (
        <>
          <div className='w-[100%] flex justify-center'>
            <div className='w-w1168  flex-column gap-[10px] pb-[32px]'>
              <div className='flex justify-start flex-wrap gap-[12px]'>
                {/* dynamic gray box */}

                {images?.map((card, index) => (
                  <GrayBoxforDetails
                    key={index}
                    card={card}
                    isSelected={selectedCard === card}
                    onCardSelect={handleCardSelect}
                  />
                ))}

                {/* test */}
                {grayBox.slice(0, grayNum).map((card, index) => (
                  <div
                    className={
                      'h-[106px] w-[106px] bg-[#f7f7f7] rounded-[16px]'
                    }
                  ></div>
                ))}
                {/* test */}
              </div>
            </div>
          </div>

          {selectedImages?.length > 0 ? (
            <div className='w-[100%] flex justify-center'>
              <div
                style={{
                  borderRadius: '32px!important',
                }}
                className='w-[688px] bg-[#ffffff] rounded-gradient-border borderRadius32px pt-[15px] pb-[24px] px-[16px]'
              >
                {/* test */}
                <p className='text-[#bbbbbb] text-[11px] leading-[14px]'>
                  You have selected
                </p>

                {typeOfContentCheckoxesTick('Exclusive Art') ||
                typeOfContentCheckoxesTick('Featured Art') ? (
                  <div className='flex mt-[7px] h-[52px]'>
                    {typeOfContentCheckoxesTick('Exclusive Art') && (
                      <div className='w-[42px] flex flex-col mr-[11px]'>
                        <div className='flex w-[100%] justify-center'>
                          {/* <img src={Exclusive} alt='' /> */}
                          <div>
                            <Exclusive_Icon28 />
                          </div>
                        </div>
                        <p className=' text-[10px] text-primaryGray mt-[5px]'>
                          Exclusive
                        </p>
                      </div>
                    )}

                    {typeOfContentCheckoxesTick('Exclusive Art') &&
                      typeOfContentCheckoxesTick('Featured Art') && (
                        <div className='w-[1px] h-[52px] bg-[#efefef]'></div>
                      )}

                    {typeOfContentCheckoxesTick('Featured Art') && (
                      <div
                        className={`${
                          typeOfContentCheckoxesTick('Exclusive Art')
                            ? 'w-[68px]'
                            : ''
                        } flex flex-col`}
                      >
                        <div className='flex w-[100%]  justify-center'>
                          {/* <img src={Featured} alt='' /> */}
                          <div>
                            <Featured_Icon28 />
                          </div>
                        </div>
                        <p className='text-center text-[10px] text-primaryGray mt-[5px]'>
                          Featured
                        </p>
                      </div>
                    )}
                  </div>
                ) : (
                  <></>
                )}

                <div className='mt-[15px] flex gap-[8px]'>
                  <div
                    className='w-[64px] h-[64px] rounded-[10px] bg-cover bg-center'
                    style={{
                      backgroundImage: `url(${selectedImages[0]?.secureUrl})`,
                    }}
                  ></div>
                  <div className='w-[64px] h-[64px] rounded-[10px] bg-[#f7f7f7]'></div>
                  <div className='w-[64px] h-[64px] rounded-[10px] bg-[#f7f7f7]'></div>
                  <div className='w-[64px] h-[64px] rounded-[10px] bg-[#f7f7f7]'></div>
                  <div className='w-[64px] h-[64px] rounded-[10px] bg-[#f7f7f7]'></div>
                </div>
                <div className='mt-5 border-t border-[#EFEFEF] w-[100%] flex '>
                  <div
                    style={{
                      width: 'fit-content',
                    }}
                    className='flex flex-col '
                  >
                    <p className='text-primaryGray text-sm12  py-[1px] font-medium leading-4  border-b border-[#EFEFEF] whitespace-no-wrap'>
                      Category:
                    </p>

                    {/* {topMatSelect > 0 && ( */}

                    {formData.artName !== '' && (
                      <p className='text-primaryGray text-sm12  py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'>
                        Art Title:
                      </p>
                    )}
                    {/* )} */}

                    {/* {bottomMatSelect > 0 && ( */}

                    {subjectMenu && (
                      <p className='text-primaryGray text-sm12  py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'>
                        Subject:
                      </p>
                    )}
                    {/* )} */}

                    {/* {includeFrame && ( */}
                    {styleMenu && (
                      <p className='text-primaryGray text-sm12  py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'>
                        Style:
                      </p>
                    )}
                    {/* )} */}

                    <p className='text-primaryGray text-sm12  py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'>
                      Medium:
                    </p>

                    {formData.typeOfContent?.length > 0 && (
                      <p
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                        className='text-primaryGray text-sm12  py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'
                      >
                        Type of Content:
                      </p>
                    )}
                    <p
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                      className='text-primaryGray text-sm12  py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-now-wrap'
                    >
                      Case Number:
                    </p>

                    {formData.commercialUser?.length > 0 && (
                      <p
                        style={{
                          whiteSpace: 'nowrap',
                        }}
                        className='text-primaryGray text-sm12  py-[1px] font-medium leading-4  border-b border-[#EFEFEF] whitespace-no-wrap whitespace-no-wrap'
                      >
                        Commercial Usage:
                      </p>
                    )}
                    {/* <p
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                      className='text-primaryGray text-sm12 font-medium leading-4  whitespace-no-wrap whitespace-no-wrap'
                    >
                      Date Submitted:
                    </p> */}
                  </div>

                  <div className='flex flex-col w-[100%] '>
                    <p className='text-primaryGray text-sm12  py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                      Art
                    </p>
                    {formData.artName !== '' && (
                      <p className='text-primaryGray text-sm12  py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                        {formData.artName}
                      </p>
                    )}
                    {subjectMenu ? (
                      <p className='text-primaryGray text-sm12  py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                        {subjectMenu?.subjectName}
                      </p>
                    ) : (
                      <></>
                    )}

                    {styleMenu ? (
                      <p className='text-primaryGray text-sm12  py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                        {styleMenu?.name}
                      </p>
                    ) : (
                      <></>
                    )}

                    <p className='text-primaryGray text-sm12  py-[1px] pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                      Digital
                    </p>
                    {formData?.typeOfContent?.length > 0 && (
                      <p className='text-primaryGray text-sm12  py-[1px] pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                        {formData?.typeOfContent?.map(
                          (obj, index) => (
                            <span>
                              {obj}
                              <span
                                className={`${
                                  formData?.typeOfContent?.length -
                                    1 ===
                                    index ||
                                  formData?.typeOfContent?.length ===
                                    1
                                    ? 'invisible'
                                    : ''
                                }`}
                              >
                                ,{' '}
                              </span>
                            </span>
                          )
                        )}
                      </p>
                    )}
                    <p className='text-primaryGray text-sm12  py-[1px] pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                      ANS7459309542
                    </p>

                    {formData.commercialUser?.length > 0 && (
                      <p className='text-primaryGray text-sm12  py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                        {formData.commercialUser?.map(
                          (obj, index) => (
                            <span>
                              {obj}
                              <span
                                className={`${
                                  formData.commercialUser?.length -
                                    1 ===
                                    index ||
                                  formData.commercialUser?.length ===
                                    1
                                    ? 'invisible'
                                    : ''
                                }`}
                              >
                                ,{' '}
                              </span>
                            </span>
                          )
                        )}
                      </p>
                    )}

                    {/* <p className='text-primaryGray text-sm12  pl-[8px] leading-4 border-b border-[#EFEFEF]'>
                          Web Media, Print Media, Print on Products
                        </p> */}
                    {/* <p className='text-primaryGray text-sm12  pl-[8px] leading-4 '>
                      05/06/2023
                    </p> */}
                  </div>
                </div>

                {/* <p
                  onClick={() => setshowMore(true)}
                  className='mt-[7px] text-[#bbbbbb]  text-sm11 font-normal cursor-pointer'
                >
                  Show more
                </p> */}

                {/* test */}
              </div>
            </div>
          ) : (
            <div></div>
          )}

          <div className='w-[100%] text-center mt-[32px]'>
            <div
              className='flex flex-col justify-center relative w-[688px] h-[27.813rem]  mx-[auto] bg-[#f7f7f7] rounded-[32px] h-full w-full bg-no-repeat bg-center bg-cover'
              style={{
                // backgroundImage: `url(${selectedImages[0]?.secureUrl})`,
                overflow: 'hidden',
              }}
            >
              {!selectedCard ? (
                <div>
                  <p className='text-pinkColor text-[18px] my-[auto]'>
                    Select thumbnail to view the image.
                  </p>
                  <p className='text-primaryGray text-[11px] my-[auto]'>
                    Select an item to add details and submit for
                    review.{' '}
                    <span className='text-orangeColor text-[11px]'>
                      Learn more
                    </span>
                  </p>
                </div>
              ) : (
                <>
                  {/* <div
                style={{
                  width: '690px',
                  height: '445px',
                  position: 'relative',
                 
                }}
                className='w-w690 h-[27.813rem] mx-[auto] bg-[#f7f7f7] rounded-[32px]'
              > */}
                  <div className='absolute z-[999] top-[16px] left-[16px] flex gap-[15px]'>
                    {typeOfContentCheckoxesTick('Exclusive Art') && (
                      <div>
                        <Exclusive_Icon24 className='fill-[#333333]' />
                      </div>
                    )}

                    {typeOfContentCheckoxesTick('Featured Art') && (
                      <div>
                        <Featured_Icon24 className='fill-[#333333]' />
                      </div>
                    )}
                  </div>
                  <Cropper
                    image={selectedImages[0]?.secureUrl}
                    crop={crop}
                    zoom={zoom}
                    aspect={1 / 1} // Aspect ratio (width / height)
                    onCropChange={handleCropChange}
                    onZoomChange={handleZoomChange}
                    onCropComplete={onCropComplete}
                    style={{ width: '100%', height: '100%' }} // Set Cropper dimensions to fill its container
                    cropSize={{
                      width: cropWidth,
                      height: cropHeight,
                    }} // Set desired crop size
                  />
                  {/* </div> */}
                  {/* <div className='flex gap-[10px]'>
                <img onClick={SetHorizontalOri} src={AddHori} alt='' />
                <img onClick={SetVerticalOri} src={AddVert} alt='' />
                <img onClick={SetSquareOri} src={AddSqua} alt='' />
              </div>
              <button
                onClick={handleCropImage}
                className='mt-[10px]'
               
              >
                Crop Image
              </button>
              {imageFile && <img src={imageFile} alt='Uploaded Image' />} */}
                  <button
                    onClick={handleCropImage}
                    className='mt-[10px]'
                  >
                    Crop Image
                  </button>
                </>
              )}
            </div>
          </div>

          <div className='w-[100%]'>
            <div className='w-[688px] mx-[auto]'>
              <div className='flex justify-between'>
                <div className='flex gap-[16px] pt-[8px]'>
                  <div className='flex gap-[8px]'>
                    <div>
                      <IconColor className='cursor-pointer ' />
                    </div>
                    <div>
                      <IconBW className='cursor-pointer opacity-[40%]' />
                    </div>
                  </div>

                  <div className='flex gap-[8px]'>
                    <div
                      onClick={SetHorizontalOri}
                      onMouseEnter={() => setHoriOrientHover(true)}
                      onMouseLeave={() => setHoriOrientHover(false)}
                      className='cursor-pointer'
                    >
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          x='23.5'
                          y='0.5'
                          width='23'
                          height='23'
                          rx='5.5'
                          transform='rotate(90 23.5 0.5)'
                          // stroke='#888888'
                          className={`${
                            HoriOrientHover
                              ? 'stroke-[#333333]'
                              : 'stroke-[#888888]'
                          }`}
                        />
                        <path
                          className={`${
                            HoriOrientHover
                              ? 'fill-[#333333]'
                              : 'fill-[#888888]'
                          }`}
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M6 6C4.89539 6 4 6.89539 4 8V16C4 17.1046 4.89539 18 6 18H18C19.1046 18 20 17.1046 20 16V8C20 6.89539 19.1046 6 18 6H6ZM10.4866 11C10.0075 10.9998 9.56445 11.2557 9.32385 11.6718L7 15.0118H13.9727L11.6488 11.6729C11.4086 11.2566 10.9658 11.0002 10.4866 11ZM15.4789 13.0118C14.902 12.183 13.9661 11.8108 12.9789 12.0118C13.1702 12.1653 13.5913 12.8624 14.006 13.5492C14.4077 14.2144 14.8035 14.8696 14.9789 15.0118H16.9611L15.4789 13.0118Z'
                          // fill='#888888'
                        />
                      </svg>

                      {/* <AddHori  /> */}
                    </div>
                    <div
                      onClick={SetVerticalOri}
                      onMouseEnter={() => setVertOrientHover(true)}
                      onMouseLeave={() => setVertOrientHover(false)}
                      className='cursor-pointer'
                    >
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          x='23.5'
                          y='0.5'
                          width='23'
                          height='23'
                          rx='5.5'
                          transform='rotate(90 23.5 0.5)'
                          className={`${
                            VertOrientHover
                              ? 'stroke-[#333333]'
                              : 'stroke-[#888888]'
                          }`}
                        />
                        <path
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M8 4C6.89539 4 6 4.89539 6 6V18C6 19.1046 6.89539 20 8 20H16C17.1046 20 18 19.1046 18 18V6C18 4.89539 17.1046 4 16 4H8ZM10.4866 11C10.0075 10.9998 9.56445 11.2557 9.32385 11.6718L7 15.0118H13.9727L11.6488 11.6729C11.4086 11.2566 10.9658 11.0002 10.4866 11ZM15.4789 13.0118C14.902 12.183 13.9661 11.8108 12.9789 12.0118C13.1702 12.1653 13.5913 12.8624 14.006 13.5492C14.4077 14.2144 14.8035 14.8696 14.9789 15.0118H16.9611L15.4789 13.0118Z'
                          className={`${
                            VertOrientHover
                              ? 'fill-[#333333]'
                              : 'fill-[#888888]'
                          }`}
                        />
                      </svg>

                      {/* <AddVert /> */}
                    </div>
                    <div
                      className='cursor-pointer'
                      onClick={SetSquareOri}
                      onMouseEnter={() => setSquaOrientHover(true)}
                      onMouseLeave={() => setSquaOrientHover(false)}
                    >
                      <svg
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <rect
                          x='23.5'
                          y='0.5'
                          width='23'
                          height='23'
                          rx='5.5'
                          transform='rotate(90 23.5 0.5)'
                          className={`${
                            SquaOrientHover
                              ? 'stroke-[#333333]'
                              : 'stroke-[#888888]'
                          }`}
                        />
                        <path
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M8 6C6.89539 6 6 6.89539 6 8V16C6 17.1046 6.89539 18 8 18H16C17.1046 18 18 17.1046 18 16V8C18 6.89539 17.1046 6 16 6H8ZM10.4866 11C10.0075 10.9998 9.56445 11.2557 9.32385 11.6718L7 15.0118H13.9727L11.6488 11.6729C11.4086 11.2566 10.9658 11.0002 10.4866 11ZM15.4789 13.0118C14.902 12.183 13.9661 11.8108 12.9789 12.0118C13.1702 12.1653 13.5913 12.8624 14.006 13.5492C14.4077 14.2144 14.8035 14.8696 14.9789 15.0118H16.9611L15.4789 13.0118Z'
                          className={`${
                            SquaOrientHover
                              ? 'fill-[#333333]'
                              : 'fill-[#888888]'
                          }`}
                        />
                      </svg>

                      {/* <AddSqua /> */}
                    </div>
                  </div>

                  <div className='opacity-[40%]'>
                    <Icon_Edit />
                  </div>
                </div>

                <div className='flex gap-[16px] pt-[8px] herenow'>
                  <div>
                    <Exclusive_Icon24
                      onClick={() => {
                        setExclusiveCheck('Exclusive Art');
                      }}
                      className={`${
                        typeOfContentCheckoxesTick('Exclusive Art')
                          ? 'fill-[#333333]'
                          : 'fill-[#888888]'
                      } hover:fill-[#333333] cursor-pointer`}
                    />
                  </div>
                  <div>
                    <Featured_Icon24
                      onClick={() => {
                        setFeaturedCheck('Featured Art');
                      }}
                      className={`${
                        typeOfContentCheckoxesTick('Featured Art')
                          ? 'fill-[#333333]'
                          : 'fill-[#888888]'
                      } hover:fill-[#333333] cursor-pointer`}
                    />
                  </div>
                </div>
              </div>

              <div className='h-[1px] bg-[#efefef] w-[100%] mt-[32px]'></div>

              <div className='mt-[31px]'>
                <p className='text-pinkColor text-sm11 font-normal'>
                  All fields are mandatory.
                </p>
                <p className='text-primaryGray text-sm11 font-normal'>
                  Art submissions that requires a Note and does not
                  fall in any of the situations below, please <br />
                  contact{' '}
                  <span className='text-orangeColor'>
                    support@artnstock.com
                  </span>{' '}
                  to assist you with your submission.
                </p>
              </div>

              <div className='flex flex-col pt-[26px]'>
                <p className='text-[18px] text-primaryBlack font-medium'>
                  Select Category
                </p>
                <div className='flex gap-[8px] pt-[12px]'>
                  <div className='flex flex-col'>
                    <div>
                      <ArtIcon />
                    </div>
                    <p className='text-primaryGray text-center text-[11px] leading-[14px] mt-[2px]'>
                      Art
                    </p>
                  </div>
                  <div className='flex flex-col'>
                    <div>
                      <PhotoIcon />
                    </div>
                    <p className='text-primaryGray text-center text-[11px] leading-[14px] mt-[2px]'>
                      Photos
                    </p>
                  </div>
                  <div className='flex flex-col'>
                    <div>
                      <FootageIcon />
                    </div>
                    <p className='text-primaryGray text-center text-[11px] leading-[14px] mt-[2px]'>
                      Footage
                    </p>
                  </div>
                  <div className='flex flex-col'>
                    <div>
                      <MusicIcon />
                    </div>
                    <p className='text-primaryGray text-center text-[11px] leading-[14px] mt-[2px]'>
                      Music
                    </p>
                  </div>
                  <div className='flex flex-col'>
                    <div>
                      <TemplatesIcon />
                    </div>
                    <p className='text-primaryGray text-center text-[11px] leading-[14px] mt-[2px]'>
                      Templates
                    </p>
                  </div>
                </div>
              </div>
              <div className='flex flex-col pt-[31px]'>
                {/* <p className='text-[18px] text-primaryBlack font-medium'>
                  Select Art Category
                </p> */}
                <div className='relative'>
                  <input
                    type='text'
                    placeholder='Enter Art Title'
                    className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
                    onChange={handleInputChange}
                    value={formData?.artName || ''}
                    name='artName'
                  />

                  <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
                    Art Title
                  </p>
                </div>
                <div className='flex gap-[8px]  pt-[16px]'>
                  <div className='flex flex-col'>
                    {/* <p className='text-[12px] primary-Gray pt-[10px]'>
                      Subject
                    </p> */}

                    <div
                      className='abc'
                      onClick={() => {
                        setIsOpenSubject(!isOpenSubject);
                      }}
                      ref={subjectClickOutside}
                    >
                      <style
                        dangerouslySetInnerHTML={{
                          __html: scrollbarStyle,
                        }}
                      />
                      <button className='w-[224px] h-[44px] rounded-[5000px] flex items-center justify-between border-[1px] border-[#d6d6d6] px-[20px] text-[14px] text-primaryGray text-start'>
                        {subjectMenu ? (
                          <div className='flex flex-col gap-[1px]'>
                            <p className='text-[#bbbbbb] text-[11px] leading-[1]'>
                              Select Subject
                            </p>
                            <p className='text-primaryGray font-medium flex text-[14px] leading-[1]'>
                              {subjectMenu.subjectName}
                            </p>
                          </div>
                        ) : (
                          <div className='flex flex-col gap-[1px]'>
                            <p className='text-[#bbbbbb] text-[11px] leading-[1]'>
                              Select Subject
                            </p>
                            <p className='text-primaryGray font-medium flex text-[14px] leading-[1]'>
                              Select Subject
                            </p>
                          </div>
                        )}
                        <div>
                          <DropArrow
                            className={`fill-[#878787]  ${
                              isOpenSubject ? 'rotate-180' : ''
                            }`}
                          />
                        </div>
                      </button>
                      {isOpenSubject && (
                        <ul className='shadow-dropShadow rounded-b-2xl dropdown__menu absolute z-50 bg-[#ffffff] w-[224px] text-center text-[14px] text-primaryGray max-h-[220px] overflow-y-auto'>
                          {subjectMenuList &&
                            subjectMenuList.map((subject) => (
                              <li
                                className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'
                                onClick={() => {
                                  handleSubjectMenuClick(subject);
                                }}
                              >
                                {subject.subjectName}
                              </li>
                            ))}
                        </ul>
                      )}
                    </div>
                  </div>

                  <div className='flex flex-col ]'>
                    {/* <p className='text-[12px] primary-Gray pt-[10px]'>
                      Style
                    </p> */}

                    <div
                      className='abc'
                      // onMouseEnter={toggleMenuStyle}
                      // onMouseLeave={toggleMenuStyle}
                      onClick={() => {
                        setIsOpenStyle(!isOpenStyle);
                      }}
                      ref={styleClickOutside}
                    >
                      <button className='w-[224px] h-[44px] rounded-[5000px] flex items-center justify-between border-[1px] border-[#d6d6d6] px-[20px] text-[14px] text-primaryGray text-start'>
                        {styleMenu ? (
                          <div className='flex flex-col gap-[1px]'>
                            <p className='text-[#bbbbbb] text-[11px] leading-[1]'>
                              Select Style
                            </p>
                            <p className='text-primaryGray font-medium flex text-[14px] leading-[1]'>
                              {styleMenu.name}
                            </p>
                          </div>
                        ) : (
                          <div className='flex flex-col gap-[1px]'>
                            <p className='text-[#bbbbbb] text-[11px] leading-[1]'>
                              Select Style
                            </p>
                            <p className='text-primaryGray font-medium flex text-[14px] leading-[1]'>
                              Select Style
                            </p>
                          </div>
                        )}

                        <div>
                          <DropArrow
                            className={`fill-[#878787]  ${
                              isOpenStyle ? 'rotate-180' : ''
                            }`}
                          />
                        </div>
                      </button>
                      {isOpenStyle && (
                        <ul
                          className='shadow-dropShadow rounded-b-2xl dropdown__menu absolute z-50 bg-[#ffffff] w-[224px] text-center text-[14px] text-primaryGray max-h-[220px] overflow-y-auto'
                          // style={{
                          //   boxShadow:
                          //     ' rgba(149, 157, 165, 0.2) 0px 8px 24px',
                          // }}
                        >
                          {styleMenuList &&
                            styleMenuList.map((style) => (
                              <li
                                className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'
                                onClick={() => {
                                  handleStyleMenuClick(style);
                                }}
                              >
                                {style.name}
                              </li>
                            ))}
                        </ul>
                      )}
                    </div>
                  </div>

                  {/* tempi */}
                  <div className='flex flex-col '>
                    {/* <p className='text-[12px] primary-Gray pt-[10px]'>
                      Medium
                    </p> */}

                    <div
                      className='abc'
                      // onMouseEnter={toggleMenuMedium}
                      // onMouseLeave={toggleMenuMedium}
                      onClick={() => {
                        setIsOpenMedium(!isOpenMedium);
                      }}
                      ref={mediumClickOutside}
                    >
                      <button className='w-[224px] h-[44px] rounded-[5000px]  flex items-center justify-between  border-[1px] border-[#d6d6d6] px-[20px] text-[14px] text-primaryGray text-start'>
                        {mediumMenu ? (
                          <div className='flex flex-col gap-[1px]'>
                            <p className='text-[#bbbbbb] text-[11px] leading-[1]'>
                              Select Medium
                            </p>
                            <p className='text-primaryGray font-medium flex text-[14px] leading-[1]'>
                              {mediumMenu}
                            </p>
                          </div>
                        ) : (
                          // <span>Select Medium</span>

                          <div className='flex flex-col gap-[1px]'>
                            <p className='text-[#bbbbbb] text-[11px] leading-[1]'>
                              Select Medium
                            </p>
                            <p className='text-primaryGray font-medium flex text-[14px] leading-[1]'>
                              Select Medium
                            </p>
                          </div>
                        )}
                        <div>
                          <DropArrow
                            className={`fill-[#878787]  ${
                              isOpenMedium ? 'rotate-180' : ''
                            }`}
                          />
                        </div>
                        {/* {mediumMenu} */}
                      </button>
                      {isOpenMedium && (
                        <ul
                          className='shadow-dropShadow rounded-b-2xl dropdown__menu absolute z-50 bg-[#ffffff] w-[224px] text-center text-[14px] text-primaryGray max-h-[220px] overflow-y-auto'
                          style={{
                            boxShadow:
                              ' rgba(149, 157, 165, 0.2) 0px 8px 24px',
                          }}
                        >
                          {/* {mediumMenuList &&
                            mediumMenuList.map((medium) => (
                              <li
                                className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'
                                onClick={() => {
                                  handleMediumMenuClick(medium);
                                }}
                              >
                                {medium.mediumType}
                              </li>
                            ))} */}
                        </ul>
                      )}
                    </div>
                  </div>
                  {/* tempi */}
                </div>
              </div>

              {/* tempi */}

              <div className='pt-[16px]'>
                {/* <p className='text-[12px] text-primaryGray'>
                  Description
                </p> */}
                <div className='relative'>
                  <textarea
                    name='description'
                    // placeholder='Art Description'
                    type='text'
                    className='resize-none border border-[#d6d6d6] leading-[18px] h-[203px] w-[456px] text-primaryGray text-[13px] rounded-[16px] px-[16px] pt-[34px] outline-none '
                    value={formData?.description || ''}
                    onChange={handleDescriptionChange}
                  />
                  <p className='absolute top-[16px] left-[16px] text-[11px] leading-[14px] text-[#bbbbbb]'>
                    Art Description
                  </p>
                  <p className='absolute bottom-[24px] left-[16px] text-[11px] text-[#bbbbbb]'>
                    {charCountt}/500 characters
                  </p>
                </div>
              </div>

              <div className='h-[1px] bg-[#efefef] w-[100%] mb-[27px] mt-[25px]'></div>

              <div className='flex flex-col w-[456px]'>
                <p className='text-[18px] text-primaryBlack font-medium'>
                  Select Type of Content
                </p>
                <p className='text-sm11 text-primaryGray mt-[11px]'>
                  Use the <span className='font-medium'>Notes </span>
                  section to explain, if your Art, Photos, Footage,
                  Music, or Template is Original <br />
                  Content, Art with Reference, Exclusive Art, Matured
                  Content, or Re-Submission.
                </p>

                <div className='flex flex-col gap-[5px] pt-[13px]'>
                  <label
                    style={{
                      width: 'fit-content',
                    }}
                    className='containerCheckGray text-[13px] text-primaryGray '
                  >
                    <input
                      type='checkbox'
                      name='typeOfContent'
                      value='Original Art'
                      onChange={handleInputChange}
                      checked={typeOfContentCheckoxesTick(
                        'Original Art'
                      )}
                    />{' '}
                    Original Art
                    <span className='checkmarkGray'></span>
                  </label>

                  <label
                    style={{
                      width: 'fit-content',
                    }}
                    className='containerCheckGray text-[13px] text-primaryGray '
                  >
                    <input
                      type='checkbox'
                      name='typeOfContent'
                      value='Art with Reference'
                      onChange={handleInputChange}
                      checked={typeOfContentCheckoxesTick(
                        'Art with Reference'
                      )}
                    />{' '}
                    Art with Reference
                    <span className='checkmarkGray'></span>
                  </label>
                </div>
                <div className={`pt-[7px] `}>
                  <div className='flex flex-col w-[456px] flex-wrap border border-dashed rounded-[13px] pb-[8px] pt-[7px] pl-[8px] border-[#d6d6d6]'>
                    <p
                      className={`text-sm11 text-primaryGray ${
                        typeOfContentCheckoxesTick(
                          'Art with Reference'
                        )
                          ? ''
                          : 'opacity-[40%]'
                      }`}
                    >
                      Attach reference file (Acceptable File: JPEG,
                      72dpi/300dpi, 2 MB Maximum)
                    </p>

                    <div
                      className={`flex gap-[8px] pt-[8px] ${
                        typeOfContentCheckoxesTick(
                          'Art with Reference'
                        )
                          ? ''
                          : 'opacity-[40%]'
                      }`}
                    >
                      <input
                        type='file'
                        multiple
                        onChange={handleFileChange}
                        accept='image/*'
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                      />
                      {formData?.referenceFile?.map((card, index) => (
                        <div
                          style={{
                            backgroundImage: `url(${card})`,
                          }}
                          className='w-[64px] relative hover:brightness-[80%] h-[64px] rounded-[10px] flex justify-center items-center bg-no-repeat bg-center bg-cover transform translate-z-0'
                        >
                          <div
                            onClick={() => {
                              deleteReferenceImage(card);
                            }}
                          >
                            <Cross8 className='absolute herennow cursor-pointer top-[5px] right-[5px]' />
                          </div>
                        </div>
                        // <AttachFileCards key={index} card={card} />
                      ))}
                      <div
                        onClick={handleButtonClick}
                        className='w-[64px] h-[64px] bg-[#f7f7f7] rounded-[10px] flex justify-center items-center'
                      >
                        <div>
                          <Plus24Icon />
                        </div>
                        {/* <img
                      className='h-[25px] w-[25px]'
                      src={addsmall}
                    /> */}
                      </div>
                    </div>

                    {/* {cards.map((card) => (
                <div
                  style={{
                    backgroundImage: `url(${URL.createObjectURL(
                      card
                    )})`,
                  }}
                  className='w-[65px] h-[65px] rounded-[10px] flex justify-center items-center bg-no-repeat bg-center bg-cover transform translate-z-0'
                ></div>
              ))} */}
                  </div>
                </div>
                <div className='flex flex-col gap-[5px] pt-[6px]'>
                  <div className='flex items-center gap-[6px]'>
                    <label
                      style={{
                        width: 'fit-content',
                      }}
                      className='containerCheckGray text-[13px] text-primaryGray '
                    >
                      <input
                        type='checkbox'
                        name='typeOfContent'
                        value='Exclusive Art'
                        onChange={handleInputChange}
                        checked={typeOfContentCheckoxesTick(
                          'Exclusive Art'
                        )}
                      />{' '}
                      Exclusive Art
                      <span className='checkmarkGray'></span>
                    </label>
                    <div>
                      <Exclusive_Icon16 className='fill-[#757575]' />
                    </div>
                  </div>

                  <div className='flex items-center gap-[8px]'>
                    <label
                      style={{
                        width: 'fit-content',
                      }}
                      className='containerCheckGray text-[13px] text-primaryGray '
                    >
                      <input
                        type='checkbox'
                        name='typeOfContent'
                        value='Featured Art'
                        onChange={handleInputChange}
                        checked={typeOfContentCheckoxesTick(
                          'Featured Art'
                        )}
                      />{' '}
                      Featured Art
                      <span className='checkmarkGray'></span>
                    </label>
                    <div>
                      <Featured_Icon16 className='fill-[#757575]' />
                    </div>
                  </div>

                  <label
                    style={{
                      width: 'fit-content',
                    }}
                    className='containerCheckGray text-[13px] text-primaryGray '
                  >
                    <input
                      type='checkbox'
                      name='typeOfContent'
                      value='Matured Art'
                      onChange={handleInputChange}
                      checked={typeOfContentCheckoxesTick(
                        'Matured Art'
                      )}
                    />{' '}
                    Matured Art
                    <span className='checkmarkGray'></span>
                  </label>
                  <label
                    style={{
                      width: 'fit-content',
                    }}
                    className='containerCheckGray text-[13px] text-primaryGray '
                  >
                    <input
                      type='checkbox'
                      name='typeOfContent'
                      value='Art Re-Submission'
                      onChange={handleInputChange}
                      checked={typeOfContentCheckoxesTick(
                        'Art Re-Submission'
                      )}
                    />{' '}
                    Art Re-Submission
                    <span className='checkmarkGray'></span>
                  </label>
                </div>
              </div>

              <div className='mt-[7px] relative'>
                {/* <p className='text-[12px] primary-Gray pt-[10px]'>
                    Image Title
                  </p> */}
                <input
                  type='text'
                  placeholder='Case Number'
                  className='w-[224px] h-[44px] rounded-[5000px] text-primaryGray text-[14px] border-[1px] border-[#d6d6d6] pl-[20px] pt-[9px] outline-none placeholder:text-[14px] placeholder:text-[#bbbbbb]'
                  // onChange={handleInputChange}
                  // readOnly
                  // value={userUniqueNo || ''}
                  name='caseNumber'
                  style={{ position: 'relative' }}
                />

                <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
                  Case Number
                </p>
              </div>

              <div className='pt-[32px]'>
                {/* <p className='text-[12px] text-primaryGray'>
                  Description
                </p> */}
                <div className='relative'>
                  <textarea
                    name='notes'
                    // placeholder='Notes'
                    type='text'
                    className='resize-none border border-[#d6d6d6] leading-[18px] text-primaryGray text-[13px] h-[132px] w-[456px] rounded-[16px] px-[16px] pt-[34px] text-[13px] outline-none '
                    value={formData.notes}
                    onChange={handleInputChange}
                  />
                  <p className='absolute top-[16px] left-[16px] text-[11px] leading-[14px] text-[#bbbbbb]'>
                    Notes
                  </p>
                  <p className='absolute bottom-[24px] left-[16px] text-[11px] text-[#bbbbbb]'>
                    {/* {charCountt}/500 characters */}0/250
                    characters
                  </p>
                </div>
              </div>

              {/* tempi */}
              <div className='h-[1px] bg-[#efefef] w-[100%] mb-[27px] mt-[25px]'></div>

              {/* release test */}

              <div>
                <div className='w-[456px]'>
                  <p className='text-[18px] text-primaryBlack font-medium mb-[11px]'>
                    Attach Release(s)
                  </p>
                  <p className='text-[11px] text-primaryGray leading-[13px] mb-[14px]'>
                    The release form is a legal document signed to
                    protect copyrights. It could be a work of any type
                    of art: Music, Visual/Graphic Arts, Literature,
                    Performing Arts, and Decorative Arts.
                  </p>
                  <p className='text-[11px] text-[#bbbbbb] mb-[6px]'>
                    Acceptable File: PDF/JPEG, 300dpi, 4 MB Maximum
                  </p>
                </div>

                <div></div>

                {tempRelese?.length > 0 && (
                  <div className='text-[0.750rem] border-t border-b border-t-[2px] border-b-[1px] border-t-[#efefef] border-b-[#efefef] '>
                    {tempRelese.map((obj, index) => (
                      <p
                        key={index}
                        className={`flex justify-between h-[49px] items-center border-b border-[#efefef]`}
                      >
                        <div className='flex gap-[8px] items-center'>
                          {/* <input
                            type='checkbox'
                            onChange={() =>
                              handleCheckboxChangeRelease(obj)
                            }
                          /> */}
                          <div>
                            <label
                              style={{
                                width: 'fit-content',
                              }}
                              className='containerCheckGray text-[13px] text-primaryGray '
                            >
                              <input
                                type='checkbox'
                                // name='typeOfContent'
                                // value='Art Re-Submission'
                                onChange={() =>
                                  handleCheckboxChangeRelease(obj)
                                }
                                // checked={typeOfContentCheckoxesTick(
                                //   'Art Re-Submission'
                                // )}
                              />{' '}
                              <span className='checkmarkGray'></span>
                            </label>
                          </div>

                          <div
                            style={{
                              backgroundImage: `url(${thumbnail?.secureUrl})`,
                            }}
                            className='w-[32px] h-[32px] bg-center bg-cover rounded-[8px]'
                          ></div>
                          <div>
                            <p className='text-sm12 font-medium text-primaryGray'>
                              {obj.cards.name}
                            </p>
                            <p className='text-[11px] leading-[13px]  text-primaryGray'>
                              File size:{' '}
                              {formatFileSize(obj.cards.size)}
                            </p>
                          </div>
                        </div>
                        <div className='flex gap-[12px]'>
                          <div className='pt-[0.188rem]'>
                            {/* <img src={view} alt='' /> */}
                            <ViewIcon />
                          </div>
                          <div>
                            {/* <img src={edit} alt='' /> */}
                            <EditIcon />
                          </div>
                          <div>
                            {/* <img src={deletee} alt='' /> */}
                            <DeleteIcon />
                          </div>
                        </div>
                      </p>
                    ))}
                  </div>
                )}

                <div className='flex gap-[8px] mt-[16px]'>
                  <div>
                    <input
                      type='file'
                      multiple
                      onChange={handleFileChangeRelease}
                      accept='image/*'
                      style={{ display: 'none' }}
                      ref={fileInputRefRelease}
                    />
                    <button
                      onClick={handleButtonClickRelease}
                      className='gray30HButton'
                    >
                      Attach Release
                    </button>
                  </div>

                  <button className='greenBlueButton'>
                    Download New Release Form
                  </button>
                </div>
              </div>
              {/* release test */}

              <div className='h-[1px] bg-[#efefef] w-[100%] mb-[27px] mt-[32px]'></div>

              <div className='flex flex-col w-[456px]'>
                <p className='text-[18px] text-primaryBlack font-medium'>
                  Select Commercial Usage
                </p>
                <p className='text-sm11 text-primaryGray mt-[11px]'>
                  Used to Commercialize, Monetize, Sell, Promote,
                  Advertise a Product, and Business or <br />
                  Service. It could be used on Web Media, Print Media,
                  and Merchandise Products.
                </p>
                <div className='flex flex-col gap-[5px] pt-[13px]'>
                  <label
                    style={{
                      width: 'fit-content',
                    }}
                    className='containerCheckGray text-[13px] text-primaryGray '
                  >
                    <input
                      type='checkbox'
                      name='commercialUser'
                      value='Web Media'
                      onChange={handleInputChange}
                      checked={commercialUsageCheckoxesTick(
                        'Web Media'
                      )}
                    />{' '}
                    Web Media
                    <span className='checkmarkGray'></span>
                  </label>

                  <label
                    style={{
                      width: 'fit-content',
                    }}
                    className='containerCheckGray text-[13px] text-primaryGray '
                  >
                    <input
                      type='checkbox'
                      name='commercialUser'
                      value='Print Media'
                      onChange={handleInputChange}
                      checked={commercialUsageCheckoxesTick(
                        'Print Media'
                      )}
                    />{' '}
                    Print Media
                    <span className='checkmarkGray'></span>
                  </label>

                  <label
                    style={{
                      width: 'fit-content',
                    }}
                    className='containerCheckGray text-[13px] text-primaryGray '
                  >
                    <input
                      type='checkbox'
                      name='commercialUser'
                      value='Print on Products'
                      onChange={handleInputChange}
                      checked={commercialUsageCheckoxesTick(
                        'Print on Products'
                      )}
                    />{' '}
                    Print on Products
                    <span className='checkmarkGray'></span>
                  </label>

                  <label
                    style={{
                      width: 'fit-content',
                    }}
                    className='containerCheckGray text-[13px] text-primaryGray '
                  >
                    <input
                      type='checkbox'
                      name='commercialUser'
                      value='Sell the rights'
                      onChange={handleInputChange}
                      checked={commercialUsageCheckoxesTick(
                        'Sell the rights'
                      )}
                    />{' '}
                    Sell the rights
                    <span className='checkmarkGray'></span>
                  </label>
                </div>
              </div>

              <div className='h-[2px] bg-[#efefef] w-[100%] mb-[27px] mt-[31px]'></div>

              <div className=' w-[456px]'>
                <div className=''>
                  <p className='text-[18px] text-primaryBlack font-medium'>
                    Art Print Markup
                  </p>
                  <p className='text-sm11 text-primaryGray mt-[11px]'>
                    Artnstock amount gets added to your markup to
                    arrive at the final sell price for print on paper
                    or canvas. Note that Mark-up is 10% fixed for all
                    prints on products.
                  </p>
                </div>

                <div className='mt-[11px]'>
                  <table className='border-separate border-spacing-0 '>
                    <tbody>
                      <tr className='text-[14px] font-medium bg-[#ececec] '>
                        <td className='pl-[8px] w-[114px] text-primaryBlack h-[36px] border border-[#d6d6d6] rounded-tl-[16px]'>
                          Size
                        </td>
                        <td className='pl-[8px] w-[114px] text-primaryBlack h-[36px] border-t border-r border-b border-[#d6d6d6]'>
                          Markup
                        </td>
                        <td className='pl-[8px] w-[114px] text-primaryBlack h-[36px] border-t border-r border-b border-[#d6d6d6]'>
                          Base Price
                        </td>
                        <td className='pl-[8px] w-[114px] text-primaryBlack h-[36px] border-t border-r border-b border-[#d6d6d6] rounded-tr-[16px]'>
                          Selling Price
                        </td>
                      </tr>

                      <tr className='text-[14px] font-medium'>
                        <td className='pl-[8px] text-primaryBlack text-[13px] h-[36px] border border-[#d6d6d6] bg-[#f7f7f7] rounded-bl-[16px]'>
                          Large
                        </td>
                        <td className='pl-[8px] border-t border-r border-b border-[#d6d6d6]'>
                          <input
                            style={{
                              /* Hide the inner spin buttons */
                              '::-webkit-inner-spin-button': {
                                display: 'none',
                              },
                              '::-webkit-outer-spin-button': {
                                display: 'none',
                              },
                              '-moz-appearance': 'textfield',
                              appearance: 'none',
                              MozAppearance: 'textfield!important',
                              WebkitAppearance: 'none!important',
                              appearance: 'none!important',
                            }}
                            type='number'
                            name='price'
                            className='w-[90px] h-[28px] border border-[#d6d6d6] text-primaryGray text-[13px] font-normal rounded-[5000px] pl-[8px] outline-none'
                            onChange={handleInputChangePrice}
                            value={formData.price}
                            // placeholder='Enter Keywords'
                          />
                        </td>
                        <td className='pl-[8px]  text-[13px] h-[36px] text-primaryGray border-t border-r border-b border-[#d6d6d6] bg-[#f7f7f7]'>
                          <span className='opacity-[40%]'>
                            1250.00
                          </span>
                        </td>
                        <td className='pl-[8px]  text-[13px] h-[36px] border-t text-primaryGray border-r border-b border-[#d6d6d6] font-normal rounded-br-[16px] '>
                          2250.00
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className='h-[2px] bg-[#efefef] w-[100%] mb-[27px] mt-[31px]'></div>

              <div>
                <div className='w-[456px]'>
                  <p className='text-[18px] text-primaryBlack font-medium'>
                    Add Keywords
                  </p>
                  <p className='text-sm11 text-primaryGray mt-[11px]'>
                    Keywords helps you understand what users are
                    searching for and the content you need to provide
                    to meet their needs.
                  </p>

                  <div className='relative mt-[15px]'>
                    <input
                      type='text'
                      className='w-[100%] h-[44px] rounded-[5000px] text-primaryGray text-[14px] pt-[9px] border border-[#d6d6d6] pl-[20px] outline-none  placeholder:text-[14px] placeholder:text-[#bbbbbb]'
                      placeholder='Enter Keywords'
                      onChange={keywordText}
                      value={textWord}
                    />
                    <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
                      Add minimum 7 and maximum 50 keywords
                    </p>
                    <button
                      onClick={addKeyword}
                      className='w-[42px] absolute h-[28px] bg-[#eeeeee] border-[1px] border-[#e9e9e9] font-medium text-primaryGray leading-[1] text-[11px] rounded-[5000px] top-[8px] right-[8px]'
                    >
                      Add
                    </button>
                  </div>
                </div>
                <div className=''>
                  {/* <div className='flex justify-between w-[220px]'>
                    <p className='text-[11px] text-primaryGray'>
                      Add at least 7 keywords
                    </p>
                    <p className='text-[11px] text-primaryGray'>
                      0/50 characters
                    </p>
                  </div> */}
                  <div className='pt-[29px]'>
                    <p className='text-sm11 text-[#bbbbbb] '>
                      11/15 Keywords Added
                    </p>

                    {formData?.keywords?.length > 0 && (
                      <div className='flex flex-wrap gap-[5px] mt-[8px]'>
                        {formData?.keywords?.map((keyword) => (
                          <div className='text-[11px] flex items-center text-primaryGray leading-[1] border border-[#dddddd] rounded-[13px] px-[8px] h-[26px]'>
                            {keyword} &nbsp;&nbsp;
                            {/* <span
                            onClick={() => {
                              deleteKeyword(keyword);
                            }}
                            className='text-[15px] text-primaryGray cursor-pointer'
                          >
                            x
                          </span> */}
                            <div>
                              <Cross8
                                className=' cursor-pointer'
                                onClick={() => {
                                  deleteKeyword(keyword);
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  {/* <div className='flex gap-[15px] pt-[10px]'>
                    <p className='text-primaryGray text-[12px]'>
                      Copy Keywords
                    </p>
                    <p className='text-primaryGray text-[12px]'>
                      Paste Keywords
                    </p>
                    <p className='text-orangeColor text-[12px]'>
                      Clear Keywords
                    </p>
                  </div> */}

                  <div className='mt-[16px]'>
                    <button className='gray30HBorderButton'>
                      Clear All Keywords
                    </button>
                  </div>
                </div>
              </div>

              <div className='h-[2px] bg-[#efefef] w-[100%] my-[32px]'></div>
            </div>
          </div>

          <div className='flex w-[100%] justify-center text-center gap-[8px] mt-[32px]'>
            {/* <button
              onClick={addToActivateProd}
              className='bg-primaryBlack text-[white] h-[40px] w-[88px] rounded-[30px] text-[14px]'
            >
              Add Details
            </button> */}

            <button onClick={addToActivateProd} className='blackBtn'>
              Continue
            </button>
            <button className='outlineBtn'>Cancel</button>
            {/* <button className='h-[40px] px-6 py-2 rounded-3xl text-sm14 text-primaryBlack border-[2px]'>
              Cancel
            </button> */}
          </div>
        </>
      ) : (
        <div className='text-center pt-[8px]'>
          <p className='text-pinkColor text-[18px]'>
            Upload your art files
          </p>
          <p className='text-primaryGray text-[12px]'>
            Get started by uploading your art files to submit
          </p>
          <button className='blackBtn mt-[20px]'>Upload Files</button>
        </div>
      )}
    </>
  );
};

export default AddDetails;
