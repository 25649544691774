import selectall from '../../../assets/images/contributor/selectall.png';
import deleteicon from '../../../assets/images/contributor/delete-icon.png';
import undo from '../../../assets/images/contributor/undo.png';
import redo from '../../../assets/images/contributor/redo.png';
import save from '../../../assets/images/contributor/save.png';
import keyword from '../../../assets/images/contributor/keyword.png';
import GrayBox from './GrayBox';
import addImage from '../../../assets/images/contributor/add.png';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setNestedTabValueUpload } from '../../../store/nestedTabSlice';
import { setpath2 } from '../../../store/contriPathSlice';
import { setSelectedImages } from '../../../store/imageSlice';
import { httpClient } from '../../../axios';

import { ReactComponent as SelectAll } from '../../../assets/images/contributor/svg/Icon_SelectAll.svg';
import { ReactComponent as Deleteicon } from '../../../assets/images/contributor/svg/Icon_DeleteFiles.svg';
import { ReactComponent as Save } from '../../../assets/images/contributor/svg/Icon_SaveDraft.svg';
import { ReactComponent as Keyword } from '../../../assets/images/contributor/svg/Icon_KeywordManager.svg';
import { ReactComponent as Add } from '../../../assets/images/contributor/svg/Icon_Add.svg';

import { loaderSliceAction } from '../../../store/loaderSlice';

const UploadContent = () => {
  const add = addImage;
  const dispatch = useDispatch();

  const selectedImages = useSelector(
    (state) => state.images.selectedImages
  );

  const userId = useSelector((state) => state.auth.userId);

  const MakeLoaderVisible = () => {
    dispatch(loaderSliceAction.setLoaderTrue());
  };
  const MakeLoaderInvisible = () => {
    dispatch(loaderSliceAction.setLoaderFalse());
  };

  const [cards, setCards] = useState([]);
  const [button, setButton] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const files = event.target.files;
    const newCards = Array.from(files);
    setCards((prevCards) => [...prevCards, ...newCards]);
  };

  useEffect(() => {
    if (cards.length < 15) {
      setgrayNum(grayBox.length - cards.length);
    }

    console.log(cards.length);
  }, [cards]);

  // drag and drop
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    const newCards = Array.from(droppedFiles);
    setCards((prevCards) => [...prevCards, ...newCards]);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (cards.length > 0) {
      setButton(true);
    } else {
      setButton(false);
    }
  }, [cards]);

  useEffect(() => {
    dispatch(setpath2('/ Upload Files'));
  }, []);

  const next = async () => {
    // MakeLoaderVisible();
    const tempImag = [];

    try {
      const uploadPromises = cards.map(async (card) => {
        let formData = new FormData();
        formData.append('file', card);
        try {
          const res = await httpClient.post(
            '/CloudinaryImageUpload?parameter=true',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
          console.log('file uploaded successfully: ', res.data);
          tempImag.push(res.data.imageId);
        } catch (err) {
          console.log(err);
        }
      });

      await Promise.all(uploadPromises);

      let obj = {
        imageId: tempImag,
        userId: userId,
      };
      // console.log(obj);
      const res = await httpClient.post('/draft_master/create', obj);
      console.log(res.data);
      dispatch(setNestedTabValueUpload('2.2'));
      // MakeLoaderInvisible();
    } catch (err) {
      // MakeLoaderInvisible();
      console.log(err);
    }
  };

  const grayBox = [
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
  ];

  const [grayNum, setgrayNum] = useState(grayBox.length);

  useEffect(() => {
    console.log(grayNum);
  }, [grayNum]);
  return (
    <div className='font-heebo'>
      <div className='flex justify-center mb-[16px]'>
        <div
          style={{
            width: 'fit-content',
          }}
          className='flex border-t border-b border-t-[#efefef] border-b-[#efefef]'
        >
          <div className='flex items-center gap-[5px]'>
            <span className='contributorUploadCount text-pinkColor'>
              13
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='contributorUploadtext pr-[8px]'>
                File(s) to be
                <br />
                submitted to
                <br />
                Artnstock
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span className='contributorUploadCount text-[#f5721a] opacity-[20%] pl-[5px]'>
              0
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                File(s)
                <br />
                pending for
                <br />
                review
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span className=' contributorUploadCount text-[#a9af20] opacity-[20%]  pl-[5px]'>
              0
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                File(s)
                <br />
                approved by
                <br />
                Artnstock
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span className='contributorUploadCount text-[#e7233b] opacity-[20%]  pl-[5px]'>
              0
            </span>
            <div className='opacity-[40%] h-[40px] flex items-center'>
              <p className='contributorUploadtext'>
                File(s)
                <br />
                rejected by
                <br />
                Artnstock
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='contributorTitleStyling'>
        <div className='w-[1168px] flex justify-between'>
          <div className='flex flex-col gap-[2px] max-w-[320px] w-[100%]'>
            <div className='flex gap-[8px] items-baseline'>
              <p className='text-[25px] font-medium leading-[1] text-primaryBlack'>
                Upload Files
              </p>
              <p className='text-pinkColor text-[11px] leading-[13px] '>
                {cards?.length}/15 File(s) uploaded
              </p>
            </div>
            <p className='text-[11px] text-primaryGray leading-[14px]'>
              Upload 15 files (maximum) in a single upload. Click
              Continue <br /> to Add Details and Activate Products.
              <span className='text-orangeColor'> Learn more</span>
            </p>
          </div>

          <div className='flex'>
            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef]'>
              <div className='flex flex-col mt-[1px] gap-[8px] w-[52px] text-center opacity-[40%]'>
                <div>
                  <SelectAll className='mx-[auto]' />
                </div>
                <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                  Select <br /> All
                </p>
              </div>
            </div>

            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef]'>
              <div className='flex flex-col  mt-[1px] opacity-[40%] gap-[8px] w-[52px] text-center '>
                <div>
                  <Deleteicon className='mx-[auto]' />
                </div>
                <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                  Delete Image
                </p>
              </div>
            </div>

            {/* <div className='flex-column max-w-[34px] text-center'>
              <img
                src={undo}
                alt=''
                className='w-[16px] h-[16px] mx-[auto] mb-[6px]'
              />
              <p className='text-primaryGray text-[11px]'>
                Undo Changes
              </p>
            </div>
            <div className='flex-column max-w-[34px] text-center'>
              <img
                src={redo}
                alt=''
                className='w-[16px] h-[16px] mx-[auto] mb-[6px]'
              />
              <p className='text-primaryGray text-[11px]'>
                Redo Changes
              </p>
            </div> */}

            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef] border-r-[2px]'>
              <div className='flex flex-col  mt-[1px] opacity-[40%] gap-[8px] w-[52px] text-center '>
                <div>
                  <Save className='mx-[auto]' />
                </div>
                <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                  Save <br /> Draft
                </p>
              </div>
            </div>

            {/* <div className=' w-[53px] text-center border-r border-r-[#efefef] '>
              <img
                src={save}
                alt=''
                className='w-[16px] h-[16px] mx-[auto] mb-[6px]'
              />
              <p className='text-primaryGray text-[11px] text-center'>
                Save <br /> Draft
              </p>
            </div> */}

            <div className='contributorUploadIconsMainDiv '>
              <div className='flex flex-col  mt-[1px] gap-[8px] w-[52px] text-center '>
                <div>
                  <Keyword className='mx-[auto]' />
                </div>
                <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                  Keyword Manager
                </p>
              </div>
            </div>
          </div>
          <div className='pl-[206px] leading-[1] '>
            <p className='text-[11px] text-primaryGray leading-[14px]'>
              Files uploaded this week
            </p>
            <p className='text-[25px] leading-[0.9] text-pinkColor  font-medium'>
              84/250
              <span className='text-[11px] font-normal ml-[2px]'>
                File(s)
              </span>
            </p>
            <button className='bg-[#888888] text-[white] text-[10px]  font-normal leading-[1] flex items-center rounded-[20px] px-[8px] h-[17px]'>
              Files Upload Summary
            </button>
          </div>
        </div>
      </div>
      <div className='w-[100%] flex justify-center'>
        <div
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          className='w-[1168px] justify-center flex-column border-[2px] border-[#efefef] rounded-[32px] gap-[10px] py-[32px]'
        >
          <div className='w-[100%] flex justify-center'>
            <p className='text-pinkColor text-[18px] leading-[22px]'>
              Drag and Drop your files here or
            </p>
          </div>
          <div className='w-[100%] flex justify-center mt-[5px]'>
            <input
              type='file'
              multiple
              onChange={handleFileChange}
              accept='image/*'
              style={{ display: 'none' }}
              ref={fileInputRef}
            />
            <button
              onClick={handleButtonClick}
              className='text-[#333333] bg-[#00f9c6] rounded-[5000px] text-[12px] leading-[1] px-[13px] h-[28px] flex items-center font-medium'
            >
              Select Multiple Files
            </button>
          </div>
          {/* <div className='w-[100%] flex justify-center mt-[6px]'>
            <p className='text-primaryGray text-[11px] leading-[14px]'>
              (Maximum 15 Images in a single upload)
            </p>
          </div> */}
          {/* gap-[16.68px] px-[16.68px] */}
          <div className='flex justify-start flex-wrap gap-[16px] px-[18px] pt-[17px] pb-[32px]'>
            {cards.map((card, index) => (
              <div>
                <GrayBox key={index} card={card} />
              </div>
            ))}

            <div
              onClick={handleButtonClick}
              className={
                'h-[127px] w-[127px] bg-[#f7f7f7] rounded-[16px] flex items-center justify-center cursor-pointer'
              }
            >
              {/* <div className='w-[100%]'> */}
              {/* <img
                  className='m-[auto] mt-[45px]'
                  src={add}
                  draggable='false'
                /> */}
              <Add className='fill-[#dddddd]' />
              {/* </div> */}
            </div>

            {grayBox.slice(0, grayNum).map((card, index) => (
              <div
                className={
                  'h-[127px] w-[127px] bg-[#f7f7f7] rounded-[16px]'
                }
              ></div>
            ))}
          </div>
          <div className='w-[100%] px-[16.68px]'>
            <div className='bg-[#efefef] h-[2px] w-[100%]'></div>
          </div>

          <div className='flex flex-wrap justify-center pt-[32px]'>
            <div className='pr-[16px] border-r border-[#efefef]'>
              <p className='text-[#333333] text-[15px] leading-[18px] font-medium mb-[15px]'>
                Art
              </p>
              <div className='flex gap-[8px]'>
                <div className='flex flex-col'>
                  <p className='uploadPageData'>File Format:</p>
                  <p className='uploadPageData'>File Size:</p>
                  <p className='uploadPageData'>Colour Space:</p>
                  <p className='uploadPageData'>Resolution:</p>
                  <p className='uploadPageData'>
                    Horizontal Size:
                    <br />
                    <br />
                  </p>
                  <p className='uploadPageData'>
                    Vertical Size:
                    <br />
                    <br />
                  </p>
                  <p className='uploadPageData'>Square Size:</p>
                </div>
                <div>
                  <p className='uploadPageData'>
                    JPEG/EPS for Vector
                  </p>
                  <p className='uploadPageData'>150 MB (Maximum)</p>
                  <p className='uploadPageData'>RGB</p>
                  <p className='uploadPageData'>300 DPI</p>
                  <p className='uploadPageData'>
                    Width 47 Inches (Fixed) <br /> Height 25-33 Inches
                  </p>
                  <p className='uploadPageData'>
                    Width 25-33 Inches <br />
                    Height 47 Inches (Fixed){' '}
                  </p>
                  <p className='uploadPageData'>
                    47 x 47 Inches (Fixed)
                  </p>
                  {/* <p className='uploadPageData'>
                    JPEG (Quality: Maximum)
                  </p> */}
                </div>
              </div>
            </div>

            <div className='px-[16px] border-r border-[#efefef]'>
              <p className='text-[#333333] text-[15px] leading-[18px] font-medium mb-[15px]'>
                Photo
              </p>
              <div className='flex gap-[8px]'>
                <div>
                  <p className='uploadPageData'>File Format:</p>
                  <p className='uploadPageData'>File Size:</p>
                  <p className='uploadPageData'>Colour Space:</p>
                  <p className='uploadPageData'>Resolution:</p>
                  <p className='uploadPageData'>
                    Horizontal Size: <br />
                    <br />
                  </p>
                  <p className='uploadPageData'>
                    Vertical Size: <br />
                    <br />
                  </p>
                  <p className='uploadPageData'>Square Size: </p>
                </div>

                <div>
                  <p className='uploadPageData'>JPEG</p>
                  <p className='uploadPageData'>50 MB (Maximum) </p>
                  <p className='uploadPageData'>RGB</p>
                  <p className='uploadPageData'>300 DPI</p>

                  <p className='uploadPageData'>
                    Width 36 Inches (Fixed)
                  </p>
                  <p className='uploadPageData'>
                    Height 16-24 Inches
                  </p>
                  <p className='uploadPageData'>Width 16-24 Inches</p>
                  <p className='uploadPageData'>
                    Height 36 Inches (Fixed)
                  </p>
                  <p className='uploadPageData'>
                    6 x 36 Inches (Fixed)
                  </p>
                </div>
              </div>
            </div>

            <div className='px-[16px] border-r border-[#efefef]'>
              <p className='text-[#333333] text-[15px] leading-[18px] font-medium mb-[15px]'>
                Footage
              </p>
              <div className='flex gap-[8px]'>
                <div>
                  <p className='uploadPageData'>
                    File Format: <br />
                    <br />
                    <br />
                  </p>
                  <p className='uploadPageData'>File Size:</p>
                  <p className='uploadPageData'>
                    Frames-Per-Second: <br />
                    <br />
                    <br />
                  </p>
                  <p className='uploadPageData'>
                    Resolution:
                    <br />
                    <br />
                  </p>
                  <p className='uploadPageData'>Clip Duration:</p>
                </div>
                <div>
                  <p className='uploadPageData'>ASF, AVI, DV, FLV,</p>
                  <p className='uploadPageData'>MOV, MPEG/MPG,</p>
                  <p className='uploadPageData'>WMV</p>
                  <p className='uploadPageData'>250 MB (Maximum)</p>
                  <p className='uploadPageData'>
                    23.98, 24, 25, 29.97,
                  </p>
                  <p className='uploadPageData'>
                    30, 47.95, 47.96, 48,
                  </p>
                  <p className='uploadPageData'>50, 59.94, 60</p>
                  <p className='uploadPageData'>4K (4096 x 2160)</p>
                  <p className='uploadPageData'>HD (1920 x 1080)</p>
                  <p className='uploadPageData'>5 - 60 Seconds</p>
                </div>
              </div>
            </div>

            <div className='px-[16px] border-r border-[#efefef]'>
              <p className='text-[#333333] text-[15px] leading-[18px] font-medium mb-[15px]'>
                Music
              </p>
              <div className='flex gap-[8px]'>
                <div>
                  <p className='uploadPageData'>File Format:</p>
                  <p className='uploadPageData'>File Size:</p>
                  <p className='uploadPageData'>Bit Depth:</p>
                  <p className='uploadPageData'>Sample Rate:</p>
                  <p className='uploadPageData'>Clip Duration:</p>
                </div>

                <div>
                  <p className='uploadPageData'>WAV, MP3</p>
                  <p className='uploadPageData'>50 MB (Maximum)</p>
                  <p className='uploadPageData'>16 or 24 bit</p>
                  <p className='uploadPageData'>44.1 or 44kHz</p>
                  <p className='uploadPageData'>
                    2:30 - 3:00 Minutes
                  </p>
                </div>
              </div>
            </div>

            <div className='pl-[16px]'>
              <p className='text-[#333333] text-[15px] leading-[18px] font-medium mb-[15px]'>
                Templates
              </p>
              <div className='flex gap-[8px]'>
                <div className='flex flex-col'>
                  <p className='uploadPageData'>File Format:</p>
                  <p className='uploadPageData'>File Size:</p>
                  <p className='uploadPageData'>Colour Space:</p>
                  <p className='uploadPageData'>Resolution:</p>
                  <p className='uploadPageData'>
                    Horizontal Size:
                    <br />
                    <br />
                  </p>
                  <p className='uploadPageData'>
                    Vertical Size:
                    <br />
                    <br />
                  </p>
                  <p className='uploadPageData'>Square Size:</p>
                </div>
                <div>
                  <p className='uploadPageData'>
                    JPEG/EPS for Vector
                  </p>
                  <p className='uploadPageData'>150 MB (Maximum)</p>
                  <p className='uploadPageData'>RGB</p>
                  <p className='uploadPageData'>300 DPI</p>
                  <p className='uploadPageData'>
                    Width 47 Inches (Fixed) <br /> Height 25-33 Inches
                  </p>
                  <p className='uploadPageData'>
                    Width 25-33 Inches <br />
                    Height 47 Inches (Fixed){' '}
                  </p>
                  <p className='uploadPageData'>
                    47 x 47 Inches (Fixed)
                  </p>
                  {/* <p className='uploadPageData'>
                    JPEG (Quality: Maximum)
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='w-[100%] text-center pt-[32px]'>
        {button == true ? (
          <button onClick={next} className='blackBtn mx-auto block'>
            Continue
          </button>
        ) : (
          <button className='grayBtn mx-auto block'>Continue</button>
        )}
      </div>
    </div>
  );
};

export default UploadContent;
