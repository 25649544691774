import React from 'react';
import { useState } from 'react';
import { Switch } from '@headlessui/react';
import pro from '../../assets/images/accountSettings/pro.png';

import { ReactComponent as ProIcon } from '../../assets/images/art-details/Icon_Pro.svg';

import { ReactComponent as DropArrow } from '../../assets/images/Icons/Down arrow.svg';

import { ReactComponent as Globe } from '../../assets/images/socials/globeIcon.svg';

const Contributor_Preferences = () => {
  const [enabled, setEnabled] = useState(true);
  const [enabled1, setEnabled1] = useState(true);
  const [enabled2, setEnabled2] = useState(false);
  const [enabled3, setEnabled3] = useState(false);
  const [enabled4, setEnabled4] = useState(false);

  const [isOpenLang, setIsOpenLang] = useState(false);

  return (
    <>
      <div className='flex justify-between'>
        <div>
          <p className='text-[25px] leading-[1] font-medium text-primaryBlack'>
            Preferences
          </p>
          <p className='text-sm11 text-pinkColor'>
            All fields are required.
          </p>
        </div>
        <div>
          <div className='border border-[#eaeaea] rounded-[7px] h-[41px] flex items-center p-[6px]'>
            <div className='flex flex-col gap-[3px]'>
              <ProIcon />
              <p className='text-[11px] leading-[1] text-primaryGray'>
                Member since 5 July 2020
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='mt-[30px]'>
        <p className='text-[#333333] text-[15px] font-medium'>
          Email Preferences
        </p>
        <p className='text-sm11 text-[#757575] '>
          What type of communication would you like to receive from
          Artnstock?
        </p>

        <div className='text-[13px] mt-[15px] text-[#757575]'>
          <div className='border-t-[1px] flex justify-between items-center h-[34px] border-[#efefef]'>
            <span>
              Design inspiration, tutorials, and professionally
              curated content
            </span>

            <Switch
              checked={enabled}
              onChange={setEnabled}
              className={`${enabled ? 'bg-teal-900' : 'bg-teal-700'}
          relative inline-flex h-[25px] w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span className='sr-only'>Use setting</span>
              <span
                aria-hidden='true'
                className={`${
                  enabled ? 'translate-x-6' : 'translate-x-0.5'
                }
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-[#333333] shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
          </div>
          <div className='border-t-[1px] flex justify-between  items-center h-[34px] border-[#efefef]'>
            <span>Special offers and amazing deals</span>

            <Switch
              checked={enabled1}
              onChange={setEnabled1}
              className={`${enabled1 ? 'bg-teal-900' : 'bg-teal-700'}
          relative inline-flex h-[25px] w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span className='sr-only'>Use setting</span>
              <span
                aria-hidden='true'
                className={`${
                  enabled1 ? 'translate-x-6' : 'translate-x-0.5'
                }
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-[#333333] shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
          </div>
          <div className='border-t-[1px] flex justify-between  items-center h-[34px] border-[#efefef]'>
            <span>Product education and update</span>

            <Switch
              checked={enabled2}
              onChange={setEnabled2}
              className={`${enabled2 ? 'bg-teal-900' : 'bg-teal-700'}
          relative inline-flex h-[25px] w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span className='sr-only'>Use setting</span>
              <span
                aria-hidden='true'
                className={`${
                  enabled2 ? 'translate-x-6' : 'translate-x-0.5'
                }
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-[#333333] shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
          </div>
          <div className='border-t-[1px] flex justify-between  items-center h-[34px] border-[#efefef]'>
            <span>Mature content</span>

            <Switch
              checked={enabled3}
              onChange={setEnabled3}
              className={`${enabled3 ? 'bg-teal-900' : 'bg-teal-700'}
          relative inline-flex h-[25px] w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span className='sr-only'>Use setting</span>
              <span
                aria-hidden='true'
                className={`${
                  enabled3 ? 'translate-x-6' : 'translate-x-0.5'
                }
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-[#333333] shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
          </div>
          <div className='border-y-[1px] flex justify-between  items-center h-[34px] border-[#efefef]'>
            <span>Show content marked as mature</span>

            <Switch
              checked={enabled4}
              onChange={setEnabled4}
              className={`${enabled4 ? 'bg-teal-900' : 'bg-teal-700'}
          relative inline-flex h-[25px] w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span className='sr-only'>Use setting</span>
              <span
                aria-hidden='true'
                className={`${
                  enabled4 ? 'translate-x-6' : 'translate-x-0.5'
                }
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-[#333333] shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
          </div>
        </div>

        <p className='mt-[30px] font-medium text-[15px] text-[#333333]'>
          Language Preferences
        </p>
        <p className='text-sm12 text-[#757575] mb-[3px] mt-3'>
          Select your Language
        </p>
        {/* <input
          type='text'
          name=''
          className='regInput mt-[0px] text-[#333333]'
          placeholder='Select Language'
          id=''
        /> */}

        <div>
          <button
            onClick={() => {
              setIsOpenLang(!isOpenLang);
            }}
            className={`${
              isOpenLang === true
                ? 'dropdownTwoGlobalTrue'
                : 'dropdownTwoGlobalFalse'
            } dropdownTwoGlobalCommon  w-[450px]`}
          >
            <div className='flex gap-[10px] items-center'>
              {/* <img src={globe} alt='' /> */}
              <div>
                <Globe />
              </div>
              <div className='flex flex-col'>
                <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                  Select
                </p>
                <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                  Language
                </p>
              </div>
            </div>

            <div>
              <DropArrow
                className={`${
                  isOpenLang ? 'transform rotate-180' : ''
                }`}
                style={{
                  fill: '#878787',
                }}
              />
            </div>
          </button>
          {isOpenLang && (
            <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray  w-[450px]'>
              <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                English
              </li>
              <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                German
              </li>
              <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0]'>
                Hindi
              </li>
            </ul>
          )}
        </div>

        <p className='text-sm12 text-[#757575] mt-[2px]'>
          This language will be used for emails you receive from us
          and browsing our site.
        </p>
      </div>

      <div className='flex gap-5 justify-center mt-[30px] mb-[509px]'>
        <button className='blackBtn text-[14px] text-medium'>
          Save
        </button>
        <button className=' text-[14px] text-medium border border-[#333333] rounded-full px-4 py-2'>
          Cancel
        </button>
      </div>
    </>
  );
};

export default Contributor_Preferences;
