import { useState, useEffect } from 'react';

import { ReactComponent as DropArrow } from '../../assets/images/Icons/Down arrow.svg';
// import { ReactComponent as DropArrow } from '../../assets/images/Icons/Down arrow.svg';

const ArtHomeFaq = ({ type }) => {
  const [show, setShow] = useState();

  const showw = (id) => {
    if (id === show) {
      setShow();
    } else {
      setShow(id);
    }
  };
  // const showw = (id) => {
  //   const find = show.find((obj) => obj.id === id.id);
  //   // console.log(find);
  //   if (find === undefined) {
  //     setShow((prev) => [...prev, id]);
  //   } else if (find !== undefined) {
  //     setShow(show.filter((obj) => obj.id !== id.id));
  //   }
  // };

  return (
    <div className='w-w1409 mx-auto pb-[100px] mt-[100px]'>
      <p className='text-heading text-primaryBlack text-center mb-6'>
        Frequently Asked Questions
      </p>
      <div className='flex flex-col justify-between'>
        <ul>
          {type?.map((item, index) => (
            <li
              key={index}
              onClick={() => {
                showw(item.faqId);
              }}
              className={`text-primaryBlack text-sm14 leading-[49px] border-t border-[#EFEFEF] cursor-pointer ${
                index === type.length - 1 ? 'border-b' : ''
              }`}
            >
              <div className='flex justify-between'>
                <span className='font-medium'>{item.question}</span>
                <div className='flex items-center'>
                  <DropArrow
                    style={{
                      fill: '#878787',
                    }}
                  />
                </div>
              </div>
              {show === item.faqId && (
                <p className='text-primaryGray'> {item.answer}</p>
              )}
              {/* {show.find((obj) => obj.id === item.faqId) && (
                <p className='text-primaryGray'> {item.answer}</p>
              )} */}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ArtHomeFaq;
