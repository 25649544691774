import React, { useEffect, useState } from 'react';
import lock from '../../assets/images/ShoppingCart/lock.png';
import leftarrow from '../../assets/images/ShoppingCart/leftarrow.png';
import Cards from '../../assets/images/ShoppingCart/Cards.png';
import removed_1 from '../../assets/images/ShoppingCart/removed_1.png';
import orderSummary from '../../assets/images/ShoppingCart/orderSummary.png';
import Paymentmethods from '../../assets/images/ShoppingCart/PaymentMethods.png';
import Footer from '../footer/Footer';
import msgBg from '../../assets/images/ShoppingCart/messageBG.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { httpClient } from '../../axios';
import checkBtnBackImg from '../../assets/images/ShoppingCart/CompleteOrderBG.svg';

import useRazorpay from 'react-razorpay';
import logo from '../../assets/Favicon.svg';
import { orderModel } from '../../models/allModel';
import { toast } from 'react-toastify';
import dropArrow from '../../assets/images/Icons/Down arrow.svg';
import { Menu } from '@headlessui/react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useDispatch, useSelector } from 'react-redux';
import { cartSliceAction } from '../../store/cartSlice';
import { useRef } from 'react';

import { ReactComponent as LogoWithoutText } from '../../assets/images/ShoppingCart/logoWithoutText.svg';

import minusIcon from '../../assets/images/Icons/minusIcon.svg';
import plusIcon from '../../assets/images/Icons/plusIcon.svg';

import { ReactComponent as DropArrow } from '../../assets/images/Icons/Down arrow.svg';

import { ReactComponent as Paypal } from '../../assets/images/footer/Paypal.svg';
import { ReactComponent as Visa } from '../../assets/images/footer/Visa.svg';
import { ReactComponent as Mastercard } from '../../assets/images/footer/Mastercard.svg';
import { ReactComponent as AmerExpress } from '../../assets/images/footer/AmerExpress.svg';
import { ReactComponent as Discover } from '../../assets/images/footer/Discover.svg';

const Checkout = () => {
  const [cartData, setCartData] = useState();
  const [userData, setUserData] = useState();
  const [paymentCheck, setPaymentCheck] = useState('card');
  const [finalCheckout, setFinalCheckout] = useState('false');

  const shippingRef = useRef();

  let location = useLocation();

  useEffect(() => {
    if (location?.state?.type === 'all') {
      getUserIdWiseCart(location?.state?.userId);
      getShiipingList();
    } else if (location?.state?.type === 'one') {
      getUserIdWiseBuyNow(location?.state);
      // getShiipingList();
    }
  }, [location?.state?.userId]);

  // const getUserIdWiseCart = (userId) => {
  //   httpClient
  //     .get(`/cart_master/getUserIdWiseCartData/${userId}`)
  //     .then((res) => {
  //       console.log(res.data);
  //       setSelect(res?.data?.shippingMethod);
  //       shippingRef.current = res?.data?.shippingMethod;
  //       setUserData(res?.data['userMaster']);

  //       setCartData(res?.data);
  //       setApplyPromo(res?.data?.codeType != null ? true : false);

  //       setShipDataToResData();
  //       if (res?.data?.codeType === 'Promo Code') {
  //         setPromoCode(res?.data?.promoCode);
  //       } else if (res?.data?.codeType === 'Promo Code') {
  //         setGiftCode(res?.data?.giftCode);
  //       }
  //     });
  // };

  const getUserIdWiseCart = async (userId) => {
    try {
      const res = await httpClient.get(
        `/cart_master/getUserIdWiseCartDetails/${userId}`
      );
      console.log(res.data);
      setSelect(res?.data?.shippingMethod);
      shippingRef.current = res?.data?.shippingMethod;
      setUserData(res?.data?.userMaster);
      setCartData(res?.data);
      setApplyPromo(res?.data?.codeType != null ? true : false);
      setShipDataToResData();

      if (res?.data?.codeType === 'Promo Code') {
        setPromoCode(res?.data?.promoCode);
      } else if (res?.data?.codeType === 'Promo Code') {
        setGiftCode(res?.data?.giftCode);
      }
    } catch (error) {
      // Handle errors here if needed
      console.error('Error fetching data:', error);
    }
  };

  // this setShipDataToResData function sets shipping data to residential data on mount of the component.

  const setShipDataToResData = () => {
    setIsChecked(true);
    setUserData({
      ...userData,
      ['residentialAddress']: {
        ...userData['residentialAddress'],
        ['addressLine1']: userData.shippingAddress.addressLine1,
        ['addressLine2']: userData.shippingAddress.addressLine2,
        ['cityName']: userData.shippingAddress.cityName,
        ['countryName']: userData.shippingAddress.countryName,
        ['phoneNo']: userData.shippingAddress.phoneNo,
        ['postalCode']: userData.shippingAddress.zipCode,
        ['stateName']: userData.shippingAddress.stateName,
      },
    });
  };

  const getUserIdWiseBuyNow = async (state) => {
    try {
      let object = {
        cartId: state.cartId,
        userId: state.userId,
        cartArtFrameId: state.cartArtFrameId,
        qty: state.qty,
      };

      const res = await httpClient.post(
        '/cart_master/BuyNow',
        object
      );

      console.log(res.data);

      setSelect(res?.data?.shippingMethod);
      shippingRef.current = res?.data?.shippingMethod;
      setUserData(res?.data?.userMaster);
      setCartData(res?.data);
      setApplyPromo(res?.data?.codeType != null ? true : false);

      if (res?.data?.codeType === 'Promo Code') {
        setPromoCode(res?.data?.promoCode);
      } else if (res?.data?.codeType === 'Gift Code') {
        setGiftCode(res?.data?.giftCode);
      }
    } catch (error) {
      // Handle errors if any
      console.error('Error fetching data:', error);
      // You might want to set an error state or perform error handling here
    }
  };

  function addZeroes(num) {
    const dec = String(num).split('.')[1];
    const len = dec && dec.length > 1 ? dec.length : 2;
    return Number(num).toFixed(len);
  }

  // Razorpay
  const [orderId, setOrderId] = useState();
  const [Razorpay] = useRazorpay();

  const createOrder = async (amount) => {
    // console.log(amount);
    const res = await httpClient.get(`/payment/${amount}`);

    console.log('createOrder: ', res?.data);

    setOrderId(res?.data);
  };

  const handlePayment = async (amount) => {
    amount = amount * 100;
    await createOrder(amount);

    const options = {
      key: 'rzp_test_HCTUbGUU3bXXQ6',
      amount: amount,
      currency: 'INR',
      name: 'Artnstock',
      description: 'Test Transaction',
      image: logo,
      order_id: orderId,
      handler: async (res) => {
        console.log(res);
        if (res) {
          await createNewOrder(res);
        }
      },
      prefill: {
        name: `${userData?.userFirstName} ${userData?.userLastName}`,
        email: userData?.emailAddress,
        contact: userData?.shippingAddress?.phoneNo,
      },
      notes: {
        address: 'Test',
      },
      theme: {
        color: '#9772FB',
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.on('payment.failed', function (response) {
      toast.error(
        'Something Went Wrong',
        response?.error?.description
      );
    });

    rzpay.open();
  };
  //End Razorpay

  // order Create
  const createNewOrder = async (paymentRes) => {
    //   {
    //     "razorpay_payment_id": "pay_MG1fxTeT3mr34J",
    //     "razorpay_order_id": "order_MG0wO31u2aNIFw",
    //     "razorpay_signature": "c12c45f998d441d55e00a5af05bf065c0d712122334ff7ef12c979f7147aeb94"
    // }

    try {
      orderModel.orderPaymentStatus = 'Success';
      orderModel.paymentInformation.razorpayOrderId =
        paymentRes?.razorpay_order_id;
      orderModel.paymentInformation.razorpayPaymentId =
        paymentRes?.razorpay_payment_id;
      orderModel.paymentInformation.signature =
        paymentRes?.razorpay_signature;
      orderModel.paymentInformation.status = 'Success';
      orderModel.userId = location?.state?.userId;
      let idArray = [];
      for (let i = 0; i < cartData?.cartArtFrameMaster?.length; i++) {
        idArray.push(cartData?.cartArtFrameMaster[i]?.cartArtFrameId);
      }
      orderModel.cartArtFrameId = idArray;

      // console.log(orderModel);
      // herenow

      await httpClient
        .post('/order_master/create', orderModel)
        .then((res) => {
          // console.log(res.data);
          toast.success('Order Successfull');

          pathhcust('3');
        });
    } catch (err) {
      console.log(err);
    }
  };

  const navigate = useNavigate();

  const pathhcust = (val) => {
    navigate('/customer-tabs', {
      state: val,
    });
  };

  // Gift and Promo code
  const [applyPromo, setApplyPromo] = useState(false);
  const [giftCode, setGiftCode] = useState('');
  const [promoCode, setPromoCode] = useState('');

  const addGiftCode = async () => {
    try {
      const promoObj = {
        giftCode: String,
        userMasterId: String,
      };
      promoObj.userMasterId = location?.state?.userId;
      promoObj.giftCode = giftCode;
      console.log(promoObj);
      await httpClient
        .post('/user_gift_code_master/create', promoObj)
        .then((res) => {
          // console.log(res.data);
          toast.success('added Gift code');
          getUserIdWiseCart();
        });
    } catch (err) {
      console.log(err);
    }
  };

  const addPromoCode = async () => {
    try {
      const promoObj = {
        promoCode: String,
        userId: String,
      };
      promoObj.userId = location?.state?.userId;
      promoObj.promoCode = promoCode;
      console.log(promoObj);
      await httpClient
        .post('/use_promo_code_master/create')
        .then((res) => {
          console.log(res.data);
          toast.success('added promo code');
        });
    } catch (err) {
      console.log(err);
    }
  };

  //  Dropdown

  const [openDropdown, setOpenDropDown] = useState(false);
  const [shipping, setShipping] = useState();
  const [select, setSelect] = useState(null);

  const dropdownEvent = () => {
    setOpenDropDown(!openDropdown);
  };

  const getShiipingList = () => {
    httpClient.get('/shipping_method').then((res) => {
      setShipping(res?.data);
    });
  };

  const outsideClickEvent = () => {
    setOpenDropDown(false);
  };
  const ref1 = useDetectClickOutside({
    onTriggered: outsideClickEvent,
  });

  const selectValue = (data) => {
    setSelect(data);
    setOpenDropDown(false);
    if (
      shippingRef.current?.shippingMethodId === data?.shippingMethodId
    ) {
      // console.log("No");
    } else {
      // console.log("Yes");
      updateShippingMethod(data);
    }
  };

  // Remove Order
  const cartCount = useSelector((state) => state.cart.cartCount);
  const dispatch = useDispatch();

  const removeOrder = async (cartArtFrameId) => {
    try {
      await httpClient
        .delete(`/cart_art_frame_master/deleteCart/${cartArtFrameId}`)
        .then((res) => {
          // console.log(res.data);
          getUserIdWiseCart(location?.state?.userId);
          dispatch(cartSliceAction.setCartCount(cartCount - 1));
          return res.data;
        });
    } catch (err) {
      console.log(err);
      getUserIdWiseCart(location?.state?.userId);
    }
  };

  // update shipping method

  const updateShippingMethod = (shippingData) => {
    try {
      const shippingModel = {
        cartId: String,
        shippingMethod: {
          dayToReceive: String,
          shippingMethodId: String,
          shippingMethodName: String,
          shippingMethodPrice: 0,
        },
      };
      shippingModel.cartId = cartData?.cartId;
      shippingModel.shippingMethod = shippingData;
      // console.log(shippingModel)
      httpClient
        .put('/cart_master/updateCartShippingMethod', shippingModel)
        .then((res) => {
          if (res.data) {
            getUserIdWiseCart(location?.state?.userId);
          }
        });
    } catch (err) {
      getUserIdWiseCart(location?.state?.userId);
      console.log(err);
    }
  };

  // Edit Art

  const editArt = () => {};

  const [selectCardTypeValue, setselectCardTypeValue] = useState();
  const [showCardType, setShowCardType] = useState(false);

  const dropList = [{ name: 'Credit Card' }, { name: 'Debit Card' }];

  const [isChecked, setIsChecked] = useState(false);

  const handleInputChangeNested = (topName, lowName, value) => {
    setUserData({
      ...userData,
      [topName]: { ...userData[topName], [lowName]: value },
    });
  };

  const changeCheckBox = (event) => {
    setIsChecked(event.target.checked);
    if (!isChecked) {
      setUserData({
        ...userData,
        ['residentialAddress']: {
          ...userData['residentialAddress'],
          ['addressLine1']: userData.shippingAddress.addressLine1,
          ['addressLine2']: userData.shippingAddress.addressLine2,
          ['cityName']: userData.shippingAddress.cityName,
          ['countryName']: userData.shippingAddress.countryName,
          ['phoneNo']: userData.shippingAddress.phoneNo,
          ['postalCode']: userData.shippingAddress.zipCode,
          ['stateName']: userData.shippingAddress.stateName,
        },
      });
    } else {
      setUserData({
        ...userData,
        ['residentialAddress']: {
          ...userData['residentialAddress'],
          ['addressLine1']: '',
          ['addressLine2']: '',
          ['cityName']: '',
          ['countryName']: '',
          ['phoneNo']: '',
          ['postalCode']: '',
          ['stateName']: '',
        },
      });
    }
  };

  useEffect(() => {
    console.log(userData);
  }, [userData]);

  return (
    <>
      <div className='wrapper pt-[30px] mb-[100px]'>
        <p className='bullet text-center mb-1 leading-[1.2]'>
          Artnstock / Shopping Cart / Checkout
        </p>
        <p className='text-[38px] text-[#333333] font-medium text-center leading-[1]'>
          Checkout
        </p>
        <div className='flex mt-7 w-w930 mx-auto'>
          {finalCheckout ? (
            <div className='w-[35.625rem]'>
              <div className='flex items-center'>
                <img src={lock} className='mr-2' alt='' />
                <p className='text-[12px] text-[#757575] leading-[1.2]'>
                  Your information is secure. Your encrypted payment
                  method will be saved for future orders. <br />{' '}
                  Manage your payment methods in your{' '}
                  <span className='text-[#F9944C]'>
                    Account Settings
                  </span>
                  .
                </p>
              </div>
              <div>
                <p className='text-[25px] font-medium text-[#333333] mt-7 mb-4 leading-[1.2]'>
                  Payment Method
                </p>
                <p className='text-[#757575] text-[12px] mt-[15px] mb-1 leading-[1.2]'>
                  Select Payment Method
                </p>
                <div className='leading-[1] text-[12px] rounded-[13px] text-[#757575] bg-[#f9f9f9] p-[16px] w-w557'>
                  <div className='flex items-center'>
                    <input
                      type='radio'
                      onClick={() => setPaymentCheck('card')}
                      checked={paymentCheck === 'card'}
                      name='payment'
                      className='mr-[7px]'
                      id=''
                    />{' '}
                    <span className='leading-[1] mr-[15px]'>
                      Credit/Debit Card
                    </span>
                    {/* <img
                      src={Cards}
                      alt=''
                      className='inline ml-[15px]'
                    /> */}
                    <div
                      style={{
                        width: 'fit-content',
                      }}
                      className='flex gap-[4px]'
                    >
                      <div>
                        <Paypal className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>

                      <div>
                        <Visa className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>

                      <div>
                        <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>

                      <div>
                        <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>
                      <div>
                        <Discover className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>
                    </div>
                  </div>
                  <div className='flex items-center mt-[12px]'>
                    <input
                      type='radio'
                      onClick={() => setPaymentCheck('paypal')}
                      checked={paymentCheck === 'paypal'}
                      name='payment'
                      className='mr-2'
                      id=''
                    />{' '}
                    <span className='leading-[1]'>Paypal</span>
                  </div>
                </div>
              </div>
              <div>
                <p className='text-[15px] font-medium text-[#333333] mt-[29px] mb-[20px] leading-[1]'>
                  Credit/Debit Card Information
                </p>
                <label className='text-[12px] text-[#757575] leading-[1]'>
                  Card Type
                </label>
                {/* <input  
                  type='text'
                  className='regInput mt-0'
                  placeholder='Select Card type'
                /> */}

                {/* test */}

                <div className='w-[100%] mb-[8px] cursor-pointer'>
                  <div
                    onClick={() => setShowCardType((prev) => !prev)}
                    className={`${
                      showCardType
                        ? 'rounded-tl-3xl rounded-tr-3xl'
                        : 'rounded-3xl'
                    } bg-[#ffffff] border border-[#D6D6D6]  flex items-center h-[40px] px-4 justify-between w-[100%] `}
                  >
                    <p className='text-[#BBBBBB] text-[13px] leading-[13px] font-medium'>
                      {selectCardTypeValue
                        ? selectCardTypeValue
                        : 'Select Card'}
                    </p>
                    <DropArrow
                      className={`${
                        showCardType === true
                          ? 'transform rotate-180'
                          : ''
                      }`}
                      style={{
                        fill: '#878787',
                      }}
                    />
                  </div>
                  {showCardType && (
                    <div className='relative'>
                      <ul
                        className={`w-[100%] border border-[#D6D6D6] border-t-0 absolute rounded-bl-3xl rounded-br-3xl  bg-[#ffffff] max-h-[199.5px] overflow-hidden hover:overflow-y-auto hover:overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                      >
                        {/* <li
                          // onClick={() => {
                          //   changeValue2('All Styles');
                          // }}
                          className='h-[25px] flex items-center px-4  cursor-pointer text-primaryGray text-[13px] leading-[13px] font-normal border-b border-[#D6D6D6]'
                        >
                          All Styles
                        </li> */}
                        {/* binded already */}
                        {dropList.map((obj, index) => (
                          <li
                            key={obj.name}
                            onClick={() => {
                              setselectCardTypeValue(obj.name);
                              setShowCardType(false);
                            }}
                            className={`h-[40px] flex items-center px-4  cursor-pointer text-primaryGray text-[13px] leading-[13px] font-normal ${
                              index === dropList.length - 1
                                ? ''
                                : 'border-b'
                            }  border-[#D6D6D6]`}
                          >
                            {obj.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                {/* test */}

                <div className='flex  mt-4'>
                  <div className='w-[275px] mr-[16px]'>
                    <label className='text-[12px] text-[#757575] leading-[1]'>
                      Card Number
                    </label>
                    <input
                      type='text'
                      className='regInput mt-0 w-[100%]'
                      placeholder='Card Number'
                    />
                    <label className='mt-1' htmlFor=''>
                      {/* <img src={Cards} alt='' /> */}

                      <div className='flex gap-[4px] mx-[auto] mt-[4px]'>
                        <div>
                          <Paypal className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                        </div>

                        <div>
                          <Visa className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                        </div>

                        <div>
                          <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                        </div>

                        <div>
                          <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                        </div>
                        <div>
                          <Discover className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                        </div>
                      </div>
                    </label>
                  </div>
                  <div className='w-[131px] mr-2.5'>
                    <label className='text-[12px] text-[#757575] leading-[1]'>
                      Month & Year
                    </label>
                    <input
                      type='text'
                      className='regInput mt-0'
                      placeholder='MM/YY'
                    />
                    <label className='text-[12px] text-[#757575] leading-[1]'>
                      Expiration Date
                    </label>
                  </div>
                  <div className='w-[134px]'>
                    <label className='text-[12px] text-[#757575] leading-[1]'>
                      CVC
                    </label>
                    <input
                      type='text'
                      className='regInput mt-0'
                      placeholder='123'
                    />
                    <label className='text-[12px] text-[#757575] leading-[1]'>
                      Security Code
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <p className='text-[25px] font-medium text-[#333333] mt-7 leading-[1]'>
                  Contact Information
                </p>
                <p className='mt-0 text-[11px] text-[#f95d99] leading-[1]'>
                  All fields are required.
                </p>

                <div>
                  <p className='text-[15px] font-medium text-[#333333] my-[18px] leading-[1]'>
                    Personal Details
                  </p>
                  <div className='mb-0'>
                    <label className='text-[12px] mt-[13px] mb-0 text-[#757575]  leading-[1]'>
                      First Name
                    </label>
                    <input
                      type='text'
                      className='regInput mt-0'
                      value={userData?.userFirstName}
                    />
                  </div>
                  <div className='mt-[13px] mb-0'>
                    <label className='text-[12px] mt-[13px] mb-0 text-[#757575] leading-[1]'>
                      Last Name
                    </label>
                    <input
                      type='text'
                      className='regInput mt-0'
                      value={userData?.userLastName}
                    />
                  </div>
                  <div className='mt-[13px] mb-0'>
                    <label className='text-[12px] text-[#757575] leading-[1]'>
                      Email Address
                    </label>
                    <input
                      type='text'
                      className='regInput mt-0'
                      value={userData?.emailAddress}
                    />
                  </div>
                </div>

                <div>
                  <p className='text-[15px] font-medium text-[#333333] mt-[33px] mb-[17px] leading-[1]'>
                    Shipping Address
                  </p>
                  <div className='mb-0'>
                    <label className='text-[12px] mt-[13px] mb-0 text-[#757575] leading-[1]'>
                      Location
                    </label>
                    <input
                      type='text'
                      className='regInput mt-0'
                      placeholder='India'
                      name='countryName'
                      value={
                        userData?.shippingAddress?.countryName || ''
                      }
                      onChange={(e) =>
                        handleInputChangeNested(
                          'shippingAddress',
                          e.target.name,
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div className='mt-[13px] mb-0'>
                    <label className='text-[12px] mt-[13px] mb-0 text-[#757575] leading-[1]'>
                      Address Line 1
                    </label>
                    <input
                      type='text'
                      className='regInput mt-0'
                      value={
                        userData?.shippingAddress?.addressLine1 || ''
                      }
                      placeholder='S. No. 203/2A, Plot 16A, Rajiv Nagar (South),'
                      name='addressLine1'
                      onChange={(e) =>
                        handleInputChangeNested(
                          'shippingAddress',
                          e.target.name,
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div className='mt-[13px] mb-0'>
                    <label className='text-[12px] text-[#757575] leading-[1]'>
                      Address Line 2
                    </label>
                    <input
                      type='text'
                      className='regInput mt-0'
                      value={userData?.shippingAddress?.addressLine2}
                      placeholder='Viman Nagar,'
                      name='addressLine2'
                      onChange={(e) =>
                        handleInputChangeNested(
                          'shippingAddress',
                          e.target.name,
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div className='mt-[13px] mb-0'>
                    <label className='text-[12px] text-[#757575] leading-[1]'>
                      City
                    </label>
                    <input
                      type='text'
                      className='regInput mt-0'
                      value={userData?.shippingAddress?.cityName}
                      placeholder='Pune'
                      name='cityName'
                      onChange={(e) =>
                        handleInputChangeNested(
                          'shippingAddress',
                          e.target.name,
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div className='flex gap-[14px]'>
                    <div className='mt-[13px] mb-0'>
                      <label className='text-[12px] text-[#757575] leading-[1]'>
                        Zip/Postal Code
                      </label>
                      <input
                        type='text'
                        className='regInput mt-0'
                        value={userData?.shippingAddress?.zipCode}
                        placeholder='411014'
                        name='zipCode'
                        onChange={(e) =>
                          handleInputChangeNested(
                            'shippingAddress',
                            e.target.name,
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className='mt-[13px] mb-0'>
                      <label className='text-[12px] text-[#757575] leading-[1]'>
                        State/Province
                      </label>
                      <input
                        type='text'
                        className='regInput mt-0'
                        value={userData?.shippingAddress?.stateName}
                        placeholder='Maharashtra'
                        name='stateName'
                        onChange={(e) =>
                          handleInputChangeNested(
                            'shippingAddress',
                            e.target.name,
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className='mt-[13px] mb-0'>
                    <label className='text-[12px] text-[#757575] leading-[1]'>
                      Phone
                    </label>
                    <input
                      type='text'
                      disabled={true}
                      className='regInput mt-0 bg-[#eeeeee]'
                      value={userData?.shippingAddress?.phoneNo}
                      placeholder='+91 7378666093'
                      name='phoneNo'
                      onChange={(e) =>
                        handleInputChangeNested(
                          'shippingAddress',
                          e.target.name,
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div className='mt-[13px] mb-0'>
                    <label className='text-[12px] text-[#757575] leading-[1]'>
                      Shipping Method
                    </label>
                    <Menu
                      ref={ref1}
                      as='div'
                      className={`w-[100%] relative block mt-2.5 ${
                        openDropdown
                          ? 'shadow-newDroShadow rounded-tl-3xl  rounded-tr-3xl'
                          : ''
                      }`}
                    >
                      <Menu.Button
                        onClick={dropdownEvent}
                        className={`flex justify-between items-center w-[100%] px-[0.938rem] py-[0.438rem] ${
                          openDropdown
                            ? 'border-[#D6D6D6] border-b '
                            : 'border-[#D6D6D6] border rounded-3xl '
                        }`}
                      >
                        <span className=' outline-none text-primaryGray  '>
                          {select !== null
                            ? select?.shippingMethodName
                            : 'Select Shipping Method'}
                        </span>
                        <img src={dropArrow} alt='' />
                      </Menu.Button>
                      <Menu.Items
                        className={`absolute right-0 w-[100%] origin-top-right bg-[#ffffff]  focus:outline-none overflow-hidden ${
                          openDropdown
                            ? 'shadow-newBotDroShadow rounded-bl-3xl  rounded-br-3xl'
                            : ''
                        }`}
                      >
                        <Menu.Item>
                          <ul>
                            {shipping?.map((item) => (
                              <li
                                onClick={() => selectValue(item)}
                                className='px-[0.938rem] py-[0.438rem] text-[#757575] hover:bg-[#f0f0f0]'
                              >
                                {item?.shippingMethodName}
                              </li>
                            ))}
                          </ul>
                        </Menu.Item>
                      </Menu.Items>
                    </Menu>
                  </div>
                  <p className='text-[11px] text-[#757575] mt-[13px] mb-[26px] leading-[1.2]'>
                    All products are manufactured on-demand and ship
                    from the manufacturing facility located nearest to
                    your destination address. Gift cards are delivered
                    instantly via e-mail. International orders may
                    take up to 10 days to arrive due to FedEx/UPS
                    delivery times. Please note - your government may
                    charge an import tax on products received from
                    outside your country. The import tax (if
                    applicable) is not included in our shipping
                    charge.
                  </p>
                </div>

                <div>
                  <p className='text-[15px] font-medium text-[#333333] mt-[33px] leading-[1]'>
                    Residential Address
                  </p>
                  <div className='my-[17px] text-[#757575] flex gap-[7px] leading-[1]'>
                    <input
                      type='checkbox'
                      onChange={changeCheckBox}
                      checked={isChecked}
                    />
                    My shipping and residential address are the same.
                  </div>
                  <div className='mb-0'>
                    <label className='text-[12px] mt-[13px] mb-0 text-[#757575] leading-[1]'>
                      Location
                    </label>
                    <input
                      type='text'
                      className='regInput mt-0'
                      value={
                        userData?.residentialAddress?.countryName ||
                        ''
                      }
                      placeholder='India'
                      name='countryName'
                      onChange={(e) =>
                        handleInputChangeNested(
                          'residentialAddress',
                          e.target.name,
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div className='mt-[13px] mb-0'>
                    <label className='text-[12px] mt-[13px] mb-0 text-[#757575] leading-[1]'>
                      Address Line 1
                    </label>
                    <input
                      type='text'
                      className='regInput mt-0'
                      value={
                        userData?.residentialAddress?.addressLine1 ||
                        ''
                      }
                      placeholder='S. No. 203/2A, Plot 16A, Rajiv Nagar (South),'
                      name='addressLine1'
                      onChange={(e) =>
                        handleInputChangeNested(
                          'residentialAddress',
                          e.target.name,
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div className='mt-[13px] mb-0'>
                    <label className='text-[12px] text-[#757575] leading-[1]'>
                      Address Line 2
                    </label>
                    <input
                      type='text'
                      className='regInput mt-0'
                      value={
                        userData?.residentialAddress?.addressLine2 ||
                        ''
                      }
                      placeholder='Viman Nagar,'
                      name='addressLine2'
                      onChange={(e) =>
                        handleInputChangeNested(
                          'residentialAddress',
                          e.target.name,
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div className='mt-[13px] mb-0'>
                    <label className='text-[12px] text-[#757575] leading-[1]'>
                      City
                    </label>
                    <input
                      type='text'
                      className='regInput mt-0'
                      value={
                        userData?.residentialAddress?.cityName || ''
                      }
                      placeholder='Pune'
                      name='cityName'
                      onChange={(e) =>
                        handleInputChangeNested(
                          'residentialAddress',
                          e.target.name,
                          e.target.value
                        )
                      }
                    />
                  </div>
                  <div className='flex gap-[14px]'>
                    <div className='mt-[13px] mb-0'>
                      <label className='text-[12px] text-[#757575] leading-[1]'>
                        Zip/Postal Code
                      </label>
                      <input
                        type='text'
                        className='regInput mt-0'
                        value={
                          userData?.residentialAddress?.postalCode ||
                          ''
                        }
                        placeholder='411014'
                        name='postalCode'
                        onChange={(e) =>
                          handleInputChangeNested(
                            'residentialAddress',
                            e.target.name,
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className='mt-[13px] mb-0'>
                      <label className='text-[12px] text-[#757575] leading-[1]'>
                        State/Province
                      </label>
                      <input
                        type='text'
                        className='regInput mt-0'
                        value={
                          userData?.residentialAddress?.stateName ||
                          ''
                        }
                        placeholder='Maharashtra'
                        name='stateName'
                        onChange={(e) =>
                          handleInputChangeNested(
                            'residentialAddress',
                            e.target.name,
                            e.target.value
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className='mt-[13px] mb-0'>
                    <label className='text-[12px] text-[#757575] leading-[1]'>
                      Phone
                    </label>
                    <input
                      type='text'
                      disabled={true}
                      className='regInput mt-0 bg-[#eeeeee]'
                      value={
                        userData?.residentialAddress?.phoneNo || ''
                      }
                    />
                  </div>
                  {/* <div className="mt-[13px] mb-[36px]">
                    <label className="text-[12px] text-[#757575] leading-[1]">
                      Shipping Method
                    </label>
                    
                      <Menu
                        ref={ref1}
                        as="div"
                        className={`w-[100%] relative block mt-2.5 ${
                          openDropdown
                            ? "shadow-newDroShadow rounded-tl-3xl  rounded-tr-3xl"
                            : ""
                        }`}
                      >
                        <Menu.Button
                          onClick={dropdownEvent}
                          className={`flex justify-between items-center w-[100%] px-[0.938rem] py-[0.438rem] ${
                            openDropdown
                              ? "border-[#D6D6D6] border-b "
                              : "border-[#D6D6D6] border rounded-3xl "
                          }`}
                        >
                          <span className=" outline-none text-primaryGray  ">
                            {select !== null
                              ? select?.shippingMethodName
                              : "Select Shipping Method"}
                          </span>
                          <img src={dropArrow} alt="" />
                        </Menu.Button>
                        <Menu.Items
                          className={`absolute right-0 w-[100%] origin-top-right bg-[#ffffff]  focus:outline-none overflow-hidden ${
                            openDropdown
                              ? "shadow-newBotDroShadow rounded-bl-3xl  rounded-br-3xl"
                              : ""
                          }`}
                        >
                          <Menu.Item>
                            <ul>
                              {shipping?.map((item) => (
                                <li
                                  onClick={() => selectValue(item)}
                                  className="px-[0.938rem] py-[0.438rem] text-[#757575] hover:bg-[#f0f0f0]"
                                >
                                  {item?.shippingMethodName}
                                </li>
                              ))}
                            </ul>
                          </Menu.Item>
                        </Menu.Items>
                      </Menu>
                  </div> */}

                  <button
                    onClick={() => setFinalCheckout(false)}
                    className='blackBtn block mx-auto mt-[36px]'
                  >
                    Continue to Checkout
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className='w-[35.625rem]'>
              <div className='flex items-center'>
                <img src={lock} className='mr-2' alt='' />
                <p className='text-[12px] text-[#757575] leading-[1.2]'>
                  Your information is secure. Your encrypted payment
                  method will be saved for future orders. <br />{' '}
                  Manage your payment methods in your{' '}
                  <span className='text-[#F9944C]'>
                    Account Settings
                  </span>
                  .
                </p>
              </div>
              <div>
                <p className='text-[25px] font-medium text-[#333333] mt-7 mb-4 leading-[1.2]'>
                  Payment Method
                </p>
                <p className='text-[#757575] text-[12px] mt-[15px] mb-1 leading-[1.2]'>
                  Select Payment Method
                </p>
                <div className='leading-[1] text-[12px] rounded-[13px] text-[#757575] bg-[#f9f9f9] p-[16px] w-w557'>
                  <div>
                    <input
                      type='radio'
                      onClick={() => setPaymentCheck('card')}
                      checked={paymentCheck === 'card'}
                      name='payment'
                      className='mr-2'
                      id=''
                    />{' '}
                    Credit/Debit Card
                    <img
                      src={Cards}
                      alt=''
                      className='inline ml-[15px]'
                    />
                  </div>
                  <div>
                    <input
                      type='radio'
                      onClick={() => setPaymentCheck('paypal')}
                      checked={paymentCheck === 'paypal'}
                      name='payment'
                      className='mr-2'
                      id=''
                    />{' '}
                    Paypal
                  </div>
                </div>
              </div>
              {/* User Details */}
              <div className='mt-[20px]'>
                <p className='text-primaryBlack text-[15px] mb-2.5 font-semibold'>
                  Your Contact Info
                </p>
                <p className='text-[13px] text-primaryGray'>
                  Name:{userData?.userFirstName}{' '}
                  {userData?.userLastName}
                </p>
                <p className='text-[13px] text-primaryGray'>
                  Email:{userData?.emailAddress}
                </p>
                <p className='text-[13px] text-primaryGray'>
                  Contact No.:{userData?.shippingAddress?.phoneNo}
                </p>
              </div>
              <div className='mt-[20px]'>
                <p className='text-primaryBlack text-[15px] mb-2.5 font-semibold'>
                  Shipping Address
                </p>
                <p className='text-[13px] text-primaryGray'>
                  {userData?.shippingAddress?.addressLine1},
                </p>
                <p className='text-[13px] text-primaryGray'>
                  {userData?.shippingAddress?.addressLine2},
                  {userData?.shippingAddress?.cityName}{' '}
                  {userData?.shippingAddress?.zipCode}.
                </p>
                <p className='text-[13px] text-primaryGray'>
                  {userData?.shippingAddress?.stateName}
                </p>
                <p className='text-[13px] text-primaryGray'>
                  {userData?.shippingAddress?.countryName}
                </p>
              </div>
              <div className='mt-[20px]'>
                <p className='text-primaryBlack text-[15px] mb-2.5 font-semibold'>
                  Shipping Method
                </p>
                <p className='text-[13px] text-primaryGray'>
                  {select?.shippingMethodName}
                </p>
                <p className='text-[11px] text-primaryGray'>
                  Order now to receive in {select?.dayToReceive}{' '}
                  business days
                </p>
              </div>

              <div className='flex gap-2.5 mt-[20px] mb-[30px]'>
                <input type='checkbox' defaultChecked='true' />
                <p className='text-[12px] text-primaryGray'>
                  Send me news and exclusive offers from Artnstock
                </p>
              </div>

              <div className='w-[242px] h-[40px]'>
                <div
                  onClick={() => handlePayment(cartData?.finalAmount)}
                  className='w-[100%] h-[100%] flex justify-center items-center cursor-pointer'
                  style={{
                    background: `url(${checkBtnBackImg})`,
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  <p className='text-[18px] text-primaryBlack font-medium '>
                    Complete order for $
                    {addZeroes(cartData?.finalAmount)}
                  </p>
                </div>
              </div>
            </div>
          )}

          {/* Second Section */}
          <div className='w-[22.5rem] pl-[30px] '>
            <div className=' rounded-2xl overflow-hidden'>
              <div className='bg-[#808080] py-2.5 px-4 flex justify-between items-center'>
                <p className='text-[18px] leading-[20px] text-[#FFFFFF] font-medium'>
                  Order Summary
                </p>
                <img src={leftarrow} alt='' />
              </div>
              <div className='bg-[#EEEEEE] px-4 py-2.5 flex items-center'>
                <div className='bg-[#333333] text-[#FFFFFF] rounded-full w-[25px] h-[25px] flex items-center justify-center'>
                  {cartCount}
                </div>
                <p className='text-[13px] leading-[20px] text-[#333333] ml-1 font-medium'>
                  Items in your cart
                </p>
              </div>

              <div className='px-[15px] pt-[10px] pb-[20px] bg-[#f9f9f9]'>
                <div
                  className='h-[100%] w-[100%] py-1 mb-[20px]'
                  style={{
                    background: `url(${msgBg})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                  }}
                >
                  <p className='text-[12px] text-primaryBlack font-semibold text-center'>
                    Shipping, Taxes, GST, and Promotional Discounts{' '}
                    <br />
                    calculated at Checkout.
                  </p>
                </div>
                <div className='h-[2px] w-[100%] my-[20px] bg-[#808080]'></div>
                <div className='flex flex-col'>
                  {cartData?.list?.map((cart, i) => (
                    <>
                      <div className='flex gap-2'>
                        <div className='w-[80px] h-[80px]  flex justify-center items-center bg-[#efefef] rounded-[9px] p-[8px]'>
                          {/* <div
                            style={{
                              margin: `${
                                cart?.['orientationMaster']?.shape ===
                                  'Vertical' && '0 auto'
                              }`,
                              width: `${
                                cart?.['orientationMaster']?.shape ===
                                'Vertical'
                                  ? 79 * 0.7
                                  : 79
                              }px`,
                              height: `${
                                cart?.['orientationMaster']?.shape ===
                                'Horizontal'
                                  ? 79 * 0.7
                                  : 79
                              }px`,
                            }}
                          > */}
                          <img
                            src={cart?.imgUrl}
                            className={`${
                              cart?.orientationMaster?.shape ===
                              'Vertical'
                                ? 'h-[100%]'
                                : cart?.orientationMaster?.shape ===
                                  'Horizontal'
                                ? 'w-[100%]'
                                : 'h-[100%] h-[100%]'
                            }`}
                            alt=''
                          />
                          {/* </div> */}
                        </div>
                        <div className='max-w-[210px] w-[100%]'>
                          <p className='text-[#333333] text-[15px] font-medium leading-[1.2]'>
                            {cart?.artMaster?.artName}
                          </p>
                          <p className='text-[#757575] text-[12px] leading-[1.2]'>
                            {cart?.description}
                          </p>
                          <p className='pt-[11px] text-[#F9944C] text-[15px] font-medium leading-[1.2]'>
                            ${addZeroes(cart?.amount)}
                            <span className='text-[#757575] line-through ml-[5px]'>
                              $220.00
                            </span>
                          </p>
                          <p className='text-[#F9944C] text-[11px] mt-0 leading-[1]'>
                            10% Discount Applied
                          </p>
                          <div
                            className='flex mt-[6px]'
                            style={{ width: 'fit-content' }}
                          >
                            <div
                              className='bg-[#EEEEEE] h-[27px] w-[25px] flex items-center justify-center rounded-l-3xl cursor-pointer'
                              // onClick={() => {
                              //   quantity > 1 && setQuantity(quantity - 1);
                              //   quantityRef.current = quantityRef.current - 1;
                              // }}
                            >
                              <img src={minusIcon} alt='' />
                            </div>
                            <input
                              className='w-[25px] text-[13px] leading-[15px] font-medium text-primaryGray text-center border-y-[2px] border-y-[#EEEEEE]  outline-none'
                              type='text'
                              value={1}
                              // onChange={setArtQuantityFunc}
                            />
                            <div
                              className='bg-[#EEEEEE]  h-[27px] w-[25px] flex items-center justify-center rounded-r-3xl  cursor-pointer'
                              // onClick={() => {
                              //   setQuantity(quantity + 1);
                              //   quantityRef.current = quantityRef.current + 1;
                              // }}
                            >
                              <img
                                className='w-[11px] h-[11px]'
                                src={plusIcon}
                                alt=''
                              />
                            </div>
                          </div>
                          <p className='text-[#757575] text-[12px] flex gap-[10px]  leading-[1] mt-1 '>
                            <span onClick={() => editArt()}>
                              Edit
                            </span>
                            <span
                              className='cursor-pointer'
                              onClick={() =>
                                removeOrder(cart?.cartArtFrameId)
                              }
                            >
                              Delete
                            </span>
                            <span>Move to Wishlist</span>
                          </p>
                        </div>
                      </div>
                      {!(cartData?.list?.length - 1 === i) && (
                        <div className='h-[1px] w-[100%] bg-[#efefef] mt-[30px] mb-[28px]'></div>
                      )}
                    </>
                  ))}
                </div>
                <div className='h-[2px] w-[100%] my-[20px] bg-[#808080]'></div>

                <div className=''>
                  <div className='flex justify-between pb-[14px]'>
                    <div className='leading-[1.2]'>
                      <p className='text-[#333333] font-medium'>
                        Your Cart Items
                      </p>
                      <p className='text-[11px] text-[#757575]'>
                        includes $45 in items discounts
                      </p>
                    </div>
                    <p className='text-[#333333] font-medium leading-[1]'>
                      ${addZeroes(cartData?.totalAmount)}
                    </p>
                  </div>

                  <div className='flex justify-between pb-[14px]'>
                    <div className='leading-[1.2]'>
                      <p className='text-[#333333] font-medium'>
                        Estimated Shipping
                      </p>
                      <p className='text-[11px] text-[#757575]'>
                        Your Order will be shipped in 1 package
                      </p>{' '}
                    </div>
                    <p className='text-[#333333] font-medium leading-[1]'>
                      ${addZeroes(cartData?.estimateShipping)}
                    </p>
                  </div>
                  <div className='flex justify-between mb-[14px]'>
                    <div className='leading-[1.2]'>
                      <p className='text-[#333333] font-medium'>
                        Tax
                      </p>
                      <p className='text-[11px] text-[#757575]'>
                        Lorem Ipsum dolor set
                      </p>{' '}
                    </div>
                    <p className='text-[#333333] font-medium'>
                      ${addZeroes(cartData?.taxAmount)}
                    </p>
                  </div>
                  <div className='h-[1px] w-[100%]  bg-[#808080]'></div>
                  <div className='flex justify-between items-center  pt-[8px] pb-[8px]'>
                    <div>
                      <p className='text-[#333333] font-medium text-[15px] leading-[14px] '>
                        Estimated Total
                      </p>
                      <p className='text-[11px] text-[#757575] leading-[14px]'>
                        includes $45 in items discounts
                      </p>
                    </div>
                    <div className='text-[#F9944C] leading-[1.2] flex flex-col items-end'>
                      <p>
                        ${' '}
                        <span className='text-[35px]'>
                          {addZeroes(cartData?.finalAmount)}
                        </span>
                      </p>
                      <p className='mt-0 pt-0 text-[#757575] text-[12px] tracking-tighter'>
                        Show Price Details
                      </p>
                    </div>
                  </div>
                </div>

                <div className='h-[2px] w-[100%]  bg-[#808080]'></div>
                {/* Promocode */}
                <div className='pt-[15px]'>
                  <div className='flex gap-2 items-start mb-2'>
                    <input
                      type='checkbox'
                      checked={applyPromo}
                      onChange={(e) => setApplyPromo(!applyPromo)}
                    />
                    <p className='text-[12px]  leading-[15px] text-primaryGray'>
                      Apply Promo codes and Gift Cards <br />
                      (One Promo code per order)
                    </p>
                  </div>
                  {applyPromo && (
                    <div className='mt-2.5'>
                      <div className='relative mb-2.5'>
                        <input
                          type='text'
                          className='regInput mt-0 placeholder:text-[13px]'
                          placeholder='Enter Promo or Coupon code'
                          value={promoCode}
                          disabled={
                            giftCode.length > 0 ? true : false
                          }
                          onChange={(e) =>
                            setPromoCode(e.target.value)
                          }
                        />
                        <button
                          onClick={addPromoCode}
                          disabled={
                            giftCode.length > 0 ? true : false
                          }
                          className='text-primaryBlack bg-[#EEEEEE] border border-[#E9E9E9] rounded-2xl py-[0.45rem] px-2 text-[10px] leading-[10px] font-medium absolute top-2 right-2'
                        >
                          Add
                        </button>
                      </div>
                      <div className='relative '>
                        <input
                          type='text'
                          className='regInput mt-0 placeholder:text-[13px]'
                          placeholder='Enter Gift Card code'
                          value={giftCode}
                          disabled={
                            promoCode.length > 0 ? true : false
                          }
                          onChange={(e) =>
                            setGiftCode(e.target.value)
                          }
                        />
                        <button
                          onClick={addGiftCode}
                          disabled={
                            promoCode.length > 0 ? true : false
                          }
                          className='text-primaryBlack bg-[#EEEEEE] border border-[#E9E9E9] rounded-2xl py-[0.45rem] px-2 text-[10px] leading-[10px] font-medium absolute top-2 right-2'
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <button
                  onClick={() => setFinalCheckout(false)}
                  className='blackBtn mt-[20px] mx-auto mb-1.5 block'
                >
                  Continue to Checkout
                </button>
                <p className='text-sm11 text-primaryGray text-center'>
                  By placing your order, you agree to the{' '}
                  <span className='text-orangeColor'>
                    Delivery Terms
                  </span>
                  .
                </p>

                <div
                  style={{
                    boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
                  }}
                  className='herenow w-[300px] rounded-[16px] flex flex-col bg-[#ffffff] p-[10px] mt-[20px] '
                >
                  <div className='rounded-[20px] h-[40px] border-[#d6d6d6] border text-primaryBlack text-[12px] flex justify-center items-center px-[30px] gap-[5px]'>
                    <div>
                      <LogoWithoutText />
                    </div>
                    <span className='font-medium'>
                      Sign in to your Artnstock account
                    </span>
                  </div>
                  <p className='text-[15px] text-[#333333] leading-[19px] font-medium mt-[7px]'>
                    Need Help?
                  </p>
                  <p className='text-sm12 text-primaryGray leading-[15px]'>
                    Shipping
                  </p>
                  <p className='text-sm12 text-primaryGray leading-[15px]'>
                    Return & Exchanges
                  </p>
                  <p className='text-sm12 text-primaryGray leading-[15px]'>
                    Contact Us
                  </p>
                </div>

                <div className='text-center mt-4'>
                  <p className='text-[#878787] mb-1 text-[11px] leading-[1.2]'>
                    Accepted Payment Methods
                  </p>
                  <p className='flex justify-center pb-5'>
                    {/* <img src={Paymentmethods} alt='' />{' '} */}
                    <div
                      style={{
                        width: 'fit-content',
                      }}
                      className='flex gap-[4px] mx-[auto] mt-[4px]'
                    >
                      <div>
                        <Paypal className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>

                      <div>
                        <Visa className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>

                      <div>
                        <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>

                      <div>
                        <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>
                      <div>
                        <Discover className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Checkout;
