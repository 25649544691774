import React from 'react';

import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { httpClient } from '../../axios';
import { toast } from 'react-toastify';
import { ReactComponent as ProIcon } from '../../assets/images/art-details/Icon_Pro.svg';
import { ReactComponent as QuestionIcon } from '../../assets/images/contributor/svg/questionMark.svg';

const Contributor_Contact = () => {
  const userId = useSelector((state) => state.auth.userId);

  const [userDetail, setUserDetail] = useState({});

  const getUserRecord = async () => {
    try {
      const res = await httpClient.get(
        `/user_master/getUserRecord/${userId}`
      );
      setUserDetail(res.data);
      console.log(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserDetail({ ...userDetail, [name]: value });
  };

  const handleInputChangeNested = (topName, lowName, value) => {
    setUserDetail({
      ...userDetail,
      [topName]: { ...userDetail[topName], [lowName]: value },
    });
  };

  useEffect(() => {
    console.log(userDetail);
  }, [userDetail]);

  const [isChecked, setIsChecked] = useState(false);

  const changeCheckBox = (event) => {
    setIsChecked(event.target.checked);
    if (!isChecked) {
      setUserDetail({
        ...userDetail,
        ['residentialAddress']: {
          ...userDetail['residentialAddress'],
          ['addressLine1']: userDetail.shippingAddress.addressLine1,
          ['addressLine2']: userDetail.shippingAddress.addressLine2,
          ['cityName']: userDetail.shippingAddress.cityName,
          ['countryName']: userDetail.shippingAddress.countryName,
          ['phoneNo']: userDetail.shippingAddress.phoneNo,
          ['postalCode']: userDetail.shippingAddress.zipCode,
          ['stateName']: userDetail.shippingAddress.stateName,
        },
      });
    } else {
      setUserDetail({
        ...userDetail,
        ['residentialAddress']: {
          ...userDetail['residentialAddress'],
          ['addressLine1']: '',
          ['addressLine2']: '',
          ['cityName']: '',
          ['countryName']: '',
          ['phoneNo']: '',
          ['postalCode']: '',
          ['stateName']: '',
        },
      });
    }
  };

  const updateUserMaster = async () => {
    try {
      const res = await httpClient.put(
        '/user_master/update',
        userDetail
      );
      toast.success('Successfully Updated User Details');
      console.log(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getUserRecord();
  }, []);

  return (
    <>
      <div className='flex justify-between'>
        <div>
          <p className='text-[25px] leading-[1] font-medium text-primaryBlack'>
            Contact Information
          </p>
          <p className='text-sm11 text-pinkColor'>
            All fields are required.
          </p>
        </div>
        <div>
          <div className='border border-[#eaeaea] rounded-[7px] h-[41px] flex items-center p-[6px]'>
            <div className='flex flex-col gap-[3px]'>
              <ProIcon />
              <p className='text-[11px] leading-[1] text-primaryGray'>
                Member since 5 July 2020
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className='mt-[30px] mb-[509px]'>
        <p className='text-[15px] font-medium text-primaryBlack'>
          Personal Details
        </p>

        <div className='relative mt-[12px]'>
          <input
            type='text'
            placeholder='Azra'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            value={userDetail?.userFirstName || ''}
            onChange={handleInputChange}
            name='userFirstName'
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            First Name
          </p>
        </div>

        {/* <p className='text-[12px] text-primaryGray mt-3'>
          First Name
        </p>
        <input
          type='text'
          className='regInput my-0 text-[#333333]'
          placeholder='Azra'
          name='userFirstName'
          value={userDetail?.userFirstName || ''}
          onChange={handleInputChange}
        /> */}

        <div className='relative mt-[12px]'>
          <input
            type='text'
            placeholder='Shaikh'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            value={userDetail?.userLastName || ''}
            onChange={handleInputChange}
            name='userLastName'
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            Last Name
          </p>
        </div>

        {/* <p className='text-[12px] text-primaryGray mt-3'>Last Name</p>
        <input
          type='text'
          className='regInput my-0 text-[#333333]'
          placeholder='Shaikh'
          name='userLastName'
          value={userDetail?.userLastName || ''}
          onChange={handleInputChange}
        /> */}

        <div className='relative mt-[12px]'>
          <input
            type='text'
            // placeholder='Shaikh'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            placeholder='ksgrafiks2012@gmail.com'
            name='emailAddress'
            value={userDetail?.emailAddress || ''}
            onChange={handleInputChange}
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            Email Address
          </p>
        </div>

        {/* <p className='text-[12px] text-primaryGray mt-3'>
          Email Address
        </p>
        <input
          type='text'
          className='regInput my-0 text-[#333333]'
          placeholder='ksgrafiks2012@gmail.com'
          name='emailAddress'
          value={userDetail?.emailAddress || ''}
          onChange={handleInputChange}
        /> */}

        <button className='gray30HButton mt-[10px]'>
          Change Email Address
        </button>
        <p className='text-[11px] mt-1 text-primaryGray flex gap-2'>
          Status: <span className='text-pinkColor'>Unconfirmed</span>
          <span className='text-orangeColor'>
            Resend Confirmation Email
          </span>
        </p>

        <p className='text-[15px] font-medium text-primaryBlack mt-[32px]'>
          Shipping Address{' '}
          {/* <img src={IconQue} className='inline' alt='' /> */}
          <QuestionIcon className='inline' />
        </p>

        <div className='relative mt-[12px]'>
          <input
            type='text'
            // placeholder='Shaikh'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            placeholder='India'
            name='countryName'
            value={userDetail?.shippingAddress?.countryName || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'shippingAddress',
                e.target.name,
                e.target.value
              )
            }
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            Location
          </p>
        </div>

        {/* <p className='text-[12px] text-primaryGray mt-[10px]'>
          Location
        </p>
        <input
          type='text'
          className='regInput my-0 text-[#333333]'
          placeholder='India'
          name='countryName'
          value={userDetail?.shippingAddress?.countryName || ''}
          onChange={(e) =>
            handleInputChangeNested(
              'shippingAddress',
              e.target.name,
              e.target.value
            )
          }
        /> */}

        <div className='relative mt-[12px]'>
          <input
            type='text'
            // placeholder='Shaikh'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            placeholder='S. No. 203/2A, Plot 16A, Rajiv Nagar (South),'
            name='addressLine1'
            value={userDetail?.shippingAddress?.addressLine1 || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'shippingAddress',
                e.target.name,
                e.target.value
              )
            }
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            Address line 1
          </p>
        </div>

        {/* <p className='text-[12px] text-primaryGray mt-3'>
          Address line 1
        </p>
        <input
          type='text'
          className='regInput my-0 text-[#333333]'
          placeholder='S. No. 203/2A, Plot 16A, Rajiv Nagar (South),'
          name='addressLine1'
          value={userDetail?.shippingAddress?.addressLine1 || ''}
          onChange={(e) =>
            handleInputChangeNested(
              'shippingAddress',
              e.target.name,
              e.target.value
            )
          }
        /> */}

        <div className='relative mt-[12px]'>
          <input
            type='text'
            // placeholder='Shaikh'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            placeholder='Viman Nagar,'
            name='addressLine2'
            value={userDetail?.shippingAddress?.addressLine2 || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'shippingAddress',
                e.target.name,
                e.target.value
              )
            }
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            Address line 2
          </p>
        </div>

        {/* <p className='text-[12px] text-primaryGray mt-3'>
          Address line 2
        </p>
        <input
          type='text'
          className='regInput my-0 text-[#333333]'
          placeholder='Viman Nagar,'
          name='addressLine2'
          value={userDetail?.shippingAddress?.addressLine2 || ''}
          onChange={(e) =>
            handleInputChangeNested(
              'shippingAddress',
              e.target.name,
              e.target.value
            )
          }
        /> */}

        <div className='relative mt-[12px]'>
          <input
            type='text'
            // placeholder='Shaikh'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            placeholder='Pune'
            name='cityName'
            value={userDetail?.shippingAddress?.cityName || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'shippingAddress',
                e.target.name,
                e.target.value
              )
            }
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            City
          </p>
        </div>

        {/* <p className='text-[12px] text-primaryGray mt-3'>City</p>
        <input
          type='text'
          className='regInput my-0 text-[#333333]'
          placeholder='Pune'
          name='cityName'
          value={userDetail?.shippingAddress?.cityName || ''}
          onChange={(e) =>
            handleInputChangeNested(
              'shippingAddress',
              e.target.name,
              e.target.value
            )
          }
        /> */}

        <div className='flex gap-[10px]'>
          <div className='relative mt-[12px] w-[100%]'>
            <input
              type='text'
              // placeholder='Shaikh'
              className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
              placeholder='411014'
              name='zipCode'
              value={userDetail?.shippingAddress?.zipCode || ''}
              onChange={(e) =>
                handleInputChangeNested(
                  'shippingAddress',
                  e.target.name,
                  e.target.value
                )
              }
            />

            <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
              Zip/Postal Code
            </p>
          </div>

          <div className='relative mt-[12px] w-[100%]'>
            <input
              type='text'
              // placeholder='Shaikh'
              className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
              placeholder='Maharashtra'
              name='stateName'
              value={userDetail?.shippingAddress?.stateName || ''}
              onChange={(e) =>
                handleInputChangeNested(
                  'shippingAddress',
                  e.target.name,
                  e.target.value
                )
              }
            />

            <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
              State/Province
            </p>
          </div>
        </div>

        {/* <div className='flex gap-5'> */}
        {/* <div>
            <p className='text-[12px] text-primaryGray mt-3'>
              Zip/Postal Code
            </p>
            <input
              type='text'
              className='regInput my-0 text-[#333333]'
              placeholder='411014'
              name='zipCode'
              value={userDetail?.shippingAddress?.zipCode || ''}
              onChange={(e) =>
                handleInputChangeNested(
                  'shippingAddress',
                  e.target.name,
                  e.target.value
                )
              }
            />
          </div> */}
        {/* <div>
            <p className='text-[12px] text-primaryGray mt-3'>
              State/Province
            </p>
            <input
              type='text'
              className='regInput my-0 text-[#333333]'
              placeholder='Maharashtra'
              name='stateName'
              value={userDetail?.shippingAddress?.stateName || ''}
              onChange={(e) =>
                handleInputChangeNested(
                  'shippingAddress',
                  e.target.name,
                  e.target.value
                )
              }
            />
          </div> */}
        {/* </div> */}

        <div className='relative mt-[12px]'>
          <input
            type='text'
            // placeholder='Shaikh'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            placeholder='+91 7378666093'
            name='phoneNo'
            value={userDetail?.shippingAddress?.phoneNo || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'shippingAddress',
                e.target.name,
                e.target.value
              )
            }
            disabled
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            Phone
          </p>
        </div>

        {/* <p className='text-[12px] text-primaryGray mt-3'>Phone</p>
        <input
          type='text'
          className='regInput my-0 text-[#333333] bg-[#eeeeee]'
          placeholder='+91 7378666093'
          name='phoneNo'
          value={userDetail?.shippingAddress?.phoneNo || ''}
          onChange={(e) =>
            handleInputChangeNested(
              'shippingAddress',
              e.target.name,
              e.target.value
            )
          }
        /> */}

        <p className='text-[15px] font-medium text-primaryBlack mt-[32px]'>
          Residential Address <QuestionIcon className='inline' />
        </p>

        <div className=''>
          <label
            style={{
              width: 'fit-content',
            }}
            className='containerCheckGray text-[12px] text-primaryGray'
          >
            <input
              type='checkbox'
              value={isChecked}
              onChange={changeCheckBox}
              className='mr-[8px]'
              name=''
              id=''
            />{' '}
            My shipping and residential address are the same.
            <span className='checkmarkGray'></span>
          </label>
        </div>
        {/* <p className='text-[11px] text-primaryGray mt-[10px]'>
          <input
            value={isChecked}
            onChange={changeCheckBox}
            type='checkbox'
            className='mr-[8px]'
            name=''
            id=''
          />{' '}
          My shipping and residential address are the same.
        </p> */}

        <div className='relative mt-[12px]'>
          <input
            type='text'
            // placeholder='Shaikh'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            placeholder='India'
            name='countryName'
            value={userDetail?.residentialAddress?.countryName || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'residentialAddress',
                e.target.name,
                e.target.value
              )
            }
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            Location
          </p>
        </div>

        {/* <p className='text-[12px] text-primaryGray mt-[10px]'>
          Location
        </p>
        <input
          type='text'
          className='regInput my-0 text-[#333333]'
          placeholder='India'
          name='countryName'
          value={userDetail?.residentialAddress?.countryName || ''}
          onChange={(e) =>
            handleInputChangeNested(
              'residentialAddress',
              e.target.name,
              e.target.value
            )
          }
        /> */}

        <div className='relative mt-[12px]'>
          <input
            type='text'
            // placeholder='Shaikh'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            placeholder='S. No. 203/2A, Plot 16A, Rajiv Nagar (South),'
            name='addressLine1'
            value={userDetail?.residentialAddress?.addressLine1 || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'residentialAddress',
                e.target.name,
                e.target.value
              )
            }
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            Address line 1
          </p>
        </div>

        {/* <p className='text-[12px] text-primaryGray mt-3'>
          Address line 1
        </p>
        <input
          type='text'
          className='regInput my-0 text-[#333333]'
          placeholder='S. No. 203/2A, Plot 16A, Rajiv Nagar (South),'
          name='addressLine1'
          value={userDetail?.residentialAddress?.addressLine1 || ''}
          onChange={(e) =>
            handleInputChangeNested(
              'residentialAddress',
              e.target.name,
              e.target.value
            )
          }
        /> */}

        <div className='relative mt-[12px]'>
          <input
            type='text'
            // placeholder='Shaikh'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            placeholder='Viman Nagar,'
            name='addressLine2'
            value={userDetail?.residentialAddress?.addressLine2 || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'residentialAddress',
                e.target.name,
                e.target.value
              )
            }
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            Address line 2
          </p>
        </div>

        {/* <p className='text-[12px] text-primaryGray mt-3'>
          Address line 2
        </p>
        <input
          type='text'
          className='regInput my-0 text-[#333333]'
          placeholder='Viman Nagar,'
          name='addressLine2'
          value={userDetail?.residentialAddress?.addressLine2 || ''}
          onChange={(e) =>
            handleInputChangeNested(
              'residentialAddress',
              e.target.name,
              e.target.value
            )
          }
        /> */}

        <div className='relative mt-[12px]'>
          <input
            type='text'
            // placeholder='Shaikh'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            placeholder='Pune'
            name='cityName'
            value={userDetail?.residentialAddress?.cityName || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'residentialAddress',
                e.target.name,
                e.target.value
              )
            }
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            City
          </p>
        </div>

        {/* <p className='text-[12px] text-primaryGray mt-3'>City</p>
        <input
          type='text'
          className='regInput my-0 text-[#333333]'
          placeholder='Pune'
          name='cityName'
          value={userDetail?.residentialAddress?.cityName || ''}
          onChange={(e) =>
            handleInputChangeNested(
              'residentialAddress',
              e.target.name,
              e.target.value
            )
          }
        /> */}

        <div className='flex gap-[10px]'>
          <div className='relative mt-[12px] w-[100%]'>
            <input
              type='text'
              // placeholder='Shaikh'
              className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
              placeholder='411014'
              name='postalCode'
              value={userDetail?.residentialAddress?.postalCode || ''}
              onChange={(e) =>
                handleInputChangeNested(
                  'residentialAddress',
                  e.target.name,
                  e.target.value
                )
              }
            />

            <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
              Zip/Postal Code
            </p>
          </div>

          <div className='relative mt-[12px] w-[100%]'>
            <input
              type='text'
              // placeholder='Shaikh'
              className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
              placeholder='Maharashtra'
              name='stateName'
              value={userDetail?.residentialAddress?.stateName || ''}
              onChange={(e) =>
                handleInputChangeNested(
                  'residentialAddress',
                  e.target.name,
                  e.target.value
                )
              }
            />

            <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
              State/Province
            </p>
          </div>
        </div>

        {/* <div className='flex gap-5'>
          <div>
            <p className='text-[12px] text-primaryGray mt-3'>
              Zip/Postal Code
            </p>
            <input
              type='text'
              className='regInput my-0 text-[#333333]'
              placeholder='411014'
              name='postalCode'
              value={userDetail?.residentialAddress?.postalCode || ''}
              onChange={(e) =>
                handleInputChangeNested(
                  'residentialAddress',
                  e.target.name,
                  e.target.value
                )
              }
            />
          </div>
          <div>
            <p className='text-[12px] text-primaryGray mt-3'>
              State/Province
            </p>
            <input
              type='text'
              className='regInput my-0 text-[#333333]'
              placeholder='Maharashtra'
              name='stateName'
              value={userDetail?.residentialAddress?.stateName || ''}
              onChange={(e) =>
                handleInputChangeNested(
                  'residentialAddress',
                  e.target.name,
                  e.target.value
                )
              }
            />
          </div>
        </div> */}

        <div className='relative mt-[12px]'>
          <input
            type='text'
            // placeholder='Shaikh'
            className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
            placeholder='+91 7378666093'
            name='phoneNo'
            value={userDetail?.residentialAddress?.phoneNo || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'residentialAddress',
                e.target.name,
                e.target.value
              )
            }
            disabled
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            Phone
          </p>
        </div>

        {/* <p className='text-[12px] text-primaryGray mt-3'>Phone</p>
        <input
          type='text'
          className='regInput my-0 text-[#333333] bg-[#eeeeee]'
          placeholder='+91 7378666093'
          name='phoneNo'
          value={userDetail?.residentialAddress?.phoneNo || ''}
          onChange={(e) =>
            handleInputChangeNested(
              'residentialAddress',
              e.target.name,
              e.target.value
            )
          }
        /> */}

        <div className='flex gap-5 justify-center mt-[30px]'>
          <button
            onClick={updateUserMaster}
            className='blackBtn text-[14px] text-medium'
          >
            Save
          </button>
          <button className='outlineBtn40h'>Cancel</button>
        </div>
      </div>
    </>
  );
};

export default Contributor_Contact;
